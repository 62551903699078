import React, { Component } from 'react';
import $ from 'jquery';
import Navbar from '../../Config/Navbar';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import AttendanceReport from './trackingreport/attendance_report';
import VisitReport from './trackingreport/visit_report';
import ActivityReport from './trackingreport/activity_report'
import { withTranslation } from 'react-i18next';

class PeopleReport extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        var navBarHeight = $('.navBar').outerHeight()
        $('.people_report_parent').css({
            paddingTop: navBarHeight + 60,
        })
    }

    render() {
        return (
            <div>
                <Navbar />
                <div className="people_report_parent">
                    <Tabs>
                        <TabList style={{ textAlign: 'left' }}>
                            <Tab>Attendance</Tab>
                            <Tab>Visit</Tab>
                            <Tab>Activity</Tab>
                        </TabList>
                        <TabPanel>
                            <AttendanceReport />
                        </TabPanel>
                        <TabPanel>
                            <VisitReport />
                        </TabPanel>   
                        <TabPanel>
                            <ActivityReport />
                        </TabPanel>                        
                    </Tabs>
                </div>
            </div>
        );
    }
}

export default withTranslation()(PeopleReport);