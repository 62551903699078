// import React, { Component } from 'react';
// import $ from 'jquery';
// import './inviteuser.scss'
// import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
// import Grid from '@mui/material/Grid';
// import DemoUser from './demo_list';
// import ManagementUser from './management_list';
// import PickDropUser from './pick_drop_list';
// import ThirdPartyUser from './third_party_list'
// import Navbar from '../../Config/Navbar';
// import DriverLists from '../drivers/driverList';
// import ManagementUserList from '../peopletracking/usermanagement/management_user_list';

// import { withTranslation } from 'react-i18next';


// class UserTab extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {

//     }
//   }

//   componentDidMount() {
//     var navBarHeight = $('.navBar').outerHeight()
//     $('.tab_parent').css({
//       paddingTop: navBarHeight + 60,
//     })
//   }

  

//   render() {
//     const { t } = this.props

//     const { activeTab } = this.state; // Get active tab index

// const tabData = [
//   { label: t('ars.dr.driver'), component: <DriverLists data={this.props} /> },
//   { label: t('iv.pd'), component: <PickDropUser data={this.props} />, hide: localStorage.getItem('group') == 28 },
//   { label: t('iv.third'), component: <ThirdPartyUser data={this.props} /> },
//   { label: t('iv.demo'), component: <DemoUser data={this.props} />, hide: localStorage.getItem('group') == 28 },
//   { label: t('iv.management'), component: <ManagementUser data={this.props} /> },
//   { label: t('iv.pt'), component: <ManagementUserList data={this.props} /> }, // Person Tracking Tab
// ];

//     return (
//       <div>
//         <Navbar />
//         <div className="tab_parent">
//           <Grid container>
//             <Grid item xs className="tableHeading">
//               <label>{t('iv.header')}</label>
//             </Grid>


//             <Grid item xs>
//               {t('iv.pt') === tabData[activeTab]?.label ? (
//                 <button className="btn green-btn" onClick={() =>
//                   this.props.history.push({
//                     pathname: '/p_send_invite'
//                   })}>
//                   {t('iv.pt.btn')}
//                 </button>
//               ) : (
//                 <button className="btn orange-btn" onClick={() => {
//                   this.props.history.push({ pathname: '/create_user' });
//                 }}>
//                   {t('iv.invite.btn')}
//                 </button>
//               )}
//             </Grid>


//             {/* <Grid item xs>
//             <button className="btn orange-btn" onClick={() => {
//                 this.props.history.push({
//                     pathname : '/create_user'
//                 })
//             }}>{t('iv.invite.btn')}</button>
//         </Grid> */}


//           </Grid>
//           <Tabs>
//             <TabList style={{ textAlign: 'left' }}>

//               <Tab>{t('ars.dr.driver')}</Tab>

//               {/* <Tab>{t('iv.pd')}</Tab>        */}
//               <Tab style={{ display: localStorage.getItem('group') == 28 ? "none" : "" }}>{t('iv.pd')}</Tab>
//               <Tab>{t('iv.third')}</Tab>
//               {/* <Tab style={{display : localStorage.getItem('group') == 28 ? "none" : ""}}>{t('iv.third')}</Tab> */}
//               {/* <Tab>{t('iv.temp')}</Tab> */}
//               {/* <Tab>{t('iv.demo')}</Tab> */}
//               <Tab style={{ display: localStorage.getItem('group') == 28 ? "none" : "" }}>{t('iv.demo')}</Tab>
//               <Tab>{t('iv.management')}</Tab>
//               {/* <Tab>Special Report</Tab> */}
//               <Tab>{t('iv.pt')}</Tab>
//             </TabList>
//             <TabPanel>
//               <DriverLists data={this.props} />
//             </TabPanel>
//             <TabPanel>
//               <PickDropUser data={this.props} />
//             </TabPanel>
//             <TabPanel>
//               <ThirdPartyUser data={this.props} />
//             </TabPanel>
//             {/* <TabPanel>
//             <TemporaryUser/>
//           </TabPanel> */}
//             <TabPanel>
//               <DemoUser data={this.props} />
//             </TabPanel>
//             <TabPanel>
//               <ManagementUser data={this.props} />
//             </TabPanel>
//             <TabPanel>
//               <ManagementUserList data={this.props} />
//             </TabPanel>

//           </Tabs>
//         </div>
//       </div>
//     );
//   }
// }

// export default withTranslation()(UserTab);

import React, { Component } from 'react';
import $ from 'jquery';
import './inviteuser.scss';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Grid from '@mui/material/Grid';
import DemoUser from './demo_list';
import ManagementUser from './management_list';
import PickDropUser from './pick_drop_list';
import ThirdPartyUser from './third_party_list';
import Navbar from '../../Config/Navbar';
import DriverLists from '../drivers/driverList';
import ManagementUserList from '../peopletracking/usermanagement/management_user_list';

import { withTranslation } from 'react-i18next';

class UserTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0, // Initialize activeTab to track selected tab
    };
  }

  componentDidMount() {
    var navBarHeight = $('.navBar').outerHeight();
    $('.tab_parent').css({
      paddingTop: navBarHeight + 60,
    });
  }

  render() {
    const { t } = this.props;
    const { activeTab } = this.state;

    const tabData = [
      { label: t('ars.dr.driver'), component: <DriverLists data={this.props} /> },
      { label: t('iv.pd'), component: <PickDropUser data={this.props} />, hide: localStorage.getItem('group') == 28 },
      { label: t('iv.third'), component: <ThirdPartyUser data={this.props} /> },
      { label: t('iv.demo'), component: <DemoUser data={this.props} />, hide: localStorage.getItem('group') == 28 },
      { label: t('iv.management'), component: <ManagementUser data={this.props} /> },
      { label: t('iv.pt'), component: <ManagementUserList data={this.props} />, hide: localStorage.getItem('group') != 37}, // Person Tracking Tab
    ];

    const visibleTabs = tabData.filter(tab => !tab.hide); // Remove hidden tabs

    return (
      <div>
        <Navbar />
        <div className="tab_parent">
          <Grid container>
            <Grid item xs className="tableHeading">
              <label>{t('iv.header')}</label>
            </Grid>

            <Grid item xs>
              {t('iv.pt') === visibleTabs[activeTab]?.label ? (
                <button className="btn orange-btn" onClick={() => this.props.history.push({ pathname: '/p_send_invite' })}>
                  {t('iv.pt.btn')}
                </button>
              ) : (
                <button className="btn orange-btn" onClick={() => this.props.history.push({ pathname: '/create_user' })}>
                  {t('iv.invite.btn')}
                </button>
              )}
            </Grid>
          </Grid>

          <Tabs 
            selectedIndex={activeTab} 
            onSelect={(index) => this.setState({ activeTab: index })}
          >
            <TabList style={{ textAlign: 'left' }}>
              {visibleTabs.map(tab => (
                <Tab key={tab.label}>{tab.label}</Tab>
              ))}
            </TabList>

            {visibleTabs.map(tab => (
              <TabPanel key={tab.label}>{tab.component}</TabPanel>
            ))}
          </Tabs>
        </div>
      </div>
    );
  }
}

export default withTranslation()(UserTab);

