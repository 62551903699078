import React, { Component } from 'react'
import $ from 'jquery';
import './dashboard_content.scss'
import Grid from '@mui/material/Grid';
import Notifications from '../notification/notification';
import PeopleNotifications from '../notification/people_notification';
import {
  FleetSummary1,
  MapView1,
  OverallVehicleHealth1,
  DriverEfficiency1,
  VehicleUsage1,
  VehicleFitness1,
  TodaysJourney1,
  VehicleDocument1,
  FuelDetails1,
  FuelConsumed1,
  VehicleStat1

} from '../widgets/widget';
import PeopleTrackingMapView from '../peopletracking/dashboard_map'
import Navbar from '../../Config/Navbar';
import ApiUrlCall from '../API/apiurl';
import CommonFunction from '../common_function'
import analytics from '../../Config/firebase'
import Amplify, { Analytics } from 'aws-amplify';
import awswebapp from '../../aws-exports';
import constant from '../constant.json';
import { withTranslation } from 'react-i18next';
import Skeleton from '@mui/lab/Skeleton';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import { MDBDataTable } from 'mdbreact';


// import packageJson from '../../../package.json';

const ONGOING_ONTIME = 2
const ONGOING_DELAY = 3
const FleetSummary_widget = "FleetSummary"
const MapView_widget = "MapView"

const DGWidgetnew_widget = "DGWidgetnew"
const OverallVehicleHealth_widget = "OverallVehicleHealth"
const DriverEfficiency_widget = "DriverEfficiency"
const VehicleUsage_widget = "VehicleUsage"
const VehicleFitness_widget = "VehicleFitness"
const TodaysJourney_widget = "TodaysJourney"
const VehicleDocument_widget = "VehicleDocument"
const FuelDetails_widget = "FuelDetails"
const FuelConsumed_widget = "FuelConsumed"
const VehicleStat_widget = "VehicleStat"
// const { t, i18n } = useTranslation()



const data1 = [
  {
    label: 'VEHICLE NAME',
    field: 'vehicle_name',
    sort: 'asc',
    width: 150,
    attributes: {
      'aria-controls': 'DataTable',
      'aria-label': 'Name',
    },
  },
  {
    label: 'STATUS',
    field: 'status',
    sort: 'asc',
    width: 150,
  },
  {
    label: 'ACTION',
    field: 'action',
    sort: 'asc',
    width: 150,
  }
]

const data2 = [
  {
    label: 'PLAN',
    field: 'checkbox',
    sort: 'asc',
    width: 150,
  },
  {
    label: 'DURATION',
    field: 'duration',
    sort: 'asc',
    width: 150,
  },
  {
    label: 'PRICING',
    field: 'price',
    sort: 'asc',
    width: 150,
  }
]

class DashboardContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notiDivHeight: '',
      noWidgetView: true,
      showLayoutID: '',
      CompWidget: '',
      ongoingJourney: '',
      chunkData: [],
      noOngoingJourney: false,
      layoutWidget: '',
      nextCount: 0,
      nextData: true,
      totalCount: true,
      demoEmail: '',
      invalidEmail: false,
      DemovalidEmail: false,
      attendanceData: '',
      visitData: '',
      userCountData: '',
      voluntaryData: '',


      expired: false,
      vehicleList: [],
      renewalPlanList: [],
      datatable: [],
      datatable1: [],
      currentPlanDetails: "",
      currentSerialNo: "",
      skuId: "",
      amount: 0,
      totalVehicle: 0,

      userName: "",
      mobileNo: "",
      emailId: "",
    }
    this.isOnboarding = this.isOnboarding.bind(this)
    this.onGoingJourney = this.onGoingJourney.bind(this)
  }

  componentWillMount() {
    this.removeStringUrl()
  }

  componentDidMount() {
    localStorage.setItem('cache focus date', new Date())
    analytics.logEvent('screen_view', {
      screen_name: "Dashboard",
      user_id: localStorage.getItem("user_id")
    })
    analytics.logEvent('page_view', {
      page_title: "Dashboard",
    })
    Amplify.configure(awswebapp);
    Analytics.record({
      name: constant.awsName,
      // Attribute values must be strings
      attributes: { screen_name: 'WEB_DASHBOARD' }
    });
    var navBarHeight = $('.navBar').height()
    var widgetHeight = $(window).height() - navBarHeight - $('.ongoingJourney').outerHeight()
    var notiDiv = $('.noti-card').outerHeight()
    $('.ongoingJourney').css({
      paddingTop: 125,
    })
    this.setState({ notiDivHeight: notiDiv - 170 })
    // ~~~ Commented by @devang on 201207 - Functionality to get email address of demo user disabled.
    //this.demoEmailCheck()   
    setTimeout(() => {
      this.checkEndpointAws()
    }, 2000);

    //localStorage.setItem('cache focus date',new Date())


    if (localStorage.getItem("default_view") == constant.people_tracking) {
      // console.log('1111111')
      this.todayAttendance()
      this.todayPlannedVisit()
      this.todayUserCount()
      this.todayVoluntaryVisit()
    } else {
      // console.log('2222222')
      //this.onGoingJourney()
      this.getVehicleList()
      this.isOnboarding()
    }

  }


  demoEmailCheck = () => {
    var isDemo = localStorage.getItem('is_demo')
    if (isDemo) {
      ApiUrlCall.getUserProfile().then(response => {
        if (response.data.response_code && response.data.is_success) {
          // console.log('check email', response.data.data.profile_basics.email)
          if (!response.data.data.profile_basics.email) {
            $('#demo_popup').slideDown()
          }
        }
      }).catch(error => {
        console.log('error while fetching email data', error)
      })
    }
  }

  validateEmail = (inputValue) => {
    var reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (inputValue.match(reg)) {
      return true;
    } else {
      return false;
    }
  }

  demoUserEmail = (e) => {
    var email = e.currentTarget.value
    this.setState({
      demoEmail: email,
      invalidEmail: false
    })
  }

  submitDemoEmail = () => {
    const { demoEmail } = this.state
    var validEmail = this.validateEmail(demoEmail)
    if (validEmail) {
      let data = { email: demoEmail }
      ApiUrlCall.updateEmail(JSON.stringify(data)).then(response => {
        // console.log('response is', response)
        if (response.data.response_code === 200 && response.data.is_success) {
          this.setState({
            invalidEmail: false,
            DemovalidEmail: true
          })
        }
      }).catch(error => {
        console.log('error while sending email', error)
      })
    } else {
      this.setState({
        invalidEmail: true,
      })
    }
  }


  checkEndpointAws = () => {
    var is_endpoint = localStorage.getItem("is_endpoint")
    var endpointId = localStorage.getItem("endpoint_id")
    if (!JSON.parse(is_endpoint)) {
      // console.log('check point aws')
      var data = { endpoint_id: endpointId }
      ApiUrlCall.updateEndpointServer(JSON.stringify(data)).then(response => {
        if (response.data.response_code === 200 && response.data.is_success) {
          localStorage.setItem("is_endpoint", true)
        }
      }).catch(error => {
        console.log('error while updating endpoint', error)
      })
    } else {
      // console.log('is end point true')
    }
  }

  removeStringUrl = () => {
    var uri = window.location.toString();
    if (uri.indexOf("?") > 0) {
      var clean_uri = uri.substring(0, uri.indexOf("?"));
      window.history.replaceState({}, document.title, clean_uri);
    }
  }

  apendComponent = () => {
    const { showLayoutID } = this.state
    if (showLayoutID !== undefined) {
      var layoutArray = []
      switch (showLayoutID) {
        case "ld_1":
          layoutArray.push(
            <div className="ld_1" style={{ display: "none" }}>
              <Grid item xs spacing={3} container>
                <Grid item xs={6} className="half_widget">
                  {this.state.CompWidget[0]}
                </Grid>
                <Grid item xs={6} className="half_widget">
                  {this.state.CompWidget[1]}
                </Grid>
                <Grid item xs={6} className="half_widget">
                  {this.state.CompWidget[2]}
                </Grid>
                <Grid item xs={6} className="half_widget">
                  {this.state.CompWidget[3]}
                </Grid>
              </Grid>
            </div>
          )
          break;
        case "ld_2":
          layoutArray.push(
            <div className="ld_2" style={{ display: "none", width: "100%" }}>
              <Grid spacing={3} container>
                <Grid item xs={6} className="full_widget">
                  {this.state.CompWidget[0]}
                </Grid>
                <Grid item spacing={3} xs={6} container>
                  <Grid item xs={12} className="half_widget">
                    {this.state.CompWidget[1]}
                  </Grid>
                  <Grid item xs={12} className="half_widget">
                    {this.state.CompWidget[2]}
                  </Grid>
                </Grid>
              </Grid>
            </div>
          )
          break;
        case "ld_3":
          layoutArray.push(
            <div className="ld_3" style={{ display: "none" }}>
              <Grid item xs spacing={3} container>
                <Grid item xs={6} className="full_widget">
                  {this.state.CompWidget[0]}
                </Grid>
                <Grid item xs={6} className="full_widget">
                  {this.state.CompWidget[1]}
                </Grid>
              </Grid>
            </div>
          )
          break;
        case "ld_4":
          layoutArray.push(
            <div className="ld_4" style={{ display: "none" }}>
              <Grid item xs spacing={3} container>
                <Grid item xs={12} className="half_widget">
                  {this.state.CompWidget[0]}
                </Grid>
                <Grid item xs={12} className="half_widget">
                  {this.state.CompWidget[1]}
                </Grid>
              </Grid>
            </div>
          )
          break;
        case "ld_5":
          layoutArray.push(
            <div className="ld_5" style={{ display: "none" }}>
              <Grid item xs spacing={3} container>
                <Grid item xs={12} className="half_widget">
                  {this.state.CompWidget[0]}
                </Grid>
                <Grid item xs={6} className="half_widget">
                  {this.state.CompWidget[1]}
                </Grid>
                <Grid item xs={6} className="half_widget">
                  {this.state.CompWidget[2]}
                </Grid>
              </Grid>
            </div>
          )
          break;
        case "ld_6":
          layoutArray.push(
            <div className="ld_6" style={{ display: "none" }}>
              <Grid item xs spacing={3} container>
                <Grid item xs={12} className="full_widget">
                  {this.state.CompWidget[0]}
                </Grid>
              </Grid>
            </div>
          )
          break;
        default:
          break;
      }
      if (layoutArray.length > 0) {
        this.setState({
          layoutWidget: layoutArray
        })
      }
    }
  }

  isOnboarding = () => {
    const user_data = { user_id: localStorage.getItem('user_id'), token: localStorage.getItem('token') }
    ApiUrlCall.getLayout(user_data).then(response => {
      if (response.status === 200) {
        // console.log('response is', response)
        // console.log("response of onbording>>>>>>>>>>>>",typeof CompWidget); 
        var arrayOfWidget = response.data.widget_ids.split(",")
        var pushComponent = []
        arrayOfWidget.map(item => {
          // console.log(item, "items");
          switch (item) {
            case FleetSummary_widget:
              pushComponent.push(<FleetSummary1 data={this.props} />)
              break;
            case MapView_widget:
              pushComponent.push(<MapView1 data={this.props} />)
              break;
            case OverallVehicleHealth_widget:
              pushComponent.push(<OverallVehicleHealth1 data={this.props} />)
              break;
            case DriverEfficiency_widget:
              pushComponent.push(<DriverEfficiency1 data={this.props} />)
              break;
            case VehicleDocument_widget:
              pushComponent.push(<VehicleDocument1 data={this.props} />)
              break;
            case VehicleFitness_widget:
              pushComponent.push(<VehicleFitness1 data={this.props} />)
              break;
            case VehicleUsage_widget:
              pushComponent.push(<VehicleUsage1 data={this.props} />)
              break;
            case TodaysJourney_widget:
              pushComponent.push(<TodaysJourney1 data={this.props} />)
            case FuelDetails_widget:
              pushComponent.push(<FuelDetails1 data={this.props} />)
              break;
            case FuelConsumed_widget:
              pushComponent.push(<FuelConsumed1 data={this.props} />)
              break;
            case VehicleStat_widget:
              pushComponent.push(<VehicleStat1 data={this.props} />)
              break;
            default:
              break;
          }
        })
        this.setState({
          noWidgetView: false,
          showLayoutID: response.data.layout_id,
          CompWidget: pushComponent
        }, () => {
        })
        const showDiv = document.getElementsByClassName(this.state.showLayoutID)
        this.apendComponent()
        $(showDiv).show()
      } else {
        // console.log("response of onbording<<<<<<<<<<<<"); 
      }
    }).catch(error => {
      this.setState({ noWidgetView: true })
      // if(error.response.status === 404){
      // }else{
      //   alert("something went wrong")
      // }
      // console.log("error is", error.response.status);      
    })
  }

  chunckData = () => {
    const { ongoingJourney, chunkData } = this.state
    if (ongoingJourney.length > 0) {
      for (let i = 0; i < ongoingJourney.length; i++) {
        const element = ongoingJourney[i];
        chunkData.push(element)
      }
      this.forceUpdate()
    }
  }

  //////////////////////// subscription start ///////////////////////////////////////////


  getVehicleList = () => {
    $('#processing').show()
    var data = {}
    // var start = new Date().getTime();
    ApiUrlCall.getVehcile_Name_latest(JSON.stringify(data)).then(response => {
      if (response.data.response_code === 200 && response.data.is_success === true) {
        //console.log(response.data.total_count)
        $('#processing').hide()
        // var end = new Date().getTime();
        // var time = end - start;
        // var min = Math.floor((time/1000/60) << 0)
        // var sec = Math.floor((time/1000) % 60);
        // alert('Execution time: ' + min +" min : "+ sec +" sec : ");
        response.data.data.vehicle_list.map(data => {
          //console.log(data)
          if (data.is_expired === true) {
            this.setState({
              expired: true,
              totalVehicle: response.data.total_count,
            })
            this.state.vehicleList.push(data)
            $('#processing').hide()
          }
        })
        this.checkExpired()
      }
    }).catch(error => {
      $('#processing').hide()
      console.log(error, "error while fetching vehicle list");
    })
  }

  checkExpired = () => {
    if (this.state.expired) {
      this.onGoingJourney()
      this.createTableData()
    }
    else {
      this.onGoingJourney()
    }
  }


  modalClose = () => {
    $('#service_expired').fadeOut("slow")
    $('html, body').css({
      overflow: 'auto',
      height: '100%'
    });
  }

  modalClose1 = () => {
    $('#current_plan').fadeOut("slow")
    $('html, body').css({
      overflow: 'auto',
      height: '100%'
    });
  }

  modalClose2 = () => {
    $('#renewal_plan').fadeOut("slow")
    $('html, body').css({
      overflow: 'auto',
      height: '100%'
    });
  }

  serviceStatus = (status) => {
    if (status === true) {
      return (
        <p className="cj_w_header" style={{ color: "#28a745" }}>Expired</p>
      )
    } else {
      return (
        <p className="cj_w_header" style={{ color: "#ed7d28" }}>Live</p>
      )
    }
  }

  createTableData = () => {
    var row = []
    const { vehicleList, datatable } = this.state
    if (vehicleList) {
      for (let i = 0; i < vehicleList.length; i++) {
        const element = vehicleList[i];
        var row_data = {
          vehicle_name: element.name,
          status: this.serviceStatus(element.is_expired),
          action: <p className="cj_w_header" style={{ color: '#f4811f', cursor: "pointer" }} onClick={() => this.showCurrentPlan(element.serial_number)}>Show plan</p>
        }
        datatable.push(row_data)
      }
      this.forceUpdate()
    }
  }

  createTableDataForRenewalPlan = () => {
    var row = []
    const { renewalPlanList, datatable1 } = this.state
    renewalPlanList.sort(function (a, b) { return (a.month > b.month) ? 1 : ((b.month > a.month) ? -1 : 0); });
    if (renewalPlanList) {
      for (let i = 0; i < renewalPlanList.length; i++) {
        const element = renewalPlanList[i];
        var row_data = {
          checkbox: <input type="checkbox" id={element.skuId} className="disabled_checkboxes" onChange={(e) => this.onRadioClick(e, element.sku_id, element.price)}></input>,
          duration: element.month + "Months",
          price: element.price,
        }
        datatable1.push(row_data)
      }
      this.forceUpdate()
    }

  }

  onRadioClick = (e, sku_id, amount) => {
    if (e.target.checked) {
      this.setState({
        skuId: sku_id,
        amount: amount,
      })
      $(".disabled_checkboxes").each(function (index) {
        var element = $(".disabled_checkboxes")[index]
        if ($(element).is(":checked") == false) {
          $(element).prop("disabled", true)
        }
      })
    }
    else {
      $(".disabled_checkboxes").each(function (index) {
        var element1 = $(".disabled_checkboxes")[index]
        if ($(element1).is(":checked") == false) {
          $(element1).prop("disabled", false)
        }
      })
    }

  }


  payNowClick = () => {
    $('#processing').show()

    const data = {
      serial_number: this.state.currentSerialNo,
      amount: this.state.amount,
      sku_id: this.state.skuId,
    }
    if (this.state.amount == 0) {
      alert("Please select plan")
      $('#processing').hide()
      return
    }
    ApiUrlCall.payNowClick(JSON.stringify(data)).then(response => {

      if (response.data.response_code === 200 && response.data.is_success === true) {
        $('#processing').hide()
        this.setState({
          userName: response.data.data.name,
          mobileNo: response.data.data.mobile_number,
          emailId: response.data.data.email,
          env: response.data.data.env,
          request_source: response.data.data.request_source,
          client_url: response.data.data.client_url,
          city: response.data.data.city,
          zipcode: response.data.data.zipcode,
          state: response.data.data.state,
          redirect_url: response.data.data.redirect_url,
          address: response.data.data.address,
          transaction_id: response.data.data.transaction_id,
          type: response.data.data.type,
        })

        $("#renewal_plan").hide()
        $("#current_plan").hide()
        setTimeout(() => {
          $("#pay_now").click()
        }, 300);

      }
    }).catch(error => {
      $('#processing').hide()
      console.log(error, "error while payment");
    })
  }


  showCurrentPlan = (serialNo) => {
    const data = { serial_number: serialNo }
    $('#processing').show()
    ApiUrlCall.showCurrentPlanDetails(JSON.stringify(data)).then(response => {
      if (response.data.response_code === 200 && response.data.is_success === true) {
        //console.log(response,"res")
        $('#processing').hide()
        this.setState({
          currentPlanDetails: response.data.data.history.slice(-1)[0],
          currentSerialNo: serialNo,
          renewalPlanList: [],
        }, () => {
          if (this.state.currentPlanDetails.plan > 12) {
            this.setState({
              planDuration: this.state.currentPlanDetails.plan / 12 + " years"
            })
          }
          else {
            this.setState({
              planDuration: this.state.currentPlanDetails.plan + " months"
            })
          }
        })
        $("#current_plan").show()
      }
    }).catch(error => {
      $('#processing').hide()
      console.log(error, "error while fetching current plan");
    })
  }

  showrenewPlan = () => {
    $('#processing').show()
    var data = { serial_number: this.state.currentSerialNo }
    ApiUrlCall.showRenewalPlan(JSON.stringify(data)).then(response => {
      if (response.data.response_code === 200 && response.data.is_success === true) {
        $('#processing').hide()
        this.setState({
          renewalPlanList: response.data.data.plan_list,
          datatable1: [],
        }, () => {
          this.createTableDataForRenewalPlan()
        })
        $("#current_plan").hide()
        $("#renewal_plan").show()
      }
    }).catch(error => {
      $('#processing').hide()
      console.log(error, "error while fetching renewal plan");
    })
  }


  onGoingJourney = () => {
    //$('#processing').show()
    // const user_data = {user_id : localStorage.getItem('user_id'), token : localStorage.getItem('token')}  
    // console.log('ongoing user data is', user_data)  
    var data = { start_date: "", end_date: "", status: 2, next_count: this.state.nextCount, search: "" }
    ApiUrlCall.getJourneyList(JSON.stringify(data)).then(response => {
      // console.log('ongoing journey data', response);
      // return
      if (response.data.response_code === 200 && response.data.is_success) {
        // $('#processing').hide()
        this.setState({
          ongoingJourney: response.data.data.journey_list,
          noOngoingJourney: false,
          nextCount: response.data.data.next_count,
          nextData: response.data.data.has_data,
          totalCount: response.data.data.total_count
        }, () => {
          this.chunckData()
          if (this.state.nextCount === this.state.totalCount) {
            $('#skeleton-div').hide()
          }
        })
      }
    }).catch(error => {
      $('#processing').hide()
      $('#skeleton-div').hide()
      console.log('>>>>>>>>>>>> error while fetching ongoing data', error)
    })
  }



  //////////////////////////////subscription end //////////////////////////////////////////////




  setLayoutWidget = () => {
    this.props.history.push('/dashboard_setup');
  }

  // componentUnmount(){
  //   console.log('component unmounted')
  // }
  uniqueWidget = (id) => {
    // console.log('journey id', id)
    this.props.history.push({
      pathname: '/journeyOverview',
      state: { journey_id: id }
    })
  }

  scrollForward = (e) => {
    e.preventDefault();
    const { nextCount, totalCount } = this.state
    $('.dx').animate({
      scrollLeft: "+=200px"
    }, "slow");
    // var last_div = $('.journeyDiv').last().addClass('last_ongoing')
    // console.log('last div', last_div)    

    // var scroll = $('.dx').scrollLeft();
    if (localStorage.getItem('default_view') !== constant.people_tracking) {
      var element = $('#skeleton-div').offset().left
      var scroll_width = $('.dx').width()
      if (element <= scroll_width) {
        if (nextCount !== totalCount) {
          // console.log('more data is present')        
          this.onGoingJourney()
        }
      }
    }
  }



  //people tracking functions

  todayAttendance = () => {
    ApiUrlCall.ptAttendanceDashboard().then(response => {
      $('#today_attendance').hide()
      if (response.data.response_code == 200 && response.data.is_success) {
        this.setState({
          attendanceData: response.data.data
        })
      } else if (response.data.response_code == 410 && !response.data.is_success) {
        if ($('#pplt_expire_dialog').is(':hidden')) {
          $('#pplt_expire_dialog').slideDown()
        }
      }
    }).catch(error => {
      $('#today_attendance').hide()
      console.log('error while fetching attendance', error)
    })
  }
  todayVoluntaryVisit = () => {
    ApiUrlCall.ptVoluntaryDashboard().then(response => {
      // console.log('response', response)
      $('#voluntary_visit').hide()
      if (response.data.response_code == 200 && response.data.is_success) {
        this.setState({
          voluntaryData: response.data.data
        })
      } else if (response.data.response_code == 410 && !response.data.is_success) {
        if ($('#pplt_expire_dialog').is(':hidden')) {
          $('#pplt_expire_dialog').slideDown()
        }
      }
    }).catch(error => {
      $('#voluntary_visit').hide()
      console.log('error while fetching voluntary vist', error)
    })
  }
  todayPlannedVisit = () => {
    ApiUrlCall.ptVisitDashboard().then(response => {
      $('#planned_visit').hide()
      if (response.data.response_code == 200 && response.data.is_success) {
        this.setState({
          visitData: response.data.data
        })
      } else if (response.data.response_code == 410 && !response.data.is_success) {
        if ($('#pplt_expire_dialog').is(':hidden')) {
          $('#pplt_expire_dialog').slideDown()
        }
      }
    }).catch(error => {
      $('#planned_visit').hide()
      console.log('error while fetching planned visit', error)
    })
  }
  todayUserCount = () => {
    const apicall = localStorage.getItem('group')==37 ? ApiUrlCall.ptUserStatusDashboardPT :ApiUrlCall.ptUserStatusDashboard;
    apicall().then(response => {
      console.log('user count pplt', response)
      $('#user_count').hide()
      if (response.data.response_code == 200 && response.data.is_success) {
        this.setState({
          userCountData: response.data.data
        })
      } else if (response.data.response_code == 410 && !response.data.is_success) {
        if ($('#pplt_expire_dialog').is(':hidden')) {
          $('#pplt_expire_dialog').slideDown()
        }
      }
    }).catch(error => {
      $('#user_count').hide()
      console.log('error while fetching user count', error)
    })
  }


  render() {

    // $(window).on("blur focus", function(e) {
    //   var prevType = $(this).data("prevType");

    //   if (prevType != e.type) {   //  reduce double fire issues
    //       switch (e.type) {
    //           case "blur":
    //               console.log("blur",Date)
    //               break;
    //           case "focus":
    //               console.log("focus")
    //               break;
    //       }
    //   }
    //   $(this).data("prevType", e.type);
    // })
    const { t } = this.props;
    const { attendanceData, visitData, voluntaryData, userCountData } = this.state
    if (this.state.ongoingJourney.length > 0) {
      var countOfJourney = (() => {
        if (this.state.chunkData.length <= 9) {
          return '0' + this.state.chunkData.length
        } else {
          return this.state.chunkData.length
        }
      })()
      // console.log(localStorage.getItem('is_people_tracking') + ">>>>>>>>>>>>>>>>>>>>>>>>>")
      // console.log('this state ongoing', this.state.ongoingJourney)
      var ongoingWidget = this.state.chunkData.map(journeydata => {
        // console.log("====================>>>>", journeydata)
        var vehicleName = journeydata.journey_vehicle_detail.map(vehicleName => {
          if (vehicleName.vehicle) {
            return vehicleName.vehicle.name
          }
        })
        var DriverName = journeydata.journey_vehicle_detail.map(vehicleName => {
          return vehicleName.assigned_to.driver_name
        })
        return (
          <div className="journeyDiv" key={journeydata.id}>

            <Grid container>
              <Grid container item xs={8}>
                <Grid item xs={2}>
                  <img src="images/navgation.png" alt="icon"></img>
                </Grid>
                <Grid item xs={10} className="j_address_div">
                  <p>{CommonFunction.tableAddressEllipsis(journeydata.planned_start_loc, 15)}</p>
                  <p>{CommonFunction.tableAddressEllipsis(journeydata.planned_end_loc, 15)}</p>
                </Grid>
              </Grid>
              <Grid item xs={4} style={{ textAlign: "right" }}>
                <span className="journey_status" style={{ display: journeydata.moving_status === 2 ? '' : 'none', color: "#28a745" }} >{t('ds.ontime')}&nbsp;&nbsp;<i className="fa fa-lg fa-clock-o" aria-hidden="true"></i></span>
                <span className="journey_status" style={{ display: journeydata.moving_status === 3 ? '' : 'none', color: "#dc3545" }}>{t('ds.delay')}&nbsp;&nbsp;<i className="fa fa-lg fa-clock-o" aria-hidden="true"></i></span>
              </Grid>
            </Grid>
            <hr className="custom-hr" style={{ marginTop: "0px" }} />
            <div>
              <i><img src="images/vehicle-1.png" alt="icon" height="35px"></img></i>&nbsp;&nbsp;<span className="j_vehicle_name">{vehicleName[0]}</span>
            </div>
            <Grid container style={{ marginTop: "20px" }}>
              <Grid item xs>
                <i><img src="images/group-8.png" alt="icon"></img></i>&nbsp;&nbsp;<span className="j_driver_name">{DriverName[0]}</span>
              </Grid>
              <Grid item xs style={{ textAlign: "right" }}>
                <div className="arrow_button" onClick={() => this.uniqueWidget(journeydata.id)}><img src="images/white_arrow.png" alt=""></img></div>
              </Grid>
            </Grid>
          </div>
        )
      })
    }


    return (
      <div>
        <div>
          <Navbar />
        </div>

        <div id="service_expired" className="p_modal_parent1" style={{ display: this.state.expired ? " " : "None" }}>
          <div className="p_modal_boday1">
            <div className="close_modal" onClick={this.modalClose}>
              <i className="fa fa-close" aria-hidden="true"></i>
            </div>
            <p className="p_header" style={{ textAlign: "center", color: "red" }}>
              <img src="images/session-expired.png" width="50" height="40" alt="image_alt_tag" /> Service Expired
            </p>
            <MDBDataTable
              displayEntries={false}
              paging={false}
              searching={false}
              scrollY
              entries={5}
              striped
              bordered
              hover
              data={{ columns: data1, rows: this.state.datatable }}
            />

            {/* /////////////Current Plan//////////////// */}
            <div className="p_modal_boday" id="current_plan" style={{ display: "None" }}>
              <div className="close_modal" onClick={this.modalClose1}>
                <i class="fa fa-close" aria-hidden="true"></i>
              </div>
              <p className="p_header" style={{ textAlign: "center" }}>Current Plan</p>
              <hr />
              <p className="cj_w_header"><label>Purchased Plan : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label> {this.state.planDuration}</p>
              <p className="cj_w_header"><label>Installation Date : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label> {CommonFunction.tableDateFormate(this.state.currentPlanDetails.installation_date)}</p>
              <p className="cj_w_header"><label>Service Start Date : &nbsp;&nbsp;</label> {CommonFunction.tableDateFormate(this.state.currentPlanDetails.service_start_date)}</p>
              <p className="cj_w_header"><label>Service End Date : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>{CommonFunction.tableDateFormate(this.state.currentPlanDetails.service_end_date)}</p>
              <p className="cj_w_header"><label>Renewal Due Date : &nbsp;&nbsp;</label> {CommonFunction.tableDateFormate(this.state.currentPlanDetails.renewal_date)}</p>
              <button className="btn orange-btn" onClick={this.showrenewPlan}>Renew Subcription</button>
            </div>
            {/* /////////////END Current plan//////////////// */}


            {/* /////////////Renewal Plan//////////////// */}
            <div className="p_modal_boday" id="renewal_plan" style={{ display: "None" }}>
              <div className="close_modal" onClick={this.modalClose2}>
                <i class="fa fa-close" aria-hidden="true"></i>
              </div>
              <p className="p_header" style={{ textAlign: "center" }}>Know Your Plan</p>
              <MDBDataTable
                displayEntries={false}
                paging={false}
                searching={false}
                sorting={true}
                scrollY
                entries={5}
                striped
                bordered
                // hover
                noBottomColumns={true}
                data={{ columns: data2, rows: this.state.datatable1 }}
              />
              <p className="p_header" style={{ color: '#f4811f', cursor: "pointer", marginTop: "10px", textAlign: "center" }} onClick={() => this.payNowClick()}>Pay Now</p>
            </div>
            {/* /////////////END Renewal Plan//////////////// */}

            {/* Send data to payment side start */}

            <div className="p_modal_boday" style={{ display: "None" }}>
              <form action="https://payments.matchpointgps.in/" method="post" >
                {/* <p>Name:</p> */}
                <input
                  type='hidden'
                  name="name"
                  value={this.state.userName}
                />
                {/* <p>Mobile Number:</p> */}
                <input
                  type='hidden'
                  name='mobile_number'
                  value={this.state.mobileNo}
                />
                {/* <p>Email Id:</p> */}
                <input
                  type='hidden'
                  name="email"
                  value={this.state.emailId}
                />
                {/* <p>Amount:</p> */}
                <input
                  type='hidden'
                  name="amount"
                  value={this.state.amount}
                />
                <input
                  type='hidden'
                  name="env"
                  value={this.state.env}
                />
                <input
                  type='hidden'
                  name="request_source"
                  value={this.state.request_source}
                />
                <input
                  type='hidden'
                  name="client_url"
                  value={this.state.client_url}
                />
                <input
                  type='hidden'
                  name="city"
                  value={this.state.city}
                />
                <input
                  type='hidden'
                  name="zipcode"
                  value={this.state.zipcode}
                />
                <input
                  type='hidden'
                  name="state"
                  value={this.state.state}
                />
                <input
                  type='hidden'
                  name="redirect_url"
                  value={this.state.redirect_url}
                />
                <input
                  type='hidden'
                  name="address"
                  value={this.state.address}
                />
                <input
                  type='hidden'
                  name="transaction_id"
                  value={this.state.transaction_id}
                />
                <input
                  type='hidden'
                  name="type"
                  value={this.state.type}
                />
                <input type="submit" id="pay_now" value="Pay Now" />
              </form>
            </div>

            {/* Send data to payment side end */}

          </div>
        </div>




        <div className="ongoingJourney" style={{ display: localStorage.getItem('default_view') !== constant.people_tracking ? "" : "none" }}>
          <h1>{t('ds.ongoing')}&nbsp;<span style={{ color: "#F0762B" }}>{t('ds.journey')}</span> - {countOfJourney}</h1>
          <div className="scroll_icon" >
            <img src="images/next.png" alt="icon" onClick={(e) => {
              e.preventDefault();
              $('.dx').animate({
                scrollLeft: "-=200px"
              }, "slow");
            }}></img>
            <img src="images/next-copy-2.png" alt="icon" onClick={this.scrollForward}></img>
          </div>
          {/* div with journeys start */}
          <div className="dx" style={{ display: this.state.noOngoingJourney ? 'none' : '' }}>
            {ongoingWidget}
            <div className="journeyDiv" id="skeleton-div">
              <Skeleton animation="wave" height={30} width="100%" style={{ marginBottom: 6 }} />
              <Skeleton animation="wave" height={30} width="100%" style={{ marginBottom: 6 }} />
              <hr className="custom-hr" style={{ marginTop: "0px" }} />
              <Skeleton animation="wave" height={30} width="100%" style={{ marginBottom: 6 }} />
              <Skeleton animation="wave" height={30} width="100%" style={{ marginBottom: 6 }} />
            </div>
          </div>
          {/* div with journeys end */}

          {/* div with empty journey start style={{display: this.state.noWidgetView ? '' : 'none'}*/}
          <div className="nj_parent" style={{ display: this.state.ongoingJourney.length > 0 ? 'none' : '' }}>
            <Grid container>
              <Grid item sm={2} lg={1} className="nj_image">
                <img src="images/group.png" alt="no journey" width="70px" height="70px"></img>
              </Grid>
              <Grid item xs className="nj_content">
                <b><p>{t('ds.noongoing.p1')}</p></b>
                <p>{t('ds.noongoing.p2')}</p>
              </Grid>
            </Grid>
          </div>
          {/* div with empty journey end */}
          <Grid container style={{ position: "absolute", bottom: "0", color: "#ffffff", paddingRight: "20px" }}>
            <Grid item xs={6}>
              {/* <p className="cj_w_header">
              <span style={{color : "#F0762B", cursor : "pointer", textDecoration : "underline"}}>Click here</span>&nbsp;
              for classic version of our Web application</p> */}
            </Grid>
            <Grid item xs={5} style={{ textAlign: "right" }}>
              <p className="cj_w_header">{t('ds.help')}? <span style={{ color: "#F0762B" }}>
                <i class="fa fa-volume-control-phone" aria-hidden="true"></i> 9029070083 (24x7)</span></p>
            </Grid>
          </Grid>

        </div>

        {/* people tracking UI */}
        <div className="ongoingJourney" style={{ display: localStorage.getItem('default_view') == constant.people_tracking ? "" : "none" }}>
          <h1 style={{ color: "#F0762B" }}>SUMMARY </h1>
          <div className="scroll_icon" >
            <img src="images/next.png" alt="icon" onClick={(e) => {
              e.preventDefault();
              $('.dx').animate({
                scrollLeft: "-=200px"
              }, "slow");
            }}></img>
            <img src="images/next-copy-2.png" alt="icon" onClick={this.scrollForward}></img>
          </div>
          {/* div with journeys start */}
          {/* <div className="dx" style={{ display: this.state.noOngoingJourney ? 'none' : '' }}>
            <div className="people_journeyDiv" style={{ width: "24vw", position: "relative" }}>
              <p className="p_header">Today's employees attendance</p>
              <Grid container>
                <Grid item xs style={{ borderRight: "1px solid #ccc" }}>
                  <p className="p_big_grey">{CommonFunction.zeroAppend(attendanceData.total)}</p>
                  <p className="p_header">Total</p>
                </Grid>
                <Grid item xs style={{ borderRight: "1px solid #ccc" }}>
                  <p className="p_big_grey">{CommonFunction.zeroAppend(attendanceData.present)}</p>
                  <p className="p_header">Present</p>
                </Grid>
                <Grid item xs>
                  <p className="p_big_grey">{CommonFunction.zeroAppend(attendanceData.absent)}</p>
                  <p className="p_header">Absent</p>
                </Grid>
              </Grid>
              <div className="ppl_loading" id="today_attendance">
                <div>
                  <CircularProgress />
                </div>
              </div>
            </div>
            {localStorage.getItem('group') != 37 && 
            <div className="people_journeyDiv" style={{position : "relative"}}>
              <p className="p_header">Today's Planned Visit</p>
              <Grid container>
                <Grid item xs style={{ borderRight: "1px solid #ccc" }}>
                  <p className="p_big_grey">{CommonFunction.zeroAppend(visitData.assigned)}</p>
                  <p className="p_header">Assingned</p>
                </Grid>
                <Grid item xs style={{ borderRight: "1px solid #ccc" }}>
                  <p className="p_big_grey">{CommonFunction.zeroAppend(visitData.ongoing)}</p>
                  <p className="p_header">ongoing</p>
                </Grid>
                <Grid item xs style={{ borderRight: "1px solid #ccc" }}>
                  <p className="p_big_grey">{CommonFunction.zeroAppend(visitData.completed)}</p>
                  <p className="p_header">completed</p>
                </Grid>
                <Grid item xs>
                  <p className="p_big_grey">{CommonFunction.zeroAppend(visitData.pending)}</p>
                  <p className="p_header">pending</p>
                </Grid>
              </Grid>
              <div className="ppl_loading" id="planned_visit">
                <div>
                  <CircularProgress />
                </div>
              </div>
            </div>}
            <div className="people_journeyDiv" style={{ width: "24vw", position : "relative" }}>
              <p className="p_header">Today's Users</p>
              <Grid container>
                <Grid item xs style={{ borderRight: "1px solid #ccc" }}>
                  <Tooltip title={<span style={{ fontSize: "15px" }}>Admin / Users</span>} placement="top">
                    <p className="p_big_grey"><span style={{ color: "#227cd0" }}>{CommonFunction.zeroAppend(userCountData.admin_total)}</span>/
                    <span style={{ color: "#dc3545" }}>{CommonFunction.zeroAppend(userCountData.users_total)}</span></p>
                  </Tooltip>
                  <p className="p_header">Total <span>({CommonFunction.zeroAppend(userCountData.total)})</span></p>
                </Grid>
                <Grid item xs style={{ borderRight: "1px solid #ccc" }}>
                  <Tooltip title={<span style={{ fontSize: "15px" }}>Admin / Users</span>} placement="top">
                  <p className="p_big_grey"><span style={{ color: "#227cd0" }}>{CommonFunction.zeroAppend(userCountData.admin_active)}</span>/
                  <span style={{ color: "#dc3545" }}>{CommonFunction.zeroAppend(userCountData.users_active)}</span></p>
                  </Tooltip>
                  <p className="p_header">Active <span>({CommonFunction.zeroAppend(userCountData.active)})</span></p>
                </Grid>
                <Grid item xs>
                  <Tooltip title={<span style={{ fontSize: "15px" }}>Admin / Users</span>} placement="top">
                  <p className="p_big_grey"><span style={{ color: "#227cd0" }}>{CommonFunction.zeroAppend(userCountData.admin_inactive)}</span>/
                  <span style={{ color: "#dc3545" }}>{CommonFunction.zeroAppend(userCountData.user_inactive)}</span></p>
                  </Tooltip>
                  <p className="p_header">Inactive <span>({CommonFunction.zeroAppend(userCountData.inactive)})</span></p>
                </Grid>
              </Grid>
              <div className="ppl_loading" id="user_count">
                <div>
                  <CircularProgress />
                </div>
              </div>
            </div>
            {localStorage.getItem('group') != 37 && 
            <div className="people_journeyDiv" style={{ width: "20vw", position : "relative", display : voluntaryData.admin == 0 && voluntaryData.users == 0 ? "none" : "" }}>
              <p className="p_header">Today's Voluntary Visit</p>
              <Grid container>
                <Grid item xs style={{ borderRight: "1px solid #ccc" }}>
                  <p className="p_big_grey">{CommonFunction.zeroAppend(voluntaryData.admin)}</p>
                  <p className="p_header" style={{ color: "#227cd0" }}>Admin</p>
                </Grid>
                <Grid item xs>
                  <p className="p_big_grey">{CommonFunction.zeroAppend(voluntaryData.users)}</p>
                  <p className="p_header" style={{ color: "#dc3545" }}>Users</p>
                </Grid>
              </Grid>
              <div className="ppl_loading" id="voluntary_visit">
                <div>
                  <CircularProgress />
                </div>
              </div>
            </div>}
          </div> */}

          <div className="dx" style={{ display: this.state.noOngoingJourney ? 'none' : 'flex', gap: '1vw', justifyContent: 'center' }}>

            {localStorage.getItem('group') != 37 && (
              <div className="people_journeyDiv" style={{ flex: 1, maxWidth: "30vw", position: "relative" }}>
                <p className="p_header">Today's Employees Attendance</p>
                <Grid container>
                  <Grid item xs style={{ borderRight: "1px solid #ccc" }}>
                    <p className="p_big_grey">{CommonFunction.zeroAppend(attendanceData.total)}</p>
                    <p className="p_header">Total</p>
                  </Grid>
                  <Grid item xs style={{ borderRight: "1px solid #ccc" }}>
                    <p className="p_big_grey">{CommonFunction.zeroAppend(attendanceData.present)}</p>
                    <p className="p_header">Present</p>
                  </Grid>
                  <Grid item xs>
                    <p className="p_big_grey">{CommonFunction.zeroAppend(attendanceData.absent)}</p>
                    <p className="p_header">Absent</p>
                  </Grid>
                </Grid>
                <div className="ppl_loading" id="today_attendance">
                  <div>
                    <CircularProgress />
                  </div>
                </div>
              </div>
            )}


            {localStorage.getItem('group') != 37 && (
              <div className="people_journeyDiv" style={{ flex: 1, maxWidth: "30vw", position: "relative" }}>
                <p className="p_header">Today's Planned Visit</p>
                <Grid container>
                  <Grid item xs style={{ borderRight: "1px solid #ccc" }}>
                    <p className="p_big_grey">{CommonFunction.zeroAppend(visitData.assigned)}</p>
                    <p className="p_header">Assigned</p>
                  </Grid>
                  <Grid item xs style={{ borderRight: "1px solid #ccc" }}>
                    <p className="p_big_grey">{CommonFunction.zeroAppend(visitData.ongoing)}</p>
                    <p className="p_header">Ongoing</p>
                  </Grid>
                  <Grid item xs style={{ borderRight: "1px solid #ccc" }}>
                    <p className="p_big_grey">{CommonFunction.zeroAppend(visitData.completed)}</p>
                    <p className="p_header">Completed</p>
                  </Grid>
                  <Grid item xs>
                    <p className="p_big_grey">{CommonFunction.zeroAppend(visitData.pending)}</p>
                    <p className="p_header">Pending</p>
                  </Grid>
                </Grid>
                <div className="ppl_loading" id="planned_visit">
                  <div>
                    <CircularProgress />
                  </div>
                </div>
              </div>
            )}
            {localStorage.getItem('group') != 37 ?
              <div className="people_journeyDiv" style={{ flex: 1, maxWidth: "30vw", position: "relative" }}>
                <p className="p_header">Today's Users</p>
                <Grid container>
                  <Grid item xs style={{ borderRight: "1px solid #ccc" }}>
                    <Tooltip title={<span style={{ fontSize: "15px" }}>Admin / Users</span>} placement="top">
                      <p className="p_big_grey"><span style={{ color: "#227cd0" }}>{CommonFunction.zeroAppend(userCountData.admin_total)}</span>/
                        <span style={{ color: "#dc3545" }}>{CommonFunction.zeroAppend(userCountData.users_total)}</span></p>
                    </Tooltip>
                    <p className="p_header">Total <span>({CommonFunction.zeroAppend(userCountData.total)})</span></p>
                  </Grid>
                  <Grid item xs style={{ borderRight: "1px solid #ccc" }}>
                    <Tooltip title={<span style={{ fontSize: "15px" }}>Admin / Users</span>} placement="top">
                      <p className="p_big_grey"><span style={{ color: "#227cd0" }}>{CommonFunction.zeroAppend(userCountData.admin_active)}</span>/
                        <span style={{ color: "#dc3545" }}>{CommonFunction.zeroAppend(userCountData.users_active)}</span></p>
                    </Tooltip>
                    <p className="p_header">Active <span>({CommonFunction.zeroAppend(userCountData.active)})</span></p>
                  </Grid>
                  <Grid item xs>
                    <Tooltip title={<span style={{ fontSize: "15px" }}>Admin / Users</span>} placement="top">
                      <p className="p_big_grey"><span style={{ color: "#227cd0" }}>{CommonFunction.zeroAppend(userCountData.admin_inactive)}</span>/
                        <span style={{ color: "#dc3545" }}>{CommonFunction.zeroAppend(userCountData.user_inactive)}</span></p>
                    </Tooltip>
                    <p className="p_header">Inactive <span>({CommonFunction.zeroAppend(userCountData.inactive)})</span></p>
                  </Grid>
                </Grid>
                <div className="ppl_loading" id="user_count">
                  <div>
                    <CircularProgress />
                  </div>
                </div>
              </div>

              :
              <div className="people_journeyDiv" style={{ flex: 1, maxWidth: "20vw", position: "relative" }}>
                <p className="p_header">Today's Users</p>

                <Tooltip
                  title={
                    <span style={{ fontSize: "15px" }}>

                      Users: {CommonFunction.zeroAppend(userCountData.users_total)}
                    </span>
                  }
                  placement="top"
                >
                  <p className="p_big_grey">

                    <span style={{ color: "#dc3545" }}>
                      {CommonFunction.zeroAppend(userCountData.users_total)}
                    </span>
                  </p>
                </Tooltip>

                <div className="ppl_loading" id="user_count">
                  <CircularProgress />
                </div>
              </div>
            }
            {/* ################################################################################ */}
            {localStorage.getItem('group') != 37 && (voluntaryData.admin !== 0 || voluntaryData.users !== 0) && (
              <div className="people_journeyDiv" style={{ flex: 1, maxWidth: "30vw", position: "relative" }}>
                <p className="p_header">Today's Voluntary Visit</p>
                <Grid container>
                  <Grid item xs style={{ borderRight: "1px solid #ccc" }}>
                    <p className="p_big_grey">{CommonFunction.zeroAppend(voluntaryData.admin)}</p>
                    <p className="p_header" style={{ color: "#227cd0" }}>Admin</p>
                  </Grid>
                  <Grid item xs>
                    <p className="p_big_grey">{CommonFunction.zeroAppend(voluntaryData.users)}</p>
                    <p className="p_header" style={{ color: "#dc3545" }}>Users</p>
                  </Grid>
                </Grid>
                <div className="ppl_loading" id="voluntary_visit">
                  <div>
                    <CircularProgress />
                  </div>
                </div>
              </div>
            )}
          </div>



          {/* div with journeys end */}

          <Grid container style={{ position: "absolute", bottom: "0", color: "#ffffff", paddingRight: "20px" }}>

            <Grid item xs={6}>
              {/* <p className="cj_w_header">
              <span style={{color : "#F0762B", cursor : "pointer", textDecoration : "underline"}}>Click here</span>&nbsp;
              for classic version of our Web application</p> */}
            </Grid>
            <Grid item xs={5} style={{ textAlign: "right" }}>
              <p className="cj_w_header">{t('ds.help')}? <span style={{ color: "#F0762B" }}>
                <i class="fa fa-volume-control-phone" aria-hidden="true"></i> 9029070083 (24x7)</span></p>
            </Grid>

          </Grid>

        </div>



        <div className="widget" style={{ display: localStorage.getItem('default_view') !== constant.people_tracking ? "" : "none" }}>
          <Grid container spacing={1}>
            <Grid item xs={8} className="widgetComponent" style={{ display: this.state.noWidgetView ? 'none' : '' }}>
              {this.state.layoutWidget}
              {/* <FleetSummary />
                <MapView />
                <OverallVehicleHealth />
                <DriverEfficiency /> */}

            </Grid>
            <Grid item xs={8} style={{ display: this.state.noWidgetView ? '' : 'none' }}>
              <div className="nw_parent">
                <div className="nw_add_widget_parent">
                  <img src="images/set_layout.png" onClick={this.setLayoutWidget} alt="icon"></img>
                  <p>{t('ds.setup')}</p>
                </div>
              </div>
            </Grid>
            <Grid item xs={4} style={{ display: this.state.noWidgetView ? '' : 'none' }}>
              <div className="na_parent">
                <p><b>{t('ds.noalert.header')}</b></p>
                <div className="na_blank_div">
                  <img src="images/no_notification.png" alt="no journey"></img>
                  <p>{t('ds.noalert')}</p>
                </div>
              </div>
            </Grid>
            <Grid item xs={4} container style={{ display: this.state.noWidgetView ? 'none' : '' }}>
              <Notifications data={this.props} />
            </Grid>
          </Grid>
        </div>

        <div className="widget" style={{ display: localStorage.getItem('default_view') == constant.people_tracking ? "" : "none" }}>
          <Grid container spacing={1}>
            <Grid item xs={8} className="widgetComponent">
              <PeopleTrackingMapView />
            </Grid>
            <Grid item xs={4} container>
              <PeopleNotifications data={this.props} />
            </Grid>
          </Grid>
        </div>

        <div id="demo_popup" className="modal_parent">
          <div className="modal_body" style={{ width: "45%", marginTop: "20%", padding: "30px" }}>

            <div className="grid-container" style={{ display: this.state.DemovalidEmail ? "none" : "" }}>
              <div className="grid-item">
                <p className="p_header p_margin0" style={{ lineHeight: "2em", marginRight: "15px" }}>Enter your email address</p>
              </div>

              <div className="grid-item">
                <input type="text" className="addtional_detail_input" placeholder="email address" style={{ width: "100%" }} onChange={this.demoUserEmail}></input>
                <p className="help_text" style={{ display: this.state.invalidEmail ? "" : "none" }}>Enter valid email</p>
              </div>
            </div>

            <p className="cj_w_header" style={{ display: this.state.DemovalidEmail ? "" : "none", textAlign: "center", fontSize: "18px", lineHeight: "1.8em" }}>
              <b>   Congratulations, Your Demo Account created successfully !!! <br />
                Please contact our Customer Support Team on 9029070083 (24x7) for any assistance.</b>
            </p>

            <div style={{ textAlign: "center", marginTop: '10px' }}>
              <button className="btn orange-btn" style={{ float: "none", display: this.state.DemovalidEmail ? "none" : "" }} onClick={this.submitDemoEmail}>Okay</button>
              <button className="btn orange-btn" style={{ float: "none", display: this.state.DemovalidEmail ? "" : "none" }} onClick={() => { $('#demo_popup').fadeOut() }}> Okay </button>
            </div>

          </div>
        </div>

      </div>
    )
  }
}

export default withTranslation()(DashboardContent);
