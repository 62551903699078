import React, { Component } from 'react';
import $ from 'jquery';
import './journey.scss'
import Table from 'react-bootstrap/Table';
import Grid from '@mui/material/Grid';
import ApiUrlCall from '../API/apiurl';
import CommonFunction from '../common_function'
import Navbar from '../../Config/Navbar';
import Tooltip from '@mui/material/Tooltip';
import analytics from '../../Config/firebase'
import Amplify, { Analytics } from 'aws-amplify';
import awswebapp from '../../aws-exports';
import constant from '../constant.json';
import { withTranslation } from 'react-i18next';
import { MDBDataTable ,MDBBtn,MDBRow } from 'mdbreact';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'mdbreact/dist/css/mdb.css';
import Calendar from 'react-calendar/dist/entry.nostyle';
import Snackbar from '@mui/material/Snackbar';
import * as XLSX from "xlsx";

const COMPLETE = 1
const ONGOING_ONTIME = 2
const ONGOING_DELAY = 3
const PENDING = 4
const PENDING_DV = 5
const ABORTED = 6

const data1 = [
  {
    label: 'TYPE',
    field: 'type',
    sort: 'asc',
    width: 100,
  },
  {
    label: 'JOURNEY START',
    field: 'journey_start',
    sort: 'asc',
    width: 300,
    attributes: {
      'aria-controls': 'DataTable',
      'aria-label': 'Name',
    },
  },
  {
    label: 'JOURNEY END',
    field: 'journey_end',
    sort: 'asc',
    width: 300,
  },
  {
    label: 'STATUS',
    field: 'status',
    sort: 'asc',
    width: 200,
  },
  {
    label: 'START DATE & TIME',
    field: 'start_time',
    sort: 'asc',
    width: 200,
  },
  {
    label: 'VEHICLE NAME',
    field: 'vehicle_name',
    sort: 'asc',
    width: 150,
  },
  {
    label: 'DRIVER NAME',
    field: 'driver_name',
    sort: 'asc',
    width: 150,
  },
  {
    label: 'AUTO START',
    field: 'auto_start',
    sort: 'asc',
    width: 150,
  },
  {
    label: 'DELETE JOURNEY',
    field: 'is_delete',
    sort: 'asc',
    width: 150,
  },
] 


class Journeys extends Component {
  counter = 0
  constructor(props){    
    super(props);
    this.state = {
      date: new Date(),
      show: false,
      showDelete: false,
      vehicles: '',
      datatable : [],          
      nextCount : 0, 
      totalCount : '', 
      buttonFlag : true, 
      dataCollection : [], 
      chunckValue : '',
      searchText : '',
      totalEntries : '',
      nextCount : 0,  
      stateFilter : 0,
      startDate : '',
      endDate : '',
      openAlert: false,
    }
    this.vehicleData = this.vehicleData.bind(this);
  }

  componentDidMount() {
    analytics.logEvent('screen_view',{
      screen_name : "JourneyList"
    }) 
    analytics.logEvent('page_view',{
      page_title : "JourneyList",        
    })
    Amplify.configure(awswebapp);
    Analytics.record({
      name: constant.awsName, 
      // Attribute values must be strings
      attributes: { screen_name: 'WEB_JOURNEY_LIST'}
    });
    var navBarHeight = $('.navBar').outerHeight();
    $('.journeryList').css({
      paddingTop: navBarHeight + 60,
    })    
    this.vehicleData()    
  }

  showDeleteIcon = (event) =>{   
    var allCheckbox = document.getElementsByName('deleteSelected') 
    var elementStatus = []    
      for (let i = 0; i < allCheckbox.length; i++) {
        const element = allCheckbox[i].checked;
        elementStatus.push(element)        
      }
      if (elementStatus.includes(true)) {
          this.setState({showDelete : true});
      } else {
          this.setState({showDelete : false});
      }                      
    }
         

    // deleteIconClick = (checkboxStatus) =>{
    //   console.log('delete icon click',checkboxStatus);      
    // }

    emptyTable = () => {
      this.setState({
        datatable : []
      })
    }
vehicleData = () => {    
      const {startDate, endDate} = this.state
      var start
      var end
      if(startDate, endDate){
        start = CommonFunction.ServerDateTime(this.state.startDate)
        end = CommonFunction.ServerDateTime(this.state.endDate)
      }else{
        start = ""
        end = ""
      }
      $("#processing").show()
      var data = {start_date:start,end_date:end,status:this.state.stateFilter, next_count:this.state.nextCount, search : this.state.searchText}
      // console.log('sending data is', data)      
      ApiUrlCall.getJourneyList(JSON.stringify(data)).then(response => {
        //console.log('journey list data', response.data)
        // return
        this.emptyTable()
        if(response.data.response_code === 200 && response.data.is_success ===  true){  
          $('#processing').hide() 
          this.setState({
            vehicles : response.data.data.journey_list,
            nextCount : response.data.data.next_count,
            totalCount : response.data.data.total_count,
            chunckValue : response.data.data.size
          },() => {
            this.createTableData()
          })
        }else{
          $('#processing').hide()
        }          
      }).catch(err => {
        $("#processing").hide()
        console.log("error in journey list", err)
      })
  }

  buttonStatus = () => {
    const {nextCount, totalCount} = this.state
    // console.log(nextCount,'.................',totalCount)
    if(nextCount === totalCount){
      // console.log('iffffffffff')      
      if(this.counter === 0){
        $('#dt-previous').removeClass('enabled-btn')
        $('#dt-previous').addClass('disable-btn')
        $('#dt-next').removeClass('enabled-btn')
        $('#dt-next').addClass('disable-btn')
      }else{
        $('#dt-previous').removeClass('disable-btn')
        $('#dt-previous').addClass('enabled-btn')
        $('#dt-next').removeClass('enabled-btn')
        $('#dt-next').addClass('disable-btn')
      }
    }else{
      // console.log('elseeeeeeee', this.counter)
      if(this.counter === 0){
        // console.log('else 1111111111')
        $('#dt-previous').removeClass('enabled-btn')
        $('#dt-previous').addClass('disable-btn')
        $('#dt-next').removeClass('disable-btn')        
        $('#dt-next').addClass('enabled-btn')
      }else{
        // console.log('else 22222222')
        $('#dt-previous').removeClass('disable-btn')
        $('#dt-previous').addClass('enabled-btn')
        $('#dt-next').addClass('enabled-btn')
        $('#dt-next').removeClass('disable-btn')        
      }
    }    
  }

  entriesData = () => {
    const {nextCount, totalCount, chunckValue} = this.state
    if(nextCount != totalCount){
      var start = nextCount - chunckValue + 1
      var end = nextCount
      var entries_text = start+'-'+end
      this.setState({
        totalEntries : entries_text
      })
    }else{
      if(nextCount <= 10){
        if(nextCount == 0){ 
          this.setState({
            totalEntries : "0"
          })
        }else{
          var entries_text = '1'+'-'+totalCount
          this.setState({
            totalEntries : entries_text
          })
        }
      }else{
        var module = nextCount % chunckValue
        var start
        var end
        var entries_text
        if(module > 0){
          start = nextCount - module + 1
          end = nextCount
          entries_text = start+'-'+end
        }else{
          start = nextCount - chunckValue
          end = nextCount
          entries_text = start+'-'+end
        }
        this.setState({
          totalEntries : entries_text
        })
      }
    }
  }

  getVehicleName = (data) => {    
    var array = []
    for (let i = 0; i < data.length; i++) {
      const element = data[i];    
      // console.log('journey details', element)  
      if(element.vehicle){
        array.push(element.vehicle.name+"\n,")      
      }
    }
    return array
  }

  getdriverName = (data) => {
    var array = []
    for (let i = 0; i < data.length; i++) {
      const element = data[i];      
      array.push(element.assigned_to.driver_name+'\n')
    }
    return array
  }

  statusText = (value,id) => {
      const {t} = this.props
      switch (value) {          
          case COMPLETE:
            return (
              <span style={{color:"#28a745"}} className={id+" "+value}><i className="fa fa-clock-o" aria-hidden="true" style={{ marginRight: "5px" }}></i>{t('ct.complete')}</span>              
            )
          case ABORTED:
              return (
                <span style={{color:"#FF0000"}} className={id+" "+value}><img src="images/group-13.png" alt="icon" style={{width : "15px", marginRight : "5px"}}></img>Aborted</span>              
                )                
          case ONGOING_ONTIME :  
            return (
              <span style={{color:"#f5920d"}} className={id+" "+value}><i className="fa fa-clock-o" aria-hidden="true" style={{ marginRight: "5px" }}></i>{t('ds.ongoing')}</span>
            )              
          case ONGOING_DELAY :  
            return (
              <span style={{color:"#f5920d"}} className={id+" "+value}><i className="fa fa-clock-o" aria-hidden="true" style={{ marginRight: "5px" }}></i>{t('ds.ongoing')}</span>
            )              
          case PENDING :  
            return (
              <span style={{color:"#227cd0"}} className={id+" "+value}><i className="fa fa-clock-o" aria-hidden="true" style={{ marginRight: "5px" }}></i>{t('ct.scheduled')}</span>
            )              
          case PENDING_DV :  
            return (
              <Tooltip title={
                <span style={{fontSize : "15px",textAlign:"center",padding : "20px"}}>
                  <p>{t('jls.no.vehicle')}</p>
                </span>
              }>
              <span style={{color:"#227cd0"}} className={id+" "+value}><img src="images/group-13.png" alt="icon" style={{width : "15px", marginRight : "5px"}}></img>Scheduled Incomplete</span>              
              </Tooltip>
            )                        
        }
  }
  

  delete_button = (journey_id,moving_state) => {
    if(moving_state === PENDING || moving_state === PENDING_DV) 
    return ( <img class="deletejourney" src="images/group-2.png" alt="icon" styles= {"marginTop: 10px; marginLeft: 15px; cursor: pointer" }></img>
    )
    else return (
      <div></div>
      )  
}


  isPrimaryJourney = (status,recurring_id) => {
    if(status){
      return "Primary"
    }else if(status == false && recurring_id == null ){
      return "Primary"
    }
    else{
      return "Copy"
    }
  }

  isAutoStartJourney = (status) => {
    if(status){
      return "YES"
    }else{
      return "NO"
    }
  }
  createTableData = () => {   
    const {t} = this.props 
    const {vehicles,datatable} = this.state    
    var row = []  
    // console.log('lenght is11111111111', datatable)
    for (let i = 0; i < vehicles.length; i++) {
      const element = vehicles[i];
      var row_data = {
      type : this.isPrimaryJourney(element.is_primary,element.recurring_id),journey_start : element.planned_start_loc, journey_end : element.planned_end_loc, status : this.statusText(element.moving_status, element.id),
      start_time : CommonFunction.tableDateFormate(element.start_time)+" "+CommonFunction.timeFormate(element.start_time), 
      vehicle_name : this.getVehicleName(element.journey_vehicle_detail), driver_name : this.getdriverName(element.journey_vehicle_detail),
      clickEvent : this.tableRowClick,
      is_delete : this.delete_button(element.id,element.moving_status),
      auto_start: this.isAutoStartJourney(element.is_auto_start),
      //clickEvent=this.deleteJourney(element.journey_id)
      }       
      datatable.push(row_data)                 
    }    
    this.buttonStatus() 
    this.entriesData()   
    this.forceUpdate()
    // console.log('data table data is2222222', datatable)
    // this.intervalData()
  }

  pageNextClick = () => {
    const {totalCount,nextCount,dataCollection,datatable} = this.state
    // console.log(this.counter,'next clicked',totalCount,'---------',nextCount)
    this.counter = this.counter + 1  
    for (let i = 0; i < datatable.length; i++) {
      const element = datatable[i];
      dataCollection.push(element)
    }      
    this.setState({
      datatable : []
    },() => {
      this.vehicleData()
    })
  }

  pagePreviousClick = () => {
    const {dataCollection, chunckValue, nextCount} = this.state
    var pageSize = nextCount % chunckValue
    if(pageSize === 0){
      pageSize = chunckValue
    }
    // console.log('previous clicked', pageSize)    
    var last = dataCollection.splice(Math.max(dataCollection.length - chunckValue))
    this.setState({
      datatable : last,
      nextCount : nextCount - pageSize
    },() => {      
      this.buttonStatus() 
      this.entriesData()     
    })
    this.counter = this.counter -1
  }

  optimizationSearch = (value) => {  
    
      this.counter = 0
      if(value.length >= 3){
        // console.log('serach.................')
        setTimeout(() => {
          this.setState({
            datatable : [],
            dataCollection : [],
            totalCount : '',
            chunckValue : '',
            nextCount : 0,
            searchText : value
          },() => {
            this.vehicleData()          
          })
        }, 1000);     
      }else if(value.length === 0){
        setTimeout(() => {
          this.setState({
            datatable : [],
            dataCollection : [],
            totalCount : '',
            chunckValue : '',
            nextCount : 0,
            searchText : value
          },() => {
            this.vehicleData()          
          })
        }, 1000);     
      }          
  }

  // journeyEdit = (id,journey_state) => {    
  //   if(journey_state === PENDING_DV){
  //     this.props.history.push({
  //       pathname : 'create_new_journey',
  //       state : {
  //         journey_id : id
  //       }
  //     })
  //   }else{
  //     this.props.history.push({
  //       pathname : 'journeyOverview',
  //       state : {
  //         journey_id : id,
  //         moving_state : journey_state
  //       }
  //     })
  //   }
  // }

  tableRowClick = (id) => {
    var span_class = $(id.currentTarget).find('span').attr('class').split(" ")
    //var deletejourney_class = []
    var statusss = id.target.classList.contains("deletejourney");
    
    var journey_id = parseInt(span_class[0])
    var journey_state = parseInt(span_class[1])
    //console.log(journey_state,"journey_state")
    //console.log(statusss,"statusss",span_class)
   
    if(journey_state == PENDING || journey_state == PENDING_DV){
         //console.log("mrunmyeeeeeeeeeeeeeeeeeeee2")
      if ( journey_state == PENDING && statusss==false)
      {
        this.props.history.push({
          pathname : 'journeyOverview',
          state : {
            journey_id : journey_id,
            moving_state : journey_state
          }
        })  
      }
      else if (statusss==true )
      {
        ApiUrlCall.deleteJourney(journey_id).then(response => {
          //console.log('deleted', response)
          if (response.status == 200) {
            this.setState({
              openAlert: true,
              errorMessage: "Journey Deleted Successfully"
            })
            
            window.location.reload();
            
          }
          else if (response.status == 405) {
            this.setState({
              openAlert: true,
              errorMessage: "Journey Can not be Deleted "
            })
          }
        })
        
      }        
      else{
         this.props.history.push({
           pathname : 'create_new_journey',
            state : {
              journey_id : journey_id,
              moving_state : 5

            }
          })
      }
    }
    else{
      this.props.history.push({
        pathname : 'journeyOverview',
        state : {
          journey_id : journey_id,
          moving_state : journey_state
        }
      })
    }    

  }

  StartdateClicked = date => {
    const {startDate, endDate} = this.state
    // var isoStartDate = date    
    // console.log('props value', CommonFunction.getTime(date))
    this.setState({ startDate: date },() =>{
      if(this.state.startDate && endDate){
        this.setState({
          datatable : [],
          dataCollection : [],
          totalCount : '',
          chunckValue : '',
          nextCount : 0,          
        },() => {
          this.counter = 0
          this.vehicleData()          
        })
      }else{
        return
      }
    })
    $('#start_date_cal').slideUp(200)
    $('#end_date_cal').slideUp(200)
  }

  // this method is used to create end date into ISO and send it to server
  EnddateClicked = date => {
    const {startDate, endDate} = this.state
    var isoStartDate = date
    isoStartDate.setHours(23,59,59,0);    
    // console.log('props value', CommonFunction.getTime(date))
    this.setState({ endDate: isoStartDate },() =>{      
      if(startDate && this.state.endDate){
        this.setState({
          datatable : [],
          dataCollection : [],
          totalCount : '',
          chunckValue : '',
          nextCount : 0,          
        },() => {
          this.counter = 0
          this.vehicleData()          
        })
      }else{
        alert("select Valid date")
      }
    })
    $('#end_date_cal').slideUp(200)
    $('#start_date_cal').slideUp(200)
  }
  showStartDate = (e) => {    
    $(e.currentTarget).next().slideToggle(200, "swing")
  }
  showEndDate = (e) => {    
    $(e.currentTarget).next().slideToggle(200, "swing")
  }
  
  readExcel = (e) =>{
    $("#processing").show()
    const [file] = e.target.files;
    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      // const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      const data = XLSX.utils.sheet_to_json(ws, {raw: false});
      var excel_data = {data}
    ApiUrlCall.createJourneyUploadingExcel(JSON.stringify(excel_data)).then(response => {
        if (response.status == 200) {
          this.setState({
            openAlert: true,
            errorMessage: response.data.response_message,
          })
          window.location.reload();
          $("#processing").hide()
        }
        else if (response.status == 405) {
          this.setState({
            openAlert: true,
            errorMessage: "Error while creating journeys"
          })
        }
    })
    };
    reader.readAsBinaryString(file); 
  }

  render(){
    const {t} = this.props
     //console.log('props in journey render is', this.props)
    let date = new Intl.DateTimeFormat('en-GB', { 
      year: 'numeric', 
      month: 'short', 
      day: '2-digit' ,
      hour:'2-digit',
      minute:'2-digit'
    }).format(this.state.date)    
    // var minDate = new Date(); minDate.setDate(minDate.getDate() - 90);      
    return (
      <div>
      <Navbar/>
  
      <div className="journeryList">
        {/*Unplanned journey*/}
        <div>
        <Grid style={{display : this.state.show ? 'block' : 'none',marginBottom:"2em"}} container>
        <Grid item xs={3} className="tableHeading">
          <label>{t('jls.unplanned.trip')}</label>
        </Grid>  
        <Grid item xs>
          <div style={{float:'right'}}>          
            <p className="clear_all">{t('jls.clear.all')}</p>              
            <span style={{display : this.state.showDelete ? '' : 'none', marginLeft:'20px'}}>   
              <button className="btn orange-btn" style={{float:'none'}}>{t('jsl.create.new')}</button>              
            </span>
          </div>
          </Grid>  
        </Grid>
        <div className="tableOuterDiv" style={{display : this.state.show ? 'block' : 'none',marginBottom:"50px"}} id="j_unplanned_trip">
          <div className="cardView tableParent">
            <Table responsive>
              <thead className="tableHeader">
                <tr>    
                  <th></th>              
                  <th>{t('ct.table.h12')}</th>                  
                  <th>{t('ct.start.datetime')}</th>
                  <th>{t('ct.start.location')}</th>
                  <th>{t('ct.end.location')}</th>            
                  <th>{t('ct.table.h9')}</th>                  
                </tr>
              </thead>
              <tbody className="tableBody">                
                  <tr>
                    <td className="checkboxTd">
                      <input type="checkbox" onChange={this.showDeleteIcon} name="deleteSelected"></input>
                    </td>  
                    <td>Honda City</td>                
                    <td>{date}</td>                    
                    <td className="uj_single_line_address">{CommonFunction.tableAddressEllipsis(
                      "Lotus Corporate Park, B-Wing, Office No: 1702, 1801 & 1802, Western Express Hwy, Goregaon, Mumbai, Maharashtra 400063",30)}</td>
                    <td className="uj_single_line_address">{CommonFunction.tableAddressEllipsis(
                      "Lotus Corporate Park, B-Wing, Office No: 1702, 1801 & 1802, Western Express Hwy, Goregaon, Mumbai, Maharashtra 400063",30)}</td>
                    <td><img src="images/group-10.png" alt="icon"></img></td>                  
                  </tr>
                  <tr>
                    <td className="checkboxTd">
                      <input type="checkbox" onChange={this.showDeleteIcon} name="deleteSelected"></input>
                    </td>  
                    <td>Honda City</td>                
                    <td>{date}</td>                    
                    <td>{CommonFunction.tableAddressEllipsis(
                      "Lotus Corporate Park, B-Wing, Office No: 1702, 1801 & 1802, Western Express Hwy, Goregaon, Mumbai, Maharashtra 400063",30)}</td>
                    <td>manish asd asda asda asda asdad asdaa daasaaasda </td>
                    <td><img src="images/group-10.png" alt="icon"></img></td>                  
                  </tr> 
                  <tr>
                    <td className="checkboxTd">
                      <input type="checkbox" onChange={this.showDeleteIcon} name="deleteSelected"></input>
                    </td>  
                    <td>Honda City</td>                
                    <td>{date}</td>                    
                    <td>manish asd asda asda asda asdad asdaa daasaaasda </td>
                    <td>manish asd asda asda asda asdad asdaa daasaaasda </td>
                    <td><img src="images/group-10.png" alt="icon"></img></td>                  
                  </tr>                  
              </tbody>
            </Table>
          </div>
        </div>
        {/*Planned journey*/}
        <Grid container style={{marginBottom:"2em"}}>
        <Grid item xs={9} className="tableHeading">
          <label>{t('jls.list')}</label>
        </Grid>  
        <Grid item xs={1} style={{textAlign : "right"}}>
        <Tooltip title={
            <span style={{fontSize : "15px",textAlign:"center"}}>
              <p style={{marginBottom : "0px"}}>Reset Filter</p>
            </span>
          }>
          <span style={{marginRight : "10px", cursor : "pointer"}}><img src="images/reset_filter.png" alt="icon" width="30px"
          onClick={() => {
            this.setState({
              stateFilter : 0,
              startDate : "",
              endDate : "",
              datatable : [],
              dataCollection : [],
              totalCount : '',
              chunckValue : '',
              nextCount : 0,
            },() => {
              $('.status-filter').val(0)
              this.counter = 0
              this.vehicleData()
            })
          }}></img></span>
          </Tooltip>
        </Grid>
        <Grid item xs={2}>        
          <button className="btn orange-btn btn-block" onClick={() => {
            this.props.history.push('/enter_journey_details')
                      }}>{t('jls.create.journey')}</button>
          <input className="btn orange-btn btn-block" style ={{marginTop: "25px"}} type="file" onChange={this.readExcel}/>
          </Grid>  
        </Grid>
        {/* <div className="tableOuterDiv" id="journey_list_table">
          <div className="cardView tableParent">
            <Table responsive>
              <thead className="tableHeader">
                <tr>                  
                  <th>{t('ct.table.h1')}</th>
                  <th>{t('ct.table.h5')}</th>
                  <th>{t('ct.start.datetime')}</th>
                  <th>{t('ct.table.h10')}</th>
                  <th>{t('ct.table.h6')}</th>   
                  <th></th>         
                </tr>
              </thead>
              <tbody className="tableBody">
                  {vehiclelist}
              </tbody>
            </Table>
          </div>
        </div> */}
        <div style={{position : "relative"}}>
          <Grid container spacing={3} style={{position : "absolute" , top : "-30px"}}>            
            <Grid item xs={2}>
              <p className="cj_w_header"><b>Status</b></p>
              <select onChange={(e) => {
                this.setState({
                  datatable : [],
                  dataCollection : [],
                  totalCount : '',
                  chunckValue : '',
                  nextCount : 0,
                  stateFilter : e.currentTarget.value
                },() => {
                  this.counter = 0
                  this.vehicleData()
                })
              }} className="status-filter" defaultValue={this.state.stateFilter}>
                <option value={0}>All</option>
                <option value={2}>Ongoing</option>
                <option value={1}>Complete</option>
                <option value={4}>Scheduled</option>
                <option value={5}>Scheduling Incomplete</option>
                <option value={6}>Aborted</option>

              </select>
            </Grid>
            <Grid item xs={2}>
              <p className="cj_w_header"><b>Start Date</b></p>
              <div className="input_div" onClick={this.showStartDate}>
                <input type="text" placeholder={t('ct.startdate')} id="start_date" disabled value={CommonFunction.dateFormate(this.state.startDate)} className="journey_input"></input>
                <img src="images/fill-111.png" className="vd_journey_icon"></img>
              </div>
              <div className="calendar_parent" id="start_date_cal">
                <Calendar
                  selectRange={false}
                  onChange={this.StartdateClicked}
                  value={this.state.date}
                  // maxDate={this.state.date}
                  // minDate={minDate}
                />
              </div>              
            </Grid>
            <Grid item xs={2}>
              <p className="cj_w_header"><b>End Date</b></p>  
              <div className="input_div" onClick={this.showEndDate} style={{zIndex : "99"}}>
                <input type="text" placeholder={t('ct.enddate')} id="end_date" disabled value={CommonFunction.dateFormate(this.state.endDate)} className="journey_input"></input>
                <img src="images/fill-111.png" className="vd_journey_icon"></img>
              </div>
              <div className="calendar_parent" id="end_date_cal">
                <Calendar
                  selectRange={false}
                  onChange={this.EnddateClicked}
                  value={this.state.date}
                  // maxDate={this.state.date}
                  // minDate={minDate}
                />
              </div>            
            </Grid>                        
          </Grid>
          <MDBDataTable
            hover 
            displayEntries={false}
            paging={false}
            ref={this.myref}
            scrollX
            entries={10}            
            striped
            bordered
            data={{columns : data1 , rows : this.state.datatable}}            
            onSearch = {this.optimizationSearch}            
          />
          <Grid container style={{margin : "20px 0px"}}>
              <Grid item xs>
                <p className="cj_w_header"><b>Entries {this.state.totalEntries} of {this.state.totalCount}</b></p>
              </Grid>
              <Grid item xs style={{textAlign : "right"}}>
                <button className="btn" id="dt-previous" onClick={this.pagePreviousClick}>Previous</button>
                <button className="btn" id="dt-next" onClick={this.pageNextClick}>Next</button>
              </Grid>
          </Grid>
        </div>
      </div>
      </div>
      <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          autoHideDuration={2000}
          onClose={(e, reason) => {
            if (reason === 'clickaway') {
              return;
            }
            this.setState({ openAlert: false })
          }
          }
          open={this.state.openAlert}
          message={this.state.errorMessage}
        />
      </div>
    );
  }
}

const styles = {
    fontSize: '20px',
  } 
 
export default withTranslation()(Journeys);
