import React, { Component } from 'react';
import $ from 'jquery';
import './dashcam.scss'
import ApiUrlCall from '../API/apiurl';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'mdbreact/dist/css/mdb.css';
import 'react-activity/lib/Digital/Digital.css';
import "react-treeview/react-treeview.css";
import { Rnd } from "react-rnd";
import moment from "moment";
import { DataSet } from "vis-data";
import { Timeline } from "vis-timeline/standalone";
import "vis-timeline/styles/vis-timeline-graph2d.min.css";



class DashCam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //panning for timeleine state end
      startTime: moment("YYYYMMDDHHmmss"),
      timeFrame: 24 * 60,
      channels: [], // Dynamically updated from API
      zoomLevel: "day", // 'day' or 'hour'
      playbackPopupVisible: false,
      playbackData: null, // To store API response data
      iframeUrl: [],
      iframeUrls: [], // Holds the array of iframe URLs

      dashcam: '',
      selectedVehicle: null, // To store serial number and name
      searchVehicleData: [],

      sendStartTime: "00:00",
      showStartTime: false,
      selectedCameraOptions: props.selectedCameraOptions || {}, // Initialize state with prop value
      searchVehicleData: [],
      selectedVehicles: [],
      searchVehicleData: [],
      isPopupVisible: false,
      width: "80%",
      x: 100,
      y: 100,
      zIndex: 10,
      height: 600,
      channellist: [],

      serialNumber: [], // Receive serial number as a prop 
      serialNumbers: props.srnos, // Accept multiple SRNOs as props (Array) 
      videoData: {}, // Object to store iframe URLs per SRNO
      selectedDate: moment().format("YYYY-MM-DD"),
      timelineData: new DataSet([]),
      showTimeline: true,
      loading: false,
    }
    this.timelineRef = React.createRef();
    this.videoRef = React.createRef();
    this.backVideoRef = React.createRef();
  }
  componentDidMount() {
    this.handleCameraData(this.state.selectedCameraOptions);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedCameraOptions !== this.props.selectedCameraOptions) {
      this.setState(
        { selectedCameraOptions: this.props.selectedCameraOptions },
        () => {
          this.handleCameraData(this.state.selectedCameraOptions);
        }
      );
    }
  }

  // New function to handle camera data
  handleCameraData = (selectedCameraOptions) => {
    if (!selectedCameraOptions) return;

    if (selectedCameraOptions.camera_options === "playback") {
      this.playBackStreaming(selectedCameraOptions);
    } else {
      this.liveStreaming(selectedCameraOptions);
    }
  };


  playBackStreaming = (vehicle) => {

    this.setState({ selectedVehicle: vehicle }, () => {

      this.checkplayBackCams(vehicle);
    });
  };

  checkplayBackCams = (vehicle) => {
    //  console.log("checkplayBackCams", vehicle);

    const serial_number = vehicle.serial_number;
    const camera_options = vehicle.camera_options;
    // console.log("Checking dash cam for vehicle:", serial_number, "camera_options", camera_options);

    $('#processing').show();
    ApiUrlCall.checkDashCamera({ serial_number })
      .then(response => {
        // console.log('Vehicle response:', response);

        $('#processing').hide();

        if (response.data.response_code === 200 && response.data.is_success) {
          this.setState({
            dashcam: response.data.data.is_dashcam, // Assuming this is_dashcam is part of the response data
            srno: serial_number, // Store the serial number
            playbackPopupVisible: true,
          }, () => {
            // Callback after state update
            if (this.state.dashcam === 0) {
              // console.log("Dashcam is available Fetching successfully...");

              this.fetchplayback(this.state.srno,); // Pass serial number for live streaming

              // this.playbackStreaming();

            } else {
              console.log('Dashcam not available for this vehicle.');
            }
          });
        } else {
          console.log('Error: Invalid response or unsuccessful request.');
        }
      })
      .catch(error => {
        $('#processing').hide();
        console.error('Error while fetching data:', error);
      });
  };

  fetchplayback = (vehicle) => {
    console.log("fetchplayback", vehicle);
 
    const { selectedDate } = this.state;
    const startTime = `${selectedDate} 00:00:00`;
    const endTime = `${selectedDate} 23:59:59`;
    const camera_option = "both";
  
    const requestData = {
      queryParam: vehicle ,  
      startTime,
      endTime,
      camera_option,
    };
  
    console.log("Request Payload:", requestData); 
    this.setState({ loading: true });
    ApiUrlCall.playbackLiveStreaming(requestData)
      .then((response) => {
        console.log("playbackStreaming Response:", response);
        const listFile = response?.data?.data?.listFile || [];
        if (!listFile.length) {
          console.warn("No files found in response.");
          alert("No playback data found. Please try again.");
          this.setState({ timelineData: new DataSet([]), loading: false  ,playbackPopupVisible: false,});
          return;
        }
  
        const formattedData = this.convertToVisFormat(listFile);
        this.setState({ 
          timelineData: new DataSet(formattedData), 
          loading: false 
        }, this.initializeTimeline);
      })
      .catch((err) => {
        console.error("Error fetching playback data:", err);
        alert("Playback data fetch failed. Please try again.");
        this.setState({ loading: false  ,  playbackPopupVisible: false,});
      });
  };





  toggleTimeline = () => {
    this.setState({
      // showTimeline: false,    // Hide timeline popup
      playbackPopupVisible: false, // Hide popup
      iframeUrl: null,        // Remove iframe link
      timelineData: new DataSet([]), // Clear timeline data
      selectedVehicle: null,  // Reset selected vehicle
      selectedDate: moment().format("YYYY-MM-DD"), // Reset date
    });
  };

  convertToVisFormat = (listFile) => {
    return listFile.map((item, index) => {
      const start = moment(item.begintime, "YYYYMMDDHHmmss");
      const end = moment(item.endtime, "YYYYMMDDHHmmss");

      if (!start.isValid() || !end.isValid()) {
        console.error(`Invalid date in item ${index + 1}:`, item);
        return null;
      }

      return {
        id: index + 1,
        group: item.channel,
        content: ``,
        start: start.toDate(),
        end: end.toDate(),
        className: "recording-block",
        title: `Start: ${start.format("h:mm:ss A")}\nEnd: ${end.format("h:mm:ss A")}`,
      };
    }).filter(Boolean);
  };

  initializeTimeline = () => {
    if (!this.timelineRef.current) return;

    const options = {
      zoomable: true,
      moveable: true,
      showCurrentTime: false,
      stack: false,
      min: moment(this.state.selectedDate).startOf("day").toDate(),
      max: moment(this.state.selectedDate).endOf("day").toDate(),
      zoomMin: 60000,
      zoomMax: 86400000,
      format: {
        minorLabels: { hour: "h A" },
        majorLabels: { day: "MMMM D, YYYY" },
      },
    };

    if (this.timeline) {
      try {
        this.timeline.destroy();
      } catch (error) {
        console.warn("Error destroying timeline:", error);
      }
    }

    this.timeline = new Timeline(this.timelineRef.current, this.state.timelineData, new DataSet([
      { id: 1, content: "Front View" },
      { id: 2, content: "Cabin View" },
    ]), options);

    this.timeline.on("select", (properties) => {
      if (properties.items.length > 0) {
        const itemId = properties.items[0];
        const selectedItem = this.state.timelineData.get(itemId);
       
         // Ensure a valid serial number is available
         const selectedDevice = this.state.selectedVehicle?.serial_number || "";
         if (!selectedDevice) {
           console.warn("No valid serial number found for the selected vehicle.");
           return;
         }
 
         this.setState({ iframeUrl: null });
 
 

        setTimeout(() => {
          const videoHost = "https://dashcam.mpgpsdc.com";
          const config = {
            url: "wss://dashcam.mpgpsdc.com/videows/",
            apiToken: "863d1f56-14f2-4e75-b92a-da559c157434",
            apiName: "test",
            lang: "en",
          };

          const param = {
            device: selectedDevice, 
            channel: selectedItem.group,
            protocolType: 1,
            codetype: 1,
            datatype: 0,
            begintime: moment(selectedItem.start).format("YYYYMMDDHHmmss"),
            endtime: moment(selectedItem.end).format("YYYYMMDDHHmmss"),
          };


          const url = this.createRecUrl(videoHost, param, config);
          this.setState({ iframeUrl: url });
        }, 100);
      }
    });
  };

  createRecUrl = (videoHost, param, config, isSleep = false, videoFormat = 98) => {
    const t = Date.now();
    return `${videoHost}/#/videoapi/rec?param=${JSON.stringify(param)}&config=${JSON.stringify(config)}&videoFormat=${videoFormat}&isSleep=${+isSleep}&t=${t}`;
  };

  handleDateChange = (e) => {
    this.setState({ selectedDate: e.target.value });
  };

  handleSearchClick = () => {
    this.setState(
      {
        timelineData: new DataSet([]), // Clear timeline
        iframeUrl: null, // Remove video playback
        playbackPopupVisible: true, // Keep the popup open
        loading: true, // Show loading indicator
      },
      () => {
        this.fetchplayback(); // Call function to update playback data
      }
    );
  };
 

  // ***************************************************************** Live  Streaming  ********************************************************
  liveStreaming = (vehicle) => {
    // console.log("liveStreaming", vehicle);

    this.setState((prevState) => {
      const exists = prevState.selectedVehicles.some(
        (v) => v.serial_number === vehicle.serial_number && v.camera_options === vehicle.camera_options
      );

      if (!exists) {
        console.log("New vehicle detected. Processing:", vehicle);

        // Call checkDashCams only for the new vehicle
        this.checkDashCams(vehicle);

        return { selectedVehicles: [vehicle] }; // Store only the current vehicle before clearing
      } else {
        console.log(
          `Vehicle with serial number ${vehicle.serial_number} and camera option ${vehicle.camera_options} already exists.`
        );
        return null;
      }
    }, () => {
      // After checkDashCams has been called, clear selectedVehicles
      this.setState({ selectedVehicles: [] });
    });
  };

  checkDashCams = (vehicle) => {
    // console.log("checkDashCams")
    const serial_number = vehicle.serial_number;
    const camera_options = vehicle.camera_options;

    console.log("Checking dash cam for vehicle:", serial_number, "camera_options", camera_options);

    ApiUrlCall.checkDashCamera({ serial_number })
      .then(response => {
        console.log("Vehicle response:", response);

        if (response.data.response_code === 200 && response.data.is_success) {
          this.setState({
            dashcam: response.data.data.is_dashcam,
            srno: serial_number,
            titlename: response.data.data.title || vehicle.title || "Unknown Title",
          }, () => {
            if (this.state.dashcam === 0) {

              console.log("Dashcam is available. Fetching successfully...");
              this.fetchLiveStreaming(this.state.srno, camera_options);
            } else {
              console.log("Dashcam not available for this vehicle.");
            }
          });
        } else {
          console.log("Error: Invalid response or unsuccessful request.");
        }
      })
      .catch(error => {
        console.error("Error while fetching data:", error);
      });
  };



  fetchLiveStreaming = (srno, camera_options) => {
    // console.log("Fetching video stream for SRNO:", srno, "Camera Option:", camera_options);

    ApiUrlCall.newfetchLiveStreamingcamera({ serial_number: srno, camera_option: camera_options })
      .then((response) => {
        // console.log(`API Response for SRNO ${srno}:`, response);

        const iframeUrls = response?.data?.iframe_urls;
        if (iframeUrls && iframeUrls.length > 0) {
          this.setState((prevState) => {
            const existingIframes = prevState.iframeUrls.map((iframe) => `${iframe.channel}-${iframe.serialNumber}`);

            const newIframes = iframeUrls
              .map((urlData) => ({
                url: urlData.iframe_url,
                channel: urlData.channel,
                serialNumber: srno
              }))
              .filter((iframe) => !existingIframes.includes(`${iframe.channel}-${iframe.serialNumber}`));

            if (newIframes.length === 0) {
              alert(`The stream for Vehicle ${srno},  ${camera_options} Camera is already active!`); // Show alert
              console.warn(`The stream for Vehicle ${srno},  ${camera_options} Camera is already active!`);
              return null; // No state update
            }

            return {
              isPopupVisible: true,
              iframeUrls: [...prevState.iframeUrls, ...newIframes]
            };
          });
        } else {
          console.warn(`No video stream available for SRNO ${srno}`);
        }
      })
      .catch((err) => {
        console.error(`Error fetching live stream for SRNO ${srno}:`, err);
      });
  };

  closeIframe = (index) => {
    this.setState((prevState) => {
      const updatedIframeUrls = prevState.iframeUrls.filter((_, i) => i !== index);
      return { iframeUrls: updatedIframeUrls };
    }, () => {
      // Hide popup if no iframes are left
      if (this.state.iframeUrls.length === 0) {
        this.setState({ isPopupVisible: false });
      }
    });
  };
  generateIframeUrl = (urlData) => {
    const videoHost = "https://dashcam.mpgpsdc.com";
    const streamMediaUrl = "wss://dashcam.mpgpsdc.com/videows/";
    const userToken = "863d1f56-14f2-4e75-b92a-da559c157434";
    const apiName = "test";
    const protocolType = 1;
    const codetype = 1;
    const datatype = 0;
    const videoFormat = 98; // 98: H264, 99: H265
    const isSleep = false;

    const device = urlData.device;
    const channel = [urlData.channel];
    console.log("device", device);
    console.log("channle", channel);
    const paramObj = { device, channel, protocolType, codetype, datatype };
    const configObj = { url: streamMediaUrl, apiToken: userToken, apiName, lang: "en" };

    const t = Date.now();

    return `${videoHost}/#/videoapi/real?param=${encodeURIComponent(
      JSON.stringify(paramObj)
    )}&config=${encodeURIComponent(JSON.stringify(configObj))}&videoFormat=${videoFormat}&isSleep=${+isSleep}&t=${t}`;
  };
  // Function to close the popup
  closePopup = () => {
    this.setState({
      //   iframeUrl: null,
      iframeUrls: [],
      isPopupVisible: false,
      playbackPopupVisible: false,
    });
  };



  render() {
    const { isPopupVisible, iframeUrls} = this.state
    const { playbackPopupVisible,} = this.state;
    return (

      <div>

        <div>

          {playbackPopupVisible && (
            <div className="video-timeline-wrapper">
              {this.state.loading && (
                <div className="spinner-overlay">
                  <div className="spinner"></div>
                </div>
              )}
              {this.state.showTimeline && (
                <div className="video-timeline-box">
                  <button className="replay-close-button" onClick={this.toggleTimeline}>X</button>
                  <div className="date-picker">
                    <label>Select Date: </label>

                    <input type="date" value={this.state.selectedDate}
                     onChange={this.handleDateChange} 
                     max={new Date().toISOString().split("T")[0]} // Disable future dates
                     />
                    <button className="search-button" onClick={this.handleSearchClick}> Search </button>
                  </div>
                  <div className="video-player">
                    {this.state.iframeUrl ? (
                      <iframe src={this.state.iframeUrl} title="Playback Video" allowFullScreen></iframe>
                    ) : (
                      <p>Select a recording to play</p>
                    )}
                  </div>
                  <div ref={this.timelineRef} className="timeline" />
                </div>
              )}
            </div>

          )}


          {isPopupVisible && (
             <div className="popup-Overlay">
             <Rnd
               className="popup-container"
               style={{ zIndex: this.state.zIndex }}
               onMouseDown={() => this.setState({ zIndex: this.state.zIndex + 1 })}
             >

               <button className="popup-close-btn" onClick={() => this.setState({ isPopupVisible: false, iframeUrls: [] })}>  X </button>

               <div className={`popup-iframe-container ${iframeUrls.length === 1 ? "one-frame" : iframeUrls.length === 2 ? "two-frames" : "multi-frames"}`}>

                 {iframeUrls.map((frame, index) => (
                   <div key={index} className="popup-iframe-div">
                     <div className="iframe-header">
                       <h4>Channel: {frame.channel} (Vehicle: {frame.serialNumber})</h4>
                       <button className="iframe-close-btn" onClick={() => this.closeIframe(index)}> X </button>
                     </div>
                     <div className="iframe-wrapper">

                       <div className="iframe-div">
                         <iframe
                           src={frame.url}
                           width="100%"
                           height="300px"
                           title={`Live Video Stream ${index + 1}`}
                           frameBorder="0"
                           allowFullScreen
                         />
                       </div>

                     </div>

                   </div>
                 ))}
               </div>
             </Rnd>
           </div>

          )}
        </div>


      </div>
    );
  }
}




export default DashCam;
