import React, { Component } from 'react';
import $ from 'jquery';
import { RotatingLines } from 'react-loader-spinner';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import '../reports/master_Dashbord.scss';
import ApiUrlCall from '../API/apiurl';
import CommonFunction from '../common_function';
import Calendar from 'react-calendar/dist/entry.nostyle';
import analytics from '../../Config/firebase';
import Amplify, { Analytics } from 'aws-amplify';
import awswebapp from '../../aws-exports';
import constant from '../constant.json';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'mdbreact/dist/css/mdb.css';
import { withTranslation } from 'react-i18next';
import Timekeeper from 'react-timekeeper';
import { Route, Redirect, Switch, Link, BrowserRouter as Router } from 'react-router-dom';
import * as XLSX from 'xlsx';

const initialState = {
  dateRange: {}
};

class IMD_reports extends Component {
  constructor(props) {
    super(props);

    const currentDate = new Date();
    const startdatetwoDaysAgo = new Date(currentDate);
    startdatetwoDaysAgo.setDate(currentDate.getDate() - 3);

    this.state = {
      isVisible: false,
      master_dash_data: [],
      selectedCircle: 'Rajasthan', // Default value set here
      selectedArea: '',
      selectedCluster: '',
      selectedFse: '',
      visibleFourthTableIndex: null,
      date: new Date(),
      counter: 0,
      dateRange: initialState,
      startDate: startdatetwoDaysAgo,
      endDate: currentDate,
      openAlert: false,
      vehicle_name: '',
      message_vehicle: '',
      sevenDayFlag: true,
      startTime: "12:00 am",
      sendStartTime: "00:00",
      showStartTime: false,
      endTime: "11:59 pm",
      sendEndTime: "23:59",
      showEndTime: false,
      generateReport: false,
      redirectToReferrer: false,
      dg_status: 'All',
      visibleAomIndex: null,
      visibleFseIndex: null,
      visibleCircleIndex: null,
      periodTypeList: [
        { id: 1, name: 'TODAY' },
        { id: 2, name: 'LAST 3 DAYS' },
        { id: 3, name: 'LAST 5 DAYS' },
        { id: 4, name: 'LAST 10 DAYS' },
        { id: 5, name: 'LAST 15 DAYS' },
        { id: 6, name: 'LAST 30 DAYS' },
        { id: 7, name: 'CUSTOM' },
      ],
      period_type: 'LAST 3 DAYS',
      circle_type: "",
      sendEmail: false,
      emailaddress: "",
      message: '',
      showCalender: false,
      recentReportList: [],
      nextCount: 5,
      list: [],
      serialNoList: [],
      allchecked: true,
      driverNameList: "",
      checkbox_value_forDriver: [],
      flag: true,
      checked: true,
      isLoading: false,
      changeStatus: "Download",
      isStartDateSelected: false,
      isEndDateSelected: false,
      areaData: [],
      visibleClusterIndex: null, // Track the visibility of the cluster table
      selectedClusterData: [], // Store the cluster data of the selected date
      visibleFseIndex: null, // Track the visibility of the FSE table
      selectedFseData: [], // Store the FSE data of the selected cluster
      visibleTechnicianIndex: null, // Track the visibility of the technician table
  selectedTechnicianData: [], 
    };
    this.toggleVisibility = this.toggleVisibility.bind(this);
    this.toggleFseVisibility = this.toggleFseVisibility.bind(this);
  }

  componentDidMount() {
    analytics.logEvent('screen_view', {
      screen_name: "TripReport"
    });
    analytics.logEvent('page_view', {
      page_title: "TripReport",
    });
    Amplify.configure(awswebapp);
    Analytics.record({
      name: constant.awsName,
      attributes: { screen_name: 'WEB_TRIP_REPORT' }
    });

    this.imd_dashbord();
  }

  toggleVisibility = (aomIndex) => {
    this.setState({ visibleAomIndex: this.state.visibleAomIndex === aomIndex ? null : aomIndex });
  };

  toggleFseVisibility = (fseIndex, event) => {
    event.stopPropagation();
    this.setState({ visibleFseIndex: this.state.visibleFseIndex === fseIndex ? null : fseIndex });
  };

  toggleClusterVisibility = (index, clusters) => {
    this.setState({
      visibleClusterIndex: this.state.visibleClusterIndex === index ? null : index,
      selectedClusterData: clusters,
      visibleFseIndex: null, // Reset the FSE table visibility
      selectedFseData: [], // Reset the FSE data
    });
  };

  toggleFseVisibility = (index, fse) => {
    this.setState({
      visibleFseIndex: this.state.visibleFseIndex === index ? null : index,
      selectedFseData: fse,
    });
  };


  toggleTechnicianVisibility = (index, technicians) => {
    this.setState({
      visibleTechnicianIndex: this.state.visibleTechnicianIndex === index ? null : index,
      selectedTechnicianData: technicians,
    });
  };


  calculateDateRange = (periodType) => {
    const currentDate = new Date();
    let startDate = new Date();
    let endDate = new Date();

    switch (periodType) {
      case 'TODAY':
        startDate = currentDate;
        break;
      case 'LAST 3 DAYS':
        startDate.setDate(currentDate.getDate() - 3);
        break;
      case 'LAST 5 DAYS':
        startDate.setDate(currentDate.getDate() - 5);
        break;
      case 'LAST 10 DAYS':
        startDate.setDate(currentDate.getDate() - 10);
        break;
      case 'LAST 15 DAYS':
        startDate.setDate(currentDate.getDate() - 15);
        break;
      case 'LAST 30 DAYS':
        startDate.setDate(currentDate.getDate() - 30);
        break;
      case 'CUSTOM':
        startDate = this.state.startDate;
        endDate = this.state.endDate;
        break;
      default:
        startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        endDate = currentDate;
    }

    return { startDate, endDate };
  }


  imd_dashbord = () => {
    this.setState({ isLoading: true });
    const { isStartDateSelected, isEndDateSelected, startDate, endDate } = this.state;
    const user_id = localStorage.getItem("user_id");
    
   
    const past_date = new Date(startDate.setHours(0, 0, 0, 0));  
    const current_date = new Date(endDate.setHours(23, 59, 59, 999));  

    const dg_report_details = {
      user_id: user_id,
      start_time: CommonFunction.createServerDateTime(past_date, this.state.sendStartTime),
      end_time: CommonFunction.createServerDateTime(current_date, this.state.sendEndTime),
    };
     //console.log("mumbai reqiust ",dg_report_details)
   
    ApiUrlCall.mumbai_dashbord_api(dg_report_details)
      .then((response) => {
      //  console.log("mumbai responce ", response)
        this.setState({ isLoading: false });
        if (response.status === 200) {
          const { bifurcated_data_start_only } = response.data.data;

          const master_dash_data = bifurcated_data_start_only
            .filter(data => new Date(data.date) >= past_date && new Date(data.date) <= current_date)
            .sort((a, b) => new Date(a.date) - new Date(b.date))  
            .map((data) => ({
              date: data.date,
              total_indus_id: data.total_indus_id,
              unique_ids_count: data.unique_ids_count,
              duration_sum: data.duration_sum,
              clusters: data.clusters.map((cluster) => ({
                cluster: cluster.cluster,
                count: cluster.count,
                unique_ids_count: cluster.unique_ids_count,
                duration_sum: cluster.duration_sum,
                fse: cluster.fse.map((fse) => ({
                  fse: fse.fse,
                  indus_id_count: fse.indus_id_count,
                  unique_indus_id: fse.unique_indus_id,
                  duration_sum_fse: fse.duration_sum_fse,
                  technicians: fse.technicians.map((technician) => ({
                    technician: technician.technician,
                    unique_indus_id_tse: technician.unique_indus_id_tse,
                    indus_id_count_tse: technician.indus_id_count_tse,
                    duration_sum_tse: technician.duration_sum_tse
                  }))
                }))
              })),
              entries: data.entries.map((entry) => ({
                name: entry.name,
                indus_id: entry.indus_id,
                start_time: entry.start_time,
                end_time: entry.end_time,
                diff: entry.diff,
              }))
            }));

          this.setState({ master_dash_data });
        }
      }).catch(err => {
        this.setState({
          openAlert: true,
          message: "Kindly select valid date"
        });
      });
  }


  


  

  generateExcel = (data) => {
    const workbook = XLSX.utils.book_new();
    const worksheetData = [];

    worksheetData.push([
      'Date',
      'Total Indus ID',
      'Unique Indus ID Count',
      'Total DG ON Duration (minutes)',

      'Cluster Name',
      'Cluster Indus ID Count',
      'Cluster Unique Indus id count',
      'Cluster DG ON Duration (minutes)',

      'FSE Name',
      'FSE Indus ID Count',
      'FSE Unique IDs Count',
      'FSE DG ON Duration (minutes)',

      'Technician Name',
      'Technician Unique  Indus Indus ID',
      'Technician  Unique Indus ID Count',
      'Technician DG ON Duration (minutes)',
    ]);

    data.forEach((masterData) => {
      masterData.clusters.forEach((cluster) => {
        cluster.fse.forEach((fse) => {
          fse.technicians.forEach((technician) => {
            worksheetData.push([
              masterData.date,
              masterData.total_indus_id,
              masterData.unique_ids_count,
              masterData.duration_sum,

              cluster.cluster,
              cluster.count,
              cluster.unique_first_index_count,
              cluster.duration_sum,

              fse.fse,
              fse.indus_id_count,
              fse.unique_indus_id,
              fse.duration_sum_fse,
              technician.technician,
              technician.unique_indus_id_tse,
              technician.indus_id_count_tse,
              technician.duration_sum_tse,
            ]);
          });
        });
      });
    });

    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    XLSX.utils.book_append_sheet(workbook, worksheet, 'MasterDashboardData');
    XLSX.writeFile(workbook, 'IMD_DashboardData.xlsx');
  };


  showCalendar = (e) => {
    var calendardiv = e.currentTarget.parentNode.parentNode.nextSibling;
    $(calendardiv).toggle();
  }

  showStartDate = (e) => {
    $(e.currentTarget).next().slideToggle(200, "swing");
  }

  dateFormate = (rdate) => {
    var date = new Date(rdate);
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    if (day < 10) {
      day = '0' + day;
    }
    if (month < 10) {
      month = '0' + month;
    }
    var convertDate = day + '/' + month + '/' + year;
    return convertDate;
  }

  showTimePicker = (e) => {
    $(e.currentTarget).next().slideToggle();
  }

  selectVehicleOpt = (list) => {
    var input = $('#vehicles_select').find('input');
    for (let j = 0; j < input.length; j++) {
      const element = input[j];
      for (let i = 0; i < list.length; i++) {
        const element1 = list[i];
        if ($(element).val() === element1) {
          $(element).prop('checked', true);
        } else {
        }
      }
    }
  }

  handleChecks() {
    this.setState({ isChecked: !this.state.isChecked });
  }

  hideAllDropDown = () => {
    $('#vehicles_select').parent().hide();
    $('#end_date_cal').hide();
    $('#start_date_cal').hide();
    $('#123').parent().hide();
    $('#456').parent().hide();
    this.setState({
      showStartTime: false,
      showEndTime: false,
    });
  }

  create_Report = () => {
    const { startDate, endDate } = this.state;
    var Difference_In_Time = endDate.getTime() - startDate.getTime();
    var Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));
    if (Difference_In_Days > 90) {
      this.setState({
        sevenDayFlag: false,
        openAlert: true,
        message_vehicle: "Not allowed to select more than 90 days"
      });
      return;
    } else {
      this.setState({
        sevenDayFlag: true
      });
    }
    this.hideAllDropDown();
    this.setState({
      generateReport: true
    }, () => {
      this.imd_dashbord();
    });
  }

  reset = () => {
    this.setState({ dateRange: initialState });
  }

  showStartDate = (e) => {
    $(e.currentTarget).next().slideToggle(200, "swing");
    $('#end_date_cal').slideUp();
    this.setState({
      showStartTime: false,
      showEndTime: false,
    });
  }

  showEndDate = (e) => {
    $(e.currentTarget).next().slideToggle(200, "swing");
    $('#start_date_cal').slideUp();
    this.setState({
      showStartTime: false,
      showEndTime: false,
    });
  }

  doubleDigit = (value) => {
    if (value <= 9) {
      return '0' + value;
    } else {
      return value;
    }
  }

  selectStartTime = (newTime) => {
    this.setState({
      startTime: newTime.formatted12,
      sendStartTime: newTime.formatted24
    });
  }

  showStartTimeContent = () => {
    $('#end_date_cal').slideUp();
    $('#start_date_cal').slideUp();
    if (this.state.showStartTime) {
      this.setState({
        showStartTime: false,
        showEndTime: false
      });
    } else {
      this.setState({
        showStartTime: true,
        showEndTime: false
      });
    }
  }

  selectEndTime = (newTime) => {
    this.setState({
      endTime: newTime.formatted12,
      sendEndTime: newTime.formatted24
    });
  }

  showEndTimeContent = () => {
    $('#end_date_cal').slideUp();
    $('#start_date_cal').slideUp();
    if (this.state.showEndTime) {
      this.setState({
        showEndTime: false,
        showStartTime: false
      });
    } else {
      this.setState({
        showEndTime: true,
        showStartTime: false
      });
    }
  }

  handlePeriodChange = (e) => {
    const periodType = e.target.value;
    const { startDate, endDate } = this.calculateDateRange(periodType);
    this.setState({
      period_type: periodType,
      startDate,
      endDate,
      isStartDateSelected: false,
      isEndDateSelected: false
    }, () => {
      this.create_Report();
    });
  };

  StartdateClicked = date => {
    this.setState({ 
      startDate: date,
      isStartDateSelected: true,
      period_type: 'CUSTOM',
      showCalendar: false
    });
  }

  EnddateClicked = date => {
    this.setState({
      endDate: date,
      isEndDateSelected: true,
      period_type: 'CUSTOM',
      showCalendar: false
    });
  }

  showStartDate = (e) => {
    $(e.currentTarget).next().slideToggle(200, "swing");
    this.setState({ showCalendar: true });
  }

  showEndDate = (e) => {
    $(e.currentTarget).next().slideToggle(200, "swing");
    this.setState({ showCalendar: true });
  }

  formatDateTime(dateTimeString) {
    const date = new Date(dateTimeString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    hours = String(hours).padStart(2, '0');
    return `${year}/${month}/${day} ${hours}:${minutes}:${ampm}`;
  }

  formatDateTimesnew(dateTimeString) {
    const date = new Date(dateTimeString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    hours = String(hours).padStart(2, '0');
    return `${year}/${month}/${day} `;
  }

  render() {
    const { master_dash_data, visibleClusterIndex, selectedClusterData,redirectToReferrer, periodTypeList, visibleFseIndex, selectedFseData, visibleTechnicianIndex, selectedTechnicianData } = this.state;
    const { t } = this.props;

    if (redirectToReferrer === true) {
      return <Redirect to={{ pathname: "/sheduler_report", state: { id: 24 } }} />;
    }

    var minDate = new Date();
    minDate.setDate(minDate.getDate() - 90);

    var period_type_list = periodTypeList.map(period_type_list => {
      return (<option key={period_type_list.id} value={period_type_list.name}>{period_type_list.name}</option>);
    });

    return (
      <div className="Main_div">
        <div className="report_filter_div">
          <Grid spacing={3} container>
            <Grid item xs>
              <p>{t('ct.startdate')}</p>
              <div className="input_div" onClick={this.showStartDate}>
                <input type="text" placeholder={t('ct.startdate')} id="start_date" disabled value={this.dateFormate(this.state.startDate)} className="journey_input"></input>
                <img src="images/fill-111.png" className="vd_journey_icon"></img>
              </div>
              {this.state.showCalendar && (
                <div className="calendar_parent" id="start_date_cal">
                  <Calendar
                    selectRange={false}
                    onChange={this.StartdateClicked}
                    value={this.state.startDate}
                    maxDate={this.state.date}
                    minDate={minDate}
                  />
                </div>
              )}
            </Grid>
            <Grid item xs>
              <p>Start Time</p>
              <div onClick={this.showStartTimeContent} className="time_div">
                <p>{this.state.startTime}</p>
              </div>
              {this.state.showStartTime &&
                <div style={{ position: "absolute", zIndex: "9" }}>
                  <Timekeeper
                    time={this.state.startTime}
                    switchToMinuteOnHourSelect
                    onDoneClick={() => {
                      this.setState({
                        showStartTime: false
                      });
                    }}
                    onChange={this.selectStartTime}
                  />
                </div>
              }
            </Grid>
            <Grid item xs>
              <p>{t('ct.enddate')}</p>
              <div className="input_div" onClick={this.showEndDate}>
                <input type="text" placeholder={t('ct.enddate')} id="end_date" disabled value={this.dateFormate(this.state.endDate)} className="journey_input"></input>
                <img src="images/fill-111.png" className="vd_journey_icon"></img>
              </div>
              {this.state.showCalendar && (
                <div className="calendar_parent" id="end_date_cal">
                  <Calendar
                    selectRange={false}
                    onChange={this.EnddateClicked}
                    value={this.state.endDate}
                    maxDate={this.state.date}
                    minDate={minDate}
                  />
                </div>
              )}
            </Grid>
            <Grid item xs>
              <p>End Time</p>
              <div onClick={this.showEndTimeContent} className="time_div">
                <p>{this.state.endTime}</p>
              </div>
              {this.state.showEndTime &&
                <div style={{ position: "absolute", zIndex: "9" }}>
                  <Timekeeper
                    time={this.state.endTime}
                    switchToMinuteOnHourSelect
                    onDoneClick={() => {
                      this.setState({
                        showEndTime: false
                      });
                    }}
                    onChange={this.selectEndTime}
                  />
                </div>
              }
            </Grid>
            <Grid item xs>
              <label>&nbsp;</label>
              <button className="btn orange-btn btn-block" style={{ marginTop: "6px" }} onClick={this.create_Report}>{t('ars.generate')}</button>
            </Grid>
            <Grid item xs onClick={() => this.generateExcel(this.state.master_dash_data)} >
              <img src="images/group-6-copy-2.png" style={{ marginRight: "5px" }}></img>
              <label className="BSC_semi_bold18">{t('ars.excel')}</label>
            </Grid>
          </Grid>
        </div>
    
        <div className="select_aom_box">
          <table className="summary-table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Total Indus ID</th>
                <th>Unique Indus ID Count</th>
                <th>DG ON Duration (minutes) </th>
              </tr>
            </thead>
            <tbody>
              {master_dash_data.map((data, index) => (
                <React.Fragment key={index}>
                  <tr onClick={() => this.toggleClusterVisibility(index, data.clusters)}>
                    <td>{this.formatDateTimesnew(data.date)}</td>
                    <td>{data.total_indus_id}</td>
                    <td>{data.unique_ids_count}</td>
                    <td>{data.duration_sum}</td>
                  </tr>
                  {visibleClusterIndex === index && (
                    <tr>
                      <td colSpan="4" style={{ padding: 0 }}>
                        <table className="cluster-table">
                          <thead>
                            <tr>
                              <th>Cluster Name</th>
                              <th>Indus ID Count</th>
                              <th>Unique Indus ID Count</th>
                              <th>DG ON Duration (minutes) </th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.clusters.map((cluster, clusterIndex) => (
                              <React.Fragment key={clusterIndex}>
                                <tr onClick={() => this.toggleFseVisibility(clusterIndex, cluster.fse)}>
                                  <td>{cluster.cluster}</td>
                                  <td>{cluster.count}</td>
                                  <td>{cluster.unique_first_index_count}</td>
                                  <td>{cluster.duration_sum}</td>
                                </tr>
                                {visibleFseIndex === clusterIndex && (
                                  <tr>
                                    <td colSpan="4" style={{ padding: 0 }}>
                                      <table className="fse-table">
                                        <thead>
                                          <tr>
                                            <th>FSE Name</th>
                                            <th>Indus ID Count</th>
                                            <th>Unique Indus ID Count</th>
                                            <th> DG ON Duration (minutes) </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {cluster.fse.map((fse, fseIndex) => (
                                            <React.Fragment key={fseIndex}>
                                              <tr onClick={() => this.toggleTechnicianVisibility(fseIndex, fse.technicians)}>
                                                <td>{fse.fse}</td>
                                                <td>{fse.indus_id_count}</td>
                                                <td>{fse.unique_indus_id}</td>
                                                <td>{fse.duration_sum_fse}</td>
                                              </tr>
                                              {visibleTechnicianIndex === fseIndex && (
                                                <tr>
                                                  <td colSpan="6" style={{ padding: 0 }}>
                                                    <table className="technician-table">
                                                      <thead>
                                                        <tr>
                                                          <th>Technician Name</th>
                                                          <th>Indus ID Count</th>
                                                          <th>Unique Indus ID  Count</th>
                                                          <th> DG ON Duration (minutes) </th>
                                                        </tr>
                                                      </thead>
                                                      <tbody>
                                                        {fse.technicians.map((technician, technicianIndex) => (
                                                          <tr key={technicianIndex}>
                                                            <td>{technician.technician}</td>
                                                            <td>{technician.indus_id_count_tse}</td>
                                                            <td>{technician.unique_indus_id_tse}</td>   
                                                            <td>{technician.duration_sum_tse}</td>
                                                          </tr>
                                                        ))}
                                                      </tbody>
                                                    </table>
                                                  </td>
                                                </tr>
                                              )}
                                            </React.Fragment>
                                          ))}
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                )}
                              </React.Fragment>
                            ))}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
    
        {this.state.isLoading && (
          <div id="scroll">
            <div id="scrolling" style={{ display: 'flex', justifyContent: 'center' }}>
              <RotatingLines
                visible={true}
                height="60"
                width="60"
                strokeColor="grey"
                strokeWidth="5"
                animationDuration="0.75"
                ariaLabel="rotating-lines-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </div>
          </div>
        )}
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          autoHideDuration={1800}
          onClose={(e, reason) => {
            if (reason === 'clickaway') {
              return;
            }
            this.setState({ openAlert: false });
          }}
          open={this.state.openAlert}
          message={this.state.message_vehicle}
        />
      </div>
    );
    
  }
}

export default withTranslation()(IMD_reports);
