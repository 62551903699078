import React, { Component, createRef } from 'react';
import domtoimage from "dom-to-image";

import $ from 'jquery';
import './vehicles.scss'
import Table from 'react-bootstrap/Table';
import Grid from '@mui/material/Grid';
import ApiUrlCall from '../API/apiurl';
// import Map from "mapmyindia-react";
import Map from '../map/map'
import Navbar from '../../Config/Navbar';
import CircularProgress from '@mui/material/CircularProgress';
import CommonFunction from '../common_function'
import analytics from '../../Config/firebase'
import Amplify, { Analytics } from 'aws-amplify';
import awswebapp from '../../aws-exports';
import constant from '../constant.json'
import { withTranslation } from 'react-i18next';
import { MDBDataTable } from 'mdbreact';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'mdbreact/dist/css/mdb.css';
import { ProgressBar } from 'react-bootstrap';
import Digital from 'react-activity/lib/Digital';
import 'react-activity/lib/Digital/Digital.css';
import Calendar from 'react-calendar/dist/entry.nostyle'
import Slider from '@mui/material/Slider';
import Snackbar from '@mui/material/Snackbar';
import Timekeeper from 'react-timekeeper';
import TreeView from "react-treeview";
import Modal from 'react-responsive-modal';
import "react-treeview/react-treeview.css";
import DashCam from '../Dashcam/DashCam';
import { isNullishCoalesce } from 'typescript';




const data1 = [
  {
    label: 'VEHICLE NAME',
    field: 'vehicle_name',
    sort: 'asc',
    width: 150,
    attributes: {
      'aria-controls': 'DataTable',
      'aria-label': 'Name',
    },
  },
  {
    label: 'FUEL TYPE',
    field: 'fuel_type',
    sort: 'asc',
    width: 150,
  },
  {
    label: 'CURRENT FUEL LEVEL',
    field: 'current_fuel',
    sort: 'asc',
    width: 150,
  },
  {
    label: 'VEHICLE NUMBER',
    field: 'reg_number',
    sort: 'asc',
    width: 150,
  },
  {
    label: 'MAKE',
    field: 'make',
    sort: 'asc',
    width: 150,
  },
  {
    label: 'MODEL',
    field: 'model',
    sort: 'asc',
    width: 150,
  },
  {
    label: 'KMS Driven',
    field: 'distance_driven',
    sort: 'asc',
    width: 150,
  },
  {
    label: 'STATUS',
    field: 'status',
    sort: 'asc',
    width: 150,
  },
  {
    label: 'ACTION',
    field: 'action',
    sort: 'asc',
    width: 150,
  }
]

const data2 = [
  {
    label: 'VEHICLE NAME',
    field: 'vehicle_name',
    sort: 'asc',
    width: 150,
    attributes: {
      'aria-controls': 'DataTable',
      'aria-label': 'Name',
    },
  },
  {
    label: 'VEHICLE REGISTRATION NUMBER',
    field: 'reg_no',
    sort: 'asc',
    width: 150,
  },
  {
    label: 'VEHICLE TYPE',
    field: 'type',
    sort: 'asc',
    width: 150,
  },
  {
    label: 'ADDED ON',
    field: 'added',
    sort: 'asc',
    width: 200,
  },
  {
    label: 'VALID TILL',
    field: 'valid',
    sort: 'asc',
    width: 150,
  },
  {
    label: 'ACTION',
    field: 'action',
    sort: 'asc',
    width: 150,
  }
]

const data3 = [
  {
    label: 'PLAN',
    field: 'checkbox',
    sort: 'asc',
    width: 150,
  },
  {
    label: 'DURATION',
    field: 'duration',
    sort: 'asc',
    width: 150,
  },
  {
    label: 'PRICING',
    field: 'price',
    sort: 'asc',
    width: 150,
  }
]

const L = window.L
const MOVING_ICON = 'images/vehicle_icon/Car moving.png';
const IDLING_ICON = 'images/vehicle_icon/Car idling.png'
const PARKED_ICON = 'images/vehicle_icon/Car parked.png'
const CONNECTION_LOST_ICON = 'images/vehicle_icon/Car lost connection.png'
var resetSliderFlag = true

class VehicleLists extends Component {
  counter = 0
  constructor(props) {
    super(props);
    this.divRef = createRef();
    this.handleScreenshot = this.handleScreenshot.bind(this);
    this.mmiMapComponentRef = React.createRef()
    this.state = {
      vehicleData: '',
      searchVehicleData: [],
      dashcamdata: [],
      showMap: false,
      selectedCameraOptions: null,


      showVehicleList: true,
      showTempVehicleList: false,
      vehicleLiveTrack: '',
      markers: [],
      mapContent: '',
      mapIntialized: true,
      boundValueis: '',
      boundLat: [],
      boundLng: [],
      boundFlag: true,
      datatable: [],
      nextCount: 0,
      totalCount: '',
      buttonFlag: true,
      dataCollection: [],
      chunckValue: '',
      searchText: '',
      totalEntries: '',
      refreshDate: new Date(),
      startDate: new Date(),
      endDate: new Date(),
      trackingSelect: 0,
      selectedVehicleName: '',
      selectedGroupName: '',
      selectedVehicleSerialNumber: '',
      selectedGroupIdNumber: '',
      setView: false,
      clusterMarker: true,
      points: '',
      polylineParam: '',
      lastPolylineLatLng: '',
      currentPolylineLatLng: '',
      vehicleStatus: 0,
      boundLat: [],
      boundLng: [],
      setIcon: true,
      live_or_replay: true,
      replayRouteData: '',
      slidedValue: 0,
      sliderDuration: 0,
      sliderMax: 0,
      playReplayRoute: true,
      openAlert: false,
      errorMessage: '',
      panningFlag: true,
      invalidShareNumber: true,
      shareNumber: '',
      replayDuration: '00:00',
      validityDate: '',
      showValidDateCal: false,
      addVehicleName: '',
      addVehiclRegNumber: '',
      isValidChecked: false,
      untrackData: [],
      vehicleList: [],
      selectedVehicle: '',
      startTime: "12:00 am",
      sendStartTime: "00:00",
      showStartTime: false,
      endTime: "11:59 pm",
      sendEndTime: "23:59",
      showEndTime: false,
      endRR: false,
      replayIntervalSpeed: 1000,
      journeyStopList: [],
      vehicle_type_id: 1,
      all_vehicle_list: [],
      nextCountFormap: 0,
      isEnd: false,
      isAllSelected: true,
      showVehicleGroup: false,
      groupDataList: [],
      vlcGroupDataList: [],
      addGroupName: '',
      vehicle_name: "",
      serial_number_is: [],
      chckebox_value: [],
      groupId: 0,
      updated: false,
      selectedcheckbox_value: [],
      counter: 0,
      showLocation: false,
      hasLocationData: false,
      currentPlanDetails: "",
      currentSerialNo: "",
      skuId: "",
      amount: 0,
      renewalPlanList: [],
      datatable1: [],
      True: "NO",
      expiry_minutes: '480',
      showDropdown: false,
      personLiveTrack: '',
    }
    //this.getVehicleList = this.getVehicleList.bind(this)
  }
  updateChildMap = (array, isLastChunk) => {
    if (this.mmiMapComponentRef.current != null) {
      this.mmiMapComponentRef.current.updateMarkers(array, isLastChunk, this.state.boundFlag)
    }
  }

  // *************************************************    start         **************************************************
  // handleMarkerRightClick = (cameraData) => {
  //   this.setState({
  //     selectedCameraOptions: cameraData, // Store selected camera data

  //     isPopupVisible: true, // Show popup
  //   });
  // };


  handleMarkerRightClick = (cameraData) => {
    this.setState(
      {
        selectedCameraOptions: cameraData,
        isPopupVisible: true,
      },
      () => {
        console.log("Updated selectedCameraOptions:", this.state.selectedCameraOptions);
      }
    );
  };



  handleScreenshot = () => {
    const mapContainer = document.querySelector("#map_div");

    if (!mapContainer) {
      console.error("Map container not found!");
      return;
    }

    // Store the original background color
    const originalBackground = mapContainer.style.backgroundColor;

    // Set the background to black
    mapContainer.style.backgroundColor = "white";

    domtoimage
      .toPng(mapContainer, {
        bgcolor: "black", // Ensure dom-to-image sets the background to black
      })
      .then((dataUrl) => {
        // Restore the original background color
        mapContainer.style.backgroundColor = originalBackground;

        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = "vehicle_map_screenshot.jpg";
        link.click();
      })
      .catch((error) => {
        console.error("Screenshot failed!", error);
        // Restore background in case of error
        mapContainer.style.backgroundColor = originalBackground;
      });
  };

  // ************************************************************************************************** end 

  componentDidMount() {
    analytics.logEvent('screen_view', {
      screen_name: "VehicleList"
    })
    analytics.logEvent('page_view', {
      page_title: "VehicleList",
    })
    Amplify.configure(awswebapp);
    Analytics.record({
      name: constant.awsName,
      // Attribute values must be strings
      attributes: { screen_name: 'WEB_VEHICLE_LIST' }
    });
    var navBarHeight = $('.navBar').outerHeight();
    $('.vehicle_list').css({
      paddingTop: navBarHeight + 60,
      paddingLeft: "100px",
      paddingRight: "100px"
    })
    this.getVehicleList()
    this.getVehicleListForGroup()
    //this.getAllVehicleList()
    this.getVehicleAndSerialNoList()
    this.checkedUserHasLocationData()
    // this.getVehicleMarker()
    //this.getJourneyStopDetails()
  }

  componentWillUnmount() {
    clearInterval(this.interval)
    clearInterval(this.singleInterval)
  }


  buttonStatus = () => {
    const { nextCount, totalCount } = this.state
    if (nextCount === totalCount) {
      if (this.counter === 0) {
        $('#dt-previous').removeClass('enabled-btn')
        $('#dt-previous').addClass('disable-btn')
        $('#dt-next').removeClass('enabled-btn')
        $('#dt-next').addClass('disable-btn')
      } else {
        $('#dt-previous').removeClass('disable-btn')
        $('#dt-previous').addClass('enabled-btn')
        $('#dt-next').removeClass('enabled-btn')
        $('#dt-next').addClass('disable-btn')
      }
    } else {
      if (this.counter === 0) {
        $('#dt-previous').removeClass('enabled-btn')
        $('#dt-previous').addClass('disable-btn')
        $('#dt-next').removeClass('disable-btn')
        $('#dt-next').addClass('enabled-btn')
      } else {
        $('#dt-previous').removeClass('disable-btn')
        $('#dt-previous').addClass('enabled-btn')
        $('#dt-next').addClass('enabled-btn')
        $('#dt-next').removeClass('disable-btn')
      }
    }
  }

  showTimePicker = (e) => {
    $(e.currentTarget).next().slideToggle()
  }

  entriesData = () => {
    const { nextCount, totalCount, chunckValue } = this.state
    if (nextCount != totalCount) {
      var start = nextCount - chunckValue + 1
      var end = nextCount
      var entries_text = start + '-' + end
      this.setState({
        totalEntries: entries_text
      })
    } else {
      if (nextCount <= 10) {
        if (nextCount == 0) {
          this.setState({
            totalEntries: "0"
          })
        } else {
          var entries_text = '1' + '-' + totalCount
          this.setState({
            totalEntries: entries_text
          })
        }
      } else {
        var module = nextCount % chunckValue
        var start
        var end
        var entries_text
        if (module > 0) {
          start = nextCount - module + 1
          end = nextCount
          entries_text = start + '-' + end
        } else {
          start = nextCount - chunckValue
          end = nextCount
          entries_text = start + '-' + end
        }
        this.setState({
          totalEntries: entries_text
        })
      }
    }
  }

  getFuelType = (type) => {
    const { t } = this.props
    // console.log(type, "fuel type is")
    var fuel_type = [t('ct.gas'), t('ct.diesel'), t('ct.petrol'), t('ct.cng'), t('ct.ethanol'), t('ct.bio')][type]
    return fuel_type
  }

  returnFuelLevel = (level) => {
    if (level) {
      return CommonFunction.zeroAppend(level) + " Litres"
    } else {
      return "NA"
    }
  }

  createTableData = () => {
    const { vehicleData, datatable, untrackData } = this.state
    var row = []
    // console.log('lenght is', vehicleData.length)
    if (this.state.showVehicleList) {
      for (let i = 0; i < vehicleData.length; i++) {
        const element = vehicleData[i];
        var row_data = {
          vehicle_name: element.vehicle_name, fuel_type: this.getFuelType(element.fuel_type), reg_number: element.reg_number, make: element.make,
          model: element.model, distance_driven: element.distance_driven,
          current_fuel: this.returnFuelLevel(element.fuel_level),
          status: this.VehicleStatus(element.status),
          action: <p className="cj_w_header" style={{ color: '#f4811f', cursor: "pointer" }} onClick={() => this.uniqueVehicleId(element.serial_number, element.service_expired)}>Vehicle Details</p>
        }
        datatable.push(row_data)
      }
      this.buttonStatus()
      this.entriesData()
      this.forceUpdate()
    }

    if (this.state.showTempVehicleList) {
      for (let i = 0; i < untrackData.length; i++) {
        const element = untrackData[i];
        var row_data = {
          vehicle_name: element.name,
          reg_no: element.reg_number,
          type: element.vehicle_type,
          added: CommonFunction.tableDateFormate(element.added_on) + ', ' + CommonFunction.timeFormate(element.added_on),
          valid: CommonFunction.tableDateFormate(element.expiry_date),
          action: <p className="cj_w_header" style={{ color: '#f4811f', cursor: "pointer" }} onClick={() => {
            this.props.history.push({
              pathname: '/setting'
            })
          }}>Map</p>
        }
        datatable.push(row_data)
      }
      this.forceUpdate()
    }
    // this.intervalData()
  }

  emptyTable = () => {
    this.setState({
      datatable: []
    })
  }
  // ################## not used 

  preferedDatavehicle = (data) => {
    const { selectedVehicle, vehicleData } = this.state
    // console.log('vehicle list', this.state.vehicleData)
    if (vehicleData.length > 0) {
      var vehicle = ''
      var serial_number = []
      for (let i = 0; i < vehicleData.length; i++) {
        const element = vehicleData[i];
        serial_number.push(parseInt(element.serial_number))
        vehicle.push(element.name)
      }
      this.setState({
        selectedVehicle: vehicle,
        prefer_vehicle: serial_number,
      })
    } else {
      return
    }
    // console.log(prefer_vehicle,'selected vehicle is', selectedVehicle)


  }

  // ################## not used 


  getAllVehicleList = () => {
    $('#processing').show()
    // return


    var data = { next_count: this.state.nextCount, search: this.state.searchText, start_time: '', end_time: '' }
    const apicall = this.state.personLiveTrack ? ApiUrlCall.getPeopleList : ApiUrlCall.getVehicleList;
    apicall(JSON.stringify(data)).then(response => {
      //console.log('vehicle list is', response)      
      this.emptyTable()
      if (response.data.response_code === 200 && response.data.is_success === true) {
        this.setState({
          vehicleData: response.data.data.vehicle_summary_list,
          searchVehicleData: response.data.data.vehicle_summary_list,
          nextCount: response.data.data.next_count,
          totalCount: response.data.data.total_count,
          chunckValue: response.data.data.size
        }, () => {
          this.createTableData()
        })
        $('#processing').hide()
      } else {
        $('#processing').hide()
      }
    }).catch(error => {
      $('#processing').hide()
      console.log(error, "error while fetching vehicle list");

    })
  }

  getVehicleList = () => {
    $('#processing').show()
    // return
    if (this.state.showMap) {
      var data = { next_count: this.state.nextCount, search: this.state.searchText, search_column: "vehicle_name", start_time: '', end_time: '' }
    } else if (this.state.showVehicleList) {
      var data = { next_count: this.state.nextCount, search: this.state.searchText, start_time: '', end_time: '' }
    }
    ApiUrlCall.getVehicleList(JSON.stringify(data)).then(response => {
      // console.log('vehicle list is', response)
      this.emptyTable()
      if (response.data.response_code === 200 && response.data.is_success === true) {
        this.setState({
          vehicleData: response.data.data.vehicle_summary_list,
          searchVehicleData: response.data.data.vehicle_summary_list,
          nextCount: response.data.data.next_count,
          totalCount: response.data.data.total_count,
          chunckValue: response.data.data.size
        }, () => {
          this.createTableData()
        })
        $('#processing').hide()
      } else {
        $('#processing').hide()
      }
    }).catch(error => {
      $('#processing').hide()
      console.log(error, "error while fetching vehicle list");
    })
  }

  getPersonList = () => {
    $('#processing').show()
    // return
    if (this.state.showMap) {
      var data = { next_count: this.state.nextCount, search: this.state.searchText, search_column: "vehicle_name", start_time: '', end_time: '' }
    } else if (this.state.showVehicleList) {
      var data = { next_count: this.state.nextCount, search: this.state.searchText, start_time: '', end_time: '' }
    }
    ApiUrlCall.getPeopleList(JSON.stringify(data)).then(response => {
      this.emptyTable()
      if (response.data.response_code === 200 && response.data.is_success === true) {
        this.setState({
          vehicleData: response.data.data.people_summary_list,
          searchVehicleData: response.data.data.people_summary_list,
          nextCount: response.data.data.next_count,
          totalCount: response.data.data.total_count,
          chunckValue: response.data.data.size
        }, () => {
          // this.createTableData()
        })
        $('#processing').hide()
      } else {
        $('#processing').hide()
      }
    }).catch(error => {
      $('#processing').hide()
      console.log(error, "error while fetching vehicle list");
    })
  }


  pageNextClick = () => {
    const { totalCount, nextCount, dataCollection, datatable } = this.state
    // console.log(this.counter,'next clicked',totalCount,'---------',nextCount)
    this.counter = this.counter + 1
    for (let i = 0; i < datatable.length; i++) {
      const element = datatable[i];
      dataCollection.push(element)
    }
    this.setState({
      datatable: []
    }, () => {
      this.getVehicleList()
    })
  }

  pagePreviousClick = () => {
    const { dataCollection, chunckValue, nextCount } = this.state
    var pageSize = nextCount % chunckValue
    if (pageSize === 0) {
      pageSize = chunckValue
    }
    // console.log('previous clicked', pageSize)    
    var last = dataCollection.splice(Math.max(dataCollection.length - chunckValue))
    this.setState({
      datatable: last,
      nextCount: nextCount - pageSize
    }, () => {
      this.buttonStatus()
      this.entriesData()
    })
    this.counter = this.counter - 1
  }

  optimizationSearch = (value) => {
    setTimeout(() => {
      this.counter = 0
      if (value.length >= 3) {
        this.setState({
          datatable: [],
          dataCollection: [],
          totalCount: '',
          chunckValue: '',
          nextCount: 0,
          searchText: value
        }, () => {
          this.getVehicleList()
        })
      } else if (value.length === 0) {
        this.setState({
          datatable: [],
          dataCollection: [],
          totalCount: '',
          chunckValue: '',
          nextCount: 0,
          searchText: value
        }, () => {
          this.getVehicleList()
        })
      }
    }, 1000);
  }

  vlListView = (e) => {
    $(e.currentTarget).addClass('selected_border')
    $(e.currentTarget).siblings().removeClass('selected_border')
    this.setState({
      showMap: false,
      showVehicleList: true,
      showTempVehicleList: false,
      searchText: "",
      totalCount: 0,
      nextCount: 0,
      boundFlag: true,
      mapContent: "",
      trackingSelect: "",
      clusterMarker: true,
      showVehicleGroup: false,
    }, () => {
      /////clearInterval(this.interval)
      clearInterval(this.singleInterval)
      this.resetLiveTrack()
      // this.selectAllTracking()
      this.getVehicleList()

    })
  }

  setIntervalLiveData = () => {
    $('#processing').show()
    // console.log("inside setinterval")

    this.getVehicleMarker();

  }
  setIntervalLivePersonData = () => {
    $('#processing').show()
    // console.log("inside setinterval")

    this.getPersonMarkers();

  }



  getVehicleMarker = () => {
    if (this.state.trackingSelect != 0) {
      return
    }
    // console.log({next_count : this.state.nextCountFormap,group_id : this.state.selectedGroupIdNumber},new  Date().toLocaleString())
    this.mounted = true
    ApiUrlCall.getAllVehicleLiveTrackingNew({ next_count: this.state.nextCountFormap, group_id: this.state.selectedGroupIdNumber }).then(response => {

      // console.log('vehicle response is', response)
      if (response.data.response_code === 200 && response.data.is_success) {
        $('#processing').hide()
        this.setState({
          vehicleLiveTrack: response.data.data.live_track,
          nextCountFormap: response.data.data.next_count,
          isEnd: response.data.data.is_end
        }, () => {
          if (this.state.isEnd) {
            this.onFeatchVehiclesCompletion()
          } else {
            this.getVehicleMarker()
          }
          this.drawMarker()
        })
      }
    }).catch(error => {
      $('#processing').hide()
      // console.log('error while fetching data is', error.response)
    })
  }

  getPersonMarkers = () => {
    // console.log("inside person marker")
    
    if (this.state.trackingSelect != 0) {
      return
    }
    // console.log({next_count : this.state.nextCountFormap,group_id : this.state.selectedGroupIdNumber},new  Date().toLocaleString())
    this.mounted = true
    ApiUrlCall.getAllPersonLiveTrackingNew({ next_count: this.state.nextCountFormap, group_id: this.state.selectedGroupIdNumber }).then(response => {

      // console.log('person response is', response)
      if (response.data.response_code === 200 && response.data.is_success) {
        $('#processing').hide()
        this.setState({
          personLiveTrack: response.data.data.live_track,
          nextCountFormap: response.data.data.next_count,
          isEnd: response.data.data.is_end
          
        }, () => {
          if (this.state.isEnd) {
            this.onFeatchPersonCompletion()
          } else {
            this.getPersonMarkers()
          }
          this.drawPersonMarkers()
          // this.drawMarker()

        })
      }
    }).catch(error => {
      $('#processing').hide()
      // console.log('error while fetching data is', error.response)
    })
  }
  onFeatchVehiclesCompletion = () => {
    setTimeout(function () {
      this.setState({
        nextCountFormap: 0,
        markers: [],
        isEnd: false,
        vehicleLiveTrack: [],
      }, () => {
        if (this.state.trackingSelect === 0) {
          this.getVehicleMarker()
        }
      })
    }.bind(this), 5000)
  }
  onFeatchPersonCompletion = () => {
    // console.log("onFeatchPersonCompletion")
    setTimeout(function () {
      this.setState({
        nextCountFormap: 0,
        markers: [],
        isEnd: false,
        vehicleLiveTrack: [],
      }, () => {
        if (this.state.trackingSelect === 0) {
          this.getPersonMarkers()
        }
      })
    }.bind(this), 5000)
  }


  getJourneyStopDetails = (serial_number, start_date, end_date, stop_type) => {
    var journey_id = "" //this.props.location.state.journey_id
    ApiUrlCall.JourneyGetStops(JSON.stringify({ journey_id: journey_id, stop_type: stop_type, serial_number: serial_number, start_date: start_date, end_date: end_date })).then(
      response => {
        console.log("JOURNEY STOPS DETAILS", serial_number)
        if (response.data.response_code == 200 && response.data.is_success) {
          this.setState({
            journeyStopList: response.data.data.stop_list
          }, () => {
            this.createStopsObj()
          })

        }
      }
    ).catch(
      error => {
        //          console.log("ERROR WHILE GETTING JOURNEY STOPS DETAILS" , error)
      }
    )

  }

  createStopsObj = () => {
    // console.log('create stops object', STOPDETAILS)
    const { journeyStopList } = this.state
    var array = []


    if (journeyStopList.length > 0) {
      for (let i = 0; i < journeyStopList.length; i++) {
        const element = journeyStopList[i];
        array.push(
          {
            position: [element.lat, element.lng],
            name: element.name,
            images: element.pic_list
          }
        )
      }
      // console.log('stop array', array)
      this.setState({
        stopObj: array
      }, () => {
        // console.log('stops', this.state.stopObj)
      })
    }
    // this.forceUpdate()
  }


  getAddress1 = (objectvehicle, index) => {
    let addressLatLng = { lat: objectvehicle.lat, lng: objectvehicle.lng }
    ApiUrlCall.getAddressMMI(JSON.stringify(addressLatLng)).then(response => {
      // console.log('address is', response)
      //console.log('address is', response.data.data.address)
      objectvehicle["address"] = response.data.data.address
      let newvehicleListtrack = [...this.state.vehicleLiveTrack]
      newvehicleListtrack[index]["address"] = response.data.data.address
      //console.log("my print",newvehicleListtrack)
      //console.log("my print new",objectvehicle)
      //return response.data.data.address
      this.setState({
        vehicleLiveTrack: newvehicleListtrack
      })
    })
  }


  updateMarker = () => {
    let timeduration = this.state.vehicleLiveTrack.length * 300
    for (let i = 0; i < this.state.vehicleLiveTrack.length; i++) {
      //var data = { name: this.state.vehicleLiveTrack[i].name }
      //console.log(this.state.vehicleLiveTrack[i])
      //console.log("Tracker serial number for vehicle name: " +this.state.vehicleLiveTrack[i].name + " is "+ this.state.vehicleLiveTrack[i].lat + ","+ this.state.vehicleLiveTrack[i].lng + " is " + this.state.vehicleLiveTrack[i].serial_number)
      //var vaddress=this.getAddress1(this.state.vehicleLiveTrack[i].lat,this.state.vehicleLiveTrack[i].lng);
      this.getAddress1(this.state.vehicleLiveTrack[i], i)
    }
    setTimeout(function () {
      this.drawMarker()
    }.bind(this), timeduration)
  }
  drawPersonMarkers = () => {
    const personArray = [];
    // console.log("drawPersonMarkers" + this.state.personLiveTrack)

    
    if (this.state.personLiveTrack) {
      for (let i = 0; i < this.state.personLiveTrack.length; i++) {
        let personData = this.state.personLiveTrack[i];

        personArray.push({
          position: [personData.lat, personData.lng],
          rotateMarker: true,
          icon: L.icon({
            iconUrl: ApiUrlCall.getVehicleIcon(this.state.personLiveTrack[i].vehicle_type_id,
              this.state.personLiveTrack[i].vehicle_state, this.state.personLiveTrack[i].tracker_state),
            iconRetinaUrl: ApiUrlCall.getVehicleIcon(this.state.personLiveTrack[i].vehicle_type_id,
              this.state.personLiveTrack[i].vehicle_state, this.state.personLiveTrack[i].tracker_state),

            iconSize: [40, 40],
            popupAnchor: [-3, -15]
          }),
          draggable: false,
          setViewFlag: this.state.setView,
          title: personData.name,
          Address: personData.address,
          Last_Reported_Time: CommonFunction.tableDateFormate(personData.last_reported_time) + ', ' + CommonFunction.timeFormate1(personData.last_reported_time),
          serial_number: personData.serial_number,
          latitude: personData.lat,
          longitude: personData.lng,
          popupData: personData,
          onClick: () => { },
          onDragend: () => { }

        });
        // console.log("personArray:" + personArray)
      }
    }

    this.setState({ markers: personArray }, () => {
      if (this.state.mapContent == "") {
        this.returnMap123();  // First render
      } else {
        this.updateChildMap(personArray, this.state.isEnd);  // Update map
      }
    });
  }


  drawMarker = () => {
    const array = []
    const icon = [MOVING_ICON, IDLING_ICON, PARKED_ICON, CONNECTION_LOST_ICON]
    if (this.state.vehicleLiveTrack) {

      for (let i = 0; i < this.state.vehicleLiveTrack.length; i++) {
        var data = { name: this.state.vehicleLiveTrack[i].name }
        array.push(
          {
            position: [this.state.vehicleLiveTrack[i].lat, this.state.vehicleLiveTrack[i].lng],
            rotateMarker: true,
            icon: L.icon({
              iconUrl: ApiUrlCall.getVehicleIcon(this.state.vehicleLiveTrack[i].vehicle_type_id,
                this.state.vehicleLiveTrack[i].vehicle_state, this.state.vehicleLiveTrack[i].tracker_state),
              iconRetinaUrl: ApiUrlCall.getVehicleIcon(this.state.vehicleLiveTrack[i].vehicle_type_id,
                this.state.vehicleLiveTrack[i].vehicle_state, this.state.vehicleLiveTrack[i].tracker_state),
              iconSize: [40, 40],
              popupAnchor: [-3, -15]
            }),
            draggable: false,
            setViewFlag: this.state.setView,
            title: this.state.vehicleLiveTrack[i].name,
            Address: this.state.vehicleLiveTrack[i].address,
            Last_Reported_Time: CommonFunction.tableDateFormate(this.state.vehicleLiveTrack[i].last_reported_time) + ', ' + CommonFunction.timeFormate(this.state.vehicleLiveTrack[i].last_reported_time),

            battery_percentage: this.state.vehicleLiveTrack[i].battery_percentage,
            serial_number: this.state.vehicleLiveTrack[i].serial_number,
            latitude: this.state.vehicleLiveTrack[i].lat,
            longitude: this.state.vehicleLiveTrack[i].lng,
            // Battery_percentage: 20,
            popupData: data,
            vehicle_type_id: this.state.vehicleLiveTrack[i].vehicle_type_id,
            onClick: () => { },
            onDragend: () => { }
          }
        )
      }
    } else if (this.state.personLiveTrack) {
      for (let i = 0; i < this.state.personLiveTrack.length; i++) {
        var data = { name: this.state.personLiveTrack[i].name }
        array.push(
          {
            position: [this.state.personLiveTrack[i].lat, this.state.personLiveTrack[i].lng],
            rotateMarker: true,
            icon: L.icon({
              iconUrl: ApiUrlCall.getVehicleIcon(this.state.personLiveTrack[i].vehicle_type_id,
                this.state.personLiveTrack[i].vehicle_state, this.state.personLiveTrack[i].tracker_state),
              iconRetinaUrl: ApiUrlCall.getVehicleIcon(this.state.personLiveTrack[i].vehicle_type_id,
                this.state.personLiveTrack[i].vehicle_state, this.state.personLiveTrack[i].tracker_state),
              iconSize: [40, 40],
              popupAnchor: [-3, -15]
            }),
            draggable: false,
            setViewFlag: this.state.setView,
            title: this.state.personLiveTrack[i].name,
            Address: this.state.personLiveTrack[i].address,
            Last_Reported_Time: CommonFunction.tableDateFormate(this.state.personLiveTrack[i].last_reported_time) + ', ' + CommonFunction.timeFormate(this.state.personLiveTrack[i].last_reported_time),

            battery_percentage: this.state.personLiveTrack[i].battery_percentage,
            serial_number: this.state.personLiveTrack[i].serial_number,
            latitude: this.state.personLiveTrack[i].lat,
            longitude: this.state.personLiveTrack[i].lng,
            // Battery_percentage: 20,
            popupData: data,
            vehicle_type_id: this.state.personLiveTrack[i].vehicle_type_id,
            onClick: () => { },
            onDragend: () => { }
          }
        )
      }


    }



    this.setState({
      markers: array,
    }, () => {
      if (this.state.mapContent == "") {
        //console.log("Inside draw new map ******** ")
        this.returnMap123()
      }
      else {
        //console.log("Inside update new map ******** ")
        this.updateChildMap(array, this.state.isEnd)
      }
    })
  }

  returnMap123 = () => {
    // console.log("returnMap123")
    const maps = []
    if (this.state.markers && this.state.trackingSelect == 0) {
      // console.log("IN RETURN MAP -- LIVE TRACK ", this.state.markers.length + "is LastChuck **** " + this.state.isEnd)
      if (this.state.markers.length > 0) {
        // console.log("mappppp1")
        maps.push(
          <Map
            ref={this.mmiMapComponentRef}
            height={"92%"}
            cluster={this.state.clusterMarker}
            onMarkerRightClick={this.handleMarkerRightClick}
            // onplaybackClick={this.RightClickPlayback}


            markers={this.state.markers}
            stops={this.state.stopObj}
            pauseBound={this.state.boundFlag}
            isLastChunk={this.state.isEnd}
            showlocation={this.state.showLocation}
            onZoom={() => {
              this.setState({
                boundFlag: false
              })
            }}
          />
        )
      }
    } else {
      // console.log('replay route map pushed')
      maps.push(
        <Map
          height={"90%"}
          zoom={14}
          stops={this.state.stopObj}
          cluster={this.state.clusterMarker}
          isLastChunk={this.state.isEnd}
          vehicleReplayRoute={true}
          // panningflag = {this.state.panningFlag}                     
          replayIntervalSpeed={this.state.replayIntervalSpeed}
          vehicle_type_id={this.state.replayRouteData.vehicle_type_id}
          // vehicle_type_id={this.state.personLiveTrack}
          showlocation={false}
          replayroutedata={
            {
              replay: this.state.replayRouteData.replay_data,
              index: this.state.slidedValue,
              flag: this.state.playReplayRoute,
              sliderValue: this.slideValue,
              serial_number: this.state.selectedVehicleSerialNumber,
              show: this.state.live_or_replay,
              title: this.state.selectedVehicleName
            }

          }
          polyline={
            {
              points: this.state.points,
              polylineParam: this.state.polylineParam,
              icon: this.state.setIcon,
              state: this.state.vehicleStatus,
              speed: 500,
              show: this.state.live_or_replay,
              serial_number: this.state.selectedVehicleSerialNumber,
              title: this.state.selectedVehicleName,
              vehicle_type_id: this.state.replayRouteData.vehicle_type_id
            }
          }
        />
      )

    }
    this.setState({
      mapContent: maps
    })
  }

  returnMap = () => {
    const maps = [];
    const isGroup = localStorage.getItem('group') == 34;

    if (this.state.markers && this.state.trackingSelect == 0) {
      if (this.state.markers.length > 0) {
        maps.push(
          <Map
            height={"92%"}
            cluster={this.state.clusterMarker}
            onMarkerRightClick={this.handleMarkerRightClick}
            stops={this.state.stopObj}
            isLastChunk={this.state.isEnd}
            pauseBound={this.state.boundFlag}
            onZoom={() => {
              this.setState({
                boundFlag: false
              });
            }}
            // Conditionally pass props
            {...(isGroup && {
              markers: this.state.markers,
              showlocation: this.state.showLocation,
            })}
          />
        );
      }
    } else {
      maps.push(
        <Map
          height={"90%"}
          zoom={14}
          stops={this.state.stopObj}
          onMarkerRightClick={this.handleMarkerRightClick}
          cluster={this.state.clusterMarker}
          vehicleReplayRoute={true}
          isLastChunk={this.state.isEnd}
          replayIntervalSpeed={this.state.replayIntervalSpeed}
          vehicle_type_id={this.state.replayRouteData.vehicle_type_id}
          replayroutedata={{
            replay: this.state.replayRouteData.replay_data,
            index: this.state.slidedValue,
            flag: this.state.playReplayRoute,
            sliderValue: this.slideValue,
            serial_number: this.state.selectedVehicleSerialNumber,
            show: this.state.live_or_replay,
            title: this.state.selectedVehicleName
          }}
          polyline={{
            points: this.state.points,
            polylineParam: this.state.polylineParam,
            icon: this.state.setIcon,
            state: this.state.vehicleStatus,
            speed: 500,
            show: this.state.live_or_replay,
            serial_number: this.state.selectedVehicleSerialNumber,
            title: this.state.selectedVehicleName,
            vehicle_type_id: this.state.replayRouteData.vehicle_type_id
          }}
          // Conditionally pass props
          {...(isGroup && {
            markers: this.state.markers,
            showlocation: this.state.showLocation,
          })}
        />
      );
    }

    this.setState({
      mapContent: maps
    }, () => {
      // $('#processing').hide()
    });
  };

  newreturnMap = () => {
    const maps = [];
    const isGroup = localStorage.getItem('group') == 34;

    if (this.state.markers && this.state.trackingSelect == 0) {
      if (this.state.markers.length > 0) {
        maps.push(
          <Map
            height={"92%"}
            cluster={this.state.clusterMarker}
            stops={this.state.stopObj}
            isLastChunk={this.state.isEnd}
            pauseBound={this.state.boundFlag}
            onZoom={() => {
              this.setState({
                boundFlag: false
              });
            }}
            // Conditionally pass props
            {...(isGroup && {
              markers: this.state.markers,
              showlocation: this.state.showLocation,
            })}
          />
        );
      }
    } else {
      maps.push(
        <Map
          height={"90%"}
          zoom={14}
          stops={this.state.stopObj}
          cluster={this.state.clusterMarker}
          vehicleReplayRoute={true}
          isLastChunk={this.state.isEnd}
          replayIntervalSpeed={this.state.replayIntervalSpeed}
          vehicle_type_id={this.state.replayRouteData.vehicle_type_id}


          replayroutedata={{
            replay: this.state.replayRouteData.replay_data,
            index: this.state.slidedValue,
            flag: this.state.playReplayRoute,
            sliderValue: this.slideValue,
            serial_number: this.state.selectedVehicleSerialNumber,
            show: this.state.live_or_replay,
            title: this.state.selectedVehicleName
          }}
          polyline={{
            // points: this.state.points,
            //  polylineParam: this.state.polylineParam,
            icon: this.state.setIcon,
            state: this.state.vehicleStatus,
            speed: 500,
            show: this.state.live_or_replay,
            serial_number: this.state.selectedVehicleSerialNumber,
            title: this.state.selectedVehicleName,
            vehicle_type_id: this.state.replayRouteData.vehicle_type_id
          }}
          // Conditionally pass props
          {...(isGroup && {
            markers: this.state.markers,
            showlocation: this.state.showLocation,
          })}
        />
      );
    }

    this.setState({
      mapContent: maps
    }, () => {
      // $('#processing').hide()
    });
  };


  resetTrackingData = () => {
    this.setState({
      vehicleLiveTrack: '',
      personLiveTrack: '',
      markers: [],
      mapContent: '',
      trackingSelect: 0,
      selectedVehicleSerialNumber: '',
      selectedVehicleName: '',
      selectedGroupIdNumber: '',
      selectedGroupName: '',
      replayRouteData: '',
      slidedValue: 0,
      sliderDuration: 0,
      sliderMax: 0,
      playReplayRoute: true,
    });
  };


  vlMapView = (e) => {
    e.preventDefault();
    this.getVehicleList();
    this.resetTrackingData();
    const selection = e.currentTarget.getAttribute("data-type"); // for centering map 
    // console.log("inside vlmap function")

    // if (this.state.showMap === false) {
    //   $(e.currentTarget).addClass('selected_border')
    //   $(e.currentTarget).siblings().removeClass('selected_border')
    //   this.setState({
    //     showMap: true,?


    //     showVehicleList: false,
    //     showTempVehicleList: false,
    //     searchVehicleData: [],
    //     showVehicleGroup: false,
    //     trackingSelect: 0,
    //     mapContent: '',
    //   }, () => {
    //     this.setIntervalLiveData()
    //     window.dispatchEvent(new Event('resize'));
    //   })
    // }
    $(e.currentTarget).addClass('selected_border')
    $(e.currentTarget).siblings().removeClass('selected_border')
    this.setState({
      showMap: true,
      showVehicleList: false,
      showTempVehicleList: false,
      searchVehicleData: [],
      showVehicleGroup: false,
      trackingSelect: 0,
      mapContent: '',
      markers: [],
    
    }, () => {
      this.setIntervalLiveData()
      window.dispatchEvent(new Event('resize'));
    })
  }

  handlePtMapView = (e) => {
    this.ptMapView(e); // Existing function call
    this.getPersonList(); // Fetch the vehicle list
  };

  ptMapView = (e) => {
    e.preventDefault();
    this.resetTrackingData();
    const selection = e.currentTarget.getAttribute("data-type");


    // console.log("inside ptMapView function")

    // if (this.state.showMap === false) {
    //   $(e.currentTarget).addClass('selected_border')
    //   $(e.currentTarget).siblings().removeClass('selected_border')
    //   this.setState({
    //     showMap: true,
    //     showVehicleList: false,
    //     showTempVehicleList: false,
    //     searchVehicleData: [],
    //     showVehicleGroup: false,
    //     trackingSelect: 0,
    //     mapContent: '',
    //   }, () => {
    //     this.setIntervalLivePersonData()
    //     window.dispatchEvent(new Event('resize'));
    //   })
    // }
    $(e.currentTarget).addClass('selected_border')
    $(e.currentTarget).siblings().removeClass('selected_border')

    this.setState({
      showMap: true, // Ensure map is always shown
      showVehicleList: false,
      showTempVehicleList: false,
      searchVehicleData: [],
      showVehicleGroup: false,
      trackingSelect: 0,
      mapContent: '', // Reset map content to force a re-render
      markers: [],
    }, () => {
      this.setIntervalLivePersonData() // Ensure data refresh
      window.dispatchEvent(new Event('resize')) // Ensure UI updates correctly
    });
  }

  getUntrackVehicleList = () => {
    $('#processing').show()
    ApiUrlCall.untrackVehicleList().then(response => {
      $('#processing').hide()
      //console.log('untrack list response', response)
      this.emptyTable()
      if (response.data.response_code == 200 && response.data.is_success) {
        this.setState({
          untrackData: response.data.data.vehicle_list
        }, () => {
          this.createTableData()
        })
      }
    }).catch(error => {
      $('#processing').hide()
      console.log('error while fetching untrack list', error)
    })
  }

  untrackList = (e) => {
    if (!this.state.showTempVehicleList) {
      $(e.currentTarget).addClass('selected_border')
      $(e.currentTarget).siblings().removeClass('selected_border')
      this.setState({
        showMap: false,
        showVehicleList: false,
        showTempVehicleList: true,
        searchVehicleData: [],
        dataCollection: [],
        datatable: [],
        searchText: "",
        totalCount: 0,
        nextCount: 0,
        boundFlag: true,
        mapContent: "",
        trackingSelect: "",
        clusterMarker: true,
        showVehicleGroup: false,
      }, () => {
        this.getUntrackVehicleList()
      })
    }
  }

  // searchContent = (input, table, column) => {
  //   var search_input, search_filter, search_table, search_tbody, search_tr, search_td, i, txtValue;
  //   search_input = document.getElementById(input);
  //   search_filter = search_input.value.toUpperCase();
  //   search_table = document.getElementById(table);
  //   search_tr = search_table.getElementsByTagName("tr");
  //   for (i = 0; i < search_tr.length; i++) {
  //     search_td = search_tr[i].getElementsByTagName("td")[column];
  //     if (search_td) {
  //       txtValue = search_td.textContent || search_td.innerText;
  //       if (txtValue.toUpperCase().indexOf(search_filter) > -1) {
  //         search_tr[i].style.display = "";
  //       } else {
  //         search_tr[i].style.display = "none";
  //       }
  //     }
  //   }
  // }
  uniqueVehicleId = (serial_number, service_expired) => {
    if (service_expired) {
      this.showCurrentPlan(serial_number)
      // console.log("serial_number", serial_number)
    } else {
      this.props.history.push({
        pathname: '/vehicle_details',
        state: { serial_number: serial_number }

      })
    }

  }

  ///////////////////////////////////////////////////////////////////

  showCurrentPlan = (serialNo) => {
    $('#processing').show()
    //console.log(serialNo)
    const data = { serial_number: serialNo }
    ApiUrlCall.showCurrentPlanDetails(JSON.stringify(data)).then(response => {
      if (response.data.response_code === 200 && response.data.is_success === true) {
        $('#processing').hide()
        this.setState({
          currentPlanDetails: response.data.data.history[0],
          currentSerialNo: serialNo,
          renewalPlanList: [],
        }, () => {
          if (this.state.currentPlanDetails.plan > 12) {
            this.setState({
              planDuration: this.state.currentPlanDetails.plan / 12 + " years"
            })
          }
          else {
            this.setState({
              planDuration: this.state.currentPlanDetails.plan + " months"
            })
          }
        })
        $("#current_plan").show()
      }
    }).catch(error => {
      $('#processing').hide()
      console.log(error, "error while fetching current plan");
    })
  }

  modalClose1 = () => {
    $('#current_plan').fadeOut("slow")
    $('html, body').css({
      overflow: 'auto',
      height: '100%'
    });
  }

  modalClose2 = () => {
    $('#renewal_plan').fadeOut("slow")
    $('html, body').css({
      overflow: 'auto',
      height: '100%'
    });
  }

  showrenewPlan = () => {
    $('#processing').show()
    var data = { serial_number: this.state.currentSerialNo }
    ApiUrlCall.showRenewalPlan(JSON.stringify(data)).then(response => {
      if (response.data.response_code === 200 && response.data.is_success === true) {
        $('#processing').hide()
        this.setState({
          renewalPlanList: response.data.data.plan_list,
          datatable1: [],
        }, () => {
          this.createTableDataForRenewalPlan()
        })
        //$("#current_plan").hide()
        $("#renewal_plan").show()
      }
    }).catch(error => {
      $('#processing').hide()
      console.log(error, "error while fetching renewal plan");
    })
  }

  createTableDataForRenewalPlan = () => {
    var row = []
    const { renewalPlanList, datatable1 } = this.state
    renewalPlanList.sort(function (a, b) { return (a.month > b.month) ? 1 : ((b.month > a.month) ? -1 : 0); });
    if (renewalPlanList) {
      for (let i = 0; i < renewalPlanList.length; i++) {
        const element = renewalPlanList[i];
        var row_data = {
          checkbox: <input type="checkbox" id={element.skuId} className="disabled_checkboxes" onChange={(e) => this.onRadioClick(e, element.sku_id, element.price)}></input>,
          duration: element.month + "Months",
          price: element.price,
        }
        datatable1.push(row_data)
      }
      this.forceUpdate()
    }

  }

  payNowClick = () => {
    $('#processing').show()

    const data = {
      serial_number: this.state.currentSerialNo,
      amount: this.state.amount,
      sku_id: this.state.skuId,
    }
    if (this.state.amount == 0) {
      alert("Please select plan")
      $('#processing').hide()
      return
    }
    ApiUrlCall.payNowClick(JSON.stringify(data)).then(response => {

      if (response.data.response_code === 200 && response.data.is_success === true) {
        $('#processing').hide()
        this.setState({
          userName: response.data.data.name,
          mobileNo: response.data.data.mobile_number,
          emailId: response.data.data.email,
          env: response.data.data.env,
          request_source: response.data.data.request_source,
          client_url: response.data.data.client_url,
          city: response.data.data.city,
          zipcode: response.data.data.zipcode,
          state: response.data.data.state,
          redirect_url: response.data.data.redirect_url,
          address: response.data.data.address,
          transaction_id: response.data.data.transaction_id,
          type: response.data.data.type,
          True: "YES",
        })

        $("#renewal_plan").hide()
        $("#current_plan").hide()
        setTimeout(() => {
          $("#pay_now").click()
        }, 300);

      }
    }).catch(error => {
      $('#processing').hide()
      console.log(error, "error while payment");
    })
  }

  onRadioClick = (e, sku_id, amount) => {
    if (e.target.checked) {
      this.setState({
        skuId: sku_id,
        amount: amount,
      })
      $(".disabled_checkboxes").each(function (index) {
        var element = $(".disabled_checkboxes")[index]
        if ($(element).is(":checked") == false) {
          $(element).prop("disabled", true)
        }
      })
    }
    else {
      $(".disabled_checkboxes").each(function (index) {
        var element1 = $(".disabled_checkboxes")[index]
        if ($(element1).is(":checked") == false) {
          $(element1).prop("disabled", false)
        }
      })
    }

  }

  //////////////////////////////////////////////////////////////////

  VehicleStatus = (status) => {
    const AVAILABLE = 1;
    const ASSIGNED = 2;
    const SERVICING = 3
    const { t } = this.props
    if (status === AVAILABLE) {
      return (
        <p className="cj_w_header" style={{ color: "#28a745" }}>{t('dds.available')}</p>
      )
    } else if (status === ASSIGNED) {
      return (
        <p className="cj_w_header" style={{ color: "#ed7d28" }}>{t('dds.assigned')}</p>
      )
    } else if (status === SERVICING) {
      return (
        <p className="cj_w_header" style={{ color: "#227cd0" }}>{t('dds.servicing')}</p>
      )
    }
  }

  refreshButton = () => {
    var date = new Date()
    this.setState({
      refreshDate: date,
      nextCount: 0,
    }, () => {
      this.getVehicleList()
    })
  }

  resetVariable = () => {
    this.setState({
      selectedVehicleName: '',
      selectedVehicleSerialNumber: '',
      searchText: '',
      selectedGroupIdNumber: "",
      selectedGroupName: '',
    })
  }

  resetLiveTrack = () => {
    this.setState({
      points: '',
      polylineParam: '',
      lastPolylineLatLng: '',
      currentPolylineLatLng: '',
      vehicleStatus: 0,
      boundLat: [],
      boundLng: [],
      setIcon: true,
    })
  }

  resetReplayRoute = () => {
    this.setState({
      replayRouteData: '',
      slidedValue: 0,
      sliderDuration: 0,
      sliderMax: 0,
      playReplayRoute: true,
      startDate: new Date(),
      endDate: new Date()
    })
  }

  selectAllTracking = () => {
    if (this.state.trackingSelect == 0) {
      //console.log("selectAllTracking")
      return
    }
    if (this.state.vlcGroupDataList.length === 0) {
      this.setState({
        trackingSelect: 0,
        setView: false,
        clusterMarker: true,
        mapContent: '',
        boundFlag: true,
        nextCount: 0,
        searchText: '',
        totalCount: 0,
        selectedGroupName: "",
        selectedGroupIdNumber: "",
      }, () => {
        clearInterval(this.singleInterval)
        if (this.state.personLiveTrack) {
          this.setIntervalLivePersonData()
        } else {
          this.setIntervalLiveData()
        }
        // this.resetVariable()
        // this.resetLiveTrack()
        // console.log("CALLING RESET REPLAY 1\n")
        // this.resetReplayRoute()
        // this.getVehicleList()
      })
    } else {
      this.setState({
        trackingSelect: 0,
        setView: false,
        clusterMarker: true,
        mapContent: '',
        boundFlag: true,
        nextCount: 0,
        searchText: '',
        totalCount: 0,
        selectedGroupName: "",
        selectedGroupIdNumber: "",
      }, () => {
        clearInterval(this.singleInterval)
        if (this.state.personLiveTrack) {
          this.setIntervalLivePersonData()
        } else {
          this.setIntervalLiveData()
        }
        // this.resetVariable()
        // this.resetLiveTrack()
        // console.log("CALLING RESET REPLAY 1\n")
        // this.resetReplayRoute()
        // this.getVehicleList()
      })
    }
  }

  selectLiveTracking = () => {
    if (this.state.trackingSelect == 1) {
      return
    }
    const { vehicleData } = this.state
    console.log('vehicle data is', vehicleData)
    var serial_number = []
    var vehicle_name = ""
    for (var i = 0; i < vehicleData.length; i++) {
      // if (vehicleData[i].service_expired === false) {
      serial_number = vehicleData[i].serial_number;
      vehicle_name = vehicleData[i].vehicle_name
      break;
      // }
    }
    var serial_number = serial_number
    var start_date = ""
    var end_date = ""
    var stop_type = 2
    this.getJourneyStopDetails(serial_number, start_date, end_date, stop_type)

    console.log("serial_number", serial_number)
    this.setState({
      trackingSelect: 1,
      live_or_replay: true,
      selectedVehicleSerialNumber: serial_number,
      selectedVehicleName: vehicle_name,
      startDate: new Date(),
      endDate: new Date()
    }, () => {
      clearInterval(this.interval)
      // console.log("CALLING RESET REPLAY 2--\n")
      this.resetReplayRoute()
      this.generateTracking()
    })
  }

  selectReplayRoute = () => {
    if (this.state.trackingSelect == 2) {
      return
    }
    const { vehicleData } = this.state
    this.setState({
      trackingSelect: 2,
      live_or_replay: false,
      clusterMarker: false,
      selectedVehicleSerialNumber: vehicleData[0].serial_number,
      selectedVehicleName: vehicleData[0].vehicle_name,
      mapContent: ''
    }, () => {
      this.generateTracking()
    })
  }


  searchVehicle = (e) => {
    var vehicle = e.currentTarget.value
    if (vehicle.length > 3) {
      this.setState({
        nextCount: 0,
        searchText: vehicle
      }, () => {
        $('#s_vehicle_list').slideDown()
        this.getPersonList()
      })
    } else {
      $('#s_vehicle_list').slideUp()
    }
  }


  // getCustomerVehicleList = () => {
  //   var local_list = localStorage.getItem('tracker_list')
  //   console.log("on vehicle click",local_list)
  //   if (local_list == null) {
  //     var data = {}
  //     ApiUrlCall.getVehcile_Name(data).then(response => {
  //       if (response.data.response_code == 200 && response.data.is_success) {
  //         var list = response.data.data.vehicle_list
  //         localStorage.setItem("tracker_list", JSON.stringify(list))
  //         this.setState({
  //           all_vehicle_list: list
  //         })
  //       }
  //     })
  //   } else {
  //     local_list = JSON.parse(localStorage.getItem('tracker_list'))
  //     this.setState({
  //       all_vehicle_list: local_list
  //     })

  //   }

  // }



  // displayAllVehicles = (e) => {
  //   var vehicle = e.currentTarget.value
  //   //console.log(" ON CLICKD " , vehicle )
  //   if (vehicle.length == 0) {
  //     this.setState({
  //       nextCount: 0,
  //       searchText: vehicle
  //     }, () => {
  //       $('#s_vehicle_list').slideDown()
  //       this.getCustomerVehicleList()
  //     })
  //   }

  //   else {
  //     $('#s_vehicle_list').slideUp()
  //   }
  // }


  getCustomerVehicleList = () => {
    var local_list;
    if (this.state.personLiveTrack) {
      local_list = JSON.parse(localStorage.getItem('people_list'));
      // console.log("on People click", local_list);


    } else {
      local_list = JSON.parse(localStorage.getItem('tracker_list'));
      // console.log("on vehicle click", local_list);
    }
    if (!local_list) {


      var data = {}; // Define 'data' before usage
      ApiUrlCall.getVehcile_Name(data).then(response => { // Corrected function name
        if (response.data.response_code === 200 && response.data.is_success) {
          var list = response.data.data.vehicle_list;
          localStorage.setItem("tracker_list", JSON.stringify(list));
          this.setState({ all_vehicle_list: list });
        }
      });
    } else {
      // console.log("unsdderrrrr elseeeee", this.state.all_vehicle_list)

      // this.setState({ all_vehicle_list: local_list }, () => {
      //   console.log("Updated all_vehicle_list:", this.state.all_vehicle_list);
      // });
    }

  };

  displayAllVehicles = (e) => {
    var vehicle = e.currentTarget.value;

    if (vehicle.length == 0) {
      this.setState({
        nextCount: 0,
        searchText: vehicle
      }, () => {  // Callback function executes after state update
        $('#s_vehicle_list').slideDown();
        if (this.state.personLiveTrack) {
          let data = {}; // Ensure 'data' is defined if required
          ApiUrlCall.getPeopleList(data).then(response => {
            // console.log("people_list_response", response);

            if (response.data.response_code === 200 && response.data.is_success) {
              var list = response.data.data.people_summary_list;
              console.log("people_list", list);

              localStorage.setItem("people_list", JSON.stringify(list));
              this.setState({ all_vehicle_list: list }, () => {
                // console.log("Updated all_peple_list:", this.state.all_vehicle_list);
                this.getCustomerVehicleList(); // Call only after state update
              });
            }
          });
        }
        var local_list = JSON.parse(localStorage.getItem('tracker_list'));
        this.setState({ all_vehicle_list: local_list }, () => {
          // console.log("Updated all_vehicle_list:", this.state.all_vehicle_list);
          this.getCustomerVehicleList(); // Call only after state update
        });
      });
    } else {
      $('#s_vehicle_list').slideUp();
    }
  };


  // displayAllVehicles = (e) => {
  //   var vehicle = e.currentTarget.value
  //   //console.log(" ON CLICKD " , vehicle )
  //   if (vehicle.length == 0) {
  //     this.setState({
  //       nextCount: 0,
  //       searchText: vehicle
  //     }, () => {
  //       $('#s_vehicle_list').slideDown()

  //       let data = {}; // Ensure 'data' is defined if required
  //       ApiUrlCall.getPeopleList(data).then(response => {
  //         console.log("people_list_response", response)

  //         if (response.data.response_code === 200 && response.data.is_success) {

  //           var list = response.data.data.people_summary_list;
  //           console.log("people_list", list)

  //           localStorage.setItem("people_list", JSON.stringify(list));
  //           this.setState({ all_vehicle_list: list });
  //         }
  //       });
  //       this.getCustomerVehicleList()
  //     })
  //   }

  //   else {
  //     $('#s_vehicle_list').slideUp()
  //   }
  // }

  // displayAllVehicles = (e) => {
  //   var vehicle = e.currentTarget.value
  //   //console.log(" ON CLICKD " , vehicle )
  //   if (vehicle.length == 0) {
  //     this.setState({
  //       nextCount: 0,
  //       searchText: vehicle
  //     }, () => {
  //       $('#s_vehicle_list').slideDown()
  //       this.getCustomerVehicleList()
  //     })
  //   }

  //   else {
  //     $('#s_vehicle_list').slideUp()
  //   }
  // }



  selectVehicleTracking = (e) => {
    var serial_number = $(e.currentTarget).prop('id')
    var vehicle_name = $(e.currentTarget).text()
    this.setState({
      selectedVehicleName: vehicle_name,
      selectedVehicleSerialNumber: serial_number,
      setView: true,
      clusterMarker: false,
    }, () => {
      this.resetLiveTrack()
      $('#s_vehicle_list').slideUp()
      $('#vehicle_group_list').slideUp()
      $('#search_input').val("")
      $('#search_input1').val("")

    })
  }



  getSingleTrackerLiveTracking = () => {

    const serial_number = JSON.stringify({ serial_number: this.state.selectedVehicleSerialNumber })
    // console.log('serial_number:', serial_number)

    let polylineLast = this.state.lastPolylineLatLng
    let polylineCurrent = this.state.currentPolylineLatLng
    // console.log('personLiveTrack:', this.state.personLiveTrack);

    const apicall = this.state.personLiveTrack ? ApiUrlCall.getSingleVehicleLiveTrackPerson : ApiUrlCall.getSingleVehicleLiveTrack;
    // console.log('API call function:', apicall.name);

    apicall(serial_number).then(response => {
      // console.log('single live tracking data', response)
      if (response.data.response_code === 200 && response.data.is_success === true) {
        // console.log('here is the problem the data is_sucess showing false ')

        if (polylineLast) {
          $('#processing').hide()
          // console.log('qqqqqqqqqqqq')
          this.setState({
            lastPolylineLatLng: polylineCurrent,
            currentPolylineLatLng: { lat: response.data.data.lat, lng: response.data.data.lng },
            setIcon: false,
            boundLat: [...this.state.boundLat, response.data.data.lat],
            boundLng: [...this.state.boundLng, response.data.data.lng],
            vehicleStatus: response.data.data.vehicle_state,
          }, () => {
            this.liveTrack()
          })
        } else {
          $('#processing').hide()
          // console.log('>>>>>>>>>>>>>>>>')
          this.setState({
            vehicleStatus: response.data.data.vehicle_state,
            currentPolylineLatLng: { lat: response.data.data.lat, lng: response.data.data.lng },
            lastPolylineLatLng: { lat: response.data.data.lat, lng: response.data.data.lng },
            setIcon: true,
          }, () => {
            this.liveTrack()
          })
        }

      }
    }).catch(error => {
      $('#processing').hide()
      clearInterval(this.singleInterval)
      //   console.log("error while fetching single tracking data", error)
    })
  }

  liveTrack = () => {
    // console.log('11111111111')  
    if (this.state.lastPolylineLatLng && this.state.currentPolylineLatLng) {
      // console.log('222222222222222')
      var oldlat = this.state.lastPolylineLatLng.lat
      var oldlng = this.state.lastPolylineLatLng.lng
      var newlat = this.state.currentPolylineLatLng.lat
      var newlng = this.state.currentPolylineLatLng.lng
      var pts = [{ "oldlat": oldlat, "oldlng": oldlng }, { "newlat": newlat, "newlng": newlng }]
      var oldLatLng = new L.LatLng(oldlat, oldlng)
      var newLatLng = new L.LatLng(newlat, newlng);

      var polylineParam =
      {
        weight: 2, // The thickness of the polyline               
      };
      this.setState({
        points: pts,
        polylineParam: polylineParam,
        vehicleStatus: this.state.vehicleStatus,
      }, () => {
        this.returnMap()
        // console.log('points value is', this.state.points)
      })
    }
  }

  getReplayRouteDuration = () => {
    const { replayRouteData } = this.state
    var start = replayRouteData.replay_data[0].datetime
    var end = replayRouteData.replay_data.slice(-1)[0].datetime
    var start_time = new Date(start)
    var end_time = new Date(end)
    var diffsecond = end_time - start_time
    var seconds = diffsecond / 1000
    var minutes = seconds / 60
    var hours = minutes / 60
    if (hours !== undefined) {
      this.setState({
        replayDuration: hours.toFixed(2)
      })
    }

  }

  getReplayRouteData = () => {
    // console.log('get replay route data')
    const isGroup = localStorage.getItem('group') == 34;
    var start_time = CommonFunction.createServerDateTime(this.state.startDate, this.state.sendStartTime)
    var end_time = CommonFunction.createServerDateTime(this.state.endDate, this.state.sendEndTime)

    var urlData = {
      serial_number: this.state.selectedVehicleSerialNumber, start_date: start_time,
      end_date: end_time
    }
    $('#processing').show()
    // console.log(this.state.live_or_replay,'>>>>>>>>>>>>>>>>>>>>>>>>>>> selected replay route vehicle', urlData)
    // var url_Json_data = JSON.stringify(url_Json_data)
    const apicall = this.state.personLiveTrack ? ApiUrlCall.getReplayRoutePT : ApiUrlCall.getReplayRoute;
    apicall(JSON.stringify(urlData)).then(response => {
      // console.log(this.state.slidedValue,'response of replay route is', response)        
      if (response.data.response_code === 200 && response.data.is_success === true) {
        this.setState({
          replayRouteData: response.data.data,
          slidedValue: 0,
          playReplayRoute: isGroup ? false : true, // If group is 34, set playReplayRoute to false
          sliderMax: response.data.data.replay_data.length,
        }, () => {
          $('#processing').hide()

          // console.log("IN API RESPONSE ")

          this.newreturnMap()
          //console.log("MAP RETURNED ")
          this.getReplayRouteDuration()
          //console.log("REPLAY DURATION CALLED ")
        })
      }
      else {
        $('#processing').hide()

        this.newreturnMap()
        this.setState({
          openAlert: true,
          errorMessage: response.data.response_message,
        })
      }
    }).catch(error => {
      console.log('error while fetching replay route data is', error)
    })
  }


  generateTracking = () => {
    const { trackingSelect } = this.state
    this.setState({
      mapContent: '',
      replayDuration: '00:00',
      vehicleLiveTrack: '',
      markers: [],
      replayRouteData: '',
      slidedValue: 0,
      sliderDuration: 0,
      sliderMax: 0,
      playReplayRoute: true,
    })
    if (trackingSelect == 1) {
      console.log("GENERATE TRACKING 1 CALLED ")
      $('#processing').show()
      clearInterval(this.singleInterval)

      var stop_type = 2
      var serial_number = this.state.selectedVehicleSerialNumber
      var start_date = ""
      var end_date = ""

      // this.getSingleTrackerLiveTracking()
      // console.log('live 4')

      // this.singleInterval = setInterval(this.getSingleTrackerLiveTracking, 10000)
      // console.log('live 5')

      // this.getJourneyStopDetails(serial_number, start_date, end_date, stop_type)
      // console.log('live 6')
      try {
        this.getSingleTrackerLiveTracking();

        this.singleInterval = setInterval(() => {
          console.log('Fetching live tracking data...');
          this.getSingleTrackerLiveTracking();
        }, 10000);

        this.getJourneyStopDetails(serial_number, start_date, end_date, stop_type);
      } catch (error) {
        console.error('Error in getSingleTrackerLiveTracking:', error);
        $('#processing').hide();
      }



    } else if (trackingSelect == 2) {
      //console.log("GENERATE TRACKING 2 CALLED ")
      $('#processing').show()
      var stop_type = 3
      // console.log('genereate replay route')
      var serial_number = this.state.selectedVehicleSerialNumber
      var start_date = CommonFunction.ServerDateTime(this.state.startDate.setHours(0, 0, 0, 0))
      var end_date = CommonFunction.ServerDateTime(this.state.endDate)
      this.getJourneyStopDetails(serial_number, start_date, end_date, stop_type)
      clearInterval(this.interval)
      clearInterval(this.singleInterval)
      this.resetLiveTrack()
      this.getReplayRouteData()
      if ($("#start_tracking_btn").html() == "Play 1x") {
        $("#start_tracking_btn").html("Play 3x")
        this.setState({
          replayIntervalSpeed: 1000

        })
      }
      else if ($("#start_tracking_btn").html() == "Play 3x") {
        $("#start_tracking_btn").html("Play 1x")
        this.setState({
          replayIntervalSpeed: 300

        })
      }
    }
  }

  playRr = (e) => {
    $(e.currentTarget).hide()
    $(e.currentTarget).siblings().show()
    // if (this.state.endRR ){
    //   this.setState({
    //     slidedValue : 0
    //   })
    // }
    this.setState({
      playReplayRoute: true,
    }, () => {
      this.returnMap()
    })
  }

  pauseRr = (e) => {
    $(e.currentTarget).hide()
    $(e.currentTarget).siblings().show()
    //console.log("PAUSE CLICKED ")
    this.setState({
      playReplayRoute: false,
    }, () => {
      this.returnMap()
    })
  }

  slideValue = (value, endReplay) => {
    if (!endReplay) {
      this.setState({
        slidedValue: value
      })
    } else {
      //console.log(" IN ELSE SLIDE VAL ")
      //$('#pause_rr').hide()
      //$("#play_rr").show()
      // this.setState({
      //   endRR : true 
      // })


      // this.setState({
      //   slidedValue: 0,
      // }, () => {
      //   $('#pause_rr').click()
      //   this.setState({
      //     playReplayRoute: false,
      //   })
      // })
    }
  }

  showStartDate = (e) => {
    $(e.currentTarget).next().slideToggle(200, "swing")
  }
  showEndDate = (e) => {
    $(e.currentTarget).next().slideToggle(200, "swing")
  }
  StartdateClicked = date => {
    date.setHours(0, 0, 0, 0);
    this.setState({ startDate: date })
    $('#start_date_cal').slideUp(200)
    $('#end_date_cal').slideUp(200)
  }

  // this method is used to create end date into ISO and send it to server
  EnddateClicked = date => {
    date.setHours(23, 59, 59, 0);
    this.setState({ endDate: date })
    $('#end_date_cal').slideUp(200)
    $('#start_date_cal').slideUp(200)
  }

  togglePanning = (e) => {
    const { panningFlag } = this.state
    var panningState = $(e.currentTarget).children()
    $(panningState).toggle()
    if (panningFlag) {
      this.setState({
        panningFlag: false
      }, () => {
        // console.log('panning flag', this.state.panningFlag)    
        this.returnMap()
      })
    } else {
      this.setState({
        panningFlag: true
      }, () => {
        // console.log('panning flag', this.state.panningFlag)    
        this.returnMap()
      })
    }
  }

  shareMobileNumber = (e) => {
    var mobile_number = $(e.currentTarget).val()
    if (mobile_number.length !== 10) {
      this.setState({
        invalidShareNumber: false
      })
    } else {
      this.setState({
        invalidShareNumber: true,
        shareNumber: mobile_number
      })
    }
  }



  shareTracking = () => {
    const { invalidShareNumber, shareNumber, trackingSelect, selectedVehicleSerialNumber,
      startDate, endDate, expiry_minutes } = this.state;

    let sendMessage;
    if (invalidShareNumber) {
      let data;

      if (trackingSelect === 1) {
        data = {
          serial_number: selectedVehicleSerialNumber,
          mobile_number: shareNumber,
          sharing_type: trackingSelect,
          expiry_minutes: expiry_minutes // Use the selected expiry time
        };
        sendMessage = "Live Track Shared Successfully";
      } else if (trackingSelect === 2) {
        data = {
          serial_number: selectedVehicleSerialNumber,
          start_date: CommonFunction.ServerDateTime(startDate),
          end_date: CommonFunction.ServerDateTime(endDate),
          mobile_number: shareNumber,
          sharing_type: trackingSelect,
          expiry_minutes: expiry_minutes // Use the selected expiry time
        };
        sendMessage = "Replay Route Shared Successfully";
      }

      // Call the API to share the tracking link
      ApiUrlCall.shareTracking(JSON.stringify(data))
        .then(response => {
          // console.log('Share response is:', response, response.data.data.url);
          if (response.data.response_code === 200 && response.data.is_success) {
            $('#share_location').show();
            this.setState({
              shareNumber: '',
              liveLocationUrl: response.data.data.url,
            }, () => {
              $('#share_number_input').val("");
            });
          }
        })
        .catch(error => {
          console.log('Error while sharing Live Track or Replay Route', error);
        });
    } else {
      this.setState({
        openAlert: true,
        errorMessage: "Kindly Enter 10 digit mobile number"
      });
    }
  }


  handleExpiryChange = (event) => {
    const selectedExpiry = event.target.value;
    // console.log("Selected expiry time:", selectedExpiry);

    this.setState({ expiry_minutes: selectedExpiry }, () => {
      // Call shareTracking after the expiry_minutes state is updated
      this.shareTracking();
    });
  }

  // handleExpiryChange = (event) => {
  //   console.log("Selected expiry time:", event.target.value); 
  //   this.setState({ expiry_minutes: event.target.value });
  // }


  modalValidDate = date => {
    this.setState({ validityDate: date })
    $('#modal_date').slideUp(200)
  }

  showModalDate = (e) => {
    $(e.currentTarget).next().slideToggle(200, "swing")
  }

  addVehicleDetails = () => {
    $('#add_vehicle').slideDown()
  }

  cancelModal = () => {
    $('#add_vehicle').fadeOut()
  }


  saveVehicleDetails = () => {
    const { addVehiclRegNumber, addVehicleName, isValidChecked, validityDate } = this.state
    var regex = new RegExp("^[A-Z|a-z]{2}\\s?[0-9]{1,2}\\s?[A-Z|a-z]{0,3}\\s?[0-9]{4}$")
    //var regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!addVehicleName.trim()) {
      this.setState({
        openAlert: true,
        errorMessage: "Kindly Enter Vehicle Name"
      })
      return
    }

    // console.log(typeof false, '>>>>>>>>>>..', typeof regex.test(addVehiclRegNumber))

    if (!regex.test(addVehiclRegNumber)) {
      this.setState({
        openAlert: true,
        errorMessage: "Kindly Enter Valid Registration Number"
      })
      return
    }
    if (isValidChecked) {
      if (!validityDate) {
        this.setState({
          openAlert: true,
          errorMessage: "Kindly Select Validity Date"
        })
        return
      }
      var data = { expiry_date: CommonFunction.ServerDateTime(validityDate), name: addVehicleName, registration_number: addVehiclRegNumber }
    } else {
      var data = { expiry_date: '', name: addVehicleName, registration_number: addVehiclRegNumber }
    }
    $('#processing').show()
    ApiUrlCall.addTemporaryVehicle(JSON.stringify(data)).then(response => {
      // console.log('>>>>>>>..', response)
      $('#processing').hide()
      if (response.data.response_code == 200 && response.data.is_success) {
        $('#add_vehicle').fadeOut()
        $('#is_temporary_check').prop('checked', false)
        this.setState({
          addVehicleName: '',
          addVehiclRegNumber: '',
          validityDate: ''
        }, () => {
          this.getUntrackVehicleList()
        })
      } else {
        this.setState({
          openAlert: true,
          errorMessage: response.data.response_message
        })
      }
    }).catch(error => {
      $('#processing').hide()
      console.log('error while creating new Vehicle', error)
    })
  }

  showValidDate = (e) => {
    if (e.currentTarget.checked) {
      this.setState({
        showValidDateCal: true,
        isValidChecked: true,
      })
    } else {
      this.setState({
        showValidDateCal: false,
        validityDate: '',
        isValidChecked: false,
      })
    }
  }

  selectStartTime = (newTime) => {
    //console.log(" SET START TIME ", this.state.sendStartTime)  
    this.setState({
      startTime: newTime.formatted12,
      sendStartTime: newTime.formatted24
    })
  }

  showStartTimeContent = () => {
    $('#end_date_cal').slideUp()
    $('#start_date_cal').slideUp()
    if (this.state.showStartTime) {
      this.setState({
        showStartTime: false,
        showEndTime: false
      })
    } else {
      this.setState({
        showStartTime: true,
        showEndTime: false
      })
    }
  }

  selectEndTime = (newTime) => {
    this.setState({
      endTime: newTime.formatted12,
      sendEndTime: newTime.formatted24
    })
  }

  showEndTimeContent = () => {
    $('#end_date_cal').slideUp()
    $('#start_date_cal').slideUp()
    if (this.state.showEndTime) {
      this.setState({
        showEndTime: false,
        showStartTime: false
      })
    } else {
      this.setState({
        showEndTime: true,
        showStartTime: false
      })
    }
  }

  /////////////////////////////Grouping///////////////////////////

  getVehicleAndSerialNoList = () => {
    var data = {}
    ApiUrlCall.getVehcile_Name(data).then(response => {
      if (response.data.response_code === 200 && response.data.is_success === true) {
        //console.log(response.data.data,)
        this.setState({
          vehicle_name: response.data.data,
          serial_number_is: response.data.data.serial_number
        })
        //console.log(response.data.data, ">>>>>>>>>>>>")
      }
    }).catch(error => {
      console.log(error, "error while fetching vehicle and serial number list");
    })
  }

  vehicleGroupList = (e) => {
    if (this.state.showVehicleGroup === false) {
      $(e.currentTarget).addClass('selected_border')
      $(e.currentTarget).siblings().removeClass('selected_border')
      this.setState({
        showMap: false,
        showVehicleList: false,
        showTempVehicleList: false,
        showVehicleGroup: true,
        trackingSelect: "",
        // totalCount: 0,
        // nextCount: 0,
        // boundFlag: true,
      }, () => {
        // clearInterval(this.interval)
        // clearInterval(this.singleInterval)
        //this.resetLiveTrack()
        // this.selectAllTracking()
        this.getVehicleListForGroup()
      })
    }
  }

  getVehicleListForGroup = () => {
    var data = {}
    ApiUrlCall.ShowVehicleGroupList(JSON.stringify(data)).then(response => {
      if (response.data.response_code == 200 && response.data.is_success) {
        //var data = response.data.data.group_data_list
        this.setState({
          groupDataList: response.data.data.group_data_list,
        })

        var data1 = []
        for (var i = 0; i < this.state.groupDataList.length; i++) {
          var obj = { groupName: this.state.groupDataList[i].group_name, groupId: this.state.groupDataList[i].group_id, collapsed: false, details: [] }
          var vehicle_data = this.state.groupDataList[i].vehicle_data_list

          for (var j = 0; j < vehicle_data.length; j++) {
            var obj1 = {}
            obj1["vehicleName"] = vehicle_data[j].vehicle_name
            obj1["serialNumber"] = vehicle_data[j].serial_number
            obj1["collapsed"] = false
            //console.log(this.state.groupDataList[i].group_name, "------->", vehicle_data[j].vehicle_name)
            obj.details.push(obj1)
          }
          data1.push(obj)
        }
        this.setState({ vlcGroupDataList: data1, selectedGroupName: "", selectedGroupIdNumber: "" })
      }
    }).catch(error => {
      console.log('error while fetching vehicle group data', error)
    })
  }

  addVehicleGroupDetails = () => {
    $('#add_vehicle_group').slideDown()
  }

  updateVehicleGroupDetails = (e, id, serialNo) => {
    $('#update_vehicle_group').slideDown()
    //$("#processing").show()
    //console.log(e)
    //console.log(id,"yyyyyyyyyyyyyyyyyyyy")
    //console.log(serialNo)
    this.setState({
      addGroupName: e,
      groupId: id,
      updated: true,
      selectedcheckbox_value: serialNo,
      chckebox_value: serialNo,
    })
    //{this.state.selectedcheckbox_value.map((e) => {this.state.chckebox_value.push(e)})}


  }

  deleteVehicleGroupDetails = (id) => {
    $("#processing").show()
    const group_id = id
    const data = { group_id: group_id }
    JSON.stringify(data)
    ApiUrlCall.deleteGroup(data).then(response => {
      if (response.status === 200) {
        $("#processing").hide()
        this.getVehicleListForGroup()
      }
    }).catch(error => {
      console.log('error while deleting group', error)
    })
  }

  deleteVehicleName = (id, serialNum) => {
    $("#processing").show()
    const group_id = id
    const serialNo = serialNum
    const data = { group_id: group_id, serial_number: serialNo }
    JSON.stringify(data)
    ApiUrlCall.deleteVehicle(data).then(response => {
      if (response.status === 200) {
        $("#processing").hide()
        this.getVehicleListForGroup()
      }
    }).catch(error => {
      console.log('error while deleting vehicle from group', error)
    })
  }

  cancelGrouplModal = (e) => {
    $('#add_vehicle_group').fadeOut()
    e.preventDefault();
  }

  cancelUpdatelModal = () => {
    $('#update_vehicle_group').fadeOut()
  }

  saveVehicleGroupDetails = () => {
    const { addGroupName, chckebox_value } = this.state
    if (!addGroupName.trim()) {
      this.setState({
        openAlert: true,
        errorMessage: "Kindly Enter Vehicle Group Name"
      })
      return
    }
    //const list = this.state.chckebox_value
    //console.log(list)
    var data = { group_name: addGroupName, serial_number_list: chckebox_value }
    $('#processing').show()
    ApiUrlCall.AddVehicleGroup(JSON.stringify(data)).then(response => {
      //console.log('>>>>>>>..', response)
      if (response.status == 200) {
        $('#processing').hide()
        $('#add_vehicle_group').fadeOut()
        this.setState({
          addGroupName: '',
          chckebox_value: [],
        }, () => {
          this.getVehicleListForGroup()
        })
        //console.log(this)
      } else {
        this.setState({
          openAlert: true,
          errorMessage: response.data.response_message
        })
      }
    })
      .catch(error => {
        $('#processing').hide()
        console.log('error while creating new Vehicle Group', error)
      })
  }



  updateDataVehicleGroupDetails = () => {
    const { addGroupName, chckebox_value, groupId } = this.state
    if (!addGroupName.trim()) {
      this.setState({
        openAlert: true,
        errorMessage: "Kindly Enter Vehicle Group Name"
      })
      return
    }
    //const list = this.state.chckebox_value
    //console.log(list)
    var data = { group_name: addGroupName, serial_number_list: chckebox_value, group_id: groupId }
    $('#processing').show()
    ApiUrlCall.UpdateVehicleGroup(JSON.stringify(data)).then(response => {

      if (response.status == 200) {
        $('#processing').hide()
        $('#update_vehicle_group').fadeOut()
        this.setState({
          addGroupName: '',
          selectedcheckbox_value: this.state.chckebox_value,
        }, () => {
          this.getVehicleListForGroup()
        })
        //console.log(this)

      } else {
        this.setState({
          openAlert: true,
          errorMessage: response.data.response_message
        })
      }
    })
      .catch(error => {
        $('#processing').hide()
        console.log('error while updating Vehicle Group', error)
      })
  }

  dropDownClick = (e) => {
    // $('#vehicles_select').slideDown('fast');
    var dropdownwidth = e.currentTarget.offsetWidth
    var checklist = e.currentTarget.nextSibling
    $(checklist).css({
      width: dropdownwidth
    })
    $(checklist).slideToggle(300);

  }

  onChange(e) {
    // current array of options
    const options = this.state.chckebox_value
    //console.log(options)
    //console.log(this.state.chckebox_value)
    let index
    let { counter } = this.state

    if (e.target.checked) {
      // add the numerical value of the checkbox to options array      
      options.push(+e.target.value)
      this.forceUpdate()

      // $('#vehicles_select').slideUp(200)

    } else {
      // or remove the value from the unchecked checkbox from the array      
      index = options.indexOf(+e.target.value)

      options.splice(index, 1)
      this.forceUpdate()
    }

    // sort the array
    options.sort()

    // update the state with the new array of options
    // this.setState({ options: options })
  }

  searchVehicle123 = (e) => {
    let input = document.getElementById('searchbar').value
    input = input.toLowerCase();
    let x = document.getElementsByClassName('vehicle');

    for (var i = 0; i < x.length; i++) {
      if (!x[i].innerHTML.toLowerCase().includes(input)) {
        x[i].style.display = "none";
      }
      else {
        x[i].style.display = "";
      }
    }
  }

  show_Location = () => {
    $('#processing').show()
    this.setState({
      showLocation: true,
      markers: [],
    }, () => {
      this.returnMap()
    })

  }

  checkedUserHasLocationData = () => {
    ApiUrlCall.checkUserHasLocationData().then(response => {
      if (response.data.response_code === 200 && response.data.is_success) {
        this.setState({
          hasLocationData: response.data.has_data
        }, () => {
          if (this.state.hasLocationData) {
            $("#show_Location_btn").show()
          } else {
            $("#show_Location_btn").hide()
          }
        })
      }
    })
  }


  modalClose = () => {
    $('#share_location').fadeOut("slow")
    $('html, body').css({
      overflow: 'auto',
      height: '100%'
    });
  }


  //////////////////////////////////////////////////////////////

  searchGroup = (e) => {
    var vehicle = e.currentTarget.value
    if (vehicle.length > 3) {
      this.setState({
        nextCount: 0,
        searchText: vehicle
      }, () => {
        $('#vehicle_group_list').slideDown()
        //this.getVehicleList()
      })
    } else {
      $('#vehicle_group_list').slideUp()
    }
  }

  displayAllGroupName = (e) => {
    var vehicle = e.currentTarget.value
    // console.log(" ON CLICKD " , vehicle )
    if (vehicle.length == 0) {
      this.setState({
        nextCount: 0,
        searchText: vehicle
      }, () => {
        $('#vehicle_group_list').slideDown()
        // this.getCustomerVehicleList()
      })
    }

    else {
      $('#vehicle_group_list').slideUp()
    }
  }

  searchGroup1 = (e) => {
    let input = document.getElementById('searchbar1').value
    input = input.toLowerCase();
    let x = document.getElementsByClassName('vehicle1');
    $('#vehicle_group_list').slideDown()
    for (var i = 0; i < x.length; i++) {
      if (!x[i].innerHTML.toLowerCase().includes(input)) {
        x[i].style.display = "none";
      }
      else {
        x[i].style.display = "";
      }
    }
  }

  selectGroupTracking = (e) => {
    $('#processing').show()
    var group_id = $(e.currentTarget).prop('id')
    var group_name = $(e.currentTarget).text()
    this.setState({
      selectedGroupName: group_name,
      selectedGroupIdNumber: Number(group_id),
      setView: false,
      clusterMarker: true,
      mapContent: '',
      boundFlag: true,
      nextCount: 0,
      searchText: '',
      totalCount: 0,
      trackingSelect: 0,
    }, () => {
      $('#vehicle_group_list').slideUp()
      $('#search_input1').val("")
    })
  }

  hideDropDown = (e) => {
    if (e.detail === 2) {
      $('#vehicle_group_list').slideUp()
      $('#s_vehicle_list').slideUp()
    }
  }

  closePopup = () => {
    this.setState({
      isPopupVisible: false,
      selectedCameraOptions: null, // Clear the selected camera options
    });
  };

  toggleDropdown = () => {
    this.setState((prevState) => ({ showDropdown: !prevState.showDropdown }));
    // console.log("here we are in toggle")
  };


  ///////////////////////////////////////////////////////////////

  render() {
    const { t } = this.props
    const { vehicleList, selectedVehicle, isPopupVisible, iframeUrls, channellist } = this.state

    var minDate = new Date(); minDate.setDate(minDate.getDate() - 90);
    var modalMinDate = new Date();
    var { all_vehicle_list, mapContent } = this.state



    if (all_vehicle_list) {
      // console.log(all_vehicle_list, "shree")
      if (all_vehicle_list.length > 0) {
        var search_list = all_vehicle_list.map(data => {
          return <p id={data.serial_number} class="vehicle" onClick={this.selectVehicleTracking}>{data.name}</p>
        })
      }
    }

    if (this.state.vlcGroupDataList) {
      if (this.state.vlcGroupDataList.length > 0) {
        var search_list1 = this.state.vlcGroupDataList.map(data => {
          return <p id={data.groupId} class="vehicle1" onClick={this.selectGroupTracking}>{data.groupName}</p>
        })
      }
    }

    if (selectedVehicle) {
      var vehicle_list = selectedVehicle
    }
    if (mapContent) {
      var map_data = mapContent.map(data => {
        return data
      })
    }

    if (this.state.replayRouteData && this.state.slidedValue !== undefined) {
      if (this.state.slidedValue !== this.state.sliderMax) {
        var speed = this.state.replayRouteData.replay_data[this.state.slidedValue].speed
        var distancDriven = this.state.replayRouteData.replay_data[this.state.slidedValue].distance_driven
        var time = this.state.replayRouteData.replay_data[this.state.slidedValue].datetime
      }
      // else {
      //   this.setState({
      //     slidedValue: 0,
      //   }, () => {
      //     $('#pause_rr').click()
      //     this.setState({
      //       playReplayRoute: false,
      //     })
      //   })
      // }
    }

    if (this.state.vehicle_name) {
      let counter = 0
      //console.log(this.state.chckebox_value,"chckebox_value")
      var vehicledataname = this.state.vehicle_name.vehicle_list.map(vehicledataname => {
        this.state.serial_number_is = vehicledataname.serial_number
        return (
          <Grid container className="select_opt_row">
            <Grid item xs={2}>
              <input type="checkbox"
                //onClick={this.checkbox}
                //defaultChecked={this.state.chckebox_value}
                value={vehicledataname.serial_number}
                onChange={this.onChange.bind(this)} name="vehicle_tick" />
            </Grid>
            <Grid item xs={10}>
              <p className="p_margin0">{vehicledataname.name}</p>
            </Grid>
          </Grid>
        )
      })
    }

    if (this.state.vehicle_name && this.state.updated) {
      let counter = 0
      //console.log("Selected",this.state.selectedcheckbox_value)
      const selectedCb = this.state.selectedcheckbox_value
      //this.state.chckebox_value.concat(selectedCb)
      //console.log(this.state.chckebox_value,"chckebox_value")

      var vehicledataname = this.state.vehicle_name.vehicle_list.map(vehicledataname => {
        this.state.serial_number_is = vehicledataname.serial_number
        //console.log(this.state.serial_number_is,"this.state.serial_number_is")
        return (
          <Grid container className="select_opt_row">
            <Grid item xs={2}>
              <input type="checkbox"
                //onClick={this.checkbox}
                //checked = {!(selectedCb.includes(this.state.serial_number_is))}
                defaultChecked={selectedCb.includes(this.state.serial_number_is)}
                value={vehicledataname.serial_number}
                name="vehicle_tick"
                onClick={this.onChange.bind(this)} />
            </Grid>
            <Grid item xs={10}>
              <p className="p_margin0">{vehicledataname.name}</p>
            </Grid>
          </Grid>
        )
      })


    }

    if (this.state.chckebox_value.length > 0) {
      if (this.state.chckebox_value.length == 1) {
        var lableText = this.state.chckebox_value.length + " " + t('ars.sum.v1')
      } else {
        var lableText = this.state.chckebox_value.length + " " + t('ars.sum.v2')
      }
    } else {
      var lableText = t('ars.sum.v3')
    }

    return (
      <div>
        <Navbar />
        <div className="vehicle_list">
          <div>
            <Map height={"90%"} zoom={14} markers={this.state.markers} />
            {this.state.isPopupVisible && (
              <div className="popup-Overlay">
                <DashCam
                  selectedCameraOptions={this.state.selectedCameraOptions} // Pass selected camera data
                  onClose={this.closePopup} // Function to close popup

                />
              </div>
            )}
          </div>



          {/* Subcription start */}
          <div id="service_expired" className="p_modal_parent" id="current_plan" style={{ display: "None" }} >
            <div className="p_modal_boday" >
              <div id="current_plan" >
                <div className="close_modal" onClick={this.modalClose1}>
                  <i class="fa fa-close" aria-hidden="true"></i>
                </div>

                <p className="p_header" style={{ textAlign: "center", color: "red" }}><img src="images/session-expired.png" width="50" height="40" alt="image_alt_tag" />Service Expired</p>
                <hr />
                <p className="p_header">Current Plan</p>
                <p className="cj_w_header"><label>Purchased Plan : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label> {this.state.planDuration}</p>
                <p className="cj_w_header"><label>Installation Date : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label> {CommonFunction.tableDateFormate(this.state.currentPlanDetails.installation_date)}</p>
                <p className="cj_w_header"><label>Service Start Date : &nbsp;&nbsp;</label> {CommonFunction.tableDateFormate(this.state.currentPlanDetails.service_start_date)}</p>
                <p className="cj_w_header"><label>Service End Date : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>{CommonFunction.tableDateFormate(this.state.currentPlanDetails.service_end_date)}</p>
                <p className="cj_w_header"><label>Renewal Due Date : &nbsp;&nbsp;</label> {CommonFunction.tableDateFormate(this.state.currentPlanDetails.renewal_date)}</p>
                <button className="btn orange-btn" onClick={this.showrenewPlan}>Renew Subcription</button>
              </div>

              <div className="p_modal_boday" id="renewal_plan" style={{ display: "None" }}>
                <div className="close_modal" onClick={this.modalClose2}>
                  <i class="fa fa-close" aria-hidden="true"></i>
                </div>
                <p className="p_header" style={{ textAlign: "center" }}>Know Your Plan</p>
                <MDBDataTable
                  displayEntries={false}
                  paging={false}
                  searching={false}
                  sorting={true}
                  scrollY
                  entries={5}
                  striped
                  bordered
                  // hover
                  noBottomColumns={true}
                  data={{ columns: data3, rows: this.state.datatable1 }}
                />
                <p className="p_header" style={{ color: '#f4811f', cursor: "pointer", marginTop: "10px", textAlign: "center" }} onClick={() => this.payNowClick()}>Pay Now</p>
              </div>

              {/* Send data to payment side start */}
              <div className="p_modal_boday" style={{ display: "None" }}>
                <form action="https://payments.matchpointgps.in/" method="post" >
                  {/* <p>Name:</p> */}
                  <input
                    type='text'
                    name="name"
                    value={this.state.userName}
                  />
                  {/* <p>Mobile Number:</p> */}
                  <input
                    type='text'
                    name='mobile_number'
                    value={this.state.mobileNo}
                  />
                  {/* <p>Email Id:</p> */}
                  <input
                    type='text'
                    name="email"
                    value={this.state.emailId}
                  />
                  {/* <p>Amount:</p> */}
                  <input
                    type='text'
                    name="amount"
                    value={this.state.amount}
                  />
                  <input
                    type='hidden'
                    name="env"
                    value={this.state.env}
                  />
                  <input
                    type='hidden'
                    name="request_source"
                    value={this.state.request_source}
                  />
                  <input
                    type='hidden'
                    name="client_url"
                    value={this.state.client_url}
                  />
                  <input
                    type='hidden'
                    name="city"
                    value={this.state.city}
                  />
                  <input
                    type='hidden'
                    name="zipcode"
                    value={this.state.zipcode}
                  />
                  <input
                    type='hidden'
                    name="state"
                    value={this.state.state}
                  />
                  <input
                    type='hidden'
                    name="redirect_url"
                    value={this.state.redirect_url}
                  />
                  <input
                    type='hidden'
                    name="address"
                    value={this.state.address}
                  />
                  <input
                    type='hidden'
                    name="transaction_id"
                    value={this.state.transaction_id}
                  />
                  <input
                    type='hidden'
                    name="type"
                    value={this.state.type}
                  />
                  <input type="submit" id="pay_now" value="Pay Now" />
                </form>
              </div>

              {/* Send data to payment side end */}
            </div>
          </div>

          {/* Subcription end */}

          <div>
            <Grid container style={{ marginBottom: "2em" }}>
              <Grid item xs className="tableHeading">
                <label>{t('vls.header')}</label>
              </Grid>
              <Grid item xs>

              </Grid>
            </Grid>
            <div className="vehicle_list_view">
              <div onClick={this.vlListView} className="selected_border">
                <p className="work_sans_regular16">{t('vls.list')}</p>
              </div>

              {/* <div onClick={this.vlMapView}>
                <p className="work_sans_regular16">{t('vls.map')}</p>
              </div> */}

              <div
                onClick={localStorage.getItem('group') == 37 ? this.toggleDropdown : this.vlMapView}
                style={{ position: 'relative', cursor: 'pointer' }}
              >
                <p className="work_sans_regular16">{t('vls.map')}</p>

                {localStorage.getItem('group') == 37 && this.state.showDropdown && (
                  <div className="dropdown-menu">
                    <div onClick={this.vlMapView}>{t('iv.view')}</div>
                    <div onClick={this.handlePtMapView}>{t('iv.ptview')}</div>
                  </div>
                )}
              </div>



              <div style={{
                display: localStorage.getItem('default_view') == constant.people_tracking &&
                  parseInt(localStorage.getItem('group')) !== 37 ? "none" : ""
              }}>
                <div onClick={this.untrackList} style={{ display: localStorage.getItem('group') == 28 ? "none" : "" }}>
                  <p className="work_sans_regular16">Untracked Vehicles</p>
                </div>
              </div>

              <div style={{
                display: localStorage.getItem('default_view') == constant.people_tracking &&
                  parseInt(localStorage.getItem('group')) !== 37 ? "none" : ""
              }}>
                <div onClick={this.vehicleGroupList} style={{ display: localStorage.getItem('group') == 28 ? "none" : "" }}>
                  <p className="work_sans_regular16">Vehicle Group</p>
                </div>
              </div>
            </div>
            {/*list view vehicle*/}
            <div style={{ display: this.state.showVehicleList ? '' : 'none' }}>
              <div style={{ position: "absolute" }}>
                <button className="btn orange-btn" style={{ float: "none", marginTop: "-22px" }} onClick={this.refreshButton}>Refresh</button>
                <div style={{ display: "inline-block", marginLeft: "10px" }}>
                  <p className="p_header" style={{ marginBottom: "0px" }}>{CommonFunction.tableDateFormate(this.state.refreshDate) + " " + CommonFunction.timeFormate(this.state.refreshDate)}</p>
                  <p style={{ fontSize: "13px" }}>Last Updated On</p>
                </div>
              </div>
              <MDBDataTable
                displayEntries={false}
                paging={false}
                scrollX
                entries={10}
                displayEntries={false}
                striped
                bordered
                data={{ columns: data1, rows: this.state.datatable }}
                onSearch={this.optimizationSearch}
              />
              <Grid container style={{ margin: "20px 0px" }}>
                <Grid item xs>
                  <p className="cj_w_header"><b>Entries {this.state.totalEntries} of {this.state.totalCount}</b></p>
                </Grid>
                <Grid item xs style={{ textAlign: "right" }}>
                  <button className="btn" id="dt-previous" onClick={this.pagePreviousClick}>Previous</button>
                  <button className="btn" id="dt-next" onClick={this.pageNextClick}>Next</button>
                </Grid>
              </Grid>
            </div>
            {/* vehicle list view end */}
            {/* Map view vehicle list */}
            <div style={{ display: this.state.showMap ? '' : 'none', position: "relative", height: "650px" }}>
              <div id="map_div" className="map_view_div" onClick={this.hideDropDown}>
                <Grid container spacing={2}>
                  <Grid item xs>
                    <div class="grid-container-vehicle">
                      <div class="grid-item-vehicle" onClick={this.selectAllTracking}>
                        <p className={this.state.trackingSelect == 0 ? "select-tracking" : ""}>ALL</p>
                      </div>
                      <div class="grid-item-vehicle" onClick={this.selectLiveTracking}>
                        <p className={this.state.trackingSelect == 1 ? "select-tracking" : ""}>Live Track</p>
                      </div>
                      <div class="grid-item-vehicle" onClick={this.selectReplayRoute}>
                        <p className={this.state.trackingSelect == 2 ? "select-tracking" : ""}>Replay Route</p>
                      </div>
                    </div>
                  </Grid>

                  {/* Map legend start */}

                  <Grid item xs style={{ display: this.state.trackingSelect == 0 ? "" : "none" }}>
                    <div className="statistic_container1" style={{ height: "fit-content" }}>
                      <div id="search_legend_container" style={{ marginBottom: "10px" }}>

                        {/* Legend for personLiveTrack */}
                        <div style={{ display: this.state.personLiveTrack ? "" : "none" }}>
                          <Grid item xs container className="legend_parent1">
                            <Grid item xs={1}></Grid>
                            <Grid item xs={2}>
                              <div className="legend_4"></div>
                            </Grid>
                            <Grid item xs={9}>
                              <p className="legend_text_map"><b>Moving</b></p>
                            </Grid>
                          </Grid>
                          <Grid item xs container className="legend_parent1">
                            <Grid item xs={1}></Grid>
                            <Grid item xs={2}>
                              <div className="legend_7"></div>
                            </Grid>
                            <Grid item xs={9}>
                              <p className="legend_text_map"><b>Stopped</b></p>
                            </Grid>
                          </Grid>
                        </div>

                        {/* Legend for non-personLiveTrack */}
                        <div style={{ display: this.state.personLiveTrack ? "none" : "" }}>
                          <Grid item xs container className="legend_parent1">
                            <Grid item xs={1}></Grid>
                            <Grid item xs={2}>
                              <div className="legend_4"></div>
                            </Grid>
                            <Grid item xs={9}>
                              <p className="legend_text_map"><b>Moving</b></p>
                            </Grid>
                          </Grid>
                          <Grid item xs container className="legend_parent1">
                            <Grid item xs={1}></Grid>
                            <Grid item xs={2}>
                              <div className="legend_7"></div>
                            </Grid>
                            <Grid item xs={9}>
                              <p className="legend_text_map"><b>Parked</b></p>
                            </Grid>
                          </Grid>
                          <Grid item xs container className="legend_parent1">
                            <Grid item xs={1}></Grid>
                            <Grid item xs={2}>
                              <div className="legend_5"></div>
                            </Grid>
                            <Grid item xs={9}>
                              <p className="legend_text_map"><b>Idle</b></p>
                            </Grid>
                          </Grid>
                          <Grid item xs container className="legend_parent1">
                            <Grid item xs={1}></Grid>
                            <Grid item xs={2}>
                              <div className="legend_6"></div>
                            </Grid>
                            <Grid item xs={9}>
                              <p className="legend_text_map"><b>Connection Lost</b></p>
                            </Grid>
                          </Grid>
                        </div>

                      </div>
                    </div>
                  </Grid>

                  {/* map legends end */}

                  <Grid item xs={2} style={{ display: this.state.trackingSelect == 2 ? "" : "none" }}>
                    {/* <p className="cj_w_header"><b>Start Date</b></p> */}
                    <div className="input_div" onClick={this.showStartDate}>
                      <input type="text" placeholder={t('ct.startdate')} id="start_date" disabled value={CommonFunction.dateFormate(this.state.startDate)} className="journey_input"></input>

                      <img src="images/fill-111.png" className="vd_journey_icon"></img>
                    </div>
                    <div className="calendar_parent" id="start_date_cal">
                      <Calendar
                        selectRange={false}
                        onChange={this.StartdateClicked}
                        value={this.state.startDate}
                        maxDate={this.state.refreshDate}
                        minDate={minDate}
                      />
                    </div>
                  </Grid>
                  <Grid item xs style={{ display: this.state.trackingSelect == 2 ? "" : "none" }}>

                    <div onClick={this.showStartTimeContent} className="time_div" disabled >
                      <p>{this.state.startTime}</p>
                    </div>
                    {this.state.showStartTime &&
                      <div style={{ position: "absolute", zIndex: "99999" }}>
                        <Timekeeper
                          time={this.state.startTime}
                          switchToMinuteOnHourSelect
                          onDoneClick={() => {
                            this.setState({
                              showStartTime: false
                            })
                          }}
                          onChange={this.selectStartTime}
                        />
                      </div>
                    }
                  </Grid>
                  <Grid item xs={2} style={{ display: this.state.trackingSelect == 2 ? "" : "none" }}>
                    {/* <p className="cj_w_header"><b>End Date</b></p>   */}
                    <div className="input_div" onClick={this.showEndDate} style={{ zIndex: "99" }}>
                      <input type="text" placeholder={t('ct.enddate')} id="end_date" disabled value={CommonFunction.dateFormate(this.state.endDate)} className="journey_input"></input>
                      <img src="images/fill-111.png" className="vd_journey_icon"></img>
                    </div>
                    <div className="calendar_parent" id="end_date_cal">
                      <Calendar
                        selectRange={false}
                        onChange={this.EnddateClicked}
                        value={this.state.endDate}
                        maxDate={this.state.refreshDate}
                        minDate={minDate}
                      />
                    </div>
                  </Grid>
                  <Grid item xs style={{ display: this.state.trackingSelect == 2 ? "" : "none" }}>
                    <div onClick={this.showEndTimeContent} className="time_div">
                      <p>{this.state.endTime}</p>
                    </div>
                    {this.state.showEndTime &&
                      <div style={{ position: "absolute", zIndex: "99999" }}>
                        <Timekeeper
                          time={this.state.endTime}
                          switchToMinuteOnHourSelect
                          onDoneClick={() => {
                            this.setState({
                              showEndTime: false
                            })
                          }}
                          onChange={this.selectEndTime}
                        />
                      </div>
                    }
                  </Grid>
                  <Grid item xs={2} style={{ display: this.state.trackingSelect == 1 || this.state.trackingSelect == 2 ? "" : "none", position: "relative" }}>

                    <input type="text" className="addtional_detail_input" style={{ width: "100%" }} placeholder={this.state.personLiveTrack ? "Enter Person Name" : "Enter Vehicle Name"}
                      onKeyUp={this.searchVehicle} onClick={this.displayAllVehicles} id="search_input" autoComplete="off"></input>
                    <div className="vehile_search_list" id="s_vehicle_list" style={{ width: "200px", height: "200px", overflow: "scroll", zIndex: 9999 }}>
                      <input id="searchbar" onChange={this.searchVehicle123} type="search"
                        name="search" placeholder="Search ..." />
                      {search_list}
                    </div>
                  </Grid>
                  <Grid item xs={2} style={{ display: this.state.trackingSelect == 0 ? "" : "none", position: "relative" }}>

                    <input className="addtional_detail_input" style={{ width: "100%" }} placeholder="Group Name"
                      onClick={this.displayAllGroupName} id="search_input1" readOnly ></input>
                    <div className="vehile_search_list" id="vehicle_group_list" style={{ width: "200px", height: "200px", overflow: "scroll", zIndex: 9999 }}>
                      <input id="searchbar1" onChange={this.searchGroup1} type="search"
                        name="search" placeholder="Search ..." />
                      {search_list1}
                    </div>
                  </Grid>

                  {/* VEHICLE SEARCH LIST  */}
                  <Grid item xs={1} style={{ display: this.state.trackingSelect == 1 || this.state.trackingSelect == 2 ? "" : "none" }}>
                    <button id="start_tracking_btn" className="btn btn-block orange-btn" onClick={this.generateTracking}>Play 1x</button>
                  </Grid>

                  {/* <Grid item xs={1} style={{ display: this.state.trackingSelect == 2 ? "" : "none" }}>
                  <Tab style={{display : localStorage.getItem('group') == 31 ? "" : "none" }}> Fuel</Tab>
                    <button id="start_tracking_btn" className="btn btn-block orange-btn"    onClick={this.handleScreenshot}> Take screen shot</button>
                  </Grid> */}

                  <Grid item xs={1} style={{ display: this.state.trackingSelect == 2 && localStorage.getItem("group") == 36 ? "" : "none", }} >
                    <button id="start_tracking_btn" className="btn btn-block orange-btn" onClick={this.handleScreenshot} >Take Screenshot  </button>
                  </Grid>

                  <Grid item xs={2} style={{ display: this.state.trackingSelect == 0 || this.state.trackingSelect == 1 ? "" : "none" }}>
                    <button id="show_Location_btn" className="btn btn-block orange-btn" onClick={this.show_Location}>Show Location</button>
                  </Grid>
                  <Grid item xs={3} style={{
                    display: this.state.trackingSelect == 1 || this.state.trackingSelect == 2 ? "" : "none",
                    textAlign: "right"
                  }}>
                    {/* <i><b>Selected Vehicle</b></i> */}
                    <i><b>{this.state.personLiveTrack ? "Selected Person" : "Selected Vehicle"}</b></i>

                    <p className="selected-vehicle" style={{ display: this.state.selectedVehicleName ? "" : "none" }}>{this.state.selectedVehicleName}</p>
                  </Grid>

                  <Grid item xs={3} style={{
                    display: this.state.trackingSelect == 0 ? "" : "none",
                    textAlign: "right"
                  }}>
                    <i><b>Selected Group</b></i>
                    <p className="selected-vehicle" style={{ display: this.state.selectedGroupName ? "" : "none" }}>{this.state.selectedGroupName}</p>
                  </Grid>
                </Grid>
                {map_data}
                <div className="replay_route_slider" style={{ display: this.state.trackingSelect == 2 ? '' : 'none', left: "0" }}>
                  <Grid container>
                    <Grid item xs={6} className="rr_slider" style={{ borderRight: "1px solid #979797" }}>
                      <div>
                        <div>
                          <img src="images/Play.png" alt="icon" height="25px" id="play_rr" onClick={this.playRr}
                            style={{ display: this.state.playReplayRoute ? 'none' : '' }}></img>
                          <img src="images/Pause.png" alt="icon" height="25px" onClick={this.pauseRr}
                            style={{ display: this.state.playReplayRoute ? '' : 'none' }} id="pause_rr"></img>
                        </div>
                        <div className="slider_div">
                          <Slider
                            min={0}
                            max={this.state.sliderMax}
                            step={1}
                            value={this.state.slidedValue}
                            onChangeCommitted={(e, value) => {
                              this.setState({
                                slidedValue: value
                              }, () => {
                                this.returnMap()
                                // if(this.state.slidedValue !== this.state.sliderMax) {
                                // this.rrSliderDuration(this.state.replayRouteData.replay_data[this.state.slidedValue].datetime,
                                //     this.state.replayRouteData.replay_data.splice(-1)[0].datetime)
                                // console.log('slider value is', this.state.slidedValue)
                                // }else{
                                //     this.setState({
                                //         slidedValue : 0,
                                //     })
                                // }
                              })
                            }}
                          />
                        </div>
                        {/* <div style={{ margin: "0px 5px 0px 5px" }}>
                          <p style={{ color: "#666666" }}> {this.state.replayDuration} </p>
                        </div> */}
                      </div>
                    </Grid>
                    <Grid item container xs={6} style={{ textAlign: "center" }}>
                      <Grid container item xs={4}>
                        <Grid item xs={3}>
                          <p style={{ marginTop: "15px" }}>Time</p>
                        </Grid>
                        <Grid item xs={9}>
                          <p><span className="rr_value" style={{ fontSize: "25px", lineHeight: "1.7em" }}>{time ? CommonFunction.timeFormate(time) : ""}</span></p>
                        </Grid>
                      </Grid>
                      <Grid container item xs={4}>
                        <Grid item xs={6}>
                          <p style={{ borderLeft: "1px solid #979797", marginTop: "15px" }}>{t('ct.speed')}</p>
                        </Grid>
                        <Grid item xs={6}>
                          <p><span className="rr_value">{speed}</span>&nbsp;<b>km/h</b></p>
                        </Grid>
                      </Grid>
                      <Grid item container xs={4}>
                        <Grid item xs={6}>
                          <div style={{ borderLeft: "1px solid #979797", marginTop: "15px" }}>
                            <p>{t('ct.distance')}</p>
                          </div>
                        </Grid>
                        <Grid item xs={6}>
                          <p><span className="rr_value">{distancDriven}</span>&nbsp;<b>kms</b></p>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>



                {/* Share Button */}
                <div className="share_live_replay" style={{ display: this.state.trackingSelect == 1 || this.state.trackingSelect == 2 ? "" : "none" }}>
                  <div className="grid-container">
                    <div className="grid-item" style={{ borderLeft: "1px solid #cccccc", padding: "5px" }}>
                      <i className="fa fa-share-alt fa-2x v_start_journey"
                        style={{
                          lineHeight: "40px",
                          color: "orange",
                          cursor: "pointer"
                        }}
                        onClick={this.shareTracking}
                        aria-hidden="true"></i>
                    </div>
                  </div>
                </div>

                {/* Popup container */}
                <div id="share_location" className="p_modal_parent1" style={{
                  display: this.state.isSharePopupVisible ? "block" : "none",  // Toggle visibility
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: "1000",
                  padding: "0",
                  width: "100%",
                  backgroundColor: "transparent"
                }}>
                  <div className="p_modal_boday1" style={{
                    backgroundColor: "#fff",
                    padding: "20px",
                    borderRadius: "10px",
                    boxShadow: "0px 4px 16px rgba(0,0,0,0.1)",
                    maxWidth: "450px",
                    width: "100%"
                  }}>
                    <div className="close_modal" onClick={this.modalClose} style={{
                      position: "absolute",
                      right: "10px",
                      top: "10px",
                      cursor: "pointer",
                      fontSize: "18px"
                    }}>
                      <i className="fa fa-close" aria-hidden="true"></i>
                    </div>
                    <p style={{

                      textAlign: "center",
                      fontWeight: 'bold',
                      fontSize: "18px",
                      marginBottom: "10px",
                      color: "#ff7817"
                    }}>Share Current Location</p>
                    <hr style={{ border: "1px solid #ddd" }} />



                    {/* New Vehicle Name Display */}
                    <p style={{
                      textAlign: "center",
                      fontWeight: 'bold',
                      fontSize: "16px",
                      marginBottom: "10px",
                      color: "#4a4a4a"
                    }}>
                      Vehicle Name: {this.state.selectedVehicleName}
                    </p>



                    <p style={{
                      textAlign: "center",
                      fontWeight: 'bold',
                      color: "#4a4a4a",
                      padding: "10px",
                      border: "1px solid #ddd",
                      borderRadius: "5px",
                      backgroundColor: "#f9f9f9",
                      maxWidth: "150%",
                      width: "100%",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      margin: "0 auto"
                    }}>
                      {this.state.liveLocationUrl}
                    </p>


                    <label style={{
                      display: 'block',
                      textAlign: 'center',
                      fontWeight: 'bold',
                      marginTop: "15px",
                      color: "#ff7817"
                    }}>Set Link Validity:</label>
                    <select value={this.state.expiry_minutes} onChange={this.handleExpiryChange} style={{
                      display: 'block',
                      margin: '10px auto',
                      padding: "10px",
                      borderRadius: "5px",
                      width: "80%",
                      boxShadow: "0px 2px 6px rgba(0,0,0,0.1)"
                    }}>
                      <option value="480">8 hours</option>
                      <option value="720">12 hours</option>
                      <option value="960">16 hours</option>
                      <option value="1440">1 day</option>
                    </select>
                  </div>
                </div>


                <div className="panning_button" style={{ display: "none" }}
                  onClick={this.togglePanning}>
                  <p>Disable Panning</p>
                  <p style={{ display: "none" }}>Enable Panning</p>
                </div>
              </div>
              <div className="map_loading">
                <CircularProgress className="map_loading_icon" />
              </div>
            </div>


            <div style={{ display: this.state.showTempVehicleList ? "" : "none" }}>
              <div style={{ position: "absolute" }}>
                <button className="btn orange-btn" style={{ float: "none", marginTop: "2px" }} onClick={this.addVehicleDetails}>Add New Vehicle</button>
              </div>
              <MDBDataTable
                displayEntries={false}
                paging={false}
                scrollX
                entries={10}
                displayEntries={false}
                striped
                bordered
                data={{ columns: data2, rows: this.state.datatable }}
                onSearch={this.optimizationSearch}
              />
              <Grid container style={{ margin: "20px 0px" }}>
                <Grid item xs>
                  <p className="cj_w_header"><b>Total Vehicle : {CommonFunction.zeroAppend(this.state.untrackData.length)}</b></p>
                </Grid>
                {/* <Grid item xs style={{ textAlign: "right" }}>
                  <button className="btn" id="dt-previous" onClick={this.pagePreviousClick}>Previous</button>
                  <button className="btn" id="dt-next" onClick={this.pageNextClick}>Next</button>
                </Grid> */}
              </Grid>
            </div>
            {/*list view vehicle group*/}
            <div style={{ display: this.state.showVehicleGroup ? '' : 'none', }} className="report_filter_div">

              <div style={{ textAlign: "right" }}>
                <button className="btn orange-btn" style={{ float: "none", marginTop: "2px", }} onClick={this.addVehicleGroupDetails}>Create New Vehicle Group</button>
              </div>
              <div>
                {this.state.vlcGroupDataList.map((node, i) => {
                  const groupNm = node.groupName;
                  const id = node.groupId
                  //console.log(type,i)
                  //const label = <span>{type}</span>
                  const serialNoList = []
                  {
                    node.details.map((e) =>
                      serialNoList.push(e.serialNumber))
                  }
                  const label = <span className="node">
                    <Grid container spacing={4}>
                      <Grid item xs>
                        <div className="p_header">{groupNm}</div>
                      </Grid>
                      <Grid item xs></Grid>
                      <Grid item xs></Grid>
                      <Grid item xs></Grid>
                      <Grid item xs></Grid>
                      <Grid item xs>
                        <button className="btn orange-btn btn-block btn-sm " style={{ float: "none" }} onClick={this.updateVehicleGroupDetails.bind(this, groupNm, id, serialNoList)} >Update Group </button>
                      </Grid>
                      <Grid item xs style={{ textAlign: "right" }}>
                        <button className="btn orange-btn btn-block btn-sm" style={{ float: "none" }} onClick={this.deleteVehicleGroupDetails.bind(this, id)} >Delete Group</button>
                      </Grid>
                    </Grid>
                    <h2></h2>
                  </span>

                  return (
                    <TreeView
                      key={groupNm + "|" + i}
                      nodeLabel={label}
                      defaultCollapsed={true}
                    //defaultCollapseIcon={<ExpandMoreIcon />}
                    //defaultExpandIcon={<ChevronRightIcon />}
                    >

                      {node.details.map((data) => {
                        <div>{data.vehicleName}</div>
                        const serialNo = data.serialNumber
                        const label12 = <span className="node" >
                          <Grid container spacing={4}>
                            <Grid item xs>
                              <div className="work_sans_regular16" >{data.vehicleName}</div>
                            </Grid>
                            {/* <Grid item xs><i class="fas fa-trash-alt" aria-hidden="true" name={i} style={{ color: "#F0762B", marginTop: "10px", fontSize: "20px" }} onClick={this.removeStops}></i></Grid> */}
                            <Grid item xs>
                              {/* <img class="deletejourney" src="images/group-2.png" alt="icon" styles= {"marginTop: 10px; marginLeft: 15px; cursor: pointer" }></img> */}
                            </Grid>
                            <Grid item xs>
                              {/* <div className="small_button" style={{ display: "inline-block", marginRight: "10px" }}>
                    <i className="fa fa-exclamation-circle del_info_icon" aria-hidden="true"></i>
                  </div> */}
                            </Grid>
                            <Grid item xs>
                              {/* <div className="small_button" style={{ display: "inline-block" }}>
                    <i className="fas fa-pencil-alt del_info_icon" aria-hidden="true"></i>
                  </div> */}
                            </Grid>
                            <Grid item xs></Grid>
                            <Grid item xs>
                              {/* <button className="btn orange-btn btn-block btn-sm" style={{ float: "none" }} onClick={this.deleteVehicleName.bind(this,id,serialNo)} >Delete</button> */}
                              <Grid item xs><i class="fas fa-trash-alt" aria-hidden="true" name={i} style={{ color: "#F0762B", marginTop: "10px", fontSize: "20px", cursor: "pointer" }} onClick={this.deleteVehicleName.bind(this, id, serialNo)}></i></Grid>

                            </Grid>

                          </Grid>
                        </span>;
                        return (
                          <div>

                            <TreeView
                              nodeLabel={label12}
                              key={data.vehicleName}
                            //defaultCollapsed={true}
                            //defaultCollapseIcon={<ExpandMoreIcon />}
                            >
                            </TreeView>
                          </div>

                        );
                      })}
                    </TreeView>

                  );
                })}
              </div>
            </div>
            {/* vehicle group list view end */}


          </div>

        </div>

        <div id="data_loading" className="p_modal_parent" style={{ display: "none" }}>
          <div className="p_modal_boday" style={{ width: "300px" }}>
            <ProgressBar now={this.state.length} label={`${this.state.length}%`} style={{ marginBottom: "5px" }} />
            <p style={{ textAlign: "center", fontSize: "20px" }} className="cj_w_header">Loading <span><Digital color="#F0762B" size={20} speed={0.9} animating={true} /></span></p>
          </div>
        </div>

        <div id="add_vehicle" className="p_modal_parent" style={{ display: "none" }}>
          <div className="p_modal_boday">
            <p className="p_header">Vehicle Details</p>
            <hr />
            <Grid container spacing={3} style={{ marginBottom: "10px" }}>
              <Grid item xs>
                <p className="cj_w_header">Vehicle Name&nbsp;*</p>
              </Grid>
              <Grid item xs>
                <input type="text" placeholder="Enter Vehicle Name" className="journey_input"
                  onChange={(e) => {
                    this.setState({
                      addVehicleName: e.currentTarget.value
                    })
                  }}></input>
              </Grid>
            </Grid>
            <Grid container spacing={3} style={{ marginBottom: "10px" }}>
              <Grid item xs>
                <p className="cj_w_header">Vehicle Registration Number&nbsp;*</p>
              </Grid>
              <Grid item xs>
                <input type="text" placeholder="Enter Vehicle Name" className="journey_input"
                  onChange={(e) => {
                    this.setState({
                      addVehiclRegNumber: e.currentTarget.value
                    })
                  }}></input>
              </Grid>
            </Grid>
            <Grid container spacing={3} style={{ marginBottom: "10px" }}>
              <Grid item xs>
                <p className="cj_w_header">Is Temporary Vehicle</p>
              </Grid>
              <Grid item xs>
                <input type="checkbox" placeholder="Enter Vehicle Name" id="is_temporary_check" onChange={this.showValidDate}></input>
              </Grid>
            </Grid>
            <Grid container spacing={3} style={{ marginBottom: "10px", display: this.state.showValidDateCal ? "" : "none" }}>
              <Grid item xs>
                <p className="cj_w_header">Vehicle details Valid&nbsp;*</p>
              </Grid>
              <Grid item xs style={{ position: "relative" }}>
                <div className="input_div" onClick={this.showModalDate}>
                  <input type="text" placeholder="Select Date" id="modal_date_input" disabled value={CommonFunction.dateFormate(this.state.validityDate)} className="journey_input"></input>
                  <img src="images/fill-111.png" className="vd_journey_icon"></img>
                </div>
                <div className="calendar_parent" id="modal_date" style={{ width: "100%" }}>
                  <Calendar
                    selectRange={false}
                    onChange={this.modalValidDate}
                    value={this.state.validityDate}
                    minDate={modalMinDate}
                  />
                </div>
              </Grid>
            </Grid>
            <hr />
            <Grid container spacing={4}>
              <Grid item xs>
                <button className="btn orange-btn btn-block" style={{ float: "none" }} onClick={this.saveVehicleDetails}>Save & Update</button>
              </Grid>
              <Grid item xs>
                <button className="btn orange-btn btn-block" style={{ float: "none" }} onClick={this.cancelModal}>Cancel</button>
              </Grid>
            </Grid>
          </div>
        </div>


        <div id="add_vehicle_group" className="p_modal_parent" style={{ display: "none" }}>
          <div className="p_modal_boday">
            <p className="p_header">Create New Vehicle Group</p>
            <hr />
            <Grid container spacing={3} style={{ marginBottom: "10px" }}>
              <Grid item xs>
                <p className="cj_w_header">Group Name&nbsp;*</p>
              </Grid>
              <Grid item xs>
                <input type="text" placeholder="Enter Group Name" className="journey_input"
                  onChange={(e) => {
                    this.setState({
                      addGroupName: e.currentTarget.value
                    })
                  }}></input>
              </Grid>
            </Grid>
            <Grid item xs>
              <label className="work_sans_regular16">{t('ars.sum.v2')}</label>
              {/* <div className="input_div" onClick={this.showVehicles}></div> */}
              <div style={{ position: "relative" }} onClick={this.dropDownClick}>
                <div className="summary_report_filters">
                  <label>
                    {lableText}
                    {/* {this.state.chckebox_value.length <= 0 ? "Select Vehicle" : this.state.chckebox_value.length + "   Vehicles"} */}
                  </label>
                </div>
                <i class="fa fa-angle-down search_icon" aria-hidden="true" style={{ color: "#F0762B" }}></i>
              </div>
              <div className="all_region_dd">
                <div className="dropdown_parent" id="vehicles_select">
                  {/* <Grid container className="select_opt_row">
                  <Grid item xs={2}>
                    <input type="checkbox" id="all_vehicle" name="vehicle_tick" 
                    onChange={this.allVehicle} />
                  </Grid>
                  <Grid item xs={10}>
                    <p className="p_margin0">{t('ct.all.text')}</p>
                  </Grid>
                </Grid> */}
                  {vehicledataname}
                </div>
              </div>
            </Grid>
            <hr />
            <Grid container spacing={4}>
              <Grid item xs>
                <button className="btn orange-btn btn-block" style={{ float: "none" }} onClick={this.saveVehicleGroupDetails}>Create Group</button>
              </Grid>
              <Grid item xs>
                <button className="btn orange-btn btn-block" style={{ float: "none" }} onClick={this.cancelGrouplModal}>Cancel</button>
              </Grid>
            </Grid>
          </div>
        </div>



        <div id="update_vehicle_group" className="p_modal_parent" style={{ display: "none" }}>
          <div className="p_modal_boday">
            <p className="p_header">Update {this.state.addGroupName}</p>
            <hr />
            <Grid container spacing={3} style={{ marginBottom: "10px" }}>
              <Grid item xs>
                <p className="cj_w_header">Group Name&nbsp;*</p>
              </Grid>
              <Grid item xs>
                <input type="text" placeholder="Enter Group Name" value={this.state.addGroupName} className="journey_input"
                  onChange={(e) => {
                    this.setState({
                      addGroupName: e.currentTarget.value
                    })
                  }}></input>
              </Grid>
            </Grid>
            <Grid item xs>
              <label className="work_sans_regular16">{t('ars.sum.v2')}</label>
              <div style={{ position: "relative" }} onClick={this.dropDownClick}>
                <div className="summary_report_filters">
                  <label>
                    {lableText}
                  </label>
                </div>
                <i class="fa fa-angle-down search_icon" aria-hidden="true" style={{ color: "#F0762B" }}></i>
              </div>
              <div className="all_region_dd">
                <div className="dropdown_parent" id="vehicles_select">

                  {vehicledataname}
                </div>
              </div>
            </Grid>
            <hr />
            <Grid container spacing={4}>
              <Grid item xs>
                <button className="btn orange-btn btn-block" style={{ float: "none" }} onClick={this.updateDataVehicleGroupDetails}>Update Group</button>
              </Grid>
              <Grid item xs>
                <button className="btn orange-btn btn-block" style={{ float: "none" }} onClick={this.cancelUpdatelModal}>Cancel</button>
              </Grid>
            </Grid>
          </div>
        </div>


        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          autoHideDuration={1200}
          onClose={(e, reason) => {
            if (reason === 'clickaway') {
              return;
            }
            this.setState({ openAlert: false })
          }
          }
          open={this.state.openAlert}
          message={this.state.errorMessage}
        />
      </div>
    );
  }
}

export default withTranslation()(VehicleLists);
