import React, { Component } from 'react';
import $ from 'jquery';
import Navbar from '../../Config/Navbar';
import { MDBDataTable } from 'mdbreact';
import Grid from '@mui/material/Grid';
import ApiUrlCall from '../API/apiurl';
import CommonFunction from '../common_function'
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'mdbreact/dist/css/mdb.css';
import Snackbar from '@mui/material/Snackbar';
import { withTranslation } from 'react-i18next';

const data1 = [  
    {
        label: 'FIRM / CLIENT NAME',
        field: 'client_name',
        sort: 'asc',
        width: 150,
    },
    {
        label: 'VISIT LOCATION',
        field: 'location',
        sort: 'asc',
        width: 250,
    },
    {
        label: 'MOBILE NUMBER',
        field: 'mobile_number',
        sort: 'asc',
        width: 150,
    },
    {
        label: 'VISITS ASSIGNED TO',
        field: 'visit_assigned_to',
        sort: 'asc',
        width: 200,
    },
    {
        label: 'VISIT ASSIGNED BY',
        field: 'visit_assigned_by',
        sort: 'asc',
        width: 200,
    },
    {
        label: 'VISIT DATE',
        field: 'date',
        sort: 'asc',
        width: 200,
    },
    {
        label: 'VISIT TYPE',
        field: 'visit_type',
        sort: 'asc',
        width: 150,
    },
    {
        label: 'ACTION',
        field: 'action',
        sort: 'asc',
        width: 150,
    }
]

const PENDING = 4
const ONGOING = 3
const COMPLETE = 5
const ASSIGNED = 2

class PeopleVisitList extends Component {
    counter = 0
    constructor(props) {
        super(props);
        this.state = {
            datatable: [],
            nextCount: 0,
            totalCount: 0,
            dataCollection: [],
            chunckValue: '',
            searchText: '',
            totalEntries: 0,
            openAlert: false,
            errorMessage: '',
            visitList: [],
            visitType: 1
        }
    }

    componentDidMount() {
        var navBarHeight = $('.navBar').outerHeight()
        $('.people_visit_parent').css({
            paddingTop: navBarHeight + 60,
        })
        this.getVisitList()
    }

    getVisitList = () => {
        $('#processing').show()
        var data = { visit_type: this.state.visitType }
        ApiUrlCall.ptVisitList(JSON.stringify(data)).then(response => {
            // console.log('visit list', response)
            $('#processing').hide()
            this.emptyTable()
            if (response.data.response_code == 200 && response.data.is_success) {
                this.setState({
                    visitList: response.data.data.visit_list,
                    nextCount: response.data.data.next_count,
                    totalCount: response.data.data.total_count,
                    chunckValue: response.data.data.size
                }, () => {
                    this.createTableData()
                })
            } else if (response.data.response_code == 410 && !response.data.is_success) {
                if ($('#pplt_expire_dialog').is(':hidden')) {
                    $('#pplt_expire_dialog').slideDown()
                }
            } else {
                this.setState({
                    openAlert: true,
                    errorMessage: response.data.response_message
                })
            }
        }).catch(error => {
            $('#processing').hide()
            console.log('error while fetching visit list', error)
        })
    }

    buttonStatus = () => {
        const { nextCount, totalCount } = this.state
        // console.log(nextCount,'.................',totalCount)
        if (nextCount === totalCount) {
            // console.log('iffffffffff')      
            if (this.counter === 0) {
                $('#dt-previous').removeClass('enabled-btn')
                $('#dt-previous').addClass('disable-btn')
                $('#dt-next').removeClass('enabled-btn')
                $('#dt-next').addClass('disable-btn')
            } else {
                $('#dt-previous').removeClass('disable-btn')
                $('#dt-previous').addClass('enabled-btn')
                $('#dt-next').removeClass('enabled-btn')
                $('#dt-next').addClass('disable-btn')
            }
        } else {
            // console.log('elseeeeeeee', this.counter)
            if (this.counter === 0) {
                // console.log('else 1111111111')
                $('#dt-previous').removeClass('enabled-btn')
                $('#dt-previous').addClass('disable-btn')
                $('#dt-next').removeClass('disable-btn')
                $('#dt-next').addClass('enabled-btn')
            } else {
                // console.log('else 22222222')
                $('#dt-previous').removeClass('disable-btn')
                $('#dt-previous').addClass('enabled-btn')
                $('#dt-next').addClass('enabled-btn')
                $('#dt-next').removeClass('disable-btn')
            }
        }
    }

    entriesData = () => {
        const { nextCount, totalCount, chunckValue } = this.state
        if (nextCount != totalCount) {
            var start = nextCount - chunckValue + 1
            var end = nextCount
            var entries_text = start + '-' + end
            this.setState({
                totalEntries: entries_text
            })
        } else {
            if (nextCount <= 10) {
                if (nextCount === 0) {
                    var entries_text = '0' + '-' + totalCount
                } else {
                    var entries_text = '1' + '-' + totalCount
                }
                this.setState({
                    totalEntries: entries_text
                })
            } else {
                var module = nextCount % chunckValue
                var start
                var end
                var entries_text
                if (module > 0) {
                    start = nextCount - module + 1
                    end = nextCount
                    entries_text = start + '-' + end
                } else {
                    start = nextCount - chunckValue
                    end = nextCount
                    entries_text = start + '-' + end
                }
                this.setState({
                    totalEntries: entries_text
                })
            }
        }
    }

    visitType = (value) => {
        switch (value) {
            case ASSIGNED:
                return "Assigned"
                break;
            case ONGOING:
                return "Ongoing"
                break;
            case COMPLETE:
                return "Completed"
                break;
            case PENDING:
                return "Pending"
                break;
            default:
                break;
        }
    }

    visitDetails = (id) => {
        this.props.history.push({
            pathname: '/p_add_visit',
            state: { visit_id: id, edit_details : false }
        })
    }

    editDetails = (id) => {
        this.props.history.push({
            pathname: '/p_add_visit',
            state: { visit_id: id, edit_details : true }
        })
    }

    visitAction = (visit_type , id, voluntary_visit) => {
        if(visit_type == COMPLETE || visit_type == ONGOING || voluntary_visit){
            return <p className="cj_w_header" style={{ color: '#f4811f', cursor: "pointer", textDecoration: "underline" }} onClick={() => this.visitDetails(id)}>View Details</p>
        }else if(visit_type == PENDING || visit_type == ASSIGNED){
            return <p className="cj_w_header" style={{ color: '#f4811f', cursor: "pointer", textDecoration: "underline" }} onClick={() => this.editDetails(id)}>Edit Details</p>
        }
    }

    createTableData = () => {
        const { visitList, datatable } = this.state
        // console.log('lenght is', driverdata.length)
        for (let i = 0; i < visitList.length; i++) {
            const element = visitList[i];
            var row_data = {
                visit_id: element.name, client_name: element.client_name, location: element.address,
                mobile_number: element.mobile_number, visit_assigned_to: element.assigned_to, visit_assigned_by: element.assigned_by,
                date: CommonFunction.tableDateFormate(element.visit_datetime) + ", " + CommonFunction.timeFormate(element.visit_datetime),
                visit_type: this.visitType(element.visit_type),
                action: this.visitAction(element.visit_type, element.id, element.is_voluntary)
            }
            datatable.push(row_data)
        }
        this.buttonStatus()
        this.entriesData()
        this.forceUpdate()
        // this.intervalData()
    }

    emptyTable = () => {
        this.setState({
            datatable: []
        })
    }

    pageNextClick = () => {
        const { totalCount, nextCount, dataCollection, datatable } = this.state
        // console.log(this.counter,'next clicked',totalCount,'---------',nextCount)
        this.counter = this.counter + 1
        for (let i = 0; i < datatable.length; i++) {
            const element = datatable[i];
            dataCollection.push(element)
        }
        this.setState({
            datatable: []
        }, () => {
            this.getVisitList()
        })
    }

    pagePreviousClick = () => {
        const { dataCollection, chunckValue, nextCount } = this.state
        var pageSize = nextCount % chunckValue
        if (pageSize === 0) {
            pageSize = chunckValue
        }
        // console.log('previous clicked', pageSize)    
        var last = dataCollection.splice(Math.max(dataCollection.length - chunckValue))
        this.setState({
            datatable: last,
            nextCount: nextCount - pageSize
        }, () => {
            this.buttonStatus()
            this.entriesData()
        })
        this.counter = this.counter - 1
    }

    optimizationSearch = (value) => {
        setTimeout(() => {
            this.counter = 0
            if (value.length >= 3) {
                this.setState({
                    datatable: [],
                    dataCollection: [],
                    totalCount: '',
                    chunckValue: '',
                    nextCount: 0,
                    searchText: value
                }, () => {
                    this.getVisitList()
                })
            } else if (value.length === 0) {
                this.setState({
                    datatable: [],
                    dataCollection: [],
                    totalCount: '',
                    chunckValue: '',
                    nextCount: 0,
                    searchText: value
                }, () => {
                    this.getVisitList()
                })
            }
        }, 1000);
    }

    resetStateValue = () => {
        this.counter = 0
        this.setState({
            datatable: [],
            dataCollection: [],
            totalCount: '',
            chunckValue: '',
            nextCount: 0,

        }, () => {
            this.counter = 0
            this.getVisitList()
        })
    }

    render() {
        const { t } = this.props
        return (
            <div>
                <Navbar />
                <div className="people_visit_parent">
                    <Grid container>
                        <Grid item xs className="tableHeading">
                            <label>Visits Listing</label>
                        </Grid>
                        <Grid item xs>
                            <button className="btn orange-btn" onClick={() => {
                                this.props.history.push({
                                    pathname: '/p_add_visit'
                                })
                            }}>Add new visit</button>
                        </Grid>
                    </Grid>
                    <div style={{ marginTop: "30px" }}>
                        <div style={{ position: "absolute", marginTop: "-20px" }}>
                            <p className="p_header">Visit type</p>
                            <select className="status-filter" defaultValue={this.state.visitType} style={{ width: "fit-content", position: "relative" }}
                                onChange={(e) => {
                                    this.setState({
                                        visitType: e.currentTarget.value
                                    }, () => {
                                        this.resetStateValue()
                                    })
                                }}>
                                <option value="1">{t('ct.all.text')}</option>
                                <option value="2">Assigned</option>
                                <option value="3">Ongoing</option>
                                <option value="5">Complete</option>
                                <option value="4">Pending</option>
                            </select>
                        </div>
                        <MDBDataTable
                            displayEntries={false}
                            paging={false}
                            scrollX
                            entries={10}
                            striped
                            bordered
                            data={{ columns: data1, rows: this.state.datatable }}
                            onSearch={this.optimizationSearch}
                        />
                        <Grid container style={{ margin: "20px 0px" }}>
                            <Grid item xs>
                                <p className="cj_w_header"><b>Entries {this.state.totalEntries} of {this.state.totalCount}</b></p>
                            </Grid>
                            <Grid item xs style={{ textAlign: "right" }}>
                                <button className="btn disable-btn" id="dt-previous" onClick={this.pagePreviousClick}>Previous</button>
                                <button className="btn disable-btn" id="dt-next" onClick={this.pageNextClick}>Next</button>
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    autoHideDuration={1200}
                    onClose={(e, reason) => {
                        if (reason === 'clickaway') {
                            return;
                        }
                        this.setState({ openAlert: false })
                    }
                    }
                    open={this.state.openAlert}
                    message={this.state.errorMessage}
                />
            </div>
        );
    }
}

export default withTranslation()(PeopleVisitList);
