import React, { Component } from 'react';
import $ from 'jquery';
import Navbar from '../../Config/Navbar';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Grid from '@mui/material/Grid';
import ManagementUserList from './usermanagement/management_user_list'
import ManagementAdminList from './usermanagement/management_admin_list'
import { withTranslation } from 'react-i18next';

class PeopleUserManagement extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    componentDidMount() {
        var navBarHeight = $('.navBar').outerHeight()
        $('.people_report_parent').css({
            paddingTop: navBarHeight + 60,
        })
    }

    sendInvite = () => {
        // console.log('props', this.props)
        this.props.history.push({
            pathname : '/p_send_invite'
        })
    }

    render() {
        return (
            <div>
                <Navbar />
                <div className="people_report_parent">
                    <Grid container>
                        <Grid item xs className="tableHeading">
                            <label>Users Listing</label>
                        </Grid>
                        <Grid item xs>
                            <button className="btn orange-btn" onClick={this.sendInvite}>send new invite</button>
                        </Grid>
                    </Grid>
                    <Tabs>
                        <TabList style={{ textAlign: 'left' }}>
                            <Tab>Admin</Tab>
                            <Tab>Users</Tab>
                        </TabList>
                        <TabPanel>
                            <ManagementAdminList data={this.props}/>
                        </TabPanel>
                        <TabPanel>
                            <ManagementUserList data={this.props}/>
                        </TabPanel>                        
                    </Tabs>
                </div>
            </div>
        );
    }
}

export default withTranslation()(PeopleUserManagement);