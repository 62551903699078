import React, { Component } from 'react';
import $ from 'jquery';
import './create_new_journey.scss';
import Grid from '@mui/material/Grid';
import Calendar from 'react-calendar/dist/entry.nostyle'
import '../calendar/calendar.scss'
import CommonFunction from '../common_function';
import Navbar from '../../Config/Navbar'
import ApiUrlCall from '../API/apiurl'
import Map from '../map/map';
import Snackbar from '@mui/material/Snackbar';
import analytics from '../../Config/firebase'
import Amplify, { Analytics } from 'aws-amplify';
import awswebapp from '../../aws-exports';
import constant from '../constant.json'
import { withTranslation } from 'react-i18next';

const newstopsArray = []

class EditJourneyDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      vehicleSelect: [],
      driverSelect: [],
      showButton: false,
      createStop: true,
      selectedStop: 0,
      enterDetail: true,
      startLocation: '',
      endLocation: '',
      startDate: new Date(),
      startTime: '',
      endDate: new Date(),
      endTime: '',
      jsonStartDateTime: '',
      jsonEndDateTime: '',
      stops: '',
      drivers: '',
      CreateJourneyData: '',
      showVehicleList: '',
      showDriverListJor: '',
      journeyId: '',
      MapContent: [],
      showMapSelect: false,
      addAddressTo: false,
      markerAddressLat: '',
      markerAddressLng: '',
      address_is: '',
      latitude_is: '',
      longitude_is: '',
      MapCenter: '',
      MapContent: [],
      stopAddress: [],
      stopMapOpen: false,
      indexStop: 0,
      startLocationLat: '',
      startLocationLng: '',
      endLocationLat: '',
      endLocationLng: '',
      autoAddress : '',
      openAlert : false,
      errorMessage : '',
      stopFlag : true,
      stopAlert : false,      
      journeyStopage : 0,
      PudoUserList : [],
      nextCount : 0,
      searchText : '',
      driverSearch : '',
      vehicleArray : '',
      DriverArray : '',
      VehicleList :[],
      DriverList :[],
      vehicleStops :'',
      JourneyDetail:'',
      driver_id: '',
      driver_serial_number: '',
      trackingSerialNumber: '',
      vehicle_name_send_json: '',
      driver_name_send_json: '',
      isAutoStart: false,
      recurringType : 1,
      isRecurring : false,
      recurringDays : "",
      includeSunday : false,
      get_recurringType : "",
      get_isRecurring : false,
      get_recurringDays : "",
      get_includeSunday : false,
      new_state: 0,

    }
  }

  UNSAFE_componentWillMount() {
    this.getMyLocation()
  }

  componentDidMount() {
    analytics.logEvent('screen_view',{
      screen_name : "EditJourney"
    })
    analytics.logEvent('page_view',{
      page_title : "EditJourney",        
    })
    Amplify.configure(awswebapp);
    Analytics.record({
      name: constant.awsName, 
      // Attribute values must be strings
      attributes: { screen_name: 'WEB_EDIT_JOURNEY'}
    });
    var navBarHeight = $('.navBar').outerHeight();
    $('.create_journey_parent').css({
      paddingTop: navBarHeight + 60,
    })
    this.getJourneyDetails()
    this.getPudoUserList()
    this.getVehicleList()
    this.getDriverList()

  }

  removeStops = (e) => {
    $('.edit_stops_parent').find('.fa-trash-alt').show()
    //$('.edit_stops_parent').last().prev().find('.fa-trash-alt').show()
    var removeStopArray = this.state.stops
    var decreaseCounter = this.state.selectedStop - 1
    var arrayToDelete = $(e.currentTarget).attr('name')
    removeStopArray.splice(arrayToDelete, 1)
    this.setState({
      selectedStop: decreaseCounter
    })
    this.forceUpdate()
   }

   
  // removeStops = (e) => {
  //   $('.stops_parent').find('.fa-trash-alt').hide()
  //   $('.stops_parent').last().prev().find('.fa-trash-alt ').show()
  //   var removeStopArray = this.state.stops
  //   var removeStopElementArray = this.state.stopElement
  //   var decreaseCounter = this.state.selectedStop - 1
  //   var deleteLocation = $(e.currentTarget).attr('name')
  //   var removeElement = $(e.currentTarget).parent().parent().attr('name')
  //   // var deleteStopIndex = removeStopArray.findIndex(x => x.location === deleteLocation)
  //   // console.log('remove element', removeElement)
  //   var deleteStopElementIndex = removeStopElementArray.findIndex(y => y.props.id === parseInt(removeElement))
  //   removeStopElementArray.map((items, index, removeStopElementArray) => {
  //     // console.log('itmes', items.props)
  //     if (items.props.name === parseInt(removeElement)) {
  //       removeStopElementArray.splice(deleteStopElementIndex, 1)
  //       this.setState({
  //         selectedStop: decreaseCounter
  //       }, () => {
  //         // console.log('previous counter value is', this.state.selectedStop)
  //         // console.log('array of stop data', this.state.stopElement)
  //       })
  //       this.forceUpdate()
  //     }
  //   })
  //   removeStopArray.splice(deleteLocation, 1)
  //   this.setState({
  //     selectedStop: decreaseCounter
  //   })
  //   this.forceUpdate()
  //   // console.log('stops data after delete', this.state.stops)
  // }

  getJourneyDetails = () => {
    $('#processing').show()
    var journey_id = this.props.location.state.journey_id
    var new_state = this.props.location.state.my_state
    this.setState({
      new_state: new_state
    })
    //console.log("shri datt",new_state)
    ApiUrlCall.uniqueJourneyDetail(journey_id).then(response => {
       //console.log('edit journey details', response.data)
       //console.log('journey_vehicle_detail length', response.data.journey_vehicle_detail.length)
      if (response.status === 200 && response.data.journey_vehicle_detail.length === 0) {
        $('#processing').hide()
        this.setState({
          CreateJourneyData: response.data,
          JourneyDetail: response.data.journey_vehicle_detail,
          journeyId: response.data.id,
          startLocation: response.data.planned_start_loc,
          endLocation: response.data.planned_end_loc,
          startDate: response.data.start_time,
          endDate: response.data.end_time,
          jsonStartDateTime: response.data.start_time,
          jsonEndDateTime: response.data.end_time,
          stops: response.data.stops,
          startTime: CommonFunction.timeFormate(response.data.start_time),
          endTime: CommonFunction.timeFormate(response.data.end_time),
          selectedStop: response.data.stops.length,
          startLocationLat: response.data.planned_start_loc_lat,
          startLocationLng: response.data.planned_start_loc_lon,
          endLocationLat: response.data.planned_end_loc_lat,
          endLocationLng: response.data.planned_end_loc_lon,
          stopAlert : response.data.is_stop_alert,
          journeyStopage : CommonFunction.convertSecToMin(response.data.stopage_duration),
          //trackingSerialNumber: response.data.journey_vehicle_detail[0].serial_number,
          vehicleStops: response.data.stops,
          isAutoStart:response.data.is_auto_start,
          get_recurring_type : response.data.recurringType, 
          get_recurring_days : response.data.recurring_days,
          get_is_sunday_journey : response.data.is_sunday_journey,
          get_is_recurring : response.data.is_recursive,
        }, () => {
          // console.log("alfa5",this.state.JourneyDetail)
          // for (let i = 0; i < this.state.JourneyDetail.length; i++) {
          //   const element = this.state.JourneyDetail[i];
          //   console.log("element",element.id)
          //   console.log("element",element.serial_number)

          //}
          //this.forceUpdate()
          this.setid_serial_number()
          this.forceUpdate()

          $('.edit_stops_parent').find('.fa-trash-alt').show()
          //$('.edit_stops_parent').last().find('.fa-trash-alt').show()
          if(this.state.stopAlert){
            $('#stop_alert').prop('checked',true)
          }
          //this.forceUpdate()
          if(this.state.isAutoStart){
            $('#auto_start').prop('checked',true)
          }
          if(this.state.get_is_recurring){
            $('#recurring_alert').prop('checked',true)
          }
          if(this.state.get_is_sunday_journey){
            $('#sunday_alert').prop('checked',true)
          }
          if(this.state.new_state === 5){
            $('#recurring_alert').prop('disabled',true)
           $('#sunday-alert').prop('disabled',true)
          }

        })
      }
      else{
        //console.log("Mithun")
        $('#processing').hide()
        this.setState({
          CreateJourneyData: response.data,
          JourneyDetail: response.data.journey_vehicle_detail,
          journeyId: response.data.id,
          startLocation: response.data.planned_start_loc,
          endLocation: response.data.planned_end_loc,
          startDate: response.data.start_time,
          endDate: response.data.end_time,
          jsonStartDateTime: response.data.start_time,
          jsonEndDateTime: response.data.end_time,
          stops: response.data.stops,
          startTime: CommonFunction.timeFormate(response.data.start_time),
          endTime: CommonFunction.timeFormate(response.data.end_time),
          selectedStop: response.data.stops.length,
          startLocationLat: response.data.planned_start_loc_lat,
          startLocationLng: response.data.planned_start_loc_lon,
          endLocationLat: response.data.planned_end_loc_lat,
          endLocationLng: response.data.planned_end_loc_lon,
          stopAlert : response.data.is_stop_alert,
          journeyStopage : CommonFunction.convertSecToMin(response.data.stopage_duration),
          trackingSerialNumber: response.data.journey_vehicle_detail[0].serial_number,
          vehicleStops: response.data.stops,
          isAutoStart:response.data.is_auto_start,
          get_recurring_type : response.data.recurringType, 
          get_recurring_days : response.data.recurring_days,
          get_is_sunday_journey : response.data.is_sunday_journey,
          get_is_recurring : response.data.is_recursive,
        }, () => {
          // console.log("alfa5",this.state.JourneyDetail)
          // for (let i = 0; i < this.state.JourneyDetail.length; i++) {
          //   const element = this.state.JourneyDetail[i];
          //   console.log("element",element.id)
          //   console.log("element",element.serial_number)

          //}
          //this.forceUpdate()
          this.setid_serial_number()
          this.forceUpdate()

          $('.edit_stops_parent').find('.fa-trash-alt').show()
          //$('.edit_stops_parent').last().find('.fa-trash-alt').show()
          if(this.state.stopAlert){
            $('#stop_alert').prop('checked',true)
          }
          //this.forceUpdate()
          if(this.state.isAutoStart){
            $('#auto_start').prop('checked',true)
          }
          // if(this.state.get_is_recurring){
          //   $('#recurring_alert').prop('checked',true)
          // }
          // if(this.state.get_is_sunday_journey){
          //   $('#sunday_alert').prop('checked',true)
          // }
           $('#recurring_alert').prop('disabled',true)
           $('#sunday-alert').prop('disabled',true)
           

        })
      }
    }).catch(error => {
      $('#processing').hide()
      console.log('error while getting journey detail', error)
    })
  
  }
 
  setid_serial_number = () => {
    var var_driver_id = 0
    var var_serial_number = 0
    for (let i = 0; i < this.state.JourneyDetail.length; i++){
        var element = this.state.JourneyDetail[i];
        //console.log("element",element["assigned_to"].id)
        //console.log("element serial_number",element)
        var_driver_id = element["assigned_to"].id;
        var_serial_number = element.serial_number;        
      }
      this.setState({
        driver_id : var_driver_id,
        driver_serial_number : var_serial_number
      })
      this.disableDriver()
      this.disableVehicle()
      this.forceUpdate()

    } 

    vehicleName = (serial_number) => {
      const { JourneyDetail } = this.state
      for (let i = 0; i < JourneyDetail.length; i++) {
          const element = JourneyDetail[i];
          if (element.serial_number === serial_number) {
              return element.vehicle_name
          }
      }
  }


  addVehicleDriver = () => {
    let validationField = CommonFunction.handleValidation(this.state.startLocation, this.state.endLocation, this.state.startDate, this.state.endDate,
      this.state.startTime, this.state.endTime)
      if(this.state.recurringDays === ''){
        var rd = this.state.get_recurring_days
      }else{
        var rd =this.state.recurringDays
      }
      // var startTimeIs = $('#j_start_time').val()
      // var endTimeIs = $('#j_end_time').val()      
      // this.startTimeCreaterFun(startTimeIs)
      // this.endTimeCreaterFun(endTimeIs)
    // console.log(this.state.stopFlag,'...........')
    // console.log(this.state.jsonStartDateTime,'....................', this.state.jsonEndDateTime)
    for (let i = 0; i < this.state.stops.length; i++) {
      const element = this.state.stops[i];
      if(!element.location){
        this.setState({
          openAlert : true,
          errorMessage : "Kindly select stop location"
        })
        return
      }
    }
    if (validationField && this.state.stopFlag) {
      if(this.state.jsonStartDateTime >= this.state.jsonEndDateTime){        
        this.setState({
          openAlert : true,
          errorMessage : 'Selected datetime is invalid'
        })
      }else{
      setTimeout(function () {
        var sendJsonData = {
          start_time: CommonFunction.ServerDateTime(this.state.jsonStartDateTime), 
          end_time: CommonFunction.ServerDateTime(this.state.jsonEndDateTime),
          planned_start_loc: this.state.startLocation, 
          planned_end_loc: this.state.endLocation, 
          planned_start_loc_lat: this.state.startLocationLat,
          planned_start_loc_lon: this.state.startLocationLng, 
          planned_end_loc_lat: this.state.endLocationLat, 
          planned_end_loc_lon: this.state.endLocationLng,
          created_at: CommonFunction.ServerDateTime(this.state.date),
          created_by: localStorage.getItem('user_id'), 
          stops: this.state.stops, 
          is_stop_alert : this.state.stopAlert, 
          stopage_duration : this.state.journeyStopage,
          is_auto_start : this.state.isAutoStart,
          serial_number : this.state.driver_serial_number,
          driver_id : this.state.driver_id,
          // vehicle_name:this.state.vehicle_name_send_json,
          // driver_name:this.state.driver_name_send_json,
          recurring_type : this.state.recurringType, 
          recurring_days : rd,
          is_sunday_journey : this.state.includeSunday,
          is_recurring : this.state.isRecurring,

        }
        var jsonSendJsonData = JSON.stringify(sendJsonData)
        //console.log('journey edit data is', jsonSendJsonData) 
        // return       
        ApiUrlCall.updateJourneyDetail(this.state.journeyId, jsonSendJsonData).then(response => {
          // console.log("success", response)
          if (response.status === 200) 
          // {
          //   this.props.history.push({
          //     pathname: 'create_new_journey',
          //     state: { journey_id: this.state.journeyId }
          //   })
          // }
          {
            this.setState({
              openAlert: true,
              errorMessage: "Journey Updated Successfully"
            })
            setTimeout(() => {
              this.props.history.push({
                pathname: '/journey',
                state: { journey_id: this.state.journeyId }
              })
            }, 2000);
            
          }
        }).catch(error => {
          // alert("enter the selected stops detail")
          this.setState({
            openAlert : true,
            errorMessage : "Kindly enter Stop location or duration"
          })
          console.log("error while creating journey", error.response.data)
        })
      }.bind(this), 100)
    }
    }else{
      this.setState({
        openAlert : true,
        errorMessage : "Invalid data of stop duration or journey time"
      })
    }
  }
  getPudoUserList = () =>{
    $('#processing').show()
    var data = {role_id : 4}
    ApiUrlCall.ShowPudoUserList(JSON.stringify(data)).then(response => {            
        //this.emptyTable()
        if(response.data.response_code === 200 && response.data.is_success){
          $('#processing').hide()          
          this.setState({
            PudoUserList: response.data.data.vender_list
          },() => {
            //this.createTableData()          
            //console.log(">>>>>>>>>>>>>. vender_list ",this.state.PudoUserList)
          })
        }else{
          $('#processing').hide()
        }
    }).catch(error => {
        $('#processing').hide()
        //console.log('error while fetching getPudoUserList list', error)
    })
}

  pudo_user = (id) => {
                    
  for (let i = 0; i < this.state.PudoUserList.length; i++){
      var element = this.state.PudoUserList[i];
      //console.log("ankita success",element)
      if (element.user_id === id) {
          return element.first_name
      }
      
    }
   
  }

getVehicleList = () =>{
  $('#processing').show()
    var data = {next_count : this.state.nextCount , search : this.state.searchText, search_column: "vehicle_name",size:1000}

  ApiUrlCall.getVehicleList(JSON.stringify(data)).then(response => {            
      //this.emptyTable()
      if(response.data.response_code === 200 && response.data.is_success){
        $('#processing').hide()          
        this.setState({
          VehicleList: response.data.data.vehicle_summary_list
        },() => {
          //this.createTableData()          
          //console.log(">>>>>>>>>>>>>. vehicle_list ",this.state.VehicleList)
        })
      }else{
        $('#processing').hide()
      }
  }).catch(error => {
      $('#processing').hide()
      console.log('error while fetching getVehicle list', error)
  })
}

getDriverList = (div,text) =>{
  $('#processing').show()
  var data = {next_count : 0 , search : "",size:1000}

  ApiUrlCall.getDriverDetails(JSON.stringify(data)).then(response => {            
      //this.emptyTable()
      //console.log(">>>>>>>>>>>>>. driver_list ",response.data.data.driver_list)
      if(response.data.response_code === 200 && response.data.is_success){
        $('#processing').hide()          
        this.setState({
          DriverList: response.data.data.driver_list
        },() => {
          //this.createTableData()          
          //console.log(">>>>>>>>>>>>>. driver_list ",this.state.DriverList)
        })
      }else{
        $('#processing').hide()
      }
  }).catch(error => {
      $('#processing').hide()
      console.log('error while fetching getdriver list', error)
  })
  
}

selectedValue = (id) => {
  //console.log("element idd__",id)
  var {JourneyDetail} = this.state
  for (let i = 0; i < JourneyDetail.length; i++) {
    const element = JourneyDetail[i]["assigned_to"].id;
    //console.log("element idd",element)
    if(element == id){
      return true
    }
  }
  return false
}

selectedValue2 = (id) => {
  //console.log("element idd2__",id)
  var {JourneyDetail} = this.state
  for (let i = 0; i < JourneyDetail.length; i++) {
    const element2 = JourneyDetail[i].serial_number;
    //console.log("element idd2",element2)
    if(element2 == id){
      return true
    }
  }
  return false
}
disableDriver = () => {    

    
    for (const option of document.getElementById('driver_')) {
     
      var disable = this.selectedValue(option.value)
      //console.log("mohan",disable)
      if(disable){          
        $(option).attr('disabled',true)
      }else{          
        $(option).attr('disabled',false)
      }        
    }  
}


disableVehicle = () => {    

    
  for (const option of document.getElementById('vehicle_')) {
   
    var disable = this.selectedValue2(option.value)
    if(disable){          
      $(option).attr('disabled',true)
    }else{          
      $(option).attr('disabled',false)
    }        
  }  
}

stopNameSet = (i,e) => {
  const stop_name_id = 'stop_'+i
  const stop_name = $("#"+stop_name_id).val()  
  const stopuser_id = 'stopuser_'+i
  const stop_user = $("#"+stopuser_id).val()
  this.state.stops[i].name = stop_name
  this.state.stops[i].person_id = stop_user
  this.forceUpdate()
  

}
driverNameSet =(e) => {
  // const driver_name = $('#driver_').val()
  // this.state.driver_name_send_json =driver_name
  // this.forceUpdate()
  this.setState({
    driver_id:e.currentTarget.value
  })
}


vehicleNameSet =(e) => {

  //var vehicle_serival_number = $('#vehicle_').val()
  this.setState({
    driver_serial_number:e.currentTarget.value
  })
  //this.forceUpdate()
}

returnShowVehicleList = () => {
  //console.log("this.state.VehicleList ",this.state.VehicleList)
  var vehicle_list = this.state.VehicleList.map(vehicle_list => {
      return (
        <option key={vehicle_list.serial_number} value={vehicle_list.serial_number}>{vehicle_list.vehicle_name}</option> 
      )
    })
    return (<Grid item xs={6}>              
    <select id={'vehicle_'} value={this.state.driver_serial_number} className="vehicle-filter journey_input" style={{ width: "100%" }}
        onChange={(e) => {

            //console.log("e.currentTarget.value",e.currentTarget.value)
            for (const option of document.getElementById('vehicle_')) {
              if (option.value === e.currentTarget.value)
              {
                option.selected = true
              }
              else{
                option.selected = false                            
              }

            }
            this.vehicleNameSet(e)


        }}
        >
        <option value={0}>Select</option> 
        {vehicle_list}
    </select>
    </Grid> )
}
returnShowDriverList = () => {

  var driver_list = this.state.DriverList.map(driver_list => {
      return (
        <option key={driver_list.id} value={driver_list.id}>{driver_list.driver_name}</option>
         
      )
    })
    return (<Grid item xs={6}>              
    <select id={'driver_'} value={this.state.driver_id} className="driver-filter journey_input" style={{ width: "100%" }}
        onChange={(e) => {


            for (const option of document.getElementById('driver_')) {
              if (option.value === e.currentTarget.value)
              {
                option.selected = true
              }
              else{
                option.selected = false                            
              }

            }
            this.driverNameSet(e)


        }}
        >
        <option value={0}>Select</option> 
        {driver_list}
    </select>
    </Grid> )
}

  returNumberOfStop = (stopsValue) => {
    const stops = []
    const {t} = this.props
    if (this.state.stops.length < stopsValue) {
      const loopValue = stopsValue - this.state.stops.length
      for (let j = 0; j < loopValue; j++) {
        var stopObj = { location: '', lat: 2.0, lon: 1.0, duration: 1 ,id:j }
        this.state.stops.push(stopObj)
        //neewstops.push(stopObj)
      }
    } else {

    }
    var pudouser_list = this.state.PudoUserList.map(pudouser_list => {
      return (
        <option key={pudouser_list.user_id} value={pudouser_list.user_id}>{pudouser_list.first_name}</option> 
      )
    })

    for (let i = 0; i < stopsValue; i++) {
      //console.log("chetan",this.state.stops[i].id)
      stops.push(
        <Grid container spacing={4} item xs={10} id={this.state.stops[i].id} className="edit_stops_parent">
          <Grid item xs={5}>
            <p className="">{t('ct.stops')} : {i+1}</p>
            <div className="input_div" onClick={(e) => this.openStopMap(i, e)}>
              <input type="text" placeholder={t('ct.select.location')} value={this.state.stops[i].location} className="journey_input" disabled></input>
              <img src="images/cj_location.png" name={this.state.stops[i].id} id={i}></img>
            </div>
            <br/>
            <div className="input_div pointer" >              
              <input type="text" placeholder="Stop Name" onChange={() => this.stopNameSet(i)} id={'stop_'+i} value= {this.state.stops[i].name} className="journey_input pointer" ></input>
              <img src="images/cj_location.png"></img>
            </div>
          </Grid>
          <Grid item xs={5}>
            <p>{t('ct.duration')}</p>
            <input type="text" placeholder={t('ct.minutes')} Value={this.state.stops[i].duration} className="journey_input" onChange={(e) => {
              if(e.currentTarget.value >= 1){
                this.state.stops[i].duration = parseInt(e.currentTarget.value)
                this.setState({
                  stopFlag : true,
                })
              }else{
                this.setState({
                  openAlert : true,
                  errorMessage : "Stop Duration should be greater than equal to 1 minutes",
                  stopFlag : false,
                })
              }
            }}></input>
            <div/>
            <br/>
          <Grid item xs={12}>              
                <select id={'stopuser_'+i} defaultValue={this.state.stops[i].person_id} className="pudo-filter journey_input" style={{ width: "100%" }}
                    onChange={(e) => {


                        for (const option of document.getElementById('stopuser_'+i)) {
                          //console.log(option);
                          if (option.value === e.currentTarget.value)
                          {
                            option.selected = true
                          }
                          else{
                            option.selected = false                            
                          }

                        }
                        this.stopNameSet(i)

  
                    }}
                    >
                    {/* {console.log("alfa2",this.state.stops[i])} */}
                    <option value="0">Select</option>
                    
                    {pudouser_list}
                </select>
            </Grid>   
            </Grid>       
          <Grid item xs={1}>
            <p>&nbsp;</p>
            <i class="fas fa-trash-alt" aria-hidden="true" name={i} style={{ color: "#F0762B", marginTop: "10px", fontSize: "20px" }} onClick={this.removeStops}></i>
          </Grid>
        </Grid>
      )
    }
    return stops
  }

  StartdateClicked = date => {
    var isoStartDate = date
    this.setState({ startDate: isoStartDate })
    $('#start_date_cal').slideUp(200)
  }
  EnddateClicked = date => {
    var isoStartDate = date
    this.setState({ endDate: isoStartDate })
    $('#end_date_cal').slideUp(200)
  }
  showStartDate = (e) => {
    $(e.currentTarget).next().slideToggle(200, "swing")
  }
  showEndDate = (e) => {
    $(e.currentTarget).next().slideToggle(200, "swing")
  }
  dateFormate = (rdate) => {
    var date = new Date(rdate);
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    if (day < 10) {
      day = '0' + day;
    }
    if (month < 10) {
      month = '0' + month;
    }
    var convertDate = day + '/' + month + '/' + year
    return convertDate
  }

  showTimePicker = (e) => {
    $(e.currentTarget).next().slideToggle()
  }

  // startTimeCreaterFun = (timeIs) => {
    
  // }

  // endTimeCreaterFun = (timeIs) => {
    
  // }

  startTimeSelected = (e) => {
    var hide_div = $(e.currentTarget).parents()[2]
    var hours = $('#startHours').val()
    var minutes = $('#startMins').val()
    var am_pm = $('#startAmPm').val()
    if (hours < 10) {
      hours = '0' + hours;
    }
    var initialTime = hours + ':' + minutes + ' ' + am_pm
    this.setState({
      startTime: initialTime
    })
    $(hide_div).hide()
    // console.log("time is", hours + ':' + minutes + am_pm)
    var createStartDate = this.createDate(this.state.startDate, initialTime)
    // console.log(this.state.date,'>>>>>>>>>>>>>>>', createStartDate)
    if(this.state.date > createStartDate){
      this.setState({
        stopFlag : false,
        openAlert : true,
        errorMessage : 'Selected time should be greater than current time'
      })
      return
    }else{
      this.setState({
        stopFlag : true,
      })
    }
    this.setState({
      jsonStartDateTime: createStartDate
    })
  }

  endTimeSelected = (e) => {
    var hide_div = $(e.currentTarget).parents()[2]
    var hours = $('#endHours').val()
    var minutes = $('#endMins').val()
    var am_pm = $('#endAmPm').val()
    if (hours < 10) {
      hours = '0' + hours;
    }
    var initialTime = hours + ':' + minutes + ' ' + am_pm
    this.setState({
      endTime: initialTime
    })
    // console.log('>>>>>>>>>>>>>>>')
    $(hide_div).hide()
    // console.log("time is", hours + ':' + minutes + am_pm)
    var createEndDate = this.createDate(this.state.endDate, initialTime)
    if(this.state.date > createEndDate){
      this.setState({
        stopFlag : false,
        openAlert : true,
        errorMessage : 'Selected time should be greater than current time'
      })
      return
    }else{
      this.setState({
        stopFlag : true,
      })
    }
    this.setState({
      jsonEndDateTime: createEndDate
    })
    // console.log('>>>>>>>>>>>>>>>', createEndDate)
  }

  createDate = (date1, time1) => {
    var date = date1
    var onlyDate = CommonFunction.getParsedDate(date)
    var time = time1
    if (time) {
      var hours = Number(time.match(/^(\d+)/)[1]);
      var minutes = Number(time.match(/:(\d+)/)[1]);
      var AMPM = time.match(/\s(.*)$/)[1];
      if (AMPM == "pm" && hours < 12) hours = hours + 12;
      if (AMPM == "am" && hours == 12) hours = hours - 12;
      var sHours = hours.toString();
      var sMinutes = minutes.toString();
      if (hours < 10) sHours = "0" + sHours;
      if (minutes < 10) sMinutes = "0" + sMinutes;
    }
    var convertedTime = sHours + ":" + sMinutes + ":00"
    var sendDate = new Date(onlyDate + ' ' + convertedTime)
    var isoDate = sendDate
    return isoDate
  }



  //  this is used to get my current location
  getMyLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.MyLocation);
    }
  }
  MyLocation = (position) => {
    this.setState({
      markerAddressLat: position.coords.latitude,
      markerAddressLng: position.coords.longitude,
    })
  }

  // this method is used to render MAP
  renderMap = () => {
    // console.log('markerAddressLat', this.state.markerAddressLat, 'markerAddressLng', this.state.markerAddressLng)
    let mapArray = []
    // if (this.state.markerAddressLat && this.state.markerAddressLng) {
      mapArray.push(
        <Map
          markers={
            [
              {
                position: [this.state.markerAddressLat, this.state.markerAddressLng],
                icon: '',
                draggable: true,
                // title: this.state.address_is,
                onClick: () => { },
                onDragend: (e) => {
                  this.setState({
                    markerAddressLat: e.target._latlng.lat,
                    markerAddressLng: e.target._latlng.lng
                  }, () => {
                    this.getLatLng(this.state.markerAddressLat, this.state.markerAddressLng)
                  })
                },
                setViewFlag : true,
                rotateMarker : false,
              }
            ]
          }
          cluster={false}
          // onClick={this.getLatLng}
          height={"650px"}
        />
      )
    // }
    this.setState({
      MapContent: mapArray
    }, () => {
    })
  }

  // open address selection i.e MAP on stops click
  openStopMap = (position, e) => {
    // console.log($(e.currentTarget).find('input').val(),"name is")
    if ($(e.currentTarget).find('input').val()) {
      this.setState({
        showMapSelect: true,
        stopMapOpen: true,
        indexStop: position,
        address_is: this.state.stops[position].location,
        markerAddressLat: this.state.stops[position].lat,
        markerAddressLng: this.state.stops[position].lon
      }, () => {
        this.renderMap()
        window.dispatchEvent(new Event('resize'));
        this.hideScroll()
      })
    } else {
      this.getMyLocation()
      this.setState({
        showMapSelect: true,
        stopMapOpen: true,
        indexStop: position,
      }, () => {
        this.renderMap()
        window.dispatchEvent(new Event('resize'));
        this.hideScroll()
      })
    }
  }

  hideScroll = () => {
    $('html, body').css({
      overflow: 'hidden',
      height: '100%'
    });
    $('.create_journey_parent').scrollTop()
  }

  showScroll = () => {
    $('html, body').css({
      overflow: 'scroll',
      height: '100%'
    });
  }


  // open address selection i.e MAP on start and end locatino
  openMap = (e) => {
    var addressAppend = (() => {
      if ($(e.currentTarget).attr("name") === "end_location_is") {
        this.setState({
          address_is: this.state.endLocation,
          markerAddressLat: this.state.endLocationLat,
          markerAddressLng: this.state.endLocationLng
        }, () => {
          this.renderMap()
          window.dispatchEvent(new Event('resize'));
          this.hideScroll()
        })
        return true
      } else {
        this.setState({
          address_is: this.state.startLocation,
          markerAddressLat: this.state.startLocationLat,
          markerAddressLng: this.state.startLocationLng,
        }, () => {
          this.renderMap()
          window.dispatchEvent(new Event('resize'));
          this.hideScroll()
        })
        return false
      }
    })()
    this.setState({
      showMapSelect: true,
      addAddressTo: addressAppend,
      openStopMap: true
    }, () => {
    })
  }

  // this method is used to get address form mongo DB
  getLatLng = (lat, lng) => {
    let addressLatLng = { lat: lat, lng: lng }
    ApiUrlCall.getAddressMMI(JSON.stringify(addressLatLng)).then(response => {
      // console.log('address is', response)
      if (response.data.response_code === 200 & response.data.is_success === true) {
        this.setState({
          address_is: response.data.data.address,
          latitude_is: response.data.data.lat,
          longitude_is: response.data.data.lng
        },() => {     
          $('#search_address_val').val(this.state.address_is)     
          if(!this.state.address_is){
            this.setState({
              openAlert : true,
              errorMessage : 'Address Not found',
            })
          }
        })
      }
    }).catch(error => {
      console.log('error in request', error)
    })
  }

  // this method is used to close address modal
  closeMapModal = () => {
    this.setState({
      markerAddressLat: '',
      markerAddressLng: '',
      showMapSelect: false,
      address_is: ''
    }, () => {
      this.getMyLocation()
      this.renderMap()
      this.showScroll()
    })
  }

  // this method used to save and show address after the selection.
  saveAddress = (e) => {
    $('#search_address_val').val("")
    if (!this.state.stopMapOpen) {
      if (this.state.addAddressTo) {
        this.setState({
          endLocation: this.state.address_is,
          showMapSelect: false,
          endLocationLat: this.state.markerAddressLat,
          endLocationLng: this.state.markerAddressLng
        })
      } else {
        this.setState({
          startLocation: this.state.address_is,
          showMapSelect: false,
          startLocationLat: this.state.markerAddressLat,
          startLocationLng: this.state.markerAddressLng
        })
      }
    } else {
      if(this.state.address_is){
        this.state.stops[this.state.indexStop].location = this.state.address_is
        this.state.stops[this.state.indexStop].lat = this.state.markerAddressLat
        this.state.stops[this.state.indexStop].lon = this.state.markerAddressLng
        this.setState({
          markerAddressLat: '',
          markerAddressLng: '',
          showMapSelect: false,
          address_is: ''
        }, () => {
          this.getMyLocation()
          this.renderMap()
          this.showScroll()
        })
      }
    }
  }

  validateLatLng = (lat, lng) => {    
    let pattern = new RegExp('^-?([1-8]?[1-9]|[1-9]0)\\.{1}\\d{1,6}');
    
    return pattern.test(lat) && pattern.test(lng);
  }
  checkstringlatlng = (value) => {
    let flag = false
    let geodata = value.split(",")
    if (geodata.length == 2)
    {  flag = this.validateLatLng(geodata[0].trim(),geodata[1].trim()) }
    return flag
  }
  setAddressUsinglatlng = (address,lat,lng) => {
    this.setState({
      address_is: address,
      markerAddressLat: lat,
      markerAddressLng: lng,
    }, () => {
      $('#search_address_val').val(this.state.address_is)
      this.renderMap()
    })
    $('#address_list').fadeOut(200)
  }

  mmiGetAddressApi = (e) => {
    var address = e.currentTarget.value
    if (e.currentTarget.value.length > 4) {

      if (this.checkstringlatlng(address))
      {
        let geodata = address.split(",")
        //console.log(">>>>>>>>>> ",geodata)
        if (geodata.length == 2)
        {  
          let addressLatLng = { lat: geodata[0].trim(), lng: geodata[1].trim() }
          ApiUrlCall.getAddressMMI(JSON.stringify(addressLatLng)).then(response => {
            //console.log('address is', response)
            if (response.data.response_code === 200 & response.data.is_success === true) {      
              if (response.data.data.address != "")
              {
                this.setAddressUsinglatlng(response.data.data.address,geodata[0].trim(),geodata[1].trim())
              }
              else{
                if (!this.state.address_is) {
                      this.setState({
                        openAlert: true,
                        errorMessage: 'Address Not found',
                      })
                    }
              }
            }
          }).catch(error => {
            console.log('error in request', error.res)
          })
        }
      }
      else{
      ApiUrlCall.getAddressIQ(address).then(response => {
        if (response.status === 200) {
          this.setState({
            autoAddress: response.data.data.address_list
          }, () => {
            // console.log('address in state is', this.state.autoAddress)
          })
        }
        $('#address_list').slideDown(200, "swing")
        // console.log('response of address is', response)
      }).catch(error => {
        console.log('error while fetching address from MMI', error)
      })
    }

    } else {
      $('#address_list').fadeOut(200)
    }
  }

  getAddressData = (e) => {
    const { autoAddress } = this.state
    var indexVal = $(e.currentTarget).attr('name')
    // console.log('index value is', indexVal)
    this.setState({
      address_is: autoAddress[indexVal].display_name,
      markerAddressLat: parseFloat(autoAddress[indexVal].lat),
      markerAddressLng: parseFloat(autoAddress[indexVal].lon),
    }, () => {
      $('#search_address_val').val(this.state.address_is)
      this.renderMap()
      // console.log('address is', this.state.address_is)
      // console.log('address is', this.state.markerAddressLat)
      // console.log('address is', this.state.markerAddressLng)
    })
    $('#address_list').fadeOut(200)
  }
  
  viewAllStops = () => {
    $('#show_all_stops').slideDown("slow", "swing")
    $('.stop_margin_bottom').last().find('.navigation').hide()
    $('html, body').css({
        overflow: 'hidden',
        height: '100%'
    });
  }

  modalClose = () => {
    $('#show_all_stops').fadeOut("slow")
    $('html, body').css({
        overflow: 'auto',
        height: '100%'
    });
}
   
  render() {
    
    const {t} = this.props
    var {autoAddress} = this.state
    var minDate = new Date(); minDate.setDate(minDate.getDate());
    if(autoAddress){
      var address_list = autoAddress.map((list, index) =>{
        return <p className="search_address" name={index}
        onClick={this.getAddressData}>{list.display_name}</p>
      })
    }
    if (this.state.vehicleStops) {
      var stopAddress = this.state.vehicleStops.map(stopsInfo => {
          // console.log("alfa new",stopsInfo)
          return (
              <Grid container className="stop_margin_bottom">
                  <Grid item xs={0}>
                      <img src="images/stop.png" width="20px" className="navgation_image"></img>
                      <div className="navigation"></div>
                  </Grid>
                  <Grid item xs={8} style={{ paddingRight: "20px" }}>
                      <p className="cj_w_header">{stopsInfo.location}</p>
                  </Grid>
                  {/* <Grid item xs={1}>
                      <p className="cj_w_header">{stopsInfo.duration} Mins</p>
                  </Grid> */}
                  <Grid item xs={3}>
                      <p className="cj_w_header"> {this.pudo_user(stopsInfo.person_id)}</p>
                  </Grid>
                  
              </Grid>
          )
      })
      //console.log(">>>>>>>>>>> driver_id",this.state.driver_id)
      //console.log(">>>>>>>>>>> driver_id",this.state.driver_serial_number)
  }
    
    return (
      
      <div>
        <Navbar />
        <div className="create_journey_parent">
          {/* add vehicle and driver to journey */}
          <div style={{ paddingLeft: "100px", paddingRight: "100px", marginBottom: "30px" }}>
            {/* <img src="images/arrow.png" alt="icon" onClick={() => {
              this.props.history.push({
                pathname: '/journeyOverview',
                state: { journey_id: this.props.location.state.journey_id }
              })
            }}></img> */}
            <h3 style={{ display: "inline", marginLeft: "15px" }} className="page_header">{t('ct.edit.journey')}</h3>
          </div>


          {/* enter journey details */}
          {/* {console.log("alfalatest",this.state.startLocation)} */}

          <div id="enter_journey_details" className="journey_detail_div">
            <div>
              <p className="p_header" style={{ marginBottom: "20px" }}>{t('dvjs.detail')}</p>
              <Grid container>
                <Grid item xs={4}>
                  <p className="BSC_semi_bold18">{t('ct.enter.location')}</p>
                  <p className="cj_w_header">{t('ct.start.location')}</p>
                  <div className="input_div" name="start_location_is" onClick={this.openMap}>
                    <input type="text" placeholder={t('ct.start.location')} id="start_location" value={this.state.startLocation} className="journey_input"
                      value={this.state.startLocation} disabled></input>
                    <img src="images/cj_location.png" ></img>
                  </div>
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={4}>
                  <p className="">&nbsp;</p>
                  <p className="cj_w_header">{t('ct.end.location')}</p>
                  <div className="input_div" name="end_location_is" onClick={this.openMap}>
                    <input type="text" placeholder={t('ct.end.location')} id="end_location" value={this.state.endLocation} className="journey_input"
                      value={this.state.endLocation} disabled></input>
                    <img src="images/cj_location.png" alt="icon"></img>
                  </div>
                </Grid>
              </Grid>

              <Grid container>
                <Grid container item xs={4}>
                  <Grid item xs={5}>
                    <p className="BSC_semi_bold18">{t('ct.datetime')}</p>
                    <p className="cj_w_header">{t('ct.startdate')}</p>
                    <div className="input_div" onClick={this.showStartDate}>
                      <input type="text" placeholder={t('ct.startdate')} id="start_date" value={this.dateFormate(this.state.startDate)} className="journey_input" disabled></input>
                      <img src="images/fill-111.png" alt="icon"></img>
                    </div>
                    <div className="calendar_parent" id="start_date_cal">
                      <Calendar
                        selectRange={false}
                        onChange={this.StartdateClicked}
                        value={this.state.date}
                        minDate={minDate}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={5} style={{ position: "relative" }}>
                    <p>&nbsp;</p>
                    <p className="cj_w_header">{t('ct.starttime')}</p>
                    <div className="input_div" onClick={this.showTimePicker}>
                      <input type="text" placeholder={t('ct.starttime')} id="start_time" value={this.state.startTime} className="journey_input" id="j_start_time" disabled></input>
                      <img src="images/cj_time.png" alt="icon"></img>
                    </div>
                    <div className="time_picker">
                      <Grid container>
                        <Grid item xs={4}>
                          <select className="time_select" id="startHours">
                            <option>1</option><option>2</option><option>3</option><option>4</option>
                            <option>5</option><option>6</option><option>7</option><option>8</option>
                            <option>9</option><option>10</option><option>11</option><option>12</option>
                          </select>
                        </Grid>
                        <Grid item xs={4}>
                          <select className="time_select" id="startMins">
                            <option>00</option><option>10</option><option>20</option>
                            <option>30</option><option>40</option><option>50</option><option>60</option>
                          </select>
                        </Grid>
                        <Grid item xs={4}>
                          <select className="time_select" id="startAmPm">
                            <option>am</option><option>pm</option>
                          </select>
                        </Grid>
                        <Grid item xs>
                          <p className="p_margin0 close_time_picker" onClick={this.startTimeSelected}>{t('ct.done')}</p>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
                <Grid item xs={2}>

                </Grid>
                <Grid container item xs={4}>
                  <Grid item xs={5}>
                    <p>&nbsp;</p>
                    <p className="cj_w_header">{t('ct.enddate')}</p>
                    <div className="input_div" onClick={this.showEndDate}>
                      <input type="text" placeholder={t('ct.enddate')} id="end_date" value={this.dateFormate(this.state.endDate)} className="journey_input" disabled></input>
                      <img src="images/fill-111.png" alt="icon"></img>
                    </div>
                    <div className="calendar_parent" id="end_date_cal">
                      <Calendar
                        selectRange={false}
                        onChange={this.EnddateClicked}
                        value={this.state.date}
                        minDate={minDate}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={5} style={{ position: "relative" }}>
                    <p>&nbsp;</p>
                    <p className="cj_w_header">{t('ct.endtime')}</p>
                    <div className="input_div" onClick={this.showTimePicker}>
                      <input type="text" placeholder={t('ct.endtime')} id="end_time" value={this.state.endTime} className="journey_input" id="j_end_time" disabled></input>
                      <img src="images/cj_time.png" alt="icon"></img>
                    </div>
                    <div className="time_picker">
                      <Grid container>
                        <Grid item xs={4}>
                          <select className="time_select" id="endHours">
                            <option>1</option><option>2</option><option>3</option><option>4</option>
                            <option>5</option><option>6</option><option>7</option><option>8</option>
                            <option>9</option><option>10</option><option>11</option><option>12</option>
                          </select>
                        </Grid>
                        <Grid item xs={4}>
                          <select className="time_select" id="endMins">
                            <option>00</option><option>10</option><option>20</option>
                            <option>30</option><option>40</option><option>50</option><option>60</option>
                          </select>
                        </Grid>
                        <Grid item xs={4}>
                          <select className="time_select" id="endAmPm">
                            <option>am</option><option>pm</option>
                          </select>
                        </Grid>
                        <Grid item xs>
                          <p className="p_margin0 close_time_picker" onClick={this.endTimeSelected}>{t('ct.done')}</p>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container>
               <Grid item xs={4}>
               <p className="BSC_semi_bold18">{t('ct.start.vehicle')}</p>
               
                {this.returnShowVehicleList()}
                </Grid>
                <Grid item xs={2}></Grid>
                <Grid item xs={4}>
                <p className="BSC_semi_bold18">{t('ct.start.driver')}</p>
                {this.returnShowDriverList()}
             
            </Grid>

              </Grid>
              <Grid container>
                <Grid container item xs={4}>
                  <Grid item xs={5}>
                    <p className="BSC_semi_bold18">{t('ct.number.stop')}</p>
                    <p className="cj_w_header">{t('ct.stops')} : {this.state.selectedStop <= 9 ? '0'+this.state.selectedStop : this.state.selectedStoph}</p>
                    <p onClick={(e) => {
                      this.setState({
                        selectedStop: this.state.selectedStop + 1
                      }, () => {
                        $('.edit_stops_parent').find('.fa-trash-alt').show()
                        //$('.edit_stops_parent').last().find('.fa-trash-alt').show()
                      })
                    }} style={{ color: '#F0762B', cursor: 'pointer' }}>{t('ct.add.stops')}</p>
                  </Grid>
                  <Grid item xs style={{display : this.state.stops.length > 0 ? "" : "none"}}>
                      <p className="cj_w_header">Stop Alert</p>
                      <input type="checkbox" onChange={(e) => {
                        if(e.currentTarget.checked){
                          this.setState({
                            stopAlert : true
                          })
                        }else{
                          this.setState({
                            stopAlert : false
                          })
                        }
                      }} id="stop_alert"></input>
                  </Grid>
                  <Grid item xs={5}>
                 <button className="btn orange-btn" onClick={this.viewAllStops}>{t('ct.vall.stops')}</button>
                 </Grid>
                </Grid>
              </Grid>
              
              <Grid container spacing={4}>
                {/* <Grid item xs={4}>
                  <p className="cj_w_header">Journey Stoppage Duration (Mins)</p>
                  <input type="number" className="journey_input" placeholder="Enter Journey Stoppage" onChange={(e) => {
                    this.setState({
                      journeyStopage : e.currentTarget.value
                    })
                  }}></input>
                </Grid> */}
                <Grid item xs={2}>
                    <p className="cj_w_header">Recurring Journey</p>
                    <input type="checkbox" onChange={(e) => {
                      if(e.currentTarget.checked){
                        $(e.currentTarget).parent().next().show()
                        this.setState({
                          isRecurring : true
                        })
                      }else{
                        $(e.currentTarget).parent().next().hide()
                        this.setState({
                          isRecurring : false,
                          recurringDays : '',
                          includeSunday : false
                        })
                      }
                    }}id="recurring_alert"></input>
                </Grid>
                <Grid container item xs spacing={2} style={{display : "none"}}>
                    <Grid item xs={2}>
                      <p className="cj_w_header">Enter Number Of Days</p>
                      <input type="number" className="journey_input" placeholder="Enter Recurring Days" Value={this.state.get_recurring_days} onChange={(e) => {
                          if(e.currentTarget.value > 90){
                            e.currentTarget.value = ""
                            this.setState({
                              openAlert : true,
                              errorMessage : "You cannot enter more than 90 days"
                            })
                          }else{
                            if (e.currentTarget.value != ''){
                            this.setState({
                              recurringDays : e.currentTarget.value
                            })
                          }
                          }
                      }}></input>
                    </Grid>
                    {/* <Grid item xs>
                      <p>&nbsp;</p>
                      <label><input type="checkbox" style={{marginRight : "10px"}} onChange={(e) => {
                        if(e.currentTarget.checked){
                          this.setState({
                            includeSunday : true
                          })
                        }else{
                          this.setState({
                            includeSunday : false
                          })
                        }
                      }}id="sunday_alert"></input>Is Journey On Sunday</label>
                    </Grid> */}
                </Grid>
              </Grid>
              <Grid item xs={2}>
                    <p className="cj_w_header">Is AutoStart</p>
                    <input type="checkbox" onChange={(e) => {
                      if(e.currentTarget.checked){
                        $(e.currentTarget).parent().next().show()
                        this.setState({                          
                          isAutoStart : true
                        })
                      }else{
                        
                        this.setState({
                          isAutoStart : false
                        })
                      }
                    }}id="auto_start"></input>
                </Grid>

              <Grid container spacing={2}>
                {this.returNumberOfStop(this.state.selectedStop)}
              </Grid>
            </div>

          </div>
          {/* end */}
          <div className="btn_div" style={{ display: this.state.enterDetail ? '' : 'none' }}>
            <button className="btn sj_btn" onClick={this.addVehicleDriver}>{t('ct.update')}</button>
          </div>

          <div className="select_address_parent" style={{ display: this.state.showMapSelect ? '' : 'none' }}>
            <div className="select_address">
              <Grid container>
                <Grid item xs={5}>
                  <div className="enter_address_div">
                    <p className="p_big_grey">{t('ct.select.location')}</p>
                    <p>{t('ct.location')}</p>
                    <input type="text"  placeholder={t('ct.enter.address')} className="journey_input"
                      onKeyUp={this.mmiGetAddressApi} id="search_address_val" autoComplete="off"></input>
                    <div className="address_list" id="address_list">
                      {address_list}
                    </div>
                    <button className="btn sj_btn save_address" onClick={this.saveAddress}>{t('ct.save')}</button>
                  </div>
                </Grid>
                <Grid item xs={7}>
                  {this.state.MapContent}
                </Grid>
              </Grid>
              <div className="close_modal" style={{ zIndex: "999" }} onClick={this.closeMapModal}>
                <i class="fa fa-close" aria-hidden="true"></i>
              </div>
            </div>
          </div>
        </div>
        {/* view stops modal*/}
        <div id="show_all_stops" className="modal_parent">
                        <div className="modal_body">
                            <p className="modal_header">{t('jos.route')}</p>
                            <Grid container className="stop_vehicle_div">
                                <Grid item xs={12}>
                                    <img src="images/vehicle-1.png" alt="car icon"></img>
                                    <p className="journey_item_vehicle">{this.vehicleName(this.state.trackingSerialNumber)}</p>
                                    {/* <span><i className="fa fa-clock-o" aria-hidden="true" style={{ marginRight: "5px" }}></i>scheduled</span><br /> */}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid  item xs={9}>
                                </Grid>
                                {/* <Grid item xs={3}>
                                    <p className="stop_dur">{t('ct.duration')}</p>
                                </Grid> */}
                                <Grid item xs={3}>
                                    <p className="pd_user">{t('ct.pduser')}</p>
                                </Grid>

                                                                
                            </Grid>
                            {/*<Grid container>
                                <Grid container item xs={10}>
                                </Grid>
                                <Grid item xs={3}>
                                    <p className="pd_user">{t('ct.pduser')}</p>
                                </Grid>                                
                            </Grid>*/}
                            <Grid container className="stop_margin_bottom">
                                <Grid item xs={0}>
                                    <img src="images/location.png" width="20px" className="navgation_image"></img>
                                    <div className="navigation"></div>
                                </Grid>
                                <Grid item xs={8} style={{ paddingRight: "20px" }}>
                                    <p className="cj_w_header">{this.state.CreateJourneyData.planned_start_loc}</p>
                                </Grid>
                                {/* <Grid item xs={3}>
                                    <p className="cj_w_header">{stopsInfo.duration} Mins</p>
                                </Grid> */}
                            </Grid>
                            {stopAddress}
                            <Grid container className="stop_margin_bottom">
                                <Grid item xs={0}>
                                    <img src="images/location.png" width="20px" className="navgation_image"></img>
                                    <div className="navigation"></div>
                                </Grid>
                                <Grid item xs={8} style={{ paddingRight: "20px" }}>
                                    <p className="cj_w_header">{this.state.CreateJourneyData.planned_end_loc}</p>
                                </Grid>
                                {/* <Grid item xs={3}>
                                    <p className="cj_w_header">{stopsInfo.duration} Mins</p>
                                </Grid> */}
                            </Grid>
                            <div className="close_modal" onClick={this.modalClose}>
                                <i class="fa fa-close" aria-hidden="true"></i>
                            </div>
                        </div>
                    </div>
                    {/* view stops modal end*/}
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          autoHideDuration={2000}
          onClose={(e, reason) => {
            if (reason === 'clickaway') {
              return;
            }
            this.setState({ openAlert: false })
          }
          }
          open={this.state.openAlert}
          message={this.state.errorMessage}
        />
      </div>
    );
        
  }
}

export default withTranslation()(EditJourneyDetails);
