import React, { Component } from 'react';
import $ from 'jquery';
import './journey_alert.scss';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Grid from '@mui/material/Grid';
import Navbar from '../../Config/Navbar';
import ApiUrlCall from '../API/apiurl';
import CommonFunction from '../common_function';
import Calendar from 'react-calendar/dist/entry.nostyle'
import '../calendar/calendar.scss'
import analytics from '../../Config/firebase'
import Amplify, { Analytics } from 'aws-amplify';
import awswebapp from '../../aws-exports';
import constant from '../constant.json';
import { withTranslation } from 'react-i18next';

class JourneyAlert extends Component {
    constructor(props) {
        super(props);
        this.state = {
            notificationData: '',
            startDate: new Date(),
            endDate: new Date(),
            vehicleList: '',
            selectedVehicle: '',
            firstLoad: true,
        }
    }

    componentDidMount() {
        analytics.logEvent('screen_view', {
            screen_name: "JourneyAlert"
        })
        analytics.logEvent('page_view', {
            page_title: "JourneyAlert",
        })
        Amplify.configure(awswebapp);
        Analytics.record({
            name: constant.awsName,
            // Attribute values must be strings
            attributes: { screen_name: 'WEB_JOURNEY_ALERT' }
        });
        var navBarHeight = $('.navBar').outerHeight();
        $('.journey_alert_parent').css({
            paddingTop: navBarHeight + 60,
            paddingLeft: "100px",
            paddingRight: "100px",
            paddingBottom: "100px",
        })
        if (localStorage.getItem('default_view') !== constant.people_tracking) {
            this.getNotificationData()
            this.getVehicleList()
        } else {
            this.getPeopleNotificationData()
        }
    }

    getVehicleList = () => {
        var data = { next_count: 0, search: '' }
        ApiUrlCall.getVehicleList(JSON.stringify(data)).then(response => {
            // console.log('vehicle list', response)
            if (response.data.response_code === 200 && response.data.is_success === true) {
                this.setState({
                    vehicleList: response.data.data.vehicle_summary_list
                }, () => {
                    // console.log('vehicle list state set', this.state.vehicleList)
                })
            }
        }).catch(error => {
            console.log('error while fetching vehicle list', error)
        })
    }

    getNotificationData = () => {
        $('#processing').show()
        var end = this.state.endDate
        var start = this.state.startDate
        if (this.state.firstLoad) {
            start.setHours(0, 0, 0, 0)
        }
        // let parameter = {start_date:  CommonFunction.ServerDateTime(start) ,end_date: CommonFunction.ServerDateTime(end) }
        let parameter = { serial_number: this.state.selectedVehicle, start_date: CommonFunction.ServerDateTime(start), end_date: CommonFunction.ServerDateTime(end) }
        // console.log('date parameter is', parameter)
        ApiUrlCall.getNotification(JSON.stringify(parameter)).then(response => {
            if (response.data.response_code === 200 && response.data.is_success === true) {
                $('#processing').hide()
                // console.log('response notification is', response.data)
                this.setState({
                    notificationData: response.data.data.notifications,
                    firstLoad: false,
                }, () => {
                    $('#processing').hide()
                    //   this.renderNotification()              
                })
            }
            else if (response.data.response_code === 204 && response.data.is_success === true) {
                $('#processing').hide()
                this.setState({ noAlert: true, })
            }
            // console.log('notification response is', response)
        }).catch(error => {
            console.log('error while fetching nottification data', error)
        })
    }

    getPeopleNotificationData = () => {
        $('#processing').show()
        var end = this.state.endDate
        var start = this.state.startDate
        if (this.state.firstLoad) {
            start.setHours(0, 0, 0, 0)
        }
        // start.setTime(end.getTime() - (30 * 60 * 1000))  
        start.setHours(0, 0, 0, 0)
        end.setHours(23, 59, 59, 0)
        let parameter = { start_date: CommonFunction.ServerDateTime(start), end_date: CommonFunction.ServerDateTime(end) }
        // let parameter = {type : 2}
        // console.log('last 30 min para', parameter)
        // let parameter = { start_date: "2020-05-01 00:00:00", end_date: "2020-06-01 00:00:00",category : this.state.category }
        ApiUrlCall.ptAlerts(JSON.stringify(parameter)).then(response => {
            // console.log('notification data is', response)
            // return
            $('#processing').hide()
            if (response.data.response_code === 200 && response.data.is_success === true) {
                this.setState({
                    notificationData: response.data.data.notifications,                    
                    firstLoad: false,
                }, () => {
                    // this.renderNotification()
                    //   $('.notification-loading').hide()
                })
            }
            else if (response.data.response_code === 204 && response.data.is_success === true) {
                this.setState({ noAlert: true, }, () => {
                    //   $('.notification-loading').hide()
                })
            }
        }).catch(error => {
            $('#processing').hide()
            console.log('error while fetching nottification data', error)
        })
    }

    getNotificationPanelData = (arraylist) => {
        // const { notificationData } = this.state
        let notiArray = []
        // for (let i = 0; i < notificationData.length; i++) {
        const notification = arraylist
        // console.log('notification list is', notification)
        for (let j = 0; j < notification.length; j++) {
            const element = notification[j];
            if(localStorage.getItem('default_view') == constant.people_tracking){
                var icon = <img src="images/ppl_notification.png" className="alert-icon" style={{width : "40px",height:"50px"}}></img>    
            }else{
                var icon = <img src={"images/ALERT_TYPE_" + element.alertType + ".png"} className="alert-icon"></img> 
            }
            notiArray.push(
                <Grid item xs={6} container className="alert_container">
                    <Grid item xs={3}>
                        <div className="alert_icon_div">
                            {icon}
                        </div>
                    </Grid>
                    <Grid item xs className="noti-content">
                        <label><b>{element.vehicle_name}</b></label>
                        <p>{element.bodyText}</p>
                        <span>{CommonFunction.timeFormate(element.event_time)}</span>&nbsp;&nbsp;<span>|
                                </span>&nbsp;&nbsp;<span>{CommonFunction.dateFormate(element.event_time)}</span>
                    </Grid>
                </Grid>
            )
        }

        // }
        return notiArray
    }

    showStartDate = (e) => {
        $(e.currentTarget).next().slideToggle(200, "swing")
    }
    showEndDate = (e) => {
        $(e.currentTarget).next().slideToggle(200, "swing")
    }

    StartdateClicked = date => {
        var isoStartDate = date
        // console.log('props value', date.toISOString())
        this.setState({ startDate: isoStartDate })
        $('#start_date_cal').slideUp(200)
    }

    // this method is used to create end date into ISO and send it to server
    EnddateClicked = date => {
        var isoStartDate = date
        // console.log('props value', date.toISOString())
        this.setState({ endDate: isoStartDate })
        $('#end_date_cal').slideUp(200)
    }


    render() {
        const { t } = this.props
        const { notificationData, vehicleList } = this.state
        let icon = <img src="images/next-copy-2.png" width="35px"></img>
        var minDate = new Date(); minDate.setDate(minDate.getDate() - 90);
        var maxDate = new Date()
        if (notificationData && notificationData.length > 0) {
            // console.log('notification data is', notificationData)
            var panel = notificationData.map(panel => {
                return (
                    <Accordion>
                        <AccordionSummary
                            expandIcon={icon}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <p className="p_margin_bottom p_header">{CommonFunction.tableDateFormate(panel.date)}&nbsp;&nbsp;
                            ({panel.notification_list.length})</p>
                        </AccordionSummary>
                        <AccordionDetails className="expand_panel">
                            <Grid container>
                                {this.getNotificationPanelData(panel.notification_list)}
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                )
            })
        }
        if (vehicleList) {
            var vehicle = vehicleList.map(vehicle => {
                return (
                    <option key={vehicle.serial_number} id={vehicle.serial_number}>{vehicle.vehicle_name}</option>
                )
            })
        }


        return (
            <div>
                <Navbar />
                <div className="journey_alert_parent">
                    <div>
                        <Grid container spacing={4}>
                            <Grid item xs>
                                <label style={{ marginTop: "15px" }}>
                                    <img src="images/arrow11.png" alt="icon" style={{ marginRight: "15px", cursor: 'pointer' }}
                                        onClick={() => {
                                            this.props.history.push('/')
                                        }}></img>
                                    <span className="start_journey_loc">{t('jas.alerts')}</span>
                                </label>
                            </Grid>
                            <Grid item xs={2} style={{display : localStorage.getItem('default_view') == constant.people_tracking ? "none" : ""}}>
                                <p className="cj_w_header">{t('ct.select.vehicle')}</p>
                                <select className="select_dropdown" onChange={(e) => {
                                    var serial_number = $(e.currentTarget).children(":selected").attr("id")
                                    this.setState({
                                        selectedVehicle: serial_number
                                    }, () => {
                                        // console.log('selected opttion is', this.state.selectedVehicle)
                                    })
                                }}>
                                    <option>{t('ct.select.vehicle')}</option>
                                    {vehicle}
                                </select>
                            </Grid>
                            <Grid item xs>
                                <p className="cj_w_header">{t('ct.startdate')}</p>
                                <div className="input_div" onClick={this.showStartDate}>
                                    <input type="text" placeholder={t('ct.startdate')} id="start_date" value={CommonFunction.dateFormate(this.state.startDate)} className="journey_input" disabled></input>
                                    <img src="images/fill-111.png"></img>
                                </div>
                                <div className="calendar_parent" id="start_date_cal">
                                    <Calendar
                                        selectRange={false}
                                        onChange={this.StartdateClicked}
                                        value={this.state.startDate}
                                        minDate={minDate}
                                        maxDate={maxDate}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs>
                                <p className="cj_w_header">{t('ct.enddate')}</p>
                                <div className="input_div" onClick={this.showEndDate}>
                                    <input type="text" placeholder={t('ct.enddate')} id="end_date" value={CommonFunction.dateFormate(this.state.endDate)} className="journey_input" disabled></input>
                                    <img src="images/fill-111.png"></img>
                                </div>
                                <div className="calendar_parent" id="end_date_cal">
                                    <Calendar
                                        selectRange={false}
                                        onChange={this.EnddateClicked}
                                        value={this.state.endDate}
                                        minDate={minDate}
                                        maxDate={maxDate}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs>
                                <p>&nbsp;</p>
                                <button className="btn orange-btn btn-block" onClick={() => {
                                    if (localStorage.getItem('default_view') !== constant.people_tracking) {
                                        this.getNotificationData()                                     
                                    } else {
                                        this.getPeopleNotificationData()
                                    }                                    
                                }} style={{ marginTop: "0px" }}>{t('ct.generate')}</button>
                            </Grid>
                        </Grid>
                    </div>
                    <div style={{ marginTop: "15px" }}>
                        <Tabs>
                            <TabList>
                                {/* <Tab>All</Tab> */}
                                <Tab>{t('ct.notifications')}</Tab>
                                {/* <Tab>Actionables</Tab> */}
                            </TabList>

                            <TabPanel >
                                <div className="document_expand_parent">
                                    {panel}
                                </div>
                            </TabPanel>
                        </Tabs>
                    </div>

                </div>
            </div>
        );
    }
}

export default withTranslation()(JourneyAlert);
