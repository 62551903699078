import React, { Component } from 'react';
import $ from 'jquery';
import { Grid } from '@mui/material';
import ApiUrlCall from '../../API/apiurl';
import { withTranslation } from 'react-i18next';
import { MDBDataTable } from 'mdbreact';
import Snackbar from '@mui/material/Snackbar';
import CommonFunction from '../../common_function';

const obj = {}
const key1 = {}

const data = [
    {
      label: 'USER NAME',
      field: 'user_name',
      sort: 'asc',
      width: 150,
      attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Name',
      },
    },
    {
      label: 'MOBILE NUMBER',
      field: 'mobile_number',
      sort: 'asc',
      width: 90,
    },
    {
      label: 'EMAIL ADDRESS',
      field: 'email_address',
      sort: 'asc',
      width: 200,
    },
    {
    label: 'DELETE LOCATION',
    field: 'is_delete',
    sort: 'asc',
    width: 150,
    }
  ]

class UserManagement extends Component {

    constructor(props) {
        super(props);
        this.state = {
            addUserName : "",
            mobileNumber : "",
            emailId : "",
            datatable: [],
            totalCount : '',
            dataCollection: [],
            chunckValue: '',
            nextCount: 0,
            searchText: '',
            userList : [],
            openAlert: false,
            errorMessage: '',
        }
      }

    componentDidMount () {
      this.showUserData()
    }

    addUserDetails = () => {
        $('#add_User').slideDown()
    }

    cancelModal = () => {
        $('#add_User').fadeOut()
      }
    
    saveUserDetails = () => {
        const { addUserName, mobileNumber,emailId} = this.state
      
        if (!addUserName.trim()) {
          this.setState({
            openAlert: true,
            errorMessage: "Kindly Enter User Name"
          })
          return
        }

        if (mobileNumber.length !== 10) {
          this.setState({
            openAlert: true,
            errorMessage: "Kindly Enter Valid Mobile Number "
          })
          return
        }

        if (emailId) {
          var reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
          if (!emailId.match(reg)) {
              this.setState({
                  openAlert: true,
                  errorMessage: "Kindly Enter Valid Email Address"
              })
              return
          }
      }

        var sendJsonData = {
          name : addUserName, mobile_number :mobileNumber,email : emailId,
        }
        var data = JSON.stringify(sendJsonData)
        console.log(data,"dataaaaaaaaaaaa")
        $('#processing').show()
        ApiUrlCall.addUser(sendJsonData).then(response => {
          $('#processing').hide()
          if (response.data.response_code == 200 && response.data.is_success) {
            $('#add_User').fadeOut()
            this.setState({
              addUserName : '',
              mobileNumber : '',
              emailId : "",
            },() => {
              this.showUserData()
            })
          } else {
            this.setState({
              openAlert: true,
              errorMessage: "User Already Exit!"
            })
          }
        }).catch(error => {
          $('#processing').hide()
          console.log('error while creating new User', error)
        })
    }

    optimizationSearch = (value) => {  
    
        this.counter = 0
        if(value.length >= 3){
          setTimeout(() => {
            this.setState({
              datatable : [],
              dataCollection : [],
              totalCount : '',
              chunckValue : '',
              nextCount : 0,
              searchText : value
            },() => {
              this.showUserData()          
            })
          }, 1000);     
        }else if(value.length === 0){
          setTimeout(() => {
            this.setState({
              datatable : [],
              dataCollection : [],
              totalCount : '',
              chunckValue : '',
              nextCount : 0,
              searchText : value
            },() => {
              this.showUserData()          
            })
          }, 1000);     
        }          
    }

    delete_button = (uniqueID) => {
      if(uniqueID) 
      return ( <img class="deleteUser" id={uniqueID} src="images/group-2.png" alt="icon" styles= {"marginTop: 10px; marginLeft: 15px; cursor: pointer" }></img>
      ) 
    }


    tableRowClick = (id) => {
      var span_class = $(id.currentTarget).find('img').attr('id')
      var statuss = id.target.classList.contains("deleteUser");
      
      if (statuss ==true )
      {
        var data = {user_id : span_class}
        //console.log(data)
        ApiUrlCall.deleteUserData(data).then(response => {
          if (response.status == 200) {
            this.setState({
              openAlert: true,
              errorMessage: "User Data Deleted Successfully"
            })
            this.showUserData()
            
          }
          else if (response.status == 405) {
            this.setState({
              openAlert: true,
              errorMessage: "User Data Can not be Deleted "
            })
          }
        }) 
      }
    }

    createTableData = () => {   
      const {t} = this.props 
      const {userList,datatable} = this.state

      var row = []  
      for (let i = 0; i < userList.length; i++) {
        const element = userList[i];
        var row_data = {
          user_name : element.user_name,
          mobile_number : element.user_mobile,
          email_address :element.user_email,
          clickEvent : this.tableRowClick,
          is_delete : this.delete_button(element.user_id)
        }       
        datatable.push(row_data)                 
      }    
      // this.buttonStatus() 
      // this.entriesData()   
      this.forceUpdate()
      // this.intervalData()
    }

    showUserData = () => {
      $('#processing').show()
      ApiUrlCall.showUserList().then(response => {
        this.emptyTable()
        if (response.data.response_code === 200 && response.data.is_success === true) {
          //console.log(response.data.user_list)
          this.setState({
            userList: response.data.user_list,
          }, () => {
            this.createTableData()
          })
        }
        // else{
        //   $('#processing').hide()
        // }
        $('#processing').hide()
      })
    }

    emptyTable = () => {
      this.setState({
        datatable: []
      })
    }



render(){
  return(
    <div className="add_location_parent" className="vr_table_view">
      <Grid container spacing={2}>
        <Grid item xs>
            <button className="btn orange-btn" onClick={this.addUserDetails}>Add User</button> 
        </Grid>
      </Grid>
      <p className="p_header" style={{position : 'absolute', lineHeight : "2em"}}>Total Number Of Entries : {CommonFunction.zeroAppend(this.state.datatable.length)}</p>
      <MDBDataTable
          hover
          displayEntries={false}
          paging={true}
          scrollX
          entries={10}
          striped
          bordered
          data={{ columns: data, rows: this.state.datatable }}
      />
        <div id="add_User" className="p_modal_parent" style={{ display: "none" }}>
          <div className="p_modal_boday">
            <p className="p_header">User Details</p>
            <hr />
            <Grid container spacing={5} style={{ marginBottom: "10px" }}>
              <Grid item xs>
                <p className="cj_w_header">Name &nbsp;*</p>
              </Grid>
              <Grid item xs>
                <input type="text" placeholder="Enter Name" className="journey_input"
                  onChange={(e) => {
                    this.setState({
                      addUserName: e.currentTarget.value
                    })
                  }}></input>
              </Grid>
            </Grid>
            <Grid container spacing={5} style={{ marginBottom: "10px" }}>
              <Grid item xs>
                <p className="cj_w_header">Mobile Number&nbsp;*</p>
              </Grid>
              <Grid item xs>
                <input type="number" placeholder="Enter Mobile Number"  autoComplete="off" className="journey_input"
                  onChange={(e) => {
                    this.setState({
                      mobileNumber: e.currentTarget.value
                    })
                  }}></input>
              </Grid>
            </Grid>
            <Grid container spacing={5} style={{ marginBottom: "10px" }}>
              <Grid item xs>
                <p className="cj_w_header">Email Address</p>
              </Grid>
              <Grid item xs>
                <input type="email" placeholder="Enter Email Address"  autoComplete="off" className="journey_input"
                  onChange={(e) => {
                    this.setState({
                      emailId: e.currentTarget.value
                    })
                  }}></input>
              </Grid>
            </Grid>
            <hr />
            <Grid container spacing={10}>
              <Grid item xs  >
                <button className="btn orange-btn btn-block" style={{ float: "none"}} onClick={this.saveUserDetails}>Save</button>
              </Grid>
              <Grid item xs>
                <button className="btn orange-btn btn-block" style={{ float: "none" }} onClick={this.cancelModal}>Cancel</button>
              </Grid>
            </Grid>
          </div>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          autoHideDuration={1200}
          onClose={(e, reason) => {
            if (reason === 'clickaway') {
              return;
            }
            this.setState({ openAlert: false })
          }
          }
          open={this.state.openAlert}
          message={this.state.errorMessage}
        />
        
    </div>
      
  )  
}
}

export default withTranslation()(UserManagement);