import React, { Component } from 'react';
import $ from 'jquery';
import Navbar from '../../Config/Navbar';
import Grid from '@mui/material/Grid';
import CommonFunction from '../common_function'
import Snackbar from '@mui/material/Snackbar';
import Calendar from 'react-calendar/dist/entry.nostyle'
// import CommonFunction from '../common_function';
import ApiUrlCall from '../API/apiurl'
import Map from '../map/map'
import Timekeeper from 'react-timekeeper';
import { withTranslation } from 'react-i18next';

const ADMIN = 2
const USER = 3
const PENDING = 4
const ONGOING = 3
const COMPLETE = 5
const ASSIGNED = 2

class AddVisit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openAlert: false,
            errorMessage: '',
            visitName: '',
            clientName: '',
            mobileNumber: '',
            VisitDate: new Date(),
            visitEndDate: new Date(),
            showTime: false,
            visitTime: "12:00 am",
            sendVisitTime: "00:00",
            showEndTime: false,
            visitEndTime: "11:59 pm",
            sendVisitEndTime: "23:59",
            selectedUser: '',
            address: '',
            min_dur: '',
            max_dur: '',
            nextVisitDate: new Date(),
            isNextVisit: 1,
            markerAddressLat: '20.5937',
            markerAddressLng: '78.9629',
            address_is: '',
            latitude_is: '',
            longitude_is: '',
            MapContent: [],
            autoAddress: '',
            showMapSelect: false,
            nextCount: 0,
            totalCount: 0,
            chunckValue: 0,
            userList: [],
            purpose: '',
            remark: '',
            nextVisitOn: '',
            visitDuration: '',
            assignedTo: '',
            visitImages: [],
            visitType: '',
            is_editable : false,
        }
    }

    componentDidMount() {
        var navBarHeight = $('.navBar').outerHeight()
        $('.people_report_parent').css({
            paddingTop: navBarHeight + 60,
        })
        if (this.props.location.state !== undefined) {
            this.getVisitDetails()
            if(this.props.location.state.edit_details){
                this.setState({
                    is_editable : true
                })
            }
        } else {
            this.getUserList()
        }
        console.log('props value is', this.props.location.state)
    }


    getVisitDetails = () => {
        $('#processing').show()
        var data = { visit_id: this.props.location.state.visit_id }
        ApiUrlCall.ptVisitDetails(JSON.stringify(data)).then(response => {
            console.log('response', response)
            $('#processing').hide()
            if (response.data.response_code == 200 && response.data.is_success) {
                this.setState({
                    visitName: response.data.data.name,
                    clientName: response.data.data.client_name,
                    assignedTo: response.data.data.assigned_to,
                    mobileNumber: response.data.data.mobile_number,
                    VisitDate: new Date(response.data.data.start_date),
                    visitEndDate: new Date(response.data.data.end_date),
                    visitTime: CommonFunction.timeFormate(new Date(response.data.data.start_date)),
                    sendVisitTime : CommonFunction.timePickerValue(new Date(response.data.data.start_date)),
                    visitEndTime: CommonFunction.timeFormate(new Date(response.data.data.end_date)),
                    sendVisitEndTime : CommonFunction.timePickerValue(new Date(response.data.data.end_date)),
                    address_is: response.data.data.address,
                    nextVisitOn: response.data.data.next_date,
                    purpose: response.data.data.feedback_data.purpose,
                    remark: response.data.data.feedback_data.feedback,
                    visitDuration: response.data.data.feedback_data.duration,
                    visitImages: response.data.data.feedback_data.image_list,
                    visitType: response.data.data.visit_type,
                    markerAddressLat : response.data.data.lat,
                    markerAddressLng : response.data.data.lng,
                    selectedUser : response.data.data.assigned_to_id
                }, () => {
                    if(!this.state.is_editable){
                        $('.map_view_div').find('input').prop('disabled', true)
                        $('.visit_input').css({
                            pointerEvents: "none"
                        })
                    }
                })
            } else if (response.data.response_code == 410 && !response.data.is_success) {
                if ($('#pplt_expire_dialog').is(':hidden')) {
                    $('#pplt_expire_dialog').slideDown()
                }
            }
        }).catch(error => {
            $('#processing').hide()
            console.log('error whicle fetching visit details', error)
        })
    }

    getUserList = () => {
        $('#processing').show()
        var data = { role_id_list: [ADMIN, USER], status: 1, next_count: this.state.nextCount }
        ApiUrlCall.ptAdminList(JSON.stringify(data)).then(response => {
            $('#processing').hide()
            // console.log('admin list', response)
            if (response.data.response_code == 200 && response.data.is_success) {
                this.setState({
                    userList: response.data.data.user_list,
                    nextCount: response.data.data.next_count,
                    totalCount: response.data.data.total_count,
                    chunckValue: response.data.data.size
                }, () => {
                    if (this.state.nextCount !== this.state.totalCount) {
                        $('#get_more_data').addClass('getmore_enable')
                    } else {
                        $('#get_more_data').addClass('getmore_disable')
                    }
                })
            } else if (response.data.response_code == 410 && !response.data.is_success) {
                if ($('#pplt_expire_dialog').is(':hidden')) {
                    $('#pplt_expire_dialog').slideDown()
                }
            } else {
                this.setState({
                    openAlert: true,
                    errorMessage: response.data.data.response_message
                })
            }
        }).catch(error => {
            $('#processing').hide()
            console.log('error while fetching admin list', error)
        })
    }

    goBack = () => {
        this.props.history.push({
            pathname: '/p_visit_list'
        })
    }

    showCalendar = (e) => {
        $(e.currentTarget).next().slideToggle(200, "swing")
        $('#visit_end_calendar').slideUp()
        this.setState({
            showTime: false,
            showEndTime: false,
        })
    }
    showEndCalendar = (e) => {
        $(e.currentTarget).next().slideToggle(200, "swing")
        $('#visit_calendar').slideUp()
        this.setState({
            showTime: false,
            showEndTime: false,
        })
    }
    VisitDateClicked = date => {
        this.setState({ VisitDate: date })
        $('#visit_calendar').slideUp(200)
    }
    VisitEndDateClicked = date => {
        this.setState({ visitEndDate: date })
        $('#visit_end_calendar').slideUp(200)
    }
    nextVisitCalendar = (e) => {
        $(e.currentTarget).next().slideToggle(200, "swing")
        this.setState({
            showTime: false,
            showEndTime: false,
        })
    }
    nextVisitDateClicked = date => {
        this.setState({ nextVisitDate: date })
        $('#next_visit_calendar').slideUp(200)
    }
    showStartTimeContent = () => {
        $('#visit_calendar').slideUp()
        $('#visit_end_calendar').slideUp()
        if (this.state.is_editable || this.props.location.state == undefined) {
            if (this.state.showTime) {
                this.setState({
                    showTime: false,
                    showEndTime: false,
                })
            } else {
                this.setState({
                    showTime: true,
                    showEndTime: false,
                })
            }
        }
    }
    showEndTimeContent = () => {
        $('#visit_calendar').slideUp()
        $('#visit_end_calendar').slideUp()
        if (this.state.is_editable || this.props.location.state == undefined) {
            if (this.state.showEndTime) {
                this.setState({
                    showTime: false,
                    showEndTime: false,
                })
            } else {
                this.setState({
                    showEndTime: true,
                    showTime: false,
                })
            }
        }
    }

    selectStartTime = (newTime) => {
        this.setState({
            visitTime: newTime.formatted12,
            sendVisitTime: newTime.formatted24
        })
    }

    selectEndTime = (newTime) => {
        this.setState({
            visitEndTime: newTime.formatted12,
            sendVisitEndTime: newTime.formatted24
        })
    }

    selectUser = (e) => {
        this.setState({
            selectedUser: e.currentTarget.value
        })
    }

    addVisit = () => {
        const { visitName, clientName, mobileNumber, VisitDate, sendVisitTime, sendVisitEndTime, selectedUser, address,
            min_dur, max_dur, nextVisitDate, latitude, longitude, address_is, markerAddressLat, markerAddressLng, isNextVisit, visitEndDate } = this.state
        // if (!visitName.trim()) {
        //     this.setState({
        //         openAlert: true,
        //         errorMessage: "Kindly Enter Visit ID / Name"
        //     })
        //     return
        // }
        // console.log(sendVisitEndTime,'select user', sendVisitTime)
        if (!selectedUser) {
            this.setState({
                openAlert: true,
                errorMessage: "Kindly Select User"
            })
            return
        }

        if (!clientName.trim()) {
            this.setState({
                openAlert: true,
                errorMessage: "Kindly Enter Client Name"
            })
            return
        }

        if (mobileNumber.length !== 10) {
            this.setState({
                openAlert: true,
                errorMessage: "Kindly Enter Valid Mobile Number"
            })
            return
        }

        if (!address_is && !latitude && !longitude) {
            this.setState({
                openAlert: true,
                errorMessage: "Kindly Select Location"
            })
            return
        }

        if (new Date() > new Date(CommonFunction.createServerDateTime(VisitDate, sendVisitTime))) {
            this.setState({
                openAlert: true,
                errorMessage: "Selected Time is Less Than Current Time"
            })
            return
        }
        if (new Date(CommonFunction.createServerDateTime(VisitDate, sendVisitTime)) > new Date(CommonFunction.createServerDateTime(visitEndDate, sendVisitEndTime))) {
            this.setState({
                openAlert: true,
                errorMessage: "Visit Date Cannot be greater than Visit End Date"
            })
            return
        }

        if (isNextVisit == 1) {
            var recurring_date = ""
        } else {
            var recurring_date = CommonFunction.ServerDateTime(nextVisitDate)
        }

        var data = {
            visit_name: visitName, mobile_number: mobileNumber, client_name: clientName, address: address_is, lat: markerAddressLat, lng: markerAddressLng,
            visit_datetime: CommonFunction.createServerDateTime(VisitDate, sendVisitTime), min_duration: min_dur,
            max_duration: max_dur, customer_id: selectedUser, is_recurring: this.returnIsRecurring(isNextVisit), next_date: recurring_date,
            end_date: CommonFunction.createServerDateTime(visitEndDate, sendVisitEndTime)
        }
        // console.log('data', data)  
        // return      
        $('#processing').show()
        if(this.state.is_editable){
            data.id = this.props.location.state.visit_id
            // console.log('update data is', data)            
            ApiUrlCall.ptUpdateVisit(JSON.stringify(data)).then(response => {
                $('#processing').hide()
                // console.log('update response is', response)                
                if (response.data.response_code == 200 && response.data.is_success) {
                    this.props.history.push({
                        pathname: '/p_visit_list'
                    })
                } else if (response.data.response_code == 410 && !response.data.is_success) {
                    if ($('#pplt_expire_dialog').is(':hidden')) {
                        $('#pplt_expire_dialog').slideDown()
                    }
                } else {
                    this.setState({
                        openAlert: true,
                        errorMessage: response.data.response_message
                    })
                }
            }).catch(error => {
                $('#processing').hide()
                console.log('error while creating visit', error)
            })
        }else{
            ApiUrlCall.ptAddVisit(JSON.stringify(data)).then(response => {
                $('#processing').hide()
                if (response.data.response_code == 200 && response.data.is_success) {
                    this.props.history.push({
                        pathname: '/p_visit_list'
                    })
                } else if (response.data.response_code == 410 && !response.data.is_success) {
                    if ($('#pplt_expire_dialog').is(':hidden')) {
                        $('#pplt_expire_dialog').slideDown()
                    }
                } else {
                    this.setState({
                        openAlert: true,
                        errorMessage: response.data.response_message
                    })
                }
            }).catch(error => {
                $('#processing').hide()
                console.log('error while creating visit', error)
            })
        }
    }

    returnIsRecurring = (value) => {
        if (value == 1) {
            return "false"
        } else {
            return "true"
        }
    }

    renderMap = () => {
        let mapArray = []
        mapArray.push(
            <Map
                markers={
                    [
                        {
                            position: [this.state.markerAddressLat, this.state.markerAddressLng],
                            icon: '',
                            draggable: true,
                            onClick: () => { },
                            onDragend: (e) => {
                                this.setState({
                                    markerAddressLat: e.target._latlng.lat,
                                    markerAddressLng: e.target._latlng.lng
                                }, () => {
                                    this.getLatLng(this.state.markerAddressLat, this.state.markerAddressLng)
                                })
                            },
                            setViewFlag: true,
                            rotateMarker: false
                        }
                    ]
                }
                cluster={false}
                height={"650px"}
            />
        )
        this.setState({
            MapContent: mapArray
        })
    }

    getLatLng = (lat, lng) => {
        let addressLatLng = { lat: lat, lng: lng }
        // console.log('address is', addressLatLng)
        ApiUrlCall.getAddressMMI(JSON.stringify(addressLatLng)).then(response => {
            // console.log('address is', response)
            if (response.data.response_code === 200 & response.data.is_success === true) {
                this.setState({
                    address_is: response.data.data.address,
                    latitude_is: response.data.data.lat,
                    longitude_is: response.data.data.lng
                }, () => {
                    $('#search_address_val').val(this.state.address_is)
                    if (!this.state.address_is) {
                        this.setState({
                            openAlert: true,
                            errorMessage: 'Address Not found',
                        })
                    }
                })
            }
        }).catch(error => {
            console.log('error in request', error)
        })
    }

    mmiGetAddressApi = (e) => {
        var address = e.currentTarget.value
        if (e.currentTarget.value.length > 4) {
            ApiUrlCall.getAddressIQ(address).then(response => {
                if (response.status === 200) {
                    this.setState({
                        autoAddress: response.data.data.address_list
                    }, () => {
                        // console.log('address in state is', this.state.autoAddress)
                    })
                }
                $('#address_list').slideDown(200, "swing")
                // console.log('response of address is', response)
            }).catch(error => {
                console.log('error while fetching address from MMI', error)
            })
        } else {
            $('#address_list').fadeOut(200)
        }
    }

    getAddressData = (e) => {
        const { autoAddress } = this.state
        var indexVal = $(e.currentTarget).attr('name')
        // console.log('index value is', indexVal)
        this.setState({
            address_is: autoAddress[indexVal].display_name,
            markerAddressLat: parseFloat(autoAddress[indexVal].lat),
            markerAddressLng: parseFloat(autoAddress[indexVal].lon),
        }, () => {
            $('#search_address_val').val(this.state.address_is)
            this.renderMap()
            // console.log('address is', this.state.address_is)
            // console.log('address is', this.state.markerAddressLat)
            // console.log('address is', this.state.markerAddressLng)
        })
        $('#address_list').fadeOut(200)
    }

    closeMapModal = () => {
        // console.log('close modal colled')
        this.setState({
            showMapSelect: false,
            // address_is: ''
        }, () => {
            // $('#search_address_val').val("")
            // console.log('scroll active')
            //   this.showScroll()
        })
    }

    saveAddress = () => {
        const { address_is } = this.state
        if (!address_is) {
            this.setState({
                openAlert: true,
                errorMessage: "Kindly select address"
            })
            return
        }
        this.setState({
            showMapSelect: false,
        }, () => {
            $('#search_address_val').val("")
        })
    }

    openMap = (e) => {
        // console.log(addressAppend,">>>>>>><<<<<<<<<>>>>>>>>>><<<<<<<<<", $(e.currentTarget).attr('name'))        
        this.setState({
            showMapSelect: true,
        }, () => {
            this.renderMap()
            window.dispatchEvent(new Event('resize'));
        })
        // console.log('open map click event')
    }

    getMoreData = () => {
        const { userList, nextCount, totalCount } = this.state
        if (nextCount !== totalCount) {
            $('#processing').show()
            var data = { role_id_list: [ADMIN, USER], status: 1, next_count: this.state.nextCount }
            ApiUrlCall.ptAdminList(JSON.stringify(data)).then(response => {
                $('#processing').hide()
                if (response.data.response_code == 200 && response.data.is_success) {
                    let user_list = response.data.data.user_list
                    for (let i = 0; i < user_list.length; i++) {
                        const element = user_list[i];
                        this.state.userList.push(element)
                    }
                    this.state.nextCount = response.data.data.next_count
                    this.forceUpdate()
                    if (this.state.nextCount !== this.state.totalCount) {
                        $('#get_more_data').addClass('getmore_enable')
                    } else {
                        $('#get_more_data').addClass('getmore_disable')
                    }
                } else if (response.data.response_code == 410 && !response.data.is_success) {
                    if ($('#pplt_expire_dialog').is(':hidden')) {
                        $('#pplt_expire_dialog').slideDown()
                    }
                } else {
                    this.setState({
                        openAlert: true,
                        errorMessage: response.data.data.response_message
                    })
                }
            }).catch(error => {
                $('#processing').hide()
                console.log('error while fetching admin list', error)
            })
        }
    }

    visitType = (value) => {
        switch (value) {
            case ASSIGNED:
                return "Assigned"
                break;
            case ONGOING:
                return "Ongoing"
                break;
            case COMPLETE:
                return "Completed"
                break;
            case PENDING:
                return "Pending"
                break;
            default:
                break;
        }
    }


    render() {
        const { t } = this.props
        const { autoAddress, userList, visitImages, nextVisitOn, visitDuration } = this.state
        var minDate = new Date();
        if (autoAddress) {
            var address_list = autoAddress.map((list, index) => {
                return <p className="search_address" name={index}
                    onClick={this.getAddressData}>{list.display_name}</p>
            })
        }
        if (userList.length > 0) {
            var users = userList.map(data => {
                return <option value={data.user_id}>{data.name}</option>
            })
        }
        if (nextVisitOn) {
            var next_visit = CommonFunction.dateFormate(nextVisitOn)
        }
        if (visitDuration) {
            var duration = CommonFunction.convertSecToHoursMin(visitDuration)
        }
        if (visitImages.length > 0) {
            var image = visitImages.map(image => {
                return (
                    <Grid item xs={3}>
                        <div className="document_image_parent" style={{ margin: "0px" }}>                            
                                <img src={image} style={{ margin: "auto", display: "block", width : "100%" }}></img>                            
                        </div>
                    </Grid>
                )
            })
        }
        return (
            <div>
                <Navbar />
                <div className="people_report_parent">
                    <div >
                        <h2 className="page_header"> <img src="images/arrow11.png" alt="icon" onClick={this.goBack}></img>&nbsp;&nbsp;
                        {this.props.location.state !== undefined ? "View Visit Details" : "Add New Visit"}</h2>
                    </div>

                    <div className="map_view_div" style={{ marginBottom: "20px" }}>
                        <p className="p_header">Visit Details</p>
                        <Grid container spacing={4}>
                            <Grid item xs={4} style={{ display: this.props.location.state !== undefined ? "" : "none" }}>
                                <p className="cj_w_header">Visit ID / Name *</p>
                                <input type="text" placeholder="Enter Visit Name / ID" className="vd_input" autoComplete="off" style={{ marginBottom: "0px" }} name="fname"
                                    onChange={(e) => {
                                        this.setState({
                                            visitName: e.currentTarget.value
                                        })
                                    }} defaultValue={this.state.visitName} disabled></input>
                            </Grid>
                            <Grid item xs={4}>
                                <p className="cj_w_header">Visit Assigned To *</p>
                                <div style={{ display: this.props.location.state == undefined ? "" : "none" }}>
                                    <select className="status-filter" style={{ width: "100%", position: "relative", padding: "15px 8px" }}
                                        onChange={this.selectUser} size="4" onScroll={this.getNewList}>
                                        {users}
                                    </select>
                                    <p className="cj_w_header" id="get_more_data" onClick={this.getMoreData}>Get More ...</p>
                                </div>
                                <div style={{ display: this.props.location.state !== undefined ? "" : "none" }}>
                                    <input type="text" placeholder="Enter Visit Name / ID" disabled className="vd_input" autoComplete="off" style={{ marginBottom: "0px" }} name="fname"
                                        defaultValue={this.state.assignedTo}></input>
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <p className="cj_w_header">Firm / Client Name *</p>
                                <input type="text" placeholder="Enter Client Name" autoComplete="off" className="vd_input" style={{ marginBottom: "0px" }} name="fname"
                                    onChange={(e) => {
                                        this.setState({
                                            clientName: e.currentTarget.value
                                        })
                                    }} defaultValue={this.state.clientName}></input>
                            </Grid>
                            <Grid item xs={4}>
                                <p className="cj_w_header">Mobile Number</p>
                                <input type="number" placeholder="Enter Mobile Number" autoComplete="off" className="vd_input" style={{ marginBottom: "0px" }} name="fname"
                                    onChange={(e) => {
                                        this.setState({
                                            mobileNumber: e.currentTarget.value
                                        })
                                    }} defaultValue={this.state.mobileNumber}></input>
                            </Grid>
                            <Grid item xs={4}>
                                <p className="cj_w_header">Visit Location *</p>
                                <div onClick={this.openMap} className="visit_input">
                                    <input type="text" placeholder="Enter Visit Location" autoComplete="off" className="vd_input" style={{ marginBottom: "0px" }}
                                        name="fname" disabled defaultValue={this.state.address_is}></input>
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <p className="cj_w_header">Visit Date *</p>
                                <div className="input_div visit_input" onClick={this.showCalendar}>
                                    <input type="text" placeholder="Select Visit Date" id="visit_date" disabled
                                        value={CommonFunction.dateFormate(this.state.VisitDate)} className="vd_input" style={{ marginBottom: "0px" }}></input>
                                    <img src="images/fill-111.png" className="vd_journey_icon"></img>
                                </div>
                                <div className="calendar_parent" id="visit_calendar">
                                    <Calendar
                                        selectRange={false}
                                        onChange={this.VisitDateClicked}
                                        value={this.state.VisitDate}
                                        minDate={minDate}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <p className="cj_w_header">Visit Time *</p>
                                <div onClick={this.showStartTimeContent} className="time_div" style={{ padding: '15px', backgroundColor: "#eeeeee" }}>
                                    <p>{this.state.visitTime}</p>
                                </div>
                                {this.state.showTime &&
                                    <div style={{ position: "absolute", zIndex: "9" }}>
                                        <Timekeeper
                                            time={this.state.visitTime}
                                            switchToMinuteOnHourSelect
                                            onDoneClick={() => {
                                                this.setState({
                                                    showTime: false
                                                })
                                            }}
                                            onChange={this.selectStartTime}
                                        />
                                    </div>
                                }
                            </Grid>
                            <Grid item xs={4}>
                                <p className="cj_w_header">Visit End Date *</p>
                                <div className="input_div visit_input" onClick={this.showEndCalendar}>
                                    <input type="text" placeholder="Select Visit Date" id="visit_end_date" disabled
                                        value={CommonFunction.dateFormate(this.state.visitEndDate)} className="vd_input" style={{ marginBottom: "0px" }}></input>
                                    <img src="images/fill-111.png" className="vd_journey_icon"></img>
                                </div>
                                <div className="calendar_parent" id="visit_end_calendar">
                                    <Calendar
                                        selectRange={false}
                                        onChange={this.VisitEndDateClicked}
                                        value={this.state.visitEndDate}
                                        minDate={minDate}
                                    />
                                </div>
                            </Grid>
                            <Grid item xs={4}>
                                <p className="cj_w_header">Visit End Time *</p>
                                <div onClick={this.showEndTimeContent} className="time_div" style={{ padding: '15px', backgroundColor: "#eeeeee" }}>
                                    <p>{this.state.visitEndTime}</p>
                                </div>
                                {this.state.showEndTime &&
                                    <div style={{ position: "absolute", zIndex: "9" }}>
                                        <Timekeeper
                                            time={this.state.visitEndTime}
                                            switchToMinuteOnHourSelect
                                            onDoneClick={() => {
                                                this.setState({
                                                    showEndTime: false
                                                })
                                            }}
                                            onChange={this.selectEndTime}
                                        />
                                    </div>
                                }
                            </Grid>
                            <Grid item xs={4} style={{ display: this.props.location.state !== undefined ? "" : "none" }}>
                                <p className="cj_w_header">Visit Status</p>
                                <input type="text" placeholder="Visit Status" autoComplete="off" className="vd_input" style={{ marginBottom: "0px" }}
                                    name="fname" disabled defaultValue={this.visitType(this.state.visitType)}></input>
                            </Grid>
                        </Grid>
                        <div style={{ display: this.props.location.state !== undefined ? "none" : "" }}>
                            <hr />
                            <p className="p_header">Visit Duration</p>
                            <Grid container spacing={4}>
                                <Grid item xs={4}>
                                    <p className="cj_w_header">Min Visit Duration (Mins)</p>
                                    <input type="number" placeholder="Enter Max Duration" autoComplete="off" className="vd_input" style={{ marginBottom: "0px" }} name="fname"
                                        onChange={(e) => {
                                            this.setState({
                                                max_dur: e.currentTarget.value
                                            })
                                        }}></input>
                                </Grid>
                                <Grid item xs={4}>
                                    <p className="cj_w_header">Max Visit Duration (Mins)</p>
                                    <input type="number" placeholder="Enter Min Duration" autoComplete="off" className="vd_input" style={{ marginBottom: "0px" }} name="fname"
                                        onChange={(e) => {
                                            this.setState({
                                                min_dur: e.currentTarget.value
                                            })
                                        }}></input>
                                </Grid>
                                <Grid item xs={4}>
                                    <p className="cj_w_header">Is Recurring Visit</p>
                                    <select className="status-filter" style={{ width: "100%", position: "relative", padding: "15px 8px" }}
                                        onChange={(e) => {
                                            this.setState({
                                                isNextVisit: e.currentTarget.value
                                            })
                                        }}>
                                        <option value="1">No</option>
                                        <option value="2">Yes</option>
                                    </select>
                                </Grid>
                                <Grid item xs={4} style={{ display: this.state.isNextVisit == 2 ? "" : "none" }}>
                                    <p className="cj_w_header">Next Visit Date</p>
                                    <div className="input_div" onClick={this.nextVisitCalendar}>
                                        <input type="text" placeholder="Select Next Visit Date" id="next_visit_date" disabled
                                            value={CommonFunction.dateFormate(this.state.nextVisitDate)} className="vd_input" style={{ marginBottom: "0px" }}></input>
                                        <img src="images/fill-111.png" className="vd_journey_icon"></img>
                                    </div>
                                    <div className="calendar_parent" id="next_visit_calendar">
                                        <Calendar
                                            selectRange={false}
                                            onChange={this.nextVisitDateClicked}
                                            value={this.state.nextVisitDate}
                                            minDate={minDate}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                        <div style={{ display: this.props.location.state !== undefined ? "" : "none" }}>
                            <hr />
                            <p className="p_header">Visit Feedback</p>
                            <div style={{ display: this.state.visitImages.length > 0 ? "" : "none" }}>
                                <p className="cj_w_header">Visit Images</p>
                                <Grid container spacing={6}>
                                    {image}
                                </Grid>
                            </div>
                            <Grid container spacing={4}>
                                <Grid item xs={4}>
                                    <p className="cj_w_header">Visit Purpose</p>
                                    <input type="text" autoComplete="off" className="vd_input" style={{ marginBottom: "0px" }} name="fname" disabled
                                        defaultValue={this.state.purpose}></input>
                                </Grid>
                                <Grid item xs={4}>
                                    <p className="cj_w_header">Visit Remark</p>
                                    <input type="text" autoComplete="off" className="vd_input" style={{ marginBottom: "0px" }} name="fname" disabled
                                        defaultValue={this.state.remark}></input>
                                </Grid>
                                <Grid item xs={4}>
                                    <p className="cj_w_header">Next Visit On</p>
                                    <input type="text" autoComplete="off" className="vd_input" style={{ marginBottom: "0px" }} name="fname" disabled
                                        defaultValue={next_visit}></input>
                                </Grid>
                                <Grid item xs={4}>
                                    <p className="cj_w_header">Visit Duration</p>
                                    <input type="text" autoComplete="off" className="vd_input" style={{ marginBottom: "0px" }} name="fname" disabled
                                        defaultValue={duration}></input>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                    <div style={{ textAlign: "center", display: this.state.is_editable || this.props.location.state == undefined ? "" : "none", marginBottom : "30px" }}>
                        <button className="btn orange-btn" style={{ float: "none" }} onClick={this.addVisit}>{this.state.is_editable ? "Update Visit" : "Add Visit"}</button>
                    </div>
                </div>
                <div className="select_address_parent" style={{ display: this.state.showMapSelect ? '' : 'none' }}>
                    <div className="select_address">
                        <Grid container>
                            <Grid item xs={5}>
                                <div className="enter_address_div">
                                    <p className="p_big_grey">{t('ct.select.location')}</p>
                                    <p>{t('ct.location')}</p>
                                    <input type="text" defaultValue={this.state.address_is} placeholder={t('ct.enter.address')} className="journey_input"
                                        onKeyUp={this.mmiGetAddressApi} id="search_address_val" autoComplete="off"></input>
                                    <div className="address_list" id="address_list">
                                        {address_list}
                                    </div>
                                    <button className="btn sj_btn save_address" onClick={this.saveAddress}>{t('ct.save')}</button>
                                </div>
                            </Grid>
                            <Grid item xs={7}>
                                {this.state.MapContent}
                            </Grid>
                        </Grid>
                        <div className="close_modal" style={{ zIndex: "999" }} onClick={this.closeMapModal}>
                            <i class="fa fa-close" aria-hidden="true"></i>
                        </div>
                    </div>
                </div>
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    autoHideDuration={1800}
                    onClose={(e, reason) => {
                        if (reason === 'clickaway') {
                            return;
                        }
                        this.setState({ openAlert: false })
                    }
                    }
                    open={this.state.openAlert}
                    message={this.state.errorMessage}
                />
            </div>
        );
    }
}

export default withTranslation()(AddVisit);
