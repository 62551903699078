import React, { Component } from 'react'
import { Route, Redirect, NavLink, BrowserRouter as Router } from 'react-router-dom'
import Grid from '@mui/material/Grid';
// import '../Component/common_style.scss'
import ApiUrlCall from '../Component/API/apiurl';
import $ from 'jquery';
import { withTranslation } from 'react-i18next';
import constant from '../Component/constant.json';
import Tooltip from '@mui/material/Tooltip';
// import constant from '../Component/constant.json'

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef()
    this.state = {
      message: '',
      token: '',
      //loggedIn:false,
      navigate: false,
      username: localStorage.getItem("username")
    }
    this.logOut = this.logOut.bind(this)
  }
  componentDidMount() {
    var navBarHeight = $('.navBar').outerHeight()
    // console.log("height of navbar", navBarHeight);          
  }
  logOut = () => {

    // localStorage.removeItem("token");
    localStorage.clear()
    this.setState({ navigate: true });
    //  this.props.history.push("/")
    // const fakeAuthCentralState = {
    //   isAuthenticated: false,
    //   authenticate(callback) {
    //      this.isAuthenticated = true;
    //      setTimeout(callback, 300);
    //   },  
    // };

  }
  showOption = () => {
    $('#setting_option').fadeToggle(200, "swing")
  }

  openPeopleTracking = (e) => {
    // console.log('>>>>>>>>>>>>>>>')
    $('#processing').show()
    var is_people_tracking = localStorage.getItem('default_view')
    if (is_people_tracking !== constant.people_tracking) {
      ApiUrlCall.ptDefaultView(JSON.stringify({ default_view: 2 })).then(response => {
        console.log('response111', response)
        if (response.data.response_code == 200 && response.data.is_success) {
          localStorage.setItem('default_view', 2)
          setTimeout(() => {
            window.location.reload(false);
          }, 100);
        }
      })
    } else {
      ApiUrlCall.ptDefaultView(JSON.stringify({ default_view: 1 })).then(response => {
        console.log('response22', response)
        if (response.data.response_code == 200 && response.data.is_success) {
          localStorage.setItem('default_view', 1)
          setTimeout(() => {
            window.location.reload(false);
          }, 100);
        }
      })
    }
  }

  render() {

    const { navigate } = this.state;
    const { t, i18n } = this.props
    // console.log("nav bar js calleddddddd",localStorage.getItem("is_people_tracking"))
    // console.log(typeof localStorage.getItem("user_id"),'>>>>>>>>>>>>>>>>>>>>>>>>>')
    if (navigate && localStorage.getItem("token") === null) {
      return <Redirect to="/login" push={true} />;
    };
    return (
      <withRouter>
        <Grid className="navBar" container>
          <Grid className="navBar-align-img" item xs={3}>
            <div className="grid-container">
              <div className="grid-item">
                <img src="images/logo.png" alt="matchpointgps log" width="235" height="31px" />
              </div>
              <div className="grid-item" style={{ marginLeft: "20px", display: parseInt(localStorage.getItem("group")) !== constant.rent_user ? "" : "none" }}>
                <NavLink tag={NavLink} exact to="/">
                {localStorage.getItem('group')!= 37 && (
                  <div className="people_tracking_icon" onClick={this.openPeopleTracking}>

                    
                    <Tooltip style={{ display: localStorage.getItem('default_view') !== constant.people_tracking ? "" : "none" }} 
                    title={<span style={{ fontSize: "15px" }}>People Tracking</span>} placement="bottom">
                      <img src="images/people_icon.png" alt="icon"></img>
                    </Tooltip>
  
                    <Tooltip
                      style={{ display: localStorage.getItem('default_view') == constant.people_tracking ? "" : "none" }}
                      title={<span style={{ fontSize: "15px" }}>
                        Vehicle Tracking
                      </span>} placement="bottom">
                      <img src="images/car_tracking_icon.png" alt="icon"></img>
                    </Tooltip>
                  
                  </div>
                )} 
                </NavLink>
              </div>
            </div>
          </Grid>
          {parseInt(localStorage.getItem("group")) !== constant.rent_user ?
            localStorage.getItem('default_view') !== constant.people_tracking ||  parseInt(localStorage.getItem('group')) === 37 ?
              <Grid item xs={7} container>
                <Grid item xs>
                </Grid>
                <Grid className="navBar-list" item xs>
                  <NavLink activeClassName='is-active' tag={NavLink} exact to="/" className="navBar-click">
                    <p>{t('os.dashbord')}</p>
                  </NavLink>
                </Grid>
                <Grid className="navBar-list" item xs>
                  <NavLink activeClassName='is-active' tag={NavLink} to="/journey" className="navBar-click">
                    <p>{t('ds.journey')}</p>
                  </NavLink>
                </Grid>
                <Grid className="navBar-list" item xs>
                  <NavLink activeClassName='is-active' tag={NavLink} to="/list_vehicle" className="navBar-click">

                    <p>{t('ars.sum.v2')}</p>
                  </NavLink>
                </Grid>
                <Grid className="navBar-list" item xs>
                  <NavLink activeClassName='is-active' tag={NavLink} to="/user_list" className="navBar-click">
                    <p>{t('iv.user')}</p>
                  </NavLink >
                </Grid>
                <Grid className="navBar-list" item xs>
                  <NavLink activeClassName='is-active' tag={NavLink} to="/reports" className="navBar-click">
                    <p>{t('ss.as.report')}</p>
                  </NavLink >
                </Grid>

              </Grid>
              :
              <Grid item xs={7} container style={{ textAlign: "center" }}>
                <Grid className="navBar-list" item xs>
                  <NavLink activeClassName='is-active' tag={NavLink} exact to="/" className="navBar-click">
                    <p>{t('os.dashbord')}</p>
                  </NavLink>
                </Grid>
                <Grid className="navBar-list" item xs>
                  <NavLink activeClassName='is-active' tag={NavLink} to="/p_user_management" className="navBar-click">
                    <p>User Management</p>
                  </NavLink>
                </Grid>
                <Grid className="navBar-list" item xs>
                  <NavLink activeClassName='is-active' tag={NavLink} to="/p_visit_list" className="navBar-click">
                    <p>Visits</p>
                  </NavLink>
                </Grid>
                <Grid className="navBar-list" item xs>
                  <NavLink activeClassName='is-active' tag={NavLink} to="/p_report" className="navBar-click">
                    <p>Report</p>
                  </NavLink >
                </Grid>
              </Grid>
            :
            <Grid item xs={7} container>
              <Grid item xs>
              </Grid>
              <Grid className="navBar-list" item xs>
                <NavLink activeClassName='is-active' tag={NavLink} exact to="/" className="navBar-click">
                  <p>{t('os.dashbord')}</p>
                </NavLink>
              </Grid>
              <Grid className="navBar-list" item xs>
                <NavLink activeClassName='is-active' tag={NavLink} to="/tracker_list" className="navBar-click">
                  <p>Tracker</p>
                </NavLink >
              </Grid>
              <Grid className="navBar-list" item xs>
                <NavLink activeClassName='is-active' tag={NavLink} to="/report_data" className="navBar-click">
                  <p>{t('ss.as.report')}</p>
                </NavLink >
              </Grid>
            </Grid>
          }

          <Grid className="navBar-align-username" item xs={2}>
            {/* <button onClick={this.logOut}>Logout</button> */}
            <div style={{ float: "right" }} onClick={this.showOption}>
              <div className="profile_icon">
                <img src="images/profile_icon.png" alt="icon"></img>
              </div>
              <div style={{ display: "inline-block", verticalAlign: "bottom", cursor: "pointer" }}>
                <Tooltip title={<span style={{ fontSize: "15px" }}>{this.state.username}</span>} placement="bottom">
                  <p className="short_username">{this.state.username}</p>
                </Tooltip>
              </div>
            </div>
          </Grid>
          {/* <div style={{display : localStorage.getItem('default_view') !== constant.people_tracking ? "" : "none"}}>
            <div className="classic_login" style={{display : parseInt(localStorage.getItem("group")) !== constant.rent_user ? "" : "none"}}>
              <p className="cj_w_header p_margin0">
              <span style={{color : "#F0762B", cursor : "pointer", textDecoration : "underline"}}
              onClick={() => {
                var loggedIn = 'pr_love_kajaldarling';
                var username = localStorage.getItem('user_name')
                var mapForm = document.createElement("form");
                mapForm.target = "Map";
                mapForm.method = "POST";
                mapForm.action = "https://track.matchpointgps.in/gps/admin_login_perm/";
                mapForm.setAttribute("class", "hform");

                var mapInput = document.createElement("input");
                mapInput.type = "text";
                mapInput.name = "usrname";
                mapInput.value = username;
                mapForm.appendChild(mapInput);
                var mapInput = document.createElement("input");
                mapInput.type = "text";
                mapInput.name = "adminuser";
                mapInput.value = loggedIn;
                mapForm.appendChild(mapInput);

                document.body.appendChild(mapForm);

                var map = window.open("", "Map")

                if (map) {
                    mapForm.submit();
                    mapForm.style.visibility = "hidden"
                }
              }}>Click here</span>&nbsp;
              for classic version of our Web application</p>
            </div>
          </div> */}
          {/* <div className="classic_open" style={{bottom : "-50px", left : "19%",position : "absolute"}}
          onClick={(e) => {
            var divVisible = $(e.currentTarget).prev()
            $(e.currentTarget).prev().fadeToggle(200)
            $(e.currentTarget).children().children().toggle()
            console.log($(divVisible), '---------------')
            setTimeout(() => {
              if($('.classic_login').is(":visible")){
                console.log('is visible')
                $('.classic_open').css({
                  bottom : "-50px"
                })
              }else{
                console.log('is hidden')
                $('.classic_open').css({
                  bottom : "-8px"
                })
              }
            },250)
          }}>
            <div class="small_button" style={{height : "15px", width : "15px"}}>
                <i class="fa fa-angle-up del_info_icon" aria-hidden="true" style={{verticalAlign : "text-top"}}></i>
                <i class="fa fa-angle-down del_info_icon" aria-hidden="true" style={{ display: "none",verticalAlign : "text-top" }}></i>
            </div>
          </div> */}
        </Grid>


        <div id="setting_option" className="option_list_parent">
          <div className="option_tri"></div>
          <div className="option_list">
            <ul> <NavLink style={{ display: parseInt(localStorage.getItem("group")) !== constant.rent_user ? "" : "none" }} to="/setting" className="setting_click"><li>{t('ss.setting')}</li></NavLink>
              <NavLink style={{ display: parseInt(localStorage.getItem("group")) === constant.rent_user ? "" : "none" }} to="/setting_rental" className="setting_click"><li>{t('ss.setting')}</li></NavLink>
              <hr style={{ display: parseInt(localStorage.getItem("group")) !== constant.rent_user ? "" : "none" }} className="opt_hr" />
              <li onClick={this.logOut}>{t('ct.logout')}</li>

              <NavLink style={{ display: parseInt(localStorage.getItem("group")) !== constant.rent_user ? "" : "none" }} to="/deleteaccount" className="setting_click"><li> Delete Account</li></NavLink>

            </ul>
          </div>
        </div>

      </withRouter>
    )
  }

}
export default withTranslation()(Navbar);
