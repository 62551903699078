import React, { Component } from 'react';
import $ from 'jquery';
import Grid from '@mui/material/Grid';
//import { BrowserRouter as Router, Link, } from "react-router-dom";
import './drivers.scss';
import Navbar from '../../Config/Navbar';
import ApiUrlCall from '../API/apiurl'
import LinearProgress from '@mui/material/LinearProgress';
import CommonFunction from '../common_function';
import Snackbar from '@mui/material/Snackbar';
import { Multiselect } from 'multiselect-react-dropdown';
import analytics from '../../Config/firebase'
import Amplify, { Analytics } from 'aws-amplify';
import awswebapp from '../../aws-exports';
import constant from '../constant.json'
import { withTranslation } from 'react-i18next';

const License = 'license'
const PanCard = "pan_card"
const AadharCard = "aadhar_card"
const Passport = "passport"

class AddDriver extends Component {
    constructor(props) {
        super(props);
        this.state = {
            first_name: '',
            last_name: '',
            mobile_no: '',
            add: '',
            prefer_area: [],
            prefer_vehicle: [],
            message: '',
            license_no: '',
            license_type: '',
            doc_name: '',
            driver_id: '',
            driverDocument: [],
            showPage: false,
            licenseType: '',
            mobileFlag : true,
            vehicleOption : '',            
            selectedVehicle : [],
            defaultArea : '',
            areaOption : '',
            selectedArea : '',
            vehicleList : [],
            areaList : [],
            regionData : '',
            vehicleData : '',
        }
    }

    componentDidMount() {        
        var navBarHeight = $('.navBar').outerHeight();
        $('.create_new_vehicle').css({
            paddingTop: navBarHeight + 60,
            paddingLeft: "100px",
            paddingRight: "100px"
        })
        if (this.props.location.state !== undefined) {
            analytics.logEvent('page_view',{
                "page_title" : "EditDriver"
            })
            analytics.logEvent('screen_view',{
                screen_name : "EditDriver",        
              }) 
            Amplify.configure(awswebapp);
            Analytics.record({
            name: constant.awsName, 
            // Attribute values must be strings
            attributes: { screen_name: 'WEB_EDIT_DRIVER'}
            });  
            this.getDriverDetail(this.props.location.state.driver_id)
        }else{
            analytics.logEvent('screen_view',{
                screen_name : "AddDriver"
            })
            analytics.logEvent('page_view',{
                page_title : "AddDriver",        
              }) 
            Amplify.configure(awswebapp);
            Analytics.record({
            name: constant.awsName, 
            // Attribute values must be strings
            attributes: { screen_name: 'WEB_ADD_DRIVER'}
            });  
            this.getVehicleList()
            this.getZoneList()
        }
        
    }

    componentWillUnmount() {
        localStorage.removeItem('driverId')
    }

    getDriverDetail = (driver_id) => {
        localStorage.setItem('driverId', driver_id)
        ApiUrlCall.uniqueidDriverData(driver_id).then(response => {
            if (response.status === 200) {
                // console.log('driver data is', response)
                let driverName = response.data.driver_name.split(" ");
                let first_name = driverName[0]
                let last_name = driverName[1]
                this.setState({
                    driver_id: response.data.id,
                    first_name: first_name,
                    last_name: last_name,
                    mobile_no: response.data.mobile_no,
                    add: response.data.driver_address,                    
                    showPage: false,
                    openAlert: false,
                    errorMessage : '',
                    regionData : response.data.preferred_region_list,
                    vehicleData : response.data.driver_pref_vehicle_list
                }, () => {
                    this.getVehicleList()
                    this.getZoneList()                     
                })
            }
        }).catch(error => {
            console.log("error while fetching journey data", error)
        })
    }

    preferedDataRegion = (data) => {
        const {prefer_area, defaultArea, regionData} = this.state
        // console.log('region list', this.state.areaList)
        if(regionData.length > 0){
            var area = []
            var area_id = []            
            for (let i = 0; i < regionData.length; i++) {
                const element = regionData[i];
                area_id.push(parseInt(element.region_id))
                area.push(element.name)
            }
            this.setState({
                defaultArea : area,
                prefer_area : area_id,
            })            
            // console.log(prefer_area,'selected vehicle is', defaultArea)
        }else{
            return
        }
        // console.log(prefer_vehicle,'selected vehicle is', selectedVehicle)
    }
    preferedDatavehicle = (data) => {
        const {prefer_vehicle, selectedVehicle, vehicleData} = this.state
        // console.log('vehicle list', this.state.vehicleList)
        if(vehicleData.length > 0){
            var vehicle = []
            var serial_number = []            
            for (let i = 0; i < vehicleData.length; i++) {
                const element = vehicleData[i];
                serial_number.push(parseInt(element.serial_number))
                vehicle.push(element.name)
            }
            this.setState({
                selectedVehicle : vehicle,
                prefer_vehicle : serial_number,
            })            
        }else{
            return
        }
        // console.log(prefer_vehicle,'selected vehicle is', selectedVehicle)
    }

    getVehicleList = () => {
        ApiUrlCall.getVehicleList().then(response => {
            // console.log('vehicle list', response)
            if(response.data.response_code === 200 && response.data.is_success === true){
                this.setState({
                    vehicleOption : response.data.data.vehicle_summary_list
                }, () => {
                    this.state.vehicleOption.map(name => {
                        // console.log('........................')
                        this.state.vehicleList.push(name.vehicle_name)
                        // console.log('vehicle list is', name.vehicle_name)
                        this.forceUpdate()                            
                    })  
                    this.preferedDatavehicle()                                     
                })
            }
        }).catch(error => {
            console.log('error while fetching vehicle list', error)
        })
    }

    getZoneList = () => {
        ApiUrlCall.getZone().then(response => {
          console.log('region list is', response)
          if (response.status === 200) {            
            this.setState({
              areaOption: response.data,              
            }, () => {
                this.state.areaOption.map(list => {
                    this.state.areaList.push(list.name)
                    this.forceUpdate()
                    // console.log('area list in multi selecte', this.state.areaList)
                })
                this.preferedDataRegion()  
              // console.log('00000000000000', this.state.regionList)              
            })
          }
        }).catch(error => {          
          console.log('error while fetching zone list', error)
        })
      }

    selectType = (e) => {
        $(e.currentTarget).addClass('selected_btn')
        $(e.currentTarget).removeClass('unseleted_btn')
        $(e.currentTarget).siblings().removeClass('selected_btn')
        $(e.currentTarget).siblings().addClass('unseleted_btn')
    }

    previousView = (e) => {
        let DriverId = localStorage.getItem('driverId')
        if ($('#enter_driver_details').is(':hidden')) {
            if (DriverId) {
                this.getDriverDetail(DriverId)
            }
        } else {

        }
    }

    nextView = (e) => {
        let validateFields = CommonFunction.handleValidationDriver(this.state.first_name, this.state.last_name, this.state.mobile_no, this.state.add)
        // console.log("..............", validateFields)
        if (validateFields && this.state.mobileFlag && this.state.mobile_no.length === 10) {
            let DriverId = localStorage.getItem('driverId')
            const DriverDetails = {
                driver_name: this.state.first_name + " " + this.state.last_name,
                mobile_no: this.state.mobile_no,
                driver_address: this.state.add,
                prefer_area: this.state.prefer_area,
                prefer_vehicle: this.state.prefer_vehicle
            };
            var driverdata = JSON.stringify(DriverDetails)
            // console.log('data is', driverdata)
            if (DriverId) {
                ApiUrlCall.updateDriver(driverdata, DriverId).then(response => {
                    if (response.status === 200) {
                        this.getLicenseType()
                        this.setState({
                            showPage: true,
                        })
                    }
                }).catch(error => {
                    console.log('error of conflict', error.response)
                    if(error.response.status === 409){
                        // console.log('409 message')
                        this.setState({
                            openAlert : true,
                            errorMessage : error.response.data.response_message
                        })
                    }
                })
            } else {
                ApiUrlCall.createDriver(driverdata).then(response => {
                    if (response.status === 201) {
                        // console.log('created driver', response)
                        this.getLicenseType()
                        localStorage.setItem('driverId', response.data.id)
                        this.setState({
                            driver_id: response.data.id,
                            showPage: true,
                        })
                    }

                }).catch(err => {
                    console.log("create driver api call err", err.response)
                    if(err.response.status === 409){
                        this.setState({
                            openAlert : true,
                            errorMessage : err.response.data.response_message
                        })
                    }

                })
            }
        }else{
            this.setState({
                openAlert : true,
                errorMessage : 'Invalid data',
            })
        }
    }



    driverRemoveFile = (e) => {
        $(e.currentTarget).parent().hide()
        $(e.currentTarget).parent().siblings().first().show()
        $(e.currentTarget).parent().prev().val("")
        var removeFileName = $(e.currentTarget).parent().prev().attr('name')
        var arrayOfData = this.state.driverDocument
        var removeIndex = arrayOfData.findIndex(x => x.name === removeFileName)
        arrayOfData.map((items, index, arrayOfData) => {
            if (items.name === removeFileName) {
                arrayOfData.splice(removeIndex, 1)
                this.forceUpdate()
            }
        })
    }

    driverFileUpload = (e) => {
        e.currentTarget.nextSibling.click()
    }

    driverFileAdded = (e) => {
        var uploadedFile = e.currentTarget.files[0].name
        let file = e.currentTarget.files[0]
        let document_type = $(e.currentTarget).attr('name')
        var DriverId = localStorage.getItem('driverId')
        $(e.currentTarget).prev().hide()
        $(e.currentTarget).next().show()
        $(e.currentTarget).next().find('label').empty()
        $(e.currentTarget).next().find('label').append(uploadedFile)
        // console.log(file)
        let fileReader = new FileReader();



        fileReader.onloadend = () => {

            let documetData = (() => {
                switch (document_type) {
                    case License:
                        return {
                            name: document_type,
                            doc_type: 3,
                            owner: DriverId,
                            owner_type: 2,
                            license_number: this.state.license_no,
                            license_type: this.state.license_type,
                            doc_file: fileReader.result.split("base64,")[1]
                        }
                        break;
                    case PanCard:
                        return {
                            name: document_type,
                            doc_type: 1,
                            owner: DriverId,
                            owner_type: 2,
                            doc_file: fileReader.result.split("base64,")[1]
                        }
                        break;
                    case AadharCard:
                        return {
                            name: document_type,
                            doc_type: 4,
                            owner: DriverId,
                            owner_type: 2,
                            doc_file: fileReader.result.split("base64,")[1]
                        }
                        break;
                    case Passport:
                        return {
                            name: document_type,
                            doc_type: 5,
                            owner: DriverId,
                            owner_type: 2,
                            doc_file: fileReader.result.split("base64,")[1]
                        }
                        break;

                    default:
                        break;
                }
            })()
            this.state.driverDocument.push(documetData)
            this.forceUpdate()
        }
        fileReader.readAsDataURL(file);
    }

    addDocument = () => {
        var jsonDataArray = this.state.driverDocument
        var counter = -1
        if (jsonDataArray.length > 0) {
            for (let i = 0; i < jsonDataArray.length; i++) {
                counter = counter + 1
                $('#loading').slideDown(200, "swing")
                $('html, body').css({
                    overflow: 'auto',
                    height: '100%'
                });
                var documentJsonData = JSON.stringify(jsonDataArray[i])
                // console.log('data is', documentJsonData)
                // return                
                ApiUrlCall.uploadDocumentUrl(documentJsonData).then(response => {
                    if (response.status === 201) {
                        if (i === counter) {
                            $('#loading').fadeOut(200, "swing")
                            localStorage.removeItem('driverId')
                            this.props.history.push({
                                pathname: 'user_list'
                            })
                        }
                    } else {
                    }
                }).catch(error => {
                    $('#loading').fadeOut(200, "swing")
                    console.log('error while adding the document of vehicle', error.response.data)
                })
            }
        } else {
            this.props.history.push({
                pathname: 'user_list'
            })
        }

    }
    goBack = () => {
        this.props.history.push('user_list')
    }

    getLicenseType = () => {
        ApiUrlCall.getLicenseType().then(response => {
            // console.log('license type is', response)
            if (response.data.response_code === 200 && response.data.is_success) {
                this.setState({
                    licenseType: response.data.data.license_type_list
                })
            }
        }).catch(error => {
            console.log('error while fetching driver license type', error)
        })
    }

    selectArea = (selectedList , selectedItem) => {
        const {areaOption, prefer_area} = this.state             
        for (let i = 0; i < areaOption.length; i++) {
            const element = areaOption[i];
            if(element.name === selectedItem){
                prefer_area.push(element.id)
                this.forceUpdate()                
            }
        }        
    }
    removeArea = (selectedList, removedItem) => {
        const {areaOption, prefer_area} = this.state        
        var removeIndex
        for (let j = 0; j < areaOption.length; j++) {
            const element = areaOption[j];
            if(element.name === removedItem){                
                removeIndex = prefer_area.indexOf(element.id)                            
                prefer_area.splice(removeIndex,1)
            }
        }        
    }
    selectVehicle = (selectedList , selectedItem) => {
        const {vehicleOption, prefer_vehicle} = this.state            
        for (let i = 0; i < vehicleOption.length; i++) {
            const element = vehicleOption[i];
            if(element.vehicle_name === selectedItem){
                prefer_vehicle.push(element.serial_number)
                this.forceUpdate()                
            }
        }        
    }
    removeVehicle = (selectedList, removedItem) => {
        const {vehicleOption, prefer_vehicle} = this.state        
        var removeIndex
        for (let j = 0; j < vehicleOption.length; j++) {
            const element = vehicleOption[j];
            if(element.vehicle_name === removedItem){                
                removeIndex = prefer_vehicle.indexOf(element.serial_number)                            
                prefer_vehicle.splice(removeIndex,1)
            }
        }         
    }

    render() {
        const {t} = this.props
        const {vehicleOption, vehicleList, defaultArea, selectedVehicle} = this.state
        if(this.state.licenseType){
            var licenseOption = this.state.licenseType.map(licenseOption => {
                return(
                    <option key={licenseOption.id} id={licenseOption.id}>{licenseOption.name}</option>
                )
            })
        }       
        
        if(defaultArea){
            var area_list = defaultArea
            // console.log('area list', this.state.vehicleList)
        }
        if(selectedVehicle) {
            var vehicle_list = selectedVehicle
        }
        // console.log('1111111111111111')
        return (
            <div>
                <Navbar />
                <div className="create_new_vehicle">
                    <div >
                        <h2 className="page_header"> <img src="images/arrow11.png" alt="icon" onClick={this.goBack}></img>&nbsp;&nbsp;
                {this.props.location.state !== undefined ? t('aeds.editdriver') : t('aeds.adddriver')}</h2>
                    </div>

                    <div className="map_view_div">
                        <Grid container>
                            <Grid item xs>
                                <p className="p_header p_margin0 BSC_extra_bold18" style={{ display: this.state.showPage ? 'none' : '' }}>{t('aeds.detail')}</p>
                                <p className="p_header p_margin0 BSC_extra_bold18" style={{ display: this.state.showPage ? '' : 'none' }}>{t('aeds.document')}</p>
                            </Grid>
                            <Grid item xs>
                            </Grid>
                        </Grid>
                    </div>
                    <div style={{ textAlign: "-webkit-right" }}>
                        <div className="prev_next_div">
                            <div>
                                <button className="btn previous_btn" onClick={this.previousView}>{t('aeds.previous')}</button>
                            </div>
                            <div>
                                <button id="d_next_detail" className="btn orange-btn" style={{ float: "none", display: this.state.showPage ? 'none' : '' }} onClick={this.nextView}>{t('aeds.next')}</button>
                                <button id="d_add_detail" className="btn orange-btn" style={{ float: "none", display: this.state.showPage ? '' : 'none' }}
                                    onClick={this.addDocument}>{this.props.location.state !== undefined ? t('aeds.edit.save') : t('aeds.new.save')}</button>
                            </div>
                        </div>
                    </div>

                    <div className="map_view_div vehicle_enter_detail">
                        {/* vehicle details */}
                        <div id="enter_driver_details" style={{ display: this.state.showPage ? 'none' : '' }}>
                            <div>
                                <Grid spacing={4} container>
                                    <Grid item xs={4}>

                                        <p className="cj_w_header">{t('aeds.firstname')}</p>
                                        <input type="text" id="first_name" placeholder={t('aeds.firstname')} value={this.state.first_name} className="vd_input" name="fname"
                                            onChange={(e) => { this.setState({ first_name: e.currentTarget.value }) }}></input>
                                        {/* <span style={{ color: "red" }}>{this.state.errors["first_name"]}</span> */}

                                    </Grid>
                                    <Grid item xs={4}>
                                        <p className="cj_w_header">{t('aeds.lastname')}</p>
                                        <input type="text" id="last_name" placeholder={t('aeds.lastname')} className="vd_input" value={this.state.last_name}
                                            onChange={(e) => { this.setState({ last_name: e.currentTarget.value }) }}></input>
                                        {/* <span style={{ color: "red" }}>{this.state.errors["last_name"]}</span> */}
                                    </Grid>
                                    <Grid item xs={4}>
                                        <p className="cj_w_header">{t('aeds.mobile')}</p>
                                        <input type="number" id="mobile_no" maxLength="10" placeholder={t('aeds.mobile')} 
                                        defaultValue={this.state.mobile_no} className="vd_input" onChange={(e) => {                                            
                                            if(e.currentTarget.value.length <= 10){
                                                this.setState({ mobile_no: e.currentTarget.value, mobileFlag: true })                                                
                                            }else{                                                
                                                this.setState({
                                                    openAlert : true,
                                                    errorMessage : 'Mobile cannot be more than 10 digit',
                                                    mobileFlag : false,
                                                })
                                            }
                                        }}  ></input>
                                        {/* <span style={{ color: "red" }} >{this.state.errors["mobile_no"]}</span> */}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <p>Address</p>
                                        <input type="text" id="address" placeholder="Address" className="vd_input" value={this.state.add} onChange={(e) => {
                                            this.setState({ add: e.currentTarget.value })
                                        }}></input>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <p className="cj_w_header">{t('aeds.area')}</p>
                                        <Multiselect 
                                        isObject={false}
                                        selectedValues={area_list}
                                        options={this.state.areaList}
                                        closeIcon="cancel"
                                        onSelect={this.selectArea}
                                        onRemove={this.removeArea}/>
                                        {/* <input type="text" placeholder="Preferred Area" className="vd_input" value={this.state.prefer_area} onChange={(e) => {
                                            this.setState({ prefer_area: e.currentTarget.value })
                                        }}></input> */}
                                    </Grid>
                                    <Grid item xs={4}>
                                        <p className="cj_w_header">{t('aeds.vehicle')}</p>                                        
                                        <Multiselect 
                                        isObject={false}
                                        options={this.state.vehicleList}
                                        selectedValues={vehicle_list}
                                        closeIcon="cancel"
                                        onSelect={this.selectVehicle}
                                        onRemove={this.removeVehicle}/>
                                        {/* <input type="text" placeholder="Preferred Vehicles" value={this.state.prefer_vehicle} className="vd_input" onChange={(e) => {
                                            this.setState({ prefer_vehicle: e.currentTarget.value })
                                        }}></input> */}
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                        {/* vehicle details end*/}
                        {/* enter vehilce document */}
                        <div id="enter_driver_document" style={{ display: this.state.showPage ? '' : 'none' }}>
                            <Grid container spacing={4}>
                                <Grid item xs={2}>
                                    <p className="p_header p_margin0">{t('aeds.license')}</p>
                                </Grid>
                                <Grid item xs={9}>
                                    <Grid item xs={5}>
                                        <div onClick={this.driverFileUpload} style={{ marginTop: "0px" }}>
                                            <img src="images/group-74.png" style={{ marginTop: "-15px" }}></img>
                                            {/* <i class="fa fa-plus" aria-hidden="true"></i> */}
                                        </div>
                                        <input type="file" name="license" className="file_upload" onChange={this.driverFileAdded}></input>
                                        <div className="show_file_name">
                                            <label className="p_margin0 cj_w_header">File name</label>
                                            <div class="remove_file" onClick={this.driverRemoveFile}><i class="fa fa-close" aria-hidden="true"></i></div>
                                        </div>
                                    </Grid>
                                    <Grid spacing={4} container style={{ marginTop: "15px" }}>
                                        <Grid item xs={5}>
                                            <p className="cj_w_header">{t('aeds.license.number')}</p>
                                            <input type="text" placeholder="License Number" className="vd_input" onChange={(e) => {
                                                var {driverDocument} = this.state                                                
                                                for (let i = 0; i < driverDocument.length; i++) {
                                                    var element = driverDocument[i]                                                    
                                                    this.state.driverDocument[i].license_number = e.currentTarget.value                                
                                                    // console.log('key value is', element) 
                                                } 
                                                this.setState({license_no : e.currentTarget.value})                                                                                               
                                            }}></input>
                                        </Grid>
                                        <Grid item xs={5}>
                                            <p className="cj_w_header">{t('aeds.license.type')}</p>
                                            <select style={{padding : "17px"}} className="select_dropdown" onChange={(e) => {
                                                var licenseType = $(e.currentTarget).children(":selected").attr("id")
                                                var {driverDocument} = this.state
                                                for (let i = 0; i < driverDocument.length; i++) {
                                                    var element = driverDocument[i]                                                    
                                                    this.state.driverDocument[i].license_type = parseInt(licenseType)
                                                    // console.log('key value is', element) 
                                                } 
                                                this.setState({
                                                    license_type : licenseType
                                                })
                                            }}>
                                            <option>Select Type</option>
                                            {licenseOption}
                                            </select>                                            
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid spacing={4} container style={{ marginBottom: "40px" }}>
                                <Grid item xs={2}>
                                    <p className="p_header p_margin0">{t('aeds.pancard')}</p>
                                </Grid>
                                <Grid item xs={4}>
                                    <div onClick={this.driverFileUpload} style={{ marginTop: "0px" }}>
                                        <img src="images/group-74.png" style={{ marginTop: "-15px" }}></img>
                                    </div>
                                    <input type="file" name="pan_card" className="file_upload" id="file" ref="file" onChange={this.driverFileAdded}></input>
                                    <div className="show_file_name">
                                        <label className="p_margin0 cj_w_header">File name</label>
                                        <div class="remove_file" id="file" ref="file" onClick={this.driverRemoveFile}><i class="fa fa-close" aria-hidden="true"></i></div>
                                    </div>
                                </Grid>
                            </Grid>

                            <Grid spacing={4} container style={{ marginBottom: "40px" }}>
                                <Grid item xs={2}>
                                    <p className="p_header p_margin0">{t('aeds.aadhar')}</p>
                                </Grid>
                                <Grid item xs={4}>
                                    <div id="file" ref="file" onClick={this.driverFileUpload} style={{ marginTop: "0px" }}>
                                        <img src="images/group-74.png" style={{ marginTop: "-15px" }}></img>
                                    </div>
                                    <input type="file" name="aadhar_card" className="file_upload" id="file" ref="file" onChange={this.driverFileAdded}></input>
                                    <div className="show_file_name">
                                        <label className="p_margin0 cj_w_header">File name</label>
                                        <div class="remove_file" onClick={this.driverRemoveFile}><i class="fa fa-close" aria-hidden="true"></i></div>
                                    </div>
                                </Grid>
                            </Grid>

                            <Grid spacing={4} container>
                                <Grid item xs={2}>
                                    <p className="p_header p_margin0">{t('aeds.passport')}</p>
                                </Grid>
                                <Grid item xs={4}>
                                    <div onClick={this.driverFileUpload} style={{ marginTop: "0px" }}>
                                        <img src="images/group-74.png" style={{ marginTop: "-15px" }}></img>
                                    </div>
                                    <input type="file" name="passport" className="file_upload" onChange={this.driverFileAdded}></input>
                                    <div className="show_file_name">
                                        <label className="p_margin0 cj_w_header">File name</label>
                                        <div class="remove_file" onClick={this.driverRemoveFile}><i class="fa fa-close" aria-hidden="true"></i></div>
                                    </div>
                                </Grid>
                            </Grid>

                            {/* <Grid container>
                        <Grid item xs={2}>
                            <p className="p_header p_margin0">permits</p>
                        </Grid>
                        <Grid item xs={4}>
                            <p>Name of insurer</p>
                            <input type="text" placeholder="Fuel Capacity" className="vd_input"></input>
                        </Grid>                        
                        <Grid item xs={2}>
                            upload button
                        </Grid>
                   </Grid> */}
                        </div>
                        {/* enter vehilce document end */}
                    </div>
                    <div id="loading" className="modal_parent" style={{ right: "0", textAlign: "center" }}>
                        <div className="modal_body">
                            <p className="modal_header">{t('aeds.upload')}</p>
                            <LinearProgress />
                        </div>
                    </div>
                </div>
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    autoHideDuration={1800}
                    onClose={(e, reason) => {
                        if (reason === 'clickaway') {
                            return;
                        }
                        this.setState({ openAlert: false })
                    }
                    }
                    open={this.state.openAlert}
                    message={this.state.errorMessage}
                />
            </div>
        )
    }
}

export default withTranslation()(AddDriver);
