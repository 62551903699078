
import React, { Component } from 'react';
import './widget.scss'
import $ from 'jquery';
import Grid from '@mui/material/Grid';
// import Map from "mapmyindia-react";
import Map from '../map/map'
import ApiUrlCall from '../API/apiurl';
import CircularProgress from '@mui/material/CircularProgress';
import { withTranslation } from 'react-i18next';
import CommonFunction from '../common_function';
import * as XLSX from 'xlsx';
import Tooltip from '@mui/material/Tooltip';
import DashCam from '../Dashcam/DashCam';
import PeopleTrackingMapView from '../peopletracking/dashboard_map'
import constant from '../constant.json';




const L = window.L
const MapmyIndia = window.MapmyIndia
const CONNECTION_LOST = 1
const MOVING = 2
const IDLING = 3
const LOW_SATELLITES = 5
const SLEEPING = 6
const OVERSPEEDING = 7
const PARKED = 4

const START = 16
const SHUTDOWN = 17
const ALL_DGCONNECTION_LOST = 444
const DNT = 8
const PARKED_UNKNOWN = 10
const ALL = [MOVING, IDLING, PARKED, START, OVERSPEEDING, CONNECTION_LOST, ALL_DGCONNECTION_LOST, SHUTDOWN, LOW_SATELLITES, SLEEPING, DNT, PARKED_UNKNOWN]

const MOVING_ICON = 'images/vehicle_icon/Car moving.png'
const DG_OFF_ICON = 'images/vehicle_icon/Car DG OFF.png'
const IDLING_ICON = 'images/vehicle_icon/Car idling.png'
const PARKED_ICON = 'images/vehicle_icon/Car parked.png'
const START_ICON = 'images/vehicle_icon/Car DG ON.png'
const DG_LOST_ICON = 'images/vehicle_icon/Car DG LOST connection.png'

const CONNECTION_LOST_ICON = 'images/vehicle_icon/Car lost connection.png'



class FleetSummary extends Component {

  constructor(props) {
    super(props);
    this.state = {
      fleetData: '',
      summaryData: '',
    }
  }

  componentDidMount() {
    // this.getFleetData()
    this.getSummaryData()
  }



  getFleetData = () => {
    ApiUrlCall.getFleetSummarySetting().then(response => {
      if (response.data.response_code === 200 && response.data.is_success === true) {
        this.setState({
          fleetData: response.data.data
        })
      }
    }).catch(error => {
      console.log('error while fetching fleet data', error)
    })
  }

  getSummaryData = () => {
    ApiUrlCall.widgetFleetSummary().then(response => {
      // console.log('response is', response)  
      if (response.data.response_code === 200 && response.data.is_success === true) {
        //console.log('fleet count is', response.data)
        this.setState({
          summaryData: response.data.data
        })
      }
    }).catch(error => {
      console.log('error while fetching fleet summary data', error)
    })
  }

  render() {
    let { fleetData, summaryData } = this.state
    const { t } = this.props
    return (

      <div className="cardView button_parent" onClick={() => {
        this.props.data.history.push("/list_vehicle")
      }} style={{ cursor: "pointer" }}>
        <Grid container className="">
          <Grid item xs style={{ textAlign: "left" }}>
            <label className="widget-header">{t('dw.f.header')}</label>
          </Grid>
          <Grid item xs style={{ textAlign: "right", fontSize: "16px" }}>
            <b>{summaryData.total < 10 ? '0' + summaryData.total : summaryData.total}</b>&nbsp;&nbsp;<i><img src="images/vehicle-1.png" height="30px" alt="icon"></img></i>
          </Grid>
        </Grid>
        <hr className="custom-hr" />
        <div className="fs_parent_div">
          <Grid container className="wid_fs_parent" id="moving_data">
            <Grid container item xs={6} className="wid_fs">
              <p className="count_style">{summaryData.in_transit < 10 ? '0' + summaryData.in_transit : summaryData.in_transit}</p>
              <p className="status_vehicle">{t('dw.f.intransit')}</p>
            </Grid>
            <Grid container item xs={6} className="wid_fs">
              <p className="count_style">{summaryData.assigned_vehicles < 10 ? '0' + summaryData.assigned_vehicles : summaryData.assigned_vehicles}</p>
              <p className="status_vehicle">{t('dw.f.ass')}</p>
            </Grid>
            <Grid container item xs={6} className="wid_fs">
              <p className="count_style">{summaryData.unassigned_vehicles < 10 ? '0' + summaryData.unassigned_vehicles : summaryData.unassigned_vehicles}</p>
              <p className="status_vehicle">{t('dw.f.uass')}</p>
            </Grid>
            <Grid container item xs={6} className="wid_fs">
              <p className="count_style">{summaryData.servicing < 10 ? '0' + summaryData.servicing : summaryData.servicing}</p>
              <p className="status_vehicle">{t('dw.f.ser')}</p>
            </Grid>
          </Grid>
        </div>
      </div>

    );
  }
}


class MapView extends Component {
  interval = 0;
  _exporter1_table;
  _exporter;

  constructor(props) {
    super(props);
    this.state = {


      open: false,
      clusternameOpen: false,

      vehicleLiveTrack: '',
      dgvehicleLiveTrack: '',
      vehicleLiveTrack_cluster: [],
      clusterData: [],// State to hold cluster data,

      vehicleLiveTrackStatus: '',
      selectedCluster: '',
      selectedCircle: '',
      isLoading: false,

      vehicleLiveTrackDGStatus: '',
      getAllDigiState_excel_data: [],
      vehicle_getAllDigiState_excel_data: [],
      getAllNormalUserState_excel: [],
      getAllNormalUserState_excel_data: [],

      markers: [],
      mapIntialized: true,
      movingCount: 0,
      idlingCount: 0,
      parkedCount: 0,

      startCount: 0,
      shutdownCount: 0,
      connection_lostCount: 0,
      dgconnection_lostCount: 0,
      boundLat: [],
      boundLng: [],
      selectedOption: ALL,
      boundFlag: true,
      clickMessage: "",
      nextCountFormap: 0,

      nextCountFormapdg: 0,
      isEnd: false,
      dgisEnd: false,
      all_count: 0,
      dgall_count: 0,
      isDGView: false, // Add this state variable
      isPeopleView: false, // peopleView
      date: new Date(),
      isVehicleView: true, // Initially set to true to show Vehicle View
      activeView: 'Vehicle_view',

    }
  }

  handleChange = (event) => {
    const selectedCluster = event.target.value;
    this.setState({ selectedCluster }, () => {
      this.getVehicleMarker(false);
    });
  };

  handleChange = (event) => {
    const selectedCircle = event.target.value;
    this.setState({ selectedCircle }, () => {
      this.getVehicleMarker(false);
    });
  };

  componentDidMount() {
    $('.map_loading').show();
    this.setIntervalLiveData();
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  componentDidCatch(error, errorInfo) {
    // console.log(error, 'component caught error', errorInfo);
  }

  setIntervalLiveData = () => {
    // console.log("first call");
    this.getVehicleMarker();
    this.dgCluster_reports();
    // this.interval = setInterval(this.getVehicleMarker, 10000);
  };

  clearDataFetchingInterval = () => {
    clearInterval(this.interval);
  };

  startVehicleDataFetching = () => {
    // console.log("second call");
    this.getVehicleMarker();
    this.dgCluster_reports();
    // this.interval = setInterval(this.getVehicleMarker, 10000);
  };

  startDGDataFetching = () => {
    //  console.log("DG data fetching call");
    this.getVehicleMarkerDG();
    // this.interval = setInterval(this.getVehicleMarkerDG, 10000);
  };


  // };



  dataViewClick = (e) => {

    const vehicleClick = "Vehicle_view";
    const dgClick = "DG_view";
    const personClick = "Person_view";

    // Add/remove active class to tabs for UI indication
    $(e.currentTarget).addClass('active_widget_btn');
    $(e.currentTarget).removeClass('inactive_widget_btn');
    $(e.currentTarget).siblings().addClass('inactive_widget_btn');
    $(e.currentTarget).siblings().removeClass('active_widget_btn');

    // Clear any ongoing data fetching intervals to prevent overlap
    this.clearDataFetchingInterval();

    const isZoomedIn = $('body').css('pointerEvents') === 'none';

    if (e.currentTarget.id === vehicleClick) {
      // Switch to Vehicle View
      document.getElementById("vehicle_view_div").style.display = "block";
      document.getElementById("DG_tabs").style.display = "none";
      document.getElementById("legend").style.display = "block";  // Show the legend
      document.getElementById("DGviewed").style.display = "none";  // Hide DGviewed
      this.setState({ activeView: "Vehicle_view", isDGView: false, isPeopleView: false, markers: [] }, () => {
        this.startVehicleDataFetching();
      });
    } else if (e.currentTarget.id === dgClick) {
      // Switch to DG View
      document.getElementById("DG_tabs").style.display = "block";
      document.getElementById("legend").style.display = "none";  // Hide the legend
      document.getElementById("DGviewed").style.display = "block";  // Show DGviewed
      document.getElementById("vehicle_view_div").style.display = "none";
      this.setState({ activeView: "DG_view", isDGView: true, markers: [] }, () => {
        this.startDGDataFetching();
      });
    }
    
  };


  switchToVehicleView = () => {
    $('#processing').show();
    
    this.setState.activeView="Vehicle_view";
    ApiUrlCall.ptDefaultView(JSON.stringify({ default_view: 1 }))
      .then(response => {
        if (response.data.response_code === 200 && response.data.is_success) {
          localStorage.setItem('default_view', 1);
          setTimeout(() => {
            window.location.reload(false);
          }, 100);
        }
      })
      .catch(error => console.error("Error switching to vehicle view:", error));
  };

  switchToPeopleTrackingView = () => {
    this.setState.activeView="Person_view";
    $('#processing').show();
    ApiUrlCall.ptDefaultView(JSON.stringify({ default_view: 2 }))
      .then(response => {
        if (response.data.response_code === 200 && response.data.is_success) {
          localStorage.setItem('default_view', 2);
          setTimeout(() => {
            window.location.reload(false);
          }, 100);
        }
      })
      .catch(error => console.error("Error switching to people tracking view:", error));
  };








  allVehicle = (e) => {
    $(e.currentTarget).addClass('selected_tab')
    $(e.currentTarget).parent().siblings().children().removeClass('selected_tab')
    this.setState({
      markers: [],
      selectedOption: ALL,
      boundFlag: true,
    }, () => {
      //this.updateMarker(ALL)
      this.createMarker(ALL)
    })
  }


  movingVehicle = (e) => {
    if (this.state.movingCount > 0) {
      $(e.currentTarget).addClass('selected_tab')
      $(e.currentTarget).parent().siblings().children().removeClass('selected_tab')
      this.setState({
        markers: [],
        selectedOption: [MOVING, OVERSPEEDING],
        clickMessage: "",
        boundFlag: true,
      }, () => {
        this.createMarker([MOVING, OVERSPEEDING])
      })
    } else {
      this.setState({
        clickMessage: "Currently No Vehicle is in Moving State*"
      })
    }
  }

  idlingVehicle = (e) => {
    if (this.state.idlingCount > 0) {
      $(e.currentTarget).addClass('selected_tab')
      $(e.currentTarget).parent().siblings().children().removeClass('selected_tab')
      this.setState({
        markers: [],
        selectedOption: [IDLING],
        clickMessage: "",
        boundFlag: true,
      }, () => {
        this.createMarker([IDLING])
      })
    } else {
      this.setState({
        clickMessage: "Currently No Vehicle is in Idling State*"
      })
    }
  }


  parkedVehicle = (e) => {
    if (this.state.parkedCount > 0) {
      $(e.currentTarget).addClass('selected_tab')
      $(e.currentTarget).parent().siblings().children().removeClass('selected_tab')
      this.setState({
        markers: [],
        selectedOption: [PARKED],
        clickMessage: "",
        boundFlag: true,
      }, () => {
        this.createMarker([PARKED])
      })
    } else {
      this.setState({
        clickMessage: "Currently No Vehicle is in Parked State*"
      })
    }
  }


  // ******************************************************  DG ***************************************************************
  DgallVehicle = (e) => {
    $(e.currentTarget).addClass('selected_tab')
    $(e.currentTarget).parent().siblings().children().removeClass('selected_tab')
    this.setState({
      markers: [],
      selectedOption: ALL,
      boundFlag: true,
    }, () => {
      //this.updateMarker(ALL)
      this.createMarkerDG(ALL)
    })
  }

  starts = (e) => {
    //  console.log("start button ")
    if (this.state.startCount > 0) {
      $(e.currentTarget).addClass('selected_tab')
      $(e.currentTarget).parent().siblings().children().removeClass('selected_tab')
      this.setState({
        markers: [],
        selectedOption: [START],
        clickMessage: "",
        boundFlag: true,
      }, () => {
        this.createMarkerDG([START])
      })
    } else {
      this.setState({
        clickMessage: "Currently No Vehicle is in Start State*"
      })
    }
  }

  shutdowns = (e) => {
    // console.log("shutdowns")
    if (this.state.shutdownCount > 0) {
      $(e.currentTarget).addClass('selected_tab')
      $(e.currentTarget).parent().siblings().children().removeClass('selected_tab')
      this.setState({
        markers: [],
        selectedOption: [SHUTDOWN],
        clickMessage: "",
        boundFlag: true,
      }, () => {
        this.createMarkerDG([SHUTDOWN])
      })
    } else {
      this.setState({
        clickMessage: "Currently No Vehicle is in Shutdown State*"
      })
    }
  }




  connectionlostVehicle = (e) => {
    if (this.state.connection_lostCount > 0) {
      $(e.currentTarget).addClass('selected_tab')
      $(e.currentTarget).parent().siblings().children().removeClass('selected_tab')
      this.setState({
        markers: [],
        selectedOption: [CONNECTION_LOST],
        clickMessage: "",
        boundFlag: true,
      }, () => {
        this.createMarker([CONNECTION_LOST])
        //this.updateMarker([PARKED])
      })
    } else {
      this.setState({
        clickMessage: "Currently No Vehicle is in Connection Lost State*"
      })
    }
  }


  dgconnectionlostVehicle = (e) => {
    if (this.state.dgconnection_lostCount > 0) {
      $(e.currentTarget).addClass('selected_tab')
      $(e.currentTarget).parent().siblings().children().removeClass('selected_tab')
      this.setState({
        markers: [],
        selectedOption: [ALL_DGCONNECTION_LOST],
        clickMessage: "",
        boundFlag: true,
      }, () => {
        this.createMarkerDG([ALL_DGCONNECTION_LOST])
      })
    } else {
      this.setState({
        clickMessage: "Currently No Vehicle is in Connection Lost State*"
      })
    }
  }

  stateCount = (state_markers) => {
    var moving = 0
    var idling = 0
    var parked = 0
    var connection_lostCount = 0


    if (state_markers) {
      for (let i = 0; i < state_markers.length; i++) {
        if (state_markers[i].tracker_state === CONNECTION_LOST) {
          connection_lostCount = connection_lostCount + 1
        }
        else if (state_markers[i].vehicle_state === MOVING || state_markers[i].vehicle_state === OVERSPEEDING) {
          moving = moving + 1
        } else if (state_markers[i].vehicle_state === IDLING) {
          idling = idling + 1
        }
        else if (state_markers[i].vehicle_state === PARKED || state_markers[i].vehicle_state === PARKED_UNKNOWN) {
          parked = parked + 1
        }
      }
    }
    this.setState({
      movingCount: moving,
      idlingCount: idling,
      parkedCount: parked,
      connection_lostCount: connection_lostCount,
    })
  }



  stateCountdg = (dgstate_markers) => {
    var start = 0
    var shutdown = 0
    var connection_lostDg = 0
    // console.log("hello")

    if (dgstate_markers) {
      //console.log("********")

      for (let i = 0; i < dgstate_markers.length; i++) {
        if (dgstate_markers[i].vehicle_state === ALL_DGCONNECTION_LOST) {
          connection_lostDg = connection_lostDg + 1
        }
        else if (dgstate_markers[i].vehicle_state === START) {


          start = start + 1
          //   console.log("start",start)
        }
        else if (dgstate_markers[i].vehicle_state === SHUTDOWN) {
          //  console.log("shutdown" ,shutdown)
          shutdown = shutdown + 1
        }

      }
    }
    this.setState({

      startCount: start,
      shutdownCount: shutdown,
      dgconnection_lostCount: connection_lostDg,


    })
  }

  getAddress1 = (objectvehicle, index) => {
    let addressLatLng = { lat: objectvehicle.lat, lng: objectvehicle.lng }
    ApiUrlCall.getAddressMMI(JSON.stringify(addressLatLng)).then(response => {
      objectvehicle["address"] = response.data.data.address
      let newvehicleListtrack = [...this.state.vehicleLiveTrack]

      newvehicleListtrack[index]["address"] = response.data.data.address
      this.setState({
        vehicleLiveTrack: newvehicleListtrack
      })
    })
  }

  updateMarker = (vstate) => {
    let timeduration = this.state.vehicleLiveTrack.length * 300
    for (let i = 0; i < this.state.vehicleLiveTrack.length; i++) {
      this.getAddress1(this.state.vehicleLiveTrack[i], i)
    }
    setTimeout(function () {
      this.createMarker(ALL)
    }.bind(this), timeduration)
  }


  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedCircle !== this.state.selectedCircle) {
      // Handle state change for selectedCircle
      $('.map_loading').show(); // Show loading indicator for Circle update
      this.getVehicleMarker(false); // Fetch vehicle marker data when the circle changes
    }

    if (prevState.selectedCluster !== this.state.selectedCluster) {
      // Handle state change for selectedCluster
      $('.map_loading').show();
      this.getVehicleMarker(false);
    }
  }


  getVehicleMarker = (isUpdate) => {
    if (this.state.isDGView) return;
    if (this.state.isPeopleView) return;


    const { nextCountFormap, selectedCluster, selectedCircle } = this.state;
    const group = localStorage.getItem('group');

    /// console.log('Group from localStorage:', group);
    // console.log('nextCountFormap:', nextCountFormap);
    // console.log('selectedCluster:', selectedCluster);
    // console.log('selectedCircle:', selectedCircle);

    // Check the group and call the appropriate method
    const liveTrackingMethod = (group == 32 || group == 33)
      ? ApiUrlCall.getAllVehicleLiveTrackingNewIndus
      : ApiUrlCall.getAllVehicleLiveTrackingNewNormal;

    // console.log('Calling liveTrackingMethod based on group:', liveTrackingMethod);

    liveTrackingMethod({ next_count: nextCountFormap, dg_cluster_name: selectedCluster, selected_Circle: selectedCircle })
      .then(response => {
        //  console.log('Live tracking response:', response.data);
        if (response.data.response_code === 200 && response.data.is_success) {
          this.setState({
            vehicleLiveTrack: response.data.data.live_track,
            nextCountFormap: response.data.data.next_count,
            isEnd: response.data.data.is_end,
            all_count: response.data.data.total_count,
          }, () => {
            //     console.log('Vehicle Live Track set in state:', this.state.vehicleLiveTrack);
            if (this.state.isEnd) {
              this.onFeatchVehiclesCompletion();
            } else {
              this.getVehicleMarker();
            }

            if (this.state.vehicleLiveTrack) {
              //     console.log('Creating marker with vehicle data:', this.state.vehicleLiveTrack);
              this.createMarker(this.state.selectedOption, isUpdate);
            }
          });
        }
      })
      .catch(error => {
        console.log('Error while fetching live tracking data:', error.response);
        this.getVehicleMarker(); // Retrying the fetch
      })
      .finally(() => {
        //  console.log('Live tracking request completed');
        $('.map_loading').hide();
        $('.map_loadings').hide();
      });

    const vehicleStateMethod = (group == 32 || group == 33)
      ? ApiUrlCall.getAllVehicleStateIndus
      : ApiUrlCall.getAllVehicleStateNormal;

    // console.log('Calling vehicleStateMethod based on group:', vehicleStateMethod);

    vehicleStateMethod({ dg_cluster_name: selectedCluster, selected_Circle: selectedCircle })
      .then(response => {
        //     console.log('Vehicle state response:', response.data);
        if (response.data.response_code === 200 && response.data.is_success) {
          this.setState({
            vehicleLiveTrackStatus: response.data.data.live_track,
          }, () => {
            //   console.log('Vehicle Live Track Status set in state:', this.state.vehicleLiveTrackStatus);
            if (this.state.vehicleLiveTrackStatus) {
              this.stateCount(this.state.vehicleLiveTrackStatus);
            }
          });
        }
      })
      .catch(error => {
        console.log('Error while fetching vehicle state data:', error.response);
        if (error.response && error.response.status === 404) {
          console.error("The API endpoint was not found. Please check the URL or contact the backend team.");
        }
      });
  }


  dgCluster_reports = () => {
    var user_id = localStorage.getItem("user_id")
    ApiUrlCall.cluster_Name(user_id).then(response => {
      if (response.data.response_code == 200) {

        this.setState({
          vehicleLiveTrack_cluster: response.data.data,
          clusterData: response.data.data,
        })

      }
    })
  }

  onFeatchVehiclesCompletion = () => {
    setTimeout(() => {
      this.setState({
        nextCountFormap: 0,
        isEnd: false,
        vehicleLiveTrack: [],

      }, () => {
        this.getVehicleMarker(true);
      });
    }, 5000);
  }


  getVehicleMarkerDG = (isUpdate) => {

    if (!this.state.isDGView) return; // Skip if not in DG view

    this.setState({ isLoading: true }); // Show loading spinner

    const { nextCountFormapdg, selectedCluster, selectedCircle } = this.state;

    //console.log("Dg count",nextCountFormapdg,"selectedCluster=",selectedCluster);

    ApiUrlCall.getAllVehicleLiveTrackingDGreports({ next_count: nextCountFormapdg, dg_cluster_name: selectedCluster, selected_Circle: selectedCircle }).then(response => {

      //  console.log("DG Api responce ", response)

      if (response.data.response_code === 200 && response.data.is_success) {
        this.setState({
          dgvehicleLiveTrack: response.data.data.report_list,

          nextCountFormapdg: response.data.data.next_count,
          dgisEnd: response.data.data.is_end,
          dgall_count: response.data.data.total_count
        }, () => {
          $('.map_loading').hide();
          $('.map_loadings').hide();
          if (this.state.dgisEnd) {
            this.onFeatchVehiclesCompletionDG();
          } else {
            this.getVehicleMarkerDG();
          }
          if (this.state.dgvehicleLiveTrack) {
            this.createMarkerDG(this.state.selectedOption, isUpdate);
            this.stateCountdg(this.state.dgvehicleLiveTrack);

          }
        });
      }
    }).catch(error => {
      console.log('error while fetching data is', error.response);
      this.getVehicleMarkerDG();
    })
      .finally(() => {
        this.setState({ isLoading: false }); // Hide loading spinner
      });



  }

  onFeatchVehiclesCompletionDG = () => {
    //console.log('onFeatchVehiclesCompletionDG DGGGGGG');
    setTimeout(() => {
      this.setState({
        nextCountFormapdg: 0,
        dgisEnd: false,
        dgvehicleLiveTrack: [],
        vehicleLiveTrack: [],
      }, () => {
        this.getVehicleMarkerDG(true);
      });
    }, 5000);
  }




  createMarker = (vehicleState, isUpdate) => {
    let array = []
    if (isUpdate == undefined) {
      array = [...this.state.markers]
    }
    const icon = [MOVING_ICON, IDLING_ICON, PARKED_ICON, CONNECTION_LOST_ICON]
    let liveVehicleData = this.state.vehicleLiveTrack
    if (this.state.vehicleLiveTrack) {
      for (let i = 0; i < liveVehicleData.length; i++) {
        if (vehicleState.includes(liveVehicleData[i].vehicle_state)) {
          var data = { name: this.state.vehicleLiveTrack[i].name }

          array.push(
            {
              position: [this.state.vehicleLiveTrack[i].lat, this.state.vehicleLiveTrack[i].lng],
              rotateMarker: true,
              icon: L.icon({
                iconUrl: ApiUrlCall.getVehicleIcon(this.state.vehicleLiveTrack[i].vehicle_type_id,
                  this.state.vehicleLiveTrack[i].vehicle_state, this.state.vehicleLiveTrack[i].tracker_state),
                iconRetinaUrl: ApiUrlCall.getVehicleIcon(this.state.vehicleLiveTrack[i].vehicle_type_id,
                  this.state.vehicleLiveTrack[i].vehicle_state, this.state.vehicleLiveTrack[i].tracker_state),
                iconSize: [40, 40],
                popupAnchor: [-3, -15]
              }),
              draggable: false,
              setViewFlag: this.state.setView,
              title: this.state.vehicleLiveTrack[i].name,
              Last_Reported_Time: CommonFunction.tableDateFormate(this.state.vehicleLiveTrack[i].last_reported_time) + ', ' + CommonFunction.timeFormate(this.state.vehicleLiveTrack[i].last_reported_time),
              battery_percentage: this.state.vehicleLiveTrack[i].battery_percentage,
              serial_number: this.state.vehicleLiveTrack[i].serial_number,
              //   site_types: this.state.dgvehicleLiveTrack[i].site_type,
              latitude: this.state.vehicleLiveTrack[i].lat,
              longitude: this.state.vehicleLiveTrack[i].lng,
              popupData: data,
              vehicle_type_id: this.state.vehicleLiveTrack[i].vehicle_type_id,
              onClick: () => { },
              onDragend: () => { }
            }
          )
        }
      }
    }
    this.setState({
      markers: array,
    }, () => {
      this.boundMarkers()
    })
  }

  createMarkerDG = (vehicleState, isUpdate) => {

    //  console.log("createMarkerDG")
    let array = [];
    if (isUpdate === undefined) {
      array = [...this.state.markers];
    }
    const icon = [MOVING_ICON, START_ICON, DG_OFF_ICON, DG_LOST_ICON, PARKED_ICON,];
    let liveVehicleData = this.state.dgvehicleLiveTrack;

    if (this.state.dgvehicleLiveTrack) {
      for (let i = 0; i < liveVehicleData.length; i++) {
        if (vehicleState.includes(liveVehicleData[i].vehicle_state)) {
          var data = { name: this.state.dgvehicleLiveTrack[i].name };
          array.push({
            position: [this.state.dgvehicleLiveTrack[i].lat, this.state.dgvehicleLiveTrack[i].lng],
            rotateMarker: true,
            icon: L.icon({
              iconUrl: ApiUrlCall.getVehicleIcon(this.state.dgvehicleLiveTrack[i].vehicle_type_id,
                this.state.dgvehicleLiveTrack[i].vehicle_state, this.state.dgvehicleLiveTrack[i].tracker_state),
              iconRetinaUrl: ApiUrlCall.getVehicleIcon(this.state.dgvehicleLiveTrack[i].vehicle_type_id,
                this.state.dgvehicleLiveTrack[i].vehicle_state, this.state.dgvehicleLiveTrack[i].tracker_state),
              iconSize: [40, 20],
              popupAnchor: [-3, -15]
            }),
            draggable: false,
            setViewFlag: this.state.setView,
            title: this.state.dgvehicleLiveTrack[i].Name,

            Last_Reported_Time: CommonFunction.tableDateFormate(this.state.dgvehicleLiveTrack[i].last_reported_time) + ', ' + CommonFunction.timeFormate(this.state.dgvehicleLiveTrack[i].last_reported_time),

            //battery_percentage: this.state.dgvehicleLiveTrack[i].battery_percentage,
            serial_number: this.state.dgvehicleLiveTrack[i].serial_number_id,
            //   site_types: this.state.dgvehicleLiveTrack[i].site_type,
            latitude: this.state.dgvehicleLiveTrack[i].lat,
            longitude: this.state.dgvehicleLiveTrack[i].lng,
            popupData: data,
            vehicle_type_id: this.state.dgvehicleLiveTrack[i].vehicle_type,
            onClick: () => { },
            onDragend: () => { }
          });
        }
      }
    }
    this.setState({
      markers: array,
    }, () => {
      this.boundMarkers();
    });
  }
  exclereports = () => {
    ApiUrlCall.Dashbord_excel_dgreport({}).then(response => {
      //    console.log("excel responce ",response)

    })

  }

  zoomInMap = (e) => {
    $(e.currentTarget).parent().parent().parent().addClass('zoom_in_map')
    $(e.currentTarget).siblings().show()
    $(e.currentTarget).hide()
    // $('#legend').show()
    // $('#DGviewed').show()
    $('body').css({
      pointerEvents: "none",
      overflow: "hidden"
    })
    window.dispatchEvent(new Event('resize'));
  }

  zoomOutMap = (e) => {
    $(e.currentTarget).parent().parent().parent().removeClass('zoom_in_map')
    $(e.currentTarget).siblings().show()
    $(e.currentTarget).hide()
    // $('#legend').hide()
    // $('#DGviewed').hide()
    $('body').css({
      pointerEvents: "auto",
      overflow: "auto"
    })
    window.dispatchEvent(new Event('resize'));
  }





  boundMarkers = () => {
    var latitudeArr = []
    var longitudeArr = []
    if (this.state.markers.length > 0) {
      this.state.markers.map(b => {
        if (b.position && Array.isArray(b.position)) {
          latitudeArr.push(b.position[0])
          longitudeArr.push(b.position[1])
        }
        this.setState({
          boundLat: latitudeArr,
          boundLng: longitudeArr
        }, () => {
        })
      })
    }
  }

  // returnMap = () => {    
  //   const maps = []    
  //   if (this.state.markers) {
  //     if (this.state.markers.length > 0 && this.state.boundLat.length > 0 && this.state.boundLng.length > 0) {
  //       maps.push(
  //         <Map
  //           key={1}
  //           height={"82%"}
  //           markers={this.state.markers}
  //           bounds={[
  //             {
  //               lat: this.state.boundLat,
  //               lng: this.state.boundLng
  //             }
  //           ]}
  //           pauseBound = {this.state.boundFlag}
  //           cluster = {true}
  //           onZoom={() => {
  //             this.setState({
  //               boundFlag : false
  //             })
  //           }}
  //           onContextMenu={(e) => e.preventDefault()} // Disable right-click
  //         />

  //         {this.state.isPopupVisible && (
  //           <div className="popup-Overlay">
  //             <DashCam
  //               selectedCameraOptions={this.state.selectedCameraOptions} // Pass selected camera data
  //               onClose={this.closePopup} // Function to close popup

  //             />
  //           </div>
  //         )}


  //       )
  //     }
  //   }
  //   if (maps.length > 0) {
  //     return maps
  //   }
  // }
  handleMarkerRightClick = (cameraData) => {
    this.setState(
      {
        selectedCameraOptions: cameraData,
        isPopupVisible: true,
      },
      () => {
        console.log("Updated selectedCameraOptions:", this.state.selectedCameraOptions);
      }
    );
  };


  returnMap = () => {
    if (this.state.markers && this.state.markers.length > 0 &&
      this.state.boundLat !== undefined && this.state.boundLng !== undefined) {

      return (
        <> {/* ✅ React Fragment to avoid extra div */}
          <Map
            key={1}
            height={"82%"}
            markers={this.state.markers}
            onMarkerRightClick={this.handleMarkerRightClick}
            bounds={[
              { lat: this.state.boundLat, lng: this.state.boundLng }
            ]}
            pauseBound={this.state.boundFlag}
            cluster={true}
            onZoom={() => {
              this.setState({ boundFlag: false });
            }}
            onContextMenu={(e) => e.preventDefault()} // Disable right-click
          />

          {this.state.isPopupVisible && (
            <div className="popup-Overlay">
              <DashCam
                selectedCameraOptions={this.state.selectedCameraOptions}
                onClose={this.closePopup}
              />
            </div>
          )}
        </>
      );
    }

    return null; // ✅ Return null if no map should be rendered
  };

  to_save_in_excel_sheet = () => {
    this.save(this._exporter1_table);
  }


  createNormalUserExcelFile = () => {
    $('.map_loading').show();
    ApiUrlCall.getAllNormalUserState_excel({})
      .then((response) => {
        const data = response.data.data;
        console.log("Vehicle_View_Report_Normal User Data@@@@:", data);

        // Set the state with the correct data structure
        this.setState(
          { getAllNormalUserState_excel_data: data },
          () => {

            this.generateExcelReportNormalUser();
            $('.map_loading').hide();
          }
        );
      })
      .catch((error) => {
        console.error("Error while fetching data:", error);
      });
  };


  generateExcelReportNormalUser = () => {
    const { getAllNormalUserState_excel_data } = this.state;
    if (!Array.isArray(getAllNormalUserState_excel_data) || getAllNormalUserState_excel_data.length === 0) {
      console.error("No data available for Excel report generation.");
      return;
    }

    try {
      const ws = XLSX.utils.json_to_sheet(getAllNormalUserState_excel_data);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Vehicle_View_Report');
      const excel_name = `Vehicle_View_Report_${CommonFunction.downloadReportDate(this.state.date)}.xlsx`;
      XLSX.writeFile(wb, excel_name);
    } catch (error) {
      console.error("Error generating Excel file:", error);
    }
  };


  createExcelFile = () => {
    const { selectedCircle } = this.state;
    ApiUrlCall.getAllDigiState_excel({ selected_Circle: selectedCircle }).then(response => {
      const data = response.data;
      console.log("DG", data);
      this.setState(
        {
          getAllDigiState_excel_data: data
        },
        () => {
          this.generateExcelReport();
        }
      );
    })
      .catch(error => {
        console.log('Error while fetching data:', error);
      });
  };

  generateExcelReport = () => {
    const { getAllDigiState_excel_data } = this.state;

    // Convert data to a worksheet
    const ws = XLSX.utils.json_to_sheet(getAllDigiState_excel_data);

    // Create a new workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'DG_Report');

    // Generate a file name
    const excel_name = `DG_Report_${CommonFunction.downloadReportDate(this.state.date)}.xlsx`;

    // Write the workbook to a binary string
    XLSX.writeFile(wb, excel_name);
  };



  vehicle_createExcelFile = () => {
    const { selectedCluster } = this.state;
    ApiUrlCall.vehicle_getAllDigiState_excel({ dg_cluster_name: selectedCluster }).then(response => {

      const data = response.data;
      //console.log(data, "*****************");
      console.log("VH", data);

      this.setState(
        {
          vehicle_getAllDigiState_excel_data: data
        },
        () => {
          this.vehicle_generateExcelReport();
        }
      );
    })
      .catch(error => {
        console.log('Error while fetching data:', error);
      });
  };


  vehicle_generateExcelReport = () => {

    const { vehicle_getAllDigiState_excel_data } = this.state;

    // Convert data to a worksheet
    const ws = XLSX.utils.json_to_sheet(vehicle_getAllDigiState_excel_data);

    // Create a new workbook
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Vehicle_Report');

    // Generate a file name
    const excel_name = `vehicle_Report_${CommonFunction.downloadReportDate(this.state.date)}.xlsx`;

    // Write the workbook to a binary string
    XLSX.writeFile(wb, excel_name);
  };


  handleCircleChange = (event) => {
    this.setState({ selectedCircle: event.target.value, selectedCluster: '' });
  }

  handleClusterChange = (event) => {
    this.setState({ selectedCluster: event.target.value });
  }



  render() {
    const { t } = this.props;
    const { selectedCluster, selectedCircle, vehicleLiveTrack_cluster, clusterData } = this.state;
    const { open, clusternameOpen } = this.state;

    var excel_name = "DG_Report_" + CommonFunction.downloadReportDate(this.state.date) + ".xlsx";
    excel_name = "vehicle_Report_" + CommonFunction.downloadReportDate(this.state.date) + ".xlsx";

    var excel_name = "Vehicle_View_Report_" + CommonFunction.downloadReportDate(this.state.date) + ".xlsx";


    const circles = clusterData.map(item => item.circlename);
    const clusters = selectedCircle ? clusterData.find(item => item.circlename === selectedCircle).clustername : [];

    return (
      // this is dashboards map
      <div className="cardView button_parent">
        <div>
          <label className="widget-header">{t('vls.map')}</label>



          <Tooltip title="zoom in" placement="top-end">
            <div style={{ textAlign: "right", marginBottom: "8px" }}>
              <img src="images/expand.png" alt="icon" onClick={this.zoomInMap} style={{ cursor: "pointer" }} />
              <img src="images/expand.png" alt="icon" onClick={this.zoomOutMap} style={{ display: "none", cursor: "pointer" }} />
            </div>
          </Tooltip>

        </div>


        <Grid item xs={6} style={{ textAlign: "left" }}>
          <span className="cj_w_header" style={{ color: "#dc3545", float: "right" }}>{this.state.clickMessage}</span>
        </Grid>

        <div style={{ display: localStorage.getItem('group') == 32 || localStorage.getItem('group') == 33 ? "" : "none" }} className='buttons_div'>

          <Grid container>

            <Grid item xs={3} style={{ textAlign: "center" }}>

              <div className="dropdown">
                <select className="dropdown-select" value={selectedCircle} onChange={this.handleCircleChange}>
                  <option value="">Select a Circle</option>
                  {circles.map((circle, index) => (
                    <option key={index} value={circle}>
                      {circle}
                    </option>
                  ))}
                </select>
              </div>

            </Grid>

            <Grid item xs={3} style={{ textAlign: "center" }}>
              <div className="dropdown">
                <select className="dropdown-select" value={selectedCluster} onChange={this.handleClusterChange}>
                  <option value="">Select a Cluster</option>
                  {clusters.map((cluster, index) => (
                    <option key={index} value={cluster}>
                      {cluster}
                    </option>
                  ))}
                </select>
              </div>
            </Grid>

            <Grid item xs={6} style={{ display: localStorage.getItem('group') == 32 || localStorage.getItem('group') == 33 ? "" : "none", textAlign: "right", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <div className='dgbuttons'>
                <div className="toggle_btn">
                  <div className='excel_report_btn'>

                    {/* <img src="images/group-6-copy-2.png" id="vehicle_excel" onClick={this.vehicle_createExcelFile} style={{
                      display: this.state.activeView === "Vehicle_view" && localStorage.getItem('group') == 32 ? "" : "none",
                      cursor: "pointer",
                      height:"20px", width:"20px",
                      marginRight: "30px",
                    }} />  */}

                    <img src="images/group-6-copy-2.png" id="DG_excel" onClick={this.createExcelFile}
                      style={{
                        display: this.state.activeView === "DG_view" && localStorage.getItem('group') == 32 || localStorage.getItem('group') == 33 ? "" : "none",
                        cursor: "pointer",
                        height: "20px", width: "20px",
                        marginRight: "30px",
                      }} />
                  </div>
                  <div className="graph_view_btn" >
                    <div className="active_widget_btn" style={{ display: "inline-block" }} onClick={this.dataViewClick} id="Vehicle_view">
                      <label>Vehicle View</label>
                    </div>
                    <div className="inactive_widget_btn" style={{ display: "inline-block" }} onClick={this.dataViewClick} id="DG_view">
                      <label>DG View</label>
                    </div>
                  </div>

                </div>
              </div>
            </Grid>
          </Grid>
        </div>

        <div style={{ display: localStorage.getItem('group') == 37 ? "" : "none" }} className='buttons_div'>

          <Grid container>


            <Grid item xs={12} style={{ display: localStorage.getItem('group') == 37 ? "" : "none", textAlign: "right", display: "flex", justifyContent: "right", alignItems: "center" }}>
              <div className='dgbuttons'>
                <div className="toggle_btn">
                  <div className='excel_report_btn'>

                    {/* <img src="images/group-6-copy-2.png" id="vehicle_excel" onClick={this.vehicle_createExcelFile} style={{
                      display: this.state.activeView === "Vehicle_view" && localStorage.getItem('group') == 32 ? "" : "none",
                      cursor: "pointer",
                      height:"20px", width:"20px",
                      marginRight: "30px",
                    }} />  */}

                    <img src="images/group-6-copy-2.png" id="PT_excel" onClick={this.createExcelFile}
                      style={{
                        display: this.state.activeView === "Person_view" && localStorage.getItem('group') == 37 ? "" : "none",
                        cursor: "pointer",
                        height: "20px", width: "20px",
                        marginRight: "30px",
                      }} />
                  </div>
                  <div className="graph_view_btn" >
                    <div className="active_widget_btn" style={{ display: "inline-block" }} onClick={this.switchToVehicleView} id="Vehicle_view">
                      <label>Vehicle View</label>
                    </div>
                    <div className="inactive_widget_btn" style={{ display: "inline-block" }} onClick={this.switchToPeopleTrackingView} id="Person_view">
                      <label>Person View</label>
                    </div>
                  </div>


                </div>
              </div>
            </Grid>
          </Grid>

          {this.state.isPeopleView && <PeopleTrackingMapView />}
        </div>





        {/* Download and Vehicle View buttons specifically for group 1, aligned on the right */}
        {/* {localStorage.getItem('group') === '1' && (
      <Grid container justifyContent="flex-end" alignItems="center" style={{ marginTop: "5px" }}>
        
     
        <Grid item style={{ marginRight: "10px" }}>
          <img 
            src="images/group-6-copy-2.png" 
            alt="Download Report"
            onClick={this.createNormalUserExcelFile} 
            style={{ cursor: "pointer", height: "20px", width: "20px" }}
          />
        </Grid>
        <Grid item>
          <div className="active_widget_btn" style={{ display: "inline-block" }} onClick={this.dataViewClick} id="Vehicle_view_group1">
            <label>Vehicle View</label>
          </div>
        </Grid>

      </Grid>
    )}
 */}

        {(localStorage.getItem('group') === '1' || localStorage.getItem('group') === '34') && (
          <Grid container justifyContent="flex-end" alignItems="center" style={{ marginTop: "5px" }}>

            {/* Download Button */}
            <Grid item style={{ marginRight: "10px" }}>
              <img
                src="images/group-6-copy-2.png"
                alt="Download Report"
                onClick={this.createNormalUserExcelFile}
                style={{ cursor: "pointer", height: "20px", width: "20px" }}
              />
            </Grid>


            <Grid item>
              <div className="active_widget_btn" style={{ display: "inline-block" }} onClick={this.dataViewClick} id="Vehicle_view_group1">
                <label>Vehicle View</label>
              </div>
            </Grid>

          </Grid>
        )}



        <div>
          <div id="vehicle_view_div">
            <Grid container>
              <Grid item xs>
                <div onClick={this.allVehicle} className="vehicle_state selected_tab">
                  <p className="p_margin0">{t('ct.all.text')}&nbsp;<span>{this.state.all_count < 9 ? '0' + this.state.all_count : this.state.all_count}</span></p>
                </div>
              </Grid>
              <Grid item xs>
                <div onClick={this.movingVehicle} className="vehicle_state">
                  <p className="p_margin0">{t('ct.table.h14')}&nbsp;<span>{this.state.movingCount <= 9 ? '0' + this.state.movingCount : this.state.movingCount}</span></p>
                </div>
              </Grid>
              <Grid item xs>
                <div onClick={this.idlingVehicle} className="vehicle_state">
                  <p className="p_margin0">{t('ct.table.h15')}&nbsp;<span>{this.state.idlingCount <= 9 ? '0' + this.state.idlingCount : this.state.idlingCount}</span></p>
                </div>
              </Grid>
              <Grid item xs>
                <div onClick={this.parkedVehicle} className="vehicle_state">
                  <p className="p_margin0">{t('ct.table.h16')}&nbsp;<span>{this.state.parkedCount <= 9 ? '0' + this.state.parkedCount : this.state.parkedCount}</span></p>
                </div>
              </Grid>
              <Grid item xs>
                <div onClick={this.connectionlostVehicle} className="vehicle_state">
                  <p className="p_margin0">{t('Connection Lost')}&nbsp;<span>{this.state.connection_lostCount <= 9 ? '0' + this.state.connection_lostCount : this.state.connection_lostCount}</span></p>
                </div>
              </Grid>
            </Grid>
          </div>
          <div id="DG_tabs" style={{ display: "none" }}>
            <Grid container>
              <Grid item xs>
                <div onClick={this.DgallVehicle} className="vehicle_state selected_tab">
                  <p className="p_margin0">DG ALL&nbsp;<span>{this.state.dgall_count < 9 ? '0' + this.state.dgall_count : this.state.dgall_count}</span></p>
                </div>
              </Grid>
              <Grid item xs>
                <div onClick={this.starts} className="vehicle_state">
                  <p className="p_margin0">Start &nbsp;<span>{this.state.startCount <= 9 ? '0' + this.state.startCount : this.state.startCount}</span></p>
                </div>
              </Grid>
              <Grid item xs>
                <div onClick={this.shutdowns} className="vehicle_state">
                  <p className="p_margin0">Stop &nbsp;<span>{this.state.shutdownCount <= 9 ? '0' + this.state.shutdownCount : this.state.shutdownCount}</span></p>
                </div>
              </Grid>
              <Grid item xs>
                <div onClick={this.dgconnectionlostVehicle} className="vehicle_state">
                  <p className="p_margin0">DG Connection Lost &nbsp;<span>{this.state.dgconnection_lostCount <= 9 ? '0' + this.state.dgconnection_lostCount : this.state.dgconnection_lostCount}</span></p>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>



        <div className="statistic_container3" id="legend" style={{ display: "block" }}>
          <div id="search_legend_container" style={{ marginBottom: "10px" }}>
            <div className="legend_inline">
              <div className="legend_item_inline">
                <div className="legend_4"></div>
                <p className="legend_text_map"><b>Moving</b></p>
              </div>
              <div className="legend_item_inline">
                <div className="legend_77"></div>
                <p className="legend_text_map"><b>Parked</b></p>
              </div>
              <div className="legend_item_inline">
                <div className="legend_5"></div>
                <p className="legend_text_map"><b>Idle</b></p>
              </div>
              <div className="legend_item_inline">
                <div className="legend_9"></div>
                <p className="legend_text_map"><b>Connection Lost</b></p>
              </div>
            </div>
          </div>
        </div>

        <div className="statistic_container4" id="DGviewed" style={{ display: "none" }}>
          <div id="search_legend_container" style={{ marginBottom: "10px" }}>
            <div className="legend_inline">
              <div className="legend_item_inline">
                <div className="legend_4"></div>
                <p className="legend_text_map"><b>Start</b></p>
              </div>
              <div className="legend_item_inline">
                <div className="legend_77"></div>
                <p className="legend_text_map"><b>Stop</b></p>
              </div>
              <div className="legend_item_inline">
                <div className="legend_9"></div>
                <p className="legend_text_map"><b>Connection Lost</b></p>
              </div>
            </div>
          </div>
        </div>

        <div className="widget-mapview">
          {this.returnMap()}
          <p className="no_data_map" style={{ display: this.state.markers.length > 0 ? "none" : "" }}>Updating Current Status.....</p>
        </div>
        <div className="map_loading">
          <CircularProgress className="map_loading_icon" />
        </div>
        <div className="map_loadings">
          <CircularProgress className="map_loading_icons" />
        </div>

      </div>
    );
  }

}





class OverallVehicleHealth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vehicleExpenseData: '',
    }
  }

  componentDidMount() {
    this.getVehicleExpense()
  }

  getVehicleExpense = () => {
    ApiUrlCall.widgetVehicleExpense().then(response => {
      if (response.data.response_code === 200 && response.data.is_success === true) {
        this.setState({
          vehicleExpenseData: response.data.data
        }, () => {

        })
      }
    }).catch(error => {
      console.log('error while fetching vehicle expense data is', error)
    })
  }

  render() {
    const { t } = this.props
    if (this.state.vehicleExpenseData) {
      var data = this.state.vehicleExpenseData
      var vehicleCount = (() => {
        if (data.vehicle_count < 10) {
          return '0' + data.vehicle_count
        } else {
          return data.vehicle_count
        }
      })()
      var totalBills = (() => {
        if (data.total_bills < 10) {
          return '0' + data.total_bills
        } else {
          return data.total_bills
        }
      })()
      var totalExpense = (() => {
        if (data.total_expense < 10) {
          return '0' + data.total_expense
        } else {
          return data.total_expense
        }
      })()
    }

    return (

      <div className="cardView button_parent">
        <Grid container className="">
          <Grid item xs style={{ textAlign: "left" }}>
            <label className="widget-header">{t('dw.exp.header')}</label>
          </Grid>

        </Grid>

        <hr className="custom-hr" />
        <Grid container className="driver_aval_parent">
          <Grid item xs={4} style={{ borderRight: "1px solid #cccccc" }}>
            <img src="images/we_vehicle_count.png" alt="icon"></img>
            <p className="count_style">{vehicleCount}</p>
            <p className="status_vehicle">{t('dw.exp.count')}</p>
          </Grid>
          <Grid item xs={4} style={{ borderRight: "1px solid #cccccc" }}>
            <img src="images/w_total_bills.png" alt="icon"></img>
            <p className="count_style">{totalBills}</p>
            <p className="status_vehicle">{t('dw.exp.bill')}</p>
          </Grid>
          <Grid item xs={4}>
            <img src="images/w_total_exp.png" height="42px" alt="icon"></img>
            <p className="count_style" style={{ wordBreak: "break-all" }}>{totalExpense}</p>
            <p className="status_vehicle">{t('dw.exp.total')}</p>
          </Grid>
        </Grid>
        <button className="btn orange-btn view_button" onClick={() => {
          this.props.data.history.push('/reports')
        }}>{t('dw.exp.view')}</button>
      </div>

    );
  }
}

class DriverEfficiency extends Component {
  constructor(props) {
    super(props);
    this.state = {
      driverAvailableData: ''
    }
  }

  componentDidMount() {
    this.getDriverAvailableData()
  }

  getDriverAvailableData = () => {
    ApiUrlCall.widgetDriverAvailabilty().then(response => {
      if (response.data.response_code === 200 && response.data.is_success === true) {
        this.setState({
          driverAvailableData: response.data.data
        })
      }
    }).catch(error => {
      console.log('error while fetching diver availability', error.response)
    })
  }

  render() {
    const { t } = this.props
    if (this.state.driverAvailableData) {
      var availableDriver = (() => {
        if (this.state.driverAvailableData.available_driver < 10) {
          return '0' + this.state.driverAvailableData.available_driver
        } else {
          return this.state.driverAvailableData.available_driver
        }
      })()
      var totalDrvier = (() => {
        if (this.state.driverAvailableData.total_driver < 10) {
          return '0' + this.state.driverAvailableData.total_driver
        } else {
          return this.state.driverAvailableData.total_driver
        }
      })()
    }


    return (

      <div className="cardView button_parent" onClick={() => {
        this.props.data.history.push("/driver_list")
      }} style={{ cursor: "pointer" }}>
        <Grid container className="">
          <Grid item xs style={{ textAlign: "left" }}>
            <label className="widget-header">{t('dw.dr.header')}</label>
          </Grid>

        </Grid>
        <hr className="custom-hr" />
        <Grid container className="driver_aval_parent">
          <Grid item xs={6} style={{ borderRight: "1px solid #cccccc" }}>
            <img src="images/w_total_driver.png" alt="icon"></img>
            <p className="count_style">{totalDrvier}</p>
            <p className="status_vehicle">{t('dw.dr.total')}</p>
          </Grid>
          <Grid item xs={6}>
            <img src="images/w_available_driver.png" alt="icon"></img>
            <p className="count_style">{availableDriver}</p>
            <p className="status_vehicle">{t('dw.dr.avail')}</p>
          </Grid>
        </Grid>
      </div>

    );
  }
}

class VehicleFitness extends Component {
  constructor(props) {
    super(props);
    this.state = {
      driverDetails: '',
    }
  }

  componentDidMount() {
    this.getDriverScore()
  }

  getDriverScore = () => {
    ApiUrlCall.widgetDriverScore().then(response => {
      if (response.data.response_code === 200 && response.data.is_success === false) {
        // console.log('>>>>>>>>>>>', response.data.data)
        this.setState({
          driverDetails: response.data.data.score_list
        })
      }
    }).catch(error => {
      console.log('error while getting driver score', error.response.data)
    })
  }
  render() {
    const { t } = this.props
    if (this.state.driverDetails) {
      var driverData = this.state.driverDetails.map(driverData => {
        return (
          <Grid container item xs={6} key={driverData.driver_id} id={driverData.driver_id} className="wid_driver_score">
            <Grid item xl={3} xs={6}>
              <div className="driver_img_div">
                <img src={driverData.driver_pic !== "" ? driverData.driver_pic : "images/group-8.png"} alt=""></img>
              </div>
            </Grid>
            <Grid item xl={9} xs={6}>
              <p>{driverData.name}</p>
              <p><b>{driverData.score} {t('dw.score')}</b></p>
            </Grid>
          </Grid>
        )
      })
    }
    return (

      <div className="cardView button_parent">
        <Grid container className="">
          <Grid item xs style={{ textAlign: "left" }}>
            <label className="widget-header">{t('dw.score.header')}</label>
          </Grid>
        </Grid>
        <Grid container className="wid_driver_parent">
          {driverData}
        </Grid>
        <button className="btn orange-btn view_button" onClick={() => {
          this.props.data.history.push('/driver_list')
        }}>{t('ct.view.all')}</button>
      </div>

    );
  }
}

class VehicleUsage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      usageData: '',
    }
  }

  componentDidMount() {
    this.getVehicleUsage()
  }

  getVehicleUsage = () => {
    ApiUrlCall.widgetVehicleUsage().then(response => {
      if (response.data.response_code === 200 && response.data.is_success === true) {
        // console.log('usage reponse', response)
        this.setState({
          usageData: response.data.data.usage_list
        })
      }
    }).catch(error => {
      console.log('error while fetching usage data', error)
    })
  }
  render() {
    let { usageData } = this.state
    const { t } = this.props
    if (usageData && usageData.length > 0) {
      var listData = usageData.map(usage => {
        return (
          <Grid container key={usage.serial_number} id={usage.serial_number} style={{ borderTop: "1px solid #cccccc", paddingTop: "5px" }}>
            <Grid item xs>
              <p className="cj_w_header">{usage.name}</p>
            </Grid>
            <Grid item xs>
              <span className="cj_w_header">{usage.distance_driven}&nbsp;<img src="images/meter-red.png" alt="icon"></img></span>
            </Grid>
            <Grid item xs>
              <p className="cj_w_header">{CommonFunction.zeroAppend(usage.avg_speed)}</p>
            </Grid>
          </Grid>
        )
      })
    }

    return (

      <div className="cardView button_parent">
        <Grid container className="">
          <Grid item xs style={{ textAlign: "left" }}>
            <label className="widget-header">{t('dw.us.header')}</label>
          </Grid>
          {/* <Grid item xs style={{textAlign:"right",fontSize:"16px"}}>
            <b>27</b>&nbsp;&nbsp;<i className="fa fa-lg fa-clock-o" aria-hidden="true"></i>
          </Grid> */}
        </Grid>
        <hr className="custom-hr" />
        <div className="dataWidgetDiv" style={{ textAlign: "center" }}>
          {/* <p className="cj_w_header" style={{textAlign : "right"}}>CFL : Current Fuel Level</p> */}
          <Grid container>
            <Grid item xs>
              <p className="cj_w_header"><b>Vehicle</b></p>
            </Grid>
            <Grid item xs>
              <p className="cj_w_header"><b>KMS Driven</b></p>
            </Grid>
            <Grid item xs>
              <p className="cj_w_header"><b>Avg.Speed (km/hr)</b></p>
            </Grid>
          </Grid>
          {listData}
        </div>
        <button className="btn orange-btn view_button" onClick={() => {
          this.props.data.history.push('/reports')
        }}>{t('dw.exp.view')}</button>
      </div>

    );
  }
}

class VehicleDocument extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totalDoc: '',
      vehicleCount: '',
      expiredDoc: ''
    }
  }

  componentDidMount() {
    this.getVehicleDocument()
  }

  getVehicleDocument = () => {
    ApiUrlCall.widgetVehicleDocument().then(response => {
      if (response.data.response_code === 200 & response.data.is_success === true) {
        this.setState({
          vehicleCount: response.data.data.vehicle_count,
          totalDoc: response.data.data.total_docs,
          expiredDoc: response.data.data.expired_docs,
        })
      }
    }).catch(error => {
      console.log('error while fetching ', error.response.data)
    })
  }


  render() {
    const { t } = this.props
    return (

      <div className="cardView button_parent">
        <Grid container className="">
          <Grid item xs style={{ textAlign: "left" }}>
            <label className="widget-header">{t('dw.doc.header')}</label>
          </Grid>
        </Grid>
        <hr className="custom-hr" />
        <Grid container className="driver_aval_parent">
          <Grid item xs={4} style={{ borderRight: "1px solid #cccccc" }}>
            <img src="images/w_vehicle_count.png" alt="icon"></img>
            <p className="count_style">{this.state.vehicleCount < 10 ? '0' + this.state.vehicleCount : this.state.vehicleCount}</p>
            <p className="status_vehicle">{t('ars.sum.v1')}<br />{t('ars.vh.count')}</p>
          </Grid>
          <Grid item xs={4} style={{ borderRight: "1px solid #cccccc" }}>
            <img src="images/w_total_doc.png" alt="icon"></img>
            <p className="count_style">{this.state.totalDoc < 10 ? '0' + this.state.totalDoc : this.state.totalDoc}</p>
            <p className="status_vehicle">{t('dw.doc.total')}<br />{t('dds.document')}</p>
          </Grid>
          <Grid item xs={4}>
            <img src="images/w_expired_doc.png" alt="icon"></img>
            <p className="count_style">{this.state.expiredDoc < 10 ? '0' + this.state.expiredDoc : this.state.expiredDoc}</p>
            <p className="status_vehicle">{t('dw.doc.exp')}<br />{t('dds.document')}</p>
          </Grid>
        </Grid>
        <button className="btn orange-btn view_button" onClick={() => {
          this.props.data.history.push('/vehicle_details')
        }}>{t('ct.view.all')}</button>
      </div>

    );
  }
}

class TodaysJourney extends Component {
  constructor(props) {
    super(props);
    this.state = {
      todayData: '',
    }
  }

  componentDidMount() {
    this.getTodayJourney()
  }

  getTodayJourney = () => {
    ApiUrlCall.widgetTodaysJourney().then(response => {
      if (response.data.response_code === 200 && response.data.is_success === true) {
        // console.log('journey data is', response)
        this.setState({
          todayData: response.data.data,
        })
      }
    }).catch(error => {
      console.log('error while fetching todays journey data', error)
    })
  }

  render() {
    const { todayData } = this.state
    const { t } = this.props
    return (

      <div className="cardView button_parent">
        <Grid container className="">
          <Grid item xs style={{ textAlign: "left" }}>
            <label className="widget-header">{t('dw.jo.header')}</label>
          </Grid>
        </Grid>
        <hr className="custom-hr" />
        <Grid container className="driver_aval_parent">
          <Grid item xs={4} style={{ borderRight: "1px solid #cccccc" }}>
            <img src="images/w_scheduled.png" alt="icon"></img>
            <p className="count_style">{todayData.scheduled_journey < 10 ? '0' + todayData.scheduled_journey : todayData.scheduled_journey}</p>
            <p className="status_vehicle">{t('ct.scheduled')}<br />{t('ct.table.h1')}</p>
          </Grid>
          <Grid item xs={4} style={{ borderRight: "1px solid #cccccc" }}>
            <img src="images/w_completed.png" alt="icon"></img>
            <p className="count_style">{todayData.completed_journey < 10 ? '0' + todayData.completed_journey : todayData.completed_journey}</p>
            <p className="status_vehicle">{t('ct.complete')}<br />{t('ct.table.h1')}</p>
          </Grid>
          <Grid item xs={4}>
            <img src="images/w_ongoing.png" alt="icon"></img>
            <p className="count_style">{todayData.ongoing_journey < 10 ? '0' + todayData.ongoing_journey : todayData.ongoing_journey}</p>
            <p className="status_vehicle">{t('ds.ongoing')}<br />{t('ct.table.h1')}</p>
          </Grid>
        </Grid>
        <button className="btn orange-btn view_button" onClick={() => {
          this.props.data.history.push('/journey')
        }}>{t('ct.view.all')}</button>
      </div>

    );
  }
}

class FuelDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expenseList: [],
      fuelList: []
    }
  }

  componentDidMount() {
    //$('.map_loading').show()
    this.getFuelDetails()
  }

  getFuelDetails = () => {
    ApiUrlCall.getWidgetFuelDetails().then(response => {
      $('.map_loading').hide()
      if (response.data.response_code === 200 && response.data.is_success) {
        this.setState({
          expenseList: response.data.data.expense_list,
          fuelList: response.data.data.fuel_list
        })
      }
    }).catch(error => {
      console.log('error while fetching fuel details', error)
    })
  }

  FuelExpense = (e) => {
    $(e.currentTarget).addClass('selected_tab')
    $(e.currentTarget).parent().siblings().children().removeClass('selected_tab')
    $('#current_fuel').hide()
    $('#fuel_expense').show()
  }

  currentFuelDetails = (e) => {
    $(e.currentTarget).addClass('selected_tab')
    $(e.currentTarget).parent().siblings().children().removeClass('selected_tab')
    $('#fuel_expense').hide()
    $('#current_fuel').show()
  }

  render() {
    const { t } = this.props
    const { fuelList, expenseList } = this.state

    if (expenseList.length > 0) {
      var exp_list = expenseList.map(data => {
        return (
          <Grid container className="table_border_top">
            <Grid item xs={6}>
              <p className="cj_w_header">{data.name}</p>
            </Grid>
            <Grid item xs={6}>
              <p className="text_right cj_w_header">{data.expense}</p>
            </Grid>
          </Grid>
        )
      })
    }

    if (fuelList.length > 0) {
      var fuel_list = fuelList.map(data => {
        return (
          <Grid container className="table_border_top">
            <Grid item xs={4}>
              <p className="cj_w_header">{data.name}</p>
            </Grid>
            <Grid item xs={4}>
              <p className="center_text cj_w_header">{data.fuel_level}</p>
            </Grid>
            <Grid item xs={4}>
              <p className="text_right cj_w_header">{data.fuel_consumed}</p>
            </Grid>
          </Grid>
        )
      })
    }

    return (

      <div className="cardView button_parent">
        <Grid container className="">
          <Grid item xs style={{ textAlign: "left" }}>
            <label className="widget-header">Fuel Details</label>
            <p className="p_margin0">(Top 4 Vehicles)</p>
          </Grid>
        </Grid>
        <hr className="custom-hr" />
        <Grid container>
          <Grid item xs>
            <div onClick={this.FuelExpense} className="vehicle_state selected_tab">
              <p className="p_margin0">Fuel Expense</p>
            </div>
          </Grid>
          <Grid item xs>
            <div onClick={this.currentFuelDetails} className="vehicle_state">
              <p className="p_margin0">Current Fuel Details</p>
            </div>
          </Grid>
        </Grid>
        <div id="fuel_expense" className="list_scroll">
          <Grid container>
            <Grid item xs={6}>
              <p className="cj_w_header"><b>Vehicles</b></p>
            </Grid>
            <Grid item xs={6}>
              <p className="text_right cj_w_header"><b>Fuel Expense (INR)</b></p>
            </Grid>
          </Grid>
          <div className="">
            {exp_list}
          </div>
          {/* <div className="total_fuel">
            <Grid container>
              <Grid item xs={6}>
                <p className="p_header p_margin0">Total Fuel Expense</p>
              </Grid>
              <Grid item xs={6}>
                <p className="text_right p_header p_margin0">2.75</p>
              </Grid>            
            </Grid>            
          </div> */}
        </div>
        <div id="current_fuel" style={{ display: "none" }} className="list_scroll">
          <Grid container spacing={1}>
            <Grid item xs={4}>
              <p className="cj_w_header"><b>Vehicles</b></p>
            </Grid>
            <Grid item xs={4}>
              <p className="center_text cj_w_header"><b>Current Fuel Level (Litres)</b></p>
            </Grid>
            <Grid item xs={4}>
              <p className="text_right cj_w_header"><b>Fuel Consumed (Litres)</b></p>
            </Grid>
          </Grid>
          <div className="">
            {fuel_list}
          </div>
          {/* <div className="total_fuel">
              <Grid container>
                <Grid item xs={4}>
                  <p className="p_header p_margin0">Total</p>
                </Grid>
                <Grid item xs={4}>
                  <p className="center_text p_header p_margin0">2.75</p>
                </Grid>
                <Grid item xs={4}>
                  <p className="text_right p_header p_margin0">2.75</p>
                </Grid>   
              </Grid>            
            </div> */}
        </div>
        <div className="map_loading">
          <CircularProgress className="map_loading_icon" />
        </div>
      </div>

    );
  }
}

class FuelConsumed extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fuelConsumed: [],
      MIPState: 0
    }
  }

  componentDidMount() {
    $('.map_loading').show()
    this.getMIPFuelData()
  }

  getMIPFuelData = () => {
    ApiUrlCall.getWidgetFuelConsumed().then(response => {
      // console.log('fuel consumed', response)
      $('.map_loading').hide()
      if (response.data.response_code === 200 && response.data.is_success) {
        this.setState({
          fuelConsumed: response.data.data.fuel_usage_list
        })
      }
    }).catch(error => {
      console.log('error while fetching fuel consumed', error)
    })
  }

  allConsumed = (e) => {
    $(e.currentTarget).addClass('selected_tab')
    $(e.currentTarget).parent().siblings().children().removeClass('selected_tab')
    this.setState({
      MIPState: 0,
    })
  }

  movingConsumed = (e) => {
    $(e.currentTarget).addClass('selected_tab')
    $(e.currentTarget).parent().siblings().children().removeClass('selected_tab')
    this.setState({
      MIPState: 1,
    })
  }
  idlingConsumed = (e) => {
    $(e.currentTarget).addClass('selected_tab')
    $(e.currentTarget).parent().siblings().children().removeClass('selected_tab')
    this.setState({
      MIPState: 2,
    })
  }

  render() {
    const { fuelConsumed } = this.state
    const { t } = this.props

    if (fuelConsumed.length > 0) {
      var all_consumed = fuelConsumed.map(data => {
        return (
          <Grid container className="table_border_top">
            <Grid item xs={4}>
              <p className="cj_w_header">{data.name}</p>
            </Grid>
            <Grid item xs={4}>
              <p className="center_text cj_w_header">{CommonFunction.convertSecToHoursMin(data.total_duration)}</p>
            </Grid>
            <Grid item xs={4}>
              <p className="text_right cj_w_header">{data.total_fuel}</p>
            </Grid>
          </Grid>
        )
      })
      var moving_consumed = fuelConsumed.map(data => {
        return (
          <Grid container className="table_border_top">
            <Grid item xs={4}>
              <p className="cj_w_header">{data.name}</p>
            </Grid>
            <Grid item xs={4}>
              <p className="center_text cj_w_header">{CommonFunction.convertSecToHoursMin(data.moving_duration)}</p>
            </Grid>
            <Grid item xs={4}>
              <p className="text_right cj_w_header">{data.moving_fuel}</p>
            </Grid>
          </Grid>
        )
      })
      var idling_consumed = fuelConsumed.map(data => {
        return (
          <Grid container className="table_border_top">
            <Grid item xs={4}>
              <p className="cj_w_header">{data.name}</p>
            </Grid>
            <Grid item xs={4}>
              <p className="center_text cj_w_header">{CommonFunction.convertSecToHoursMin(data.idling_duration)}</p>
            </Grid>
            <Grid item xs={4}>
              <p className="text_right cj_w_header">{data.idling_fuel}</p>
            </Grid>
          </Grid>
        )
      })
    }


    return (

      <div className="cardView button_parent">
        <Grid container className="">
          <Grid item xs style={{ textAlign: "left" }}>
            <label className="widget-header">Fuel Consumed</label>
            <p className="p_margin0">(Top 4 Vehicles)</p>
          </Grid>
        </Grid>
        <hr className="custom-hr" />
        <Grid container>
          <Grid item xs>
            <div onClick={this.allConsumed} className="vehicle_state selected_tab">
              <p className="p_margin0">All</p>
            </div>
          </Grid>
          <Grid item xs>
            <div onClick={this.movingConsumed} className="vehicle_state">
              <p className="p_margin0">Moving</p>
            </div>
          </Grid>
          <Grid item xs>
            <div onClick={this.idlingConsumed} className="vehicle_state">
              <p className="p_margin0">Idling</p>
            </div>
          </Grid>
        </Grid>
        <div className="list_scroll">
          <Grid container>
            <Grid item xs={4}>
              <p className="cj_w_header"><b>Vehicles</b></p>
            </Grid>
            <Grid item xs={4}>
              <p className="center_text cj_w_header"><b>Duration (HH:MM)</b></p>
            </Grid>
            <Grid item xs={4}>
              <p className="text_right cj_w_header"><b>Fuel Consumed (Litres)</b></p>
            </Grid>
          </Grid>
          <div style={{ display: this.state.MIPState == 0 ? "" : "none" }}>
            {all_consumed}
          </div>
          <div style={{ display: this.state.MIPState == 1 ? "" : "none" }}>
            {moving_consumed}
          </div>
          <div style={{ display: this.state.MIPState == 2 ? "" : "none" }}>
            {idling_consumed}
          </div>
          {/* <div className="total_fuel">
              <Grid container>
                <Grid item xs={4}>
                  <p className="p_header p_margin0">Total</p>
                </Grid>
                <Grid item xs={4}>
                  <p className="center_text p_header p_margin0">2.75</p>
                </Grid>
                <Grid item xs={4}>
                  <p className="text_right p_header p_margin0">2.75</p>
                </Grid>   
              </Grid>             
          </div> */}
        </div>
        <div className="map_loading">
          <CircularProgress className="map_loading_icon" />
        </div>
      </div>

    );
  }
}


class VehicleStat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vlcstatData: '',
      vlcstatData1: '',
      refreshDate: new Date(),
    }
  }

  componentDidMount() {
    // this.getFleetData()
    this.getVehicleStatData()
    this.getVehicleStatData1()
  }


  // fsStatus = (e) => {
  //   const divShowValue = $(e.currentTarget).attr("name")
  //   const divShow = document.getElementById(divShowValue)
  //   $(e.currentTarget).addClass('selected_tab')
  //   $(e.currentTarget).parent().siblings().children().removeClass('selected_tab')
  //   $('#' + divShowValue + '.wid_fs_parent').show()
  //   $('#' + divShowValue + '.wid_fs_parent').siblings().hide()
  // }

  getVehicleStatData = () => {
    ApiUrlCall.widgetVehicleStat().then(response => {
      // console.log('response is', response)  
      if (response.data.response_code === 200 && response.data.is_success === true) {
        //console.log('Vehicle Stat response', response.data.data.count_data)
        this.setState({
          vlcstatData: response.data.data.count_data,
        })
      }
    }).catch(error => {
      console.log('error while fetching Vehicle stat data', error)
    })
  }

  getVehicleStatData1 = () => {
    ApiUrlCall.widgetVehicleStat1().then(response => {
      //console.log('response is', response.data)  
      if (response.data.response_code === 200 && response.data.is_success === true) {
        //console.log('Vehicle Stat response1', response.data)
        this.setState({
          vlcstatData1: response.data.data.count_data,
        })
      }
    }).catch(error => {
      console.log('error while fetching Vehicle stat data', error)
    })
  }

  refreshButton = () => {
    var date = new Date()
    this.setState({
      refreshDate: date,
    }, () => {
      this.getVehicleStatData()
      this.getVehicleStatData1()
    })
  }


  render() {
    let { vlcstatData, vlcstatData1 } = this.state
    const { t } = this.props
    return (

      <div className="cardView button_parent">
        <Grid container className="">
          <Grid item xs style={{ textAlign: "left" }}>
            <label className="widget-header">Vehicle Stats</label>
          </Grid>
          <Grid item xs style={{ textAlign: "right", fontSize: "16px" }}>
            <b>{vlcstatData.total_vehicle_count < 10 ? '0' + vlcstatData.total_vehicle_count : vlcstatData.total_vehicle_count}</b>&nbsp;&nbsp;<i><img src="images/vehicle-1.png" height="30px" alt="icon"></img></i>
          </Grid>
        </Grid>
        <hr className="custom-hr" />
        <div className="fs_parent_div">
          <Grid container className="wid_fs_parent" id="moving_data">
            <Grid container item xs={4} className="wid_fs1" style={{ cursor: "pointer" }} onClick={() => { this.props.data.history.push('/reports') }}>
              <p className="count_style">{vlcstatData.moving_count < 10 ? '0' + vlcstatData.moving_count : vlcstatData.moving_count}</p>
              <p className="status_vehicle">Moving Vehicles</p>
            </Grid>
            <Grid container item xs={4} className="wid_fs1" style={{ cursor: "pointer" }} onClick={() => { this.props.data.history.push('/reports') }}>
              <p className="count_style">{vlcstatData.idling_count < 10 ? '0' + vlcstatData.idling_count : vlcstatData.idling_count}</p>
              <p className="status_vehicle">Idling Vehicles</p>
            </Grid>
            <Grid container item xs={4} className="wid_fs1" style={{ cursor: "pointer" }} onClick={() => { this.props.data.history.push('/reports') }}>
              <p className="count_style">{vlcstatData.conn_lost_count < 10 ? '0' + vlcstatData.conn_lost_count : vlcstatData.conn_lost_count}</p>
              <p className="status_vehicle">Connection Lost Vehicles</p>
            </Grid>
            <Grid container item xs={4} className="wid_fs1" style={{ cursor: "pointer" }} onClick={() => { this.props.data.history.push('/reports') }}>
              <p className="count_style">{vlcstatData.parked_count < 10 ? '0' + vlcstatData.parked_count : vlcstatData.parked_count}</p>
              <p className="status_vehicle">Parked Vehicles</p>
            </Grid>
            <Grid container item xs={4} className="wid_fs1" style={{ cursor: "pointer" }} onClick={() => { this.props.data.history.push('/reports') }}>
              <p className="count_style">{vlcstatData1.nightdrive_count < 10 ? '0' + vlcstatData1.nightdrive_count : vlcstatData1.nightdrive_count}</p>
              <p className="status_vehicle">Night Drive Count</p>
            </Grid>
            <Grid container item xs={4} className="wid_fs1" style={{ cursor: "pointer" }} onClick={() => { this.props.data.history.push('/reports') }}>
              <p className="count_style">{vlcstatData1.overspeed_count < 10 ? '0' + vlcstatData1.overspeed_count : vlcstatData1.overspeed_count}</p>
              <p className="status_vehicle">Overspeed Count</p>
            </Grid>
            <div style={{ display: "inline-block", marginLeft: "10px" }}>
              <p className="p_header" style={{ marginBottom: "0px", fontSize: "15px" }}>{CommonFunction.tableDateFormate(this.state.refreshDate) + " " + CommonFunction.timeFormate(this.state.refreshDate)}</p>
              <p style={{ fontSize: "10px" }}>Last Updated On</p>
            </div>

            <button className="btn orange-btn view_button btn-sm" onClick={this.refreshButton}>Refresh</button>
          </Grid>
        </div>
      </div>

    );
  }
}


export const FleetSummary1 = withTranslation()(FleetSummary);
export const MapView1 = withTranslation()(MapView);
export const OverallVehicleHealth1 = withTranslation()(OverallVehicleHealth);
export const DriverEfficiency1 = withTranslation()(DriverEfficiency);
export const VehicleFitness1 = withTranslation()(VehicleFitness);
export const VehicleUsage1 = withTranslation()(VehicleUsage);
export const VehicleDocument1 = withTranslation()(VehicleDocument);
export const TodaysJourney1 = withTranslation()(TodaysJourney);
export const FuelDetails1 = withTranslation()(FuelDetails);
export const FuelConsumed1 = withTranslation()(FuelConsumed);
export const VehicleStat1 = withTranslation()(VehicleStat);



