import React, { Component } from 'react';


class PeopleTrackingRedirect extends Component {
  constructor(props){
    super(props);
    this.state = {

    }
  }

  componentWillMount(){
      localStorage.clear()
  }

  componentDidMount(){
      this.props.history.push({
          pathname : "/login"
      })
  }

  render(){
    return (
      <div className="">
        
      </div>
    );
  }
}

export default PeopleTrackingRedirect;
