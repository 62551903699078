import React, { Component } from 'react';
import $ from 'jquery';
import { Grid } from '@mui/material';
import ApiUrlCall from '../API/apiurl';
import { withTranslation } from 'react-i18next';
import { MDBDataTable } from 'mdbreact';
import Snackbar from '@mui/material/Snackbar';
import { validationLatitudeLongitude } from "validation-latitude-longitude";
import CommonFunction from '../common_function';

const obj = {}
const key1 = {}

const data = [
    {
      label: 'LOCATION NAME',
      field: 'location_name',
      sort: 'asc',
      width: 150,
      attributes: {
        'aria-controls': 'DataTable',
        'aria-label': 'Name',
      },
    },
    {
      label: 'ID',
      field: 'id',
      sort: 'asc',
      width: 90,
    },
    {
      label: 'ADDRESS',
      field: 'address',
      sort: 'asc',
      width: 200,
    },
    {
      label: 'LATITUDE',
      field: 'latitude',
      sort: 'asc',
      width: 100,
    },
    {
      label: 'LONGITUDE',
      field: 'longitude',
      sort: 'asc',
      width: 150,
    },  
    {
      label: 'OTHER INFORMATION',
      field: 'other_info',
      sort: 'asc',
      width: 150,
    },
    {
    label: 'DELETE LOCATION',
    field: 'is_delete',
    sort: 'asc',
    width: 150,
    }
  ]

class Location extends Component {

    constructor(props) {
        super(props);
        this.state = {
            addLocationName : "",
            addID : "",
            addAddress : "",
            addLatitude : "",
            datatable: [],
            totalCount : '',
            dataCollection: [],
            chunckValue: '',
            nextCount: 0,
            searchText: '',
            locationList : "",
            selectedKey: 0,
            keyValueElement : "",
            keyValue : [],
            openAlert: false,
            errorMessage: '',
            key : "",
            otherinfo : "",

        }
      }

    componentDidMount () {
      this.locationData()
    }

    addLocationDetails = () => {
        $('#add_location').slideDown()
    }

    cancelModal = () => {
        $('#add_location').fadeOut()
      }
    
    saveLocationDetails = () => {
        const { addLocationName, addID, addAddress, addLatitude, addLangitude,otherinfo } = this.state
        const addLatitude2 = addLatitude
        const addLangitude2 = addLangitude
        var addLatitude1 = parseFloat(addLatitude2).toFixed(4);
        var addLangitude1 = parseFloat(addLangitude2).toFixed(4);
        if (!addLocationName.trim()) {
          this.setState({
            openAlert: true,
            errorMessage: "Kindly Enter Location Name"
          })
          return
        }

        if (!addID.trim()) {
          this.setState({
            openAlert: true,
            errorMessage: "Kindly Enter ID "
          })
          return
        }

        if (!addAddress.trim()) {
          this.setState({
            openAlert: true,
            errorMessage: "Kindly Enter Address"
          })
          return
        }

        if (!addLatitude1.trim()) {
          this.setState({
            openAlert: true,
            errorMessage: "Kindly Enter Latitude"
          })
          return
        }
        else if(isNaN(addLatitude1)){
          this.setState({
            openAlert: true,
            errorMessage: "Kindly Enter Latitude in Number"
          })
          return
        }
        else if(!(validationLatitudeLongitude.latitude(addLatitude1))){
          this.setState({
            openAlert: true,
            errorMessage: "Kindly Enter Valid Latitude "
          })
          return
        }

        if (!addLangitude1.trim()) {
          this.setState({
            openAlert: true,
            errorMessage: "Kindly Enter Longitude"
          })
          return
        }
        else if(isNaN(addLangitude1)){
          this.setState({
            openAlert: true,
            errorMessage: "Kindly Enter Longitude in Number"
          })
          return
        }
        else if(!(validationLatitudeLongitude.longitude(addLangitude1))){
          this.setState({
            openAlert: true,
            errorMessage: "Kindly Enter Valid Longitude"
          })
          return
        }
        var sendJsonData = {
          name : addLocationName, id : addID,
          address : addAddress , lat : addLatitude1,
          lon : addLangitude1 , other_information  : otherinfo,
        }
        //var data = JSON.stringify(sendJsonData)
        $('#processing').show()
        ApiUrlCall.addLocation(sendJsonData).then(response => {
          $('#processing').hide()
          if (response.data.response_code == 200 && response.data.is_success) {
            $('#add_location').fadeOut()
            this.setState({
              addLocationName : '',
              addID : '',
              addAddress : '',
              addLatitude : "",
              addLangitude : '',
              otherinfo : "",
              key : "",
            },() => {
              for (var key2 in obj) {
                  delete obj[key2];
              }
              for (var key2 in key1) {
                delete key1[key2];
              }
              this.locationData()
            })
          } else {
            this.setState({
              openAlert: true,
              errorMessage: response.data.response_message
            })
          }
        }).catch(error => {
          $('#processing').hide()
          console.log('error while creating new Location', error)
        })
    }

    optimizationSearch = (value) => {  
    
        this.counter = 0
        if(value.length >= 3){
          setTimeout(() => {
            this.setState({
              datatable : [],
              dataCollection : [],
              totalCount : '',
              chunckValue : '',
              nextCount : 0,
              searchText : value
            },() => {
              this.locationData()          
            })
          }, 1000);     
        }else if(value.length === 0){
          setTimeout(() => {
            this.setState({
              datatable : [],
              dataCollection : [],
              totalCount : '',
              chunckValue : '',
              nextCount : 0,
              searchText : value
            },() => {
              this.locationData()          
            })
          }, 1000);     
        }          
    }

    delete_button = (uniqueID) => {
      if(uniqueID) 
      return ( <img class="deleteLocation" id={uniqueID} src="images/group-2.png" alt="icon" styles= {"marginTop: 10px; marginLeft: 15px; cursor: pointer" }></img>
      ) 
    }


    tableRowClick = (id) => {
      var span_class = $(id.currentTarget).find('img').attr('id')
      var statuss = id.target.classList.contains("deleteLocation");
      
      if (statuss ==true )
      {
        var data = {unique_id : span_class}
        ApiUrlCall.deleteLocation(data).then(response => {
          if (response.status == 200) {
            this.setState({
              openAlert: true,
              errorMessage: "Location Deleted Successfully"
            })
            this.locationData()
            
          }
          else if (response.status == 405) {
            this.setState({
              openAlert: true,
              errorMessage: "Location Can not be Deleted "
            })
          }
        }) 
      }
    }

    createTableData = () => {   
      const {t} = this.props 
      const {locationList,datatable} = this.state    
      var row = []  
      for (let i = 0; i < locationList.length; i++) {
        const element = locationList[i];
        var row_data = {
          location_name : element.name,
          id : element.id,
          address :element.address,
          latitude : element.lat,
          longitude : element.lon,
          other_info : element.other_information,
          clickEvent : this.tableRowClick,
          is_delete : this.delete_button(element.unique_id)
        }       
        datatable.push(row_data)                 
      }    
      // this.buttonStatus() 
      // this.entriesData()   
      this.forceUpdate()
      // this.intervalData()
    }

    locationData = () => {
      $('#processing').show()
      ApiUrlCall.getlocationdata().then(response => {
        this.emptyTable()
        if (response.data.response_code === 200 && response.data.is_success === true) {
          this.setState({
            locationList: response.data.location_list,
          }, () => {
            this.createTableData()
          })
        }
        // else{
        //   $('#processing').hide()
        // }
        $('#processing').hide()
      })
    }

    emptyTable = () => {
      this.setState({
        datatable: []
      })
    }

    createObject = (i,e) => {
      var key = this.state.key[i]
      obj[key] = e.currentTarget.value
      $('.stops_parent').find('#'+i).prop("disabled", true)
      this.setState({
        otherinfo : obj,
      })
      
    }

    createKey = (i,e) => {
      key1[i] = e.currentTarget.value
      this.setState({
        key : key1,
      })
    }

    returNumberOKeyValue = (e) => {
      const keyValue = []
      const { t } = this.props
      var KeyValueNo = this.state.selectedKey + 1
      
      for (let i = 0; i < KeyValueNo; i++) {
        keyValue.push(
          <Grid container spacing={4} item xs={11} key={i} name={i} className="stops_parent">
            <Grid item xs={5}>    
              <div className="input_div pointer">
                <input type="text" placeholder="Enter Key" id={i} onChange={(e) => this.createKey(i,e)} className="journey_input pointer"></input>
              </div>
            </Grid>
            <Grid>
              <h3>:</h3>
            </Grid>
            {/* <Grid item xs={5}>
            <div className="input_div pointer" >
                <input type="text" placeholder="Enter Value" id={'key_'+i} onChange={(e) => {
                    this.setState({
                      value: e.currentTarget.value
                    })
                  }} className="journey_input pointer" disabled></input>
              </div>
            </Grid> */}
            <Grid item xs={5}>
            <div className="input_div pointer" >
                <input type="text" placeholder="Enter Value" id={'key_'+i} onChange={(e) => this.createObject(i,e)} className="journey_input pointer"></input>
              </div>
            </Grid>
            <Grid item xs={1}>
              <i class="fas fa-trash-alt" aria-hidden="true" name={i} style={{ color: "#F0762B", marginTop: "10px", fontSize: "20px" }} onClick={this.removekeyValuePair}></i>
            </Grid>
          </Grid>
        )
      }
      this.setState({
        keyValueElement: keyValue,
        selectedKey: KeyValueNo,
      }, () => {
        $('.stops_parent').find('.fa-trash-alt').show()
        $('.stops_parent').last().find('.fa-trash-alt').show()
        // console.log("stop array after state set is", this.state.stopElement)
      })
    }

    removekeyValuePair = (e) => {
      const {key,otherinfo} = this.state
      $('.stops_parent').find('.fa-trash-alt').show()
      $('.stops_parent').last().prev().find('.fa-trash-alt ').show()
      var removeStopArray = this.state.keyValue
      var removeStopElementArray = this.state.keyValueElement
      var decreaseCounter = this.state.selectedKey - 1
      var deleteLocation = $(e.currentTarget).attr('name')
      var removeElement = $(e.currentTarget).parent().parent().attr('name')
      // var deleteStopIndex = removeStopArray.findIndex(x => x.location === deleteLocation)
      // console.log('remove element', removeElement)
      var deleteStopElementIndex = removeStopElementArray.findIndex(y => y.props.id === parseInt(removeElement))
      removeStopElementArray.map((items, index, removeStopElementArray) => {
        // console.log('itmes', items.props)
        if (items.props.name === parseInt(removeElement)) {
          removeStopElementArray.splice(deleteStopElementIndex, 1)
          this.setState({
            selectedKey: decreaseCounter
          }, () => {
             //console.log('previous counter value is', this.state.selectedKey)
            // console.log('array of stop data', this.state.stopElement)
            var a = key[this.state.selectedKey]
            delete otherinfo[a]  
          })
          this.forceUpdate()
        }
      })
      removeStopArray.splice(deleteLocation, 1)
      this.setState({
        selectedKey: decreaseCounter
      })
      this.forceUpdate()
      // console.log('stops data after delete', this.state.stops)
    }

render(){
  var arrayOfKeyValue = this.state.keyValueElement
  if (this.state.keyValueElement) {
    var KeyvalueContent = arrayOfKeyValue.map(KeyvalueContent => {
      return KeyvalueContent
    })
  }
  return(
    <div className="add_location_parent vr_table_view">
      <Grid container spacing={2}>
        <Grid item xs>
            <button className="btn orange-btn" onClick={this.addLocationDetails}>Add Location</button> 
        </Grid>
      </Grid>
      <p className="p_header" style={{position : 'absolute', lineHeight : "2em"}}>Total Number Of Entries : {CommonFunction.zeroAppend(this.state.datatable.length)}</p>
      <MDBDataTable
          hover
          displayEntries={false}
          paging={true}
          scrollX
          entries={10}
          striped
          bordered
          data={{ columns: data, rows: this.state.datatable }}
      />
        <div id="add_location" className="p_modal_parent" style={{ display: "none" }}>
          <div className="p_modal_boday">
            <p className="p_header">Location Details</p>
            <hr />
            <Grid container spacing={5} style={{ marginBottom: "10px" }}>
              <Grid item xs>
                <p className="cj_w_header">Location Name &nbsp;*</p>
              </Grid>
              <Grid item xs>
                <input type="text" placeholder="Enter Name" className="journey_input"
                  onChange={(e) => {
                    this.setState({
                      addLocationName: e.currentTarget.value
                    })
                  }}></input>
              </Grid>
            </Grid>
            <Grid container spacing={5} style={{ marginBottom: "10px" }}>
              <Grid item xs>
                <p className="cj_w_header">ID&nbsp;*</p>
              </Grid>
              <Grid item xs>
                <input type="text" placeholder="Enter ID" className="journey_input"
                  onChange={(e) => {
                    this.setState({
                      addID: e.currentTarget.value
                    })
                  }}></input>
              </Grid>
            </Grid>
            <Grid container spacing={5} style={{ marginBottom: "10px" }}>
              <Grid item xs>
                <p className="cj_w_header">Address&nbsp;*</p>
              </Grid>
              <Grid item xs>
                <input type="text" placeholder="Enter Address " className="journey_input"
                  onChange={(e) => {
                    this.setState({
                      addAddress: e.currentTarget.value
                    })
                  }}></input>
              </Grid>
            </Grid>
            <Grid container spacing={5} style={{ marginBottom: "10px" }}>
              <Grid item xs>
                <p className="cj_w_header">Latitude&nbsp;*</p>
              </Grid>
              <Grid item xs>
                <input type="text" placeholder="Enter Latitude" className="journey_input"
                  onChange={(e) => {
                    this.setState({
                      addLatitude: e.currentTarget.value
                    })
                  }}></input>
              </Grid>
              <Grid item xs>
                <p className="cj_w_header">Longitude&nbsp;*</p>
              </Grid>
              <Grid item xs>
                <input type="text" placeholder="Enter Longitude" className="journey_input"
                  onChange={(e) => {
                    this.setState({
                      addLangitude: e.currentTarget.value
                    })
                  }}></input>
              </Grid>
            </Grid>
            <Grid container spacing={5} style={{ marginBottom: "10px" }}>
              <Grid item xs>
                <p className="cj_w_header" style={{ marginTop : "10px" }}>Other Information&nbsp;</p>
              </Grid>
              <Grid item xs ={9}>
                  <img src="images/add_zone.png" alt="icon" onClick={this.returNumberOKeyValue}></img>
              </Grid> 
            </Grid>
            <Grid >
              {KeyvalueContent}
            </Grid>

            <hr />
            <Grid container spacing={10}>
              <Grid item xs = {5} style ={{marginLeft : "50px"}} >
                <button className="btn orange-btn btn-block" style={{ float: "none"}} onClick={this.saveLocationDetails}>Save</button>
              </Grid>
              <Grid item xs = {5}>
                <button className="btn orange-btn btn-block" style={{ float: "none" }} onClick={this.cancelModal}>Cancel</button>
              </Grid>
            </Grid>
          </div>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          autoHideDuration={1200}
          onClose={(e, reason) => {
            if (reason === 'clickaway') {
              return;
            }
            this.setState({ openAlert: false })
          }
          }
          open={this.state.openAlert}
          message={this.state.errorMessage}
        />
        
    </div>
      
  )  
}
}

export default withTranslation()(Location);