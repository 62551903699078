import React, { Component } from 'react';
import $ from 'jquery';
//import Region from './region';
import VendorIntegration from './vendor_integration1'
import TrackerManagement from './tracker_management1'
import UserManagement from './user_mgmt'
import LiveTrack from './live_track'
import Tooltip from '@mui/material/Tooltip';
import Navbar from '../../../Config/Navbar';
import './setting1.scss'
import {Link}  from 'react-router-dom'
import constant from '../../constant.json';
import { withTranslation } from 'react-i18next';

class SettingForJSW extends Component {
    constructor(props) {
        super(props);
        this.state = {
            resizeMap : false,
            tabComponent : <TrackerManagement/>,
        }
    }

    componentDidMount() {
        setTimeout(() => {
            var navBarHeight = $('.navBar').outerHeight()
            $('.setting_parent').css({
              paddingTop : navBarHeight+60,
            })            
        }, 500);
    }

    optionSeletion = (div) => {
        $(div).addClass('settting_sel')
        $(div).siblings().removeClass('settting_sel')
    }

 
    vendorClick = (e) => {
        this.optionSeletion(e.currentTarget)
        this.setState({
            tabComponent : <VendorIntegration />
        })
    }
    trackerManagementClick = (e) => {
       this.optionSeletion(e.currentTarget)
        this.setState({
            tabComponent : <TrackerManagement />
        })
    }

    LiveTrackClick = (e) => {
        this.optionSeletion(e.currentTarget)
         this.setState({
             tabComponent : <LiveTrack />
         })
     }

     UserMgmtClick = (e) => {
        this.optionSeletion(e.currentTarget)
         this.setState({
             tabComponent : <UserManagement />
         })
     }


    // regionClick = (e) => {
    //     this.optionSeletion(e.currentTarget)
    //     this.showDiv('region_div')
    //     window.dispatchEvent(new Event('resize'));
    // }

    render() {
        const {t} = this.props
        return (
            <div>
                <Navbar/>
                <div className="setting_parent">
                    <Link to="/"><img src="images/arrow.png"></img></Link>
                    <p className="page_header" style={{display : "inline",marginLeft:"15px"}}>{t('ss.setting')}</p>
                    <span style={{float : "right", display : "none"}}>version : {constant.app_version}</span>
                    <div className="setting_opt">
                        <div className="settting_sel" onClick={this.trackerManagementClick}>                            
                            <Tooltip title={<span style={{fontSize : "12px"}}>Map Your Vehicle & Tracker from this section</span>} placement="top">
                                <p className="p_margin0">{t('ss.tracker.mgmt')}<i class="fa fa-info-circle" style={{marginLeft : "5px"}} aria-hidden="true"></i></p>
                            </Tooltip>
                        </div>
                        {/* 8 = vendor api integration */}
                        <div style={{display : localStorage.getItem('group') == 8 ? "" : "none"}} onClick={this.vendorClick}>
                            {/* <div onClick={this.vendorClick} style={{display : localStorage.getItem('group') == 8 ? "" : "none"}}>
                                <p className="p_margin0">{t('ss.vendor')}</p>
                            </div> */}
                            {/* <div onClick={this.vendorClick}> */}
                                <p className="p_margin0">{t('ss.vendor')}</p>
                            {/* </div> */}
                        </div>
                        <div onClick={this.UserMgmtClick}>
                            <p className="p_margin0">User Management</p>
                        </div>
                        <div onClick={this.LiveTrackClick}>
                            <p className="p_margin0">Live Track</p>
                        </div>
                        {/* <div onClick={this.regionClick}>
                            <p className="p_margin0">Regions</p>
                        </div> */}
                    </div>
                    {/* contend to show on slection */}
                    <div style={{marginTop: "22px"}}>
                        <div id="tab_component">
                            {this.state.tabComponent}
                        </div>                                                                        
                        {/* <div id="region_div">   
                            <Region />
                        </div> */}
                    </div>
                    {/* contend to show on slection end*/}
                </div>                
            </div>
        );
    }
}

export default withTranslation()(SettingForJSW);
