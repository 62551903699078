import React from "react";
import PropTypes from "prop-types";
import isEqual from "lodash.isequal";
import $ from 'jquery';
import './map.scss';
import ApiUrlCall from '../API/apiurl';
import Snackbar from '@mui/material/Snackbar';


const L = window.L
const MapmyIndia = window.MapmyIndia
const Location_ICON = 'images/vehicle_icon/cj_location.png'
const MOVING = 2
const IDLING = 3
const PARKED = 4
const LOW_SATELLITES = 5
const SLEEPING = 6
const OVERSPEEDING = 7
const DNT = 8


const START = 10
const SHUTDOWN = 11
const ALL_DGCONNECTION_LOST = 1


export default class Map extends React.Component {


  constructor(props) {
    super(props);
    this.state = {
      mapHeight: props.height,
      mapWidth: props.width,
      mapMarkers: props.markers,
      mapBoundFlag: props.pauseBound,
      isUpdate: true,
      isLastChunk: props.isLastChunk,
      previousMarker: [],
      showLocation: props.showlocation,

      locationTrack: "",
      locations: [],
      flag: true,
      openAlert: false,
      errorMessage: '',

      map: null,
      styles: [],
      selectedStyle: "",
      indicator: 0,
    }
  }

  mapNode = null;
  map = null;
  pathInterval = null
  markers = [];
  boundrycircle = [];
  mounted = true;
  tmarker = [];
  replaymarker = [];
  completedlat = null;
  completedlng = null;
  poly = [];
  counter = 0;
  path = new L.Polyline({ color: '#f4811f', weight: 4 })

  replaypath = new L.Polyline({ color: '#f4811f', weight: 4 })
  Dataflag = true;
  pp = [];
  resetPoly = false;
  forwardFlag = true;
  forwardCounter = 0
  cluster
  previousMarkerPosition = null;
  previousAngle = null;
  previousLiveTrackAngle = null
  endReplay = false;
  vehicleStateMarker = [];
  distanceOld = null;
  replaySpeed = 1000;
  stopcircle = [];

  locations = []

  // clusterMarker = []

  // polyPath = new L.Polyline(x,y)

  componentDidMount() {
    this.mounted = true
    this.initializeMap();
  }

  componentWillUnmount() {
    //console.log('map is unmounted')
    this.removeMarkers();
    this.mounted = false
    this.map.remove()
    if (this.pathInterval !== null) {
      clearInterval(this.pathInterval)
      clearInterval(this.interval)
    }
  }



  UNSAFE_componentWillReceiveProps(nextProps) {
    //console.log('this.props', this.props)       
    if (!isEqual(this.props.markers, nextProps.markers)) {
      //  console.log('previous props value', this.props.markers, 'next props value is', nextProps.markers)            
      this.removeMarkers();
      this.renderMarkers(nextProps);
    }
    if (!isEqual(this.props.polyline, nextProps.polyline)) {
      if (this.props.polyline.show) {
        if (!isEqual(this.props.polyline.serial_number, nextProps.polyline.serial_number)) {
          // console.log('new tracker selected', nextProps)
          this.stopAnimationInterval()
          this.removeLiveTrackPoly()
          this.DrawPolyLine(nextProps)
        } else {
          this.DrawPolyLine(nextProps)
        }
      }
      // console.log('next props value', nextProps)
    } else {
      this.DrawPolyLine(nextProps)
    }
    if (!isEqual(this.props.replayroutedata, nextProps.replayroutedata)) {
      // console.log('>>>>>>>>>>>>>>>>>>>>>>>>serial_number',this.props.replayroutedata.serial_number, nextProps.replayroutedata.serial_number)      
      // return
      if (!this.props.replayroutedata.show) {
        // console.log(this.props.replayroutedata.replay,'replay route data is',nextProps.replayroutedata.replay)
        if (!isEqual(this.props.replayroutedata.replay, nextProps.replayroutedata.replay)) {
          // console.log('props value if')
          this.stopAnimationInterval()
          clearInterval(this.interval)
          this.removePolyline()
          this.Dataflag = true
          this.ReplayRoute(nextProps)
        } else {
          // console.log('props value else')
          clearInterval(this.interval)
          setInterval(this.interval, 1000)
          this.ReplayRoute(nextProps)
        }
      }
    }
    if (!isEqual(this.props.circle, nextProps.circle)) {
      // console.log('new props circle')
      this.removeRadius()
      this.showCicrle(nextProps);
    }

    if (!isEqual(this.props.fitBound, nextProps.fitBound)) {
      // console.log('new fitbound props')
      this.circleFitbound()
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state.isUpdate

  }

  updateMarkers = (latestMarkers, lastChunk, boundFlag) => {
    if (this.state.mapBoundFlag != boundFlag) {
      this.setState({
        mapBoundFlag: boundFlag
      }, () => {
        this.processMarkersToBeUpdated(latestMarkers, lastChunk)
      })
    } else {
      this.processMarkersToBeUpdated(latestMarkers, lastChunk)
    }
  }



  processMarkersToBeUpdated = (latestMarkers, lastChunk) => {
    if (latestMarkers && Array.isArray(latestMarkers)) {
      if (this.state.isLastChunk) {
        let markers = this.state.mapMarkers
        for (let index = 0; index < latestMarkers.length; index++) {
          const marker = latestMarkers[index]
          const matchedIndex = this.getIndexOfMarker(marker)
          if (matchedIndex != -1) {
            markers.splice(matchedIndex, 1, marker)
          }
        }

        if (lastChunk) {
          this.removeMarkers();
          this.renderMarkers(markers)
        }

        this.setState({
          mapMarkers: markers,
          isUpdate: lastChunk
        })

      } else {
        let currentMarkers = this.state.mapMarkers
        let updatedMarkers = currentMarkers.concat(latestMarkers)
        this.renderMarkers(latestMarkers)
        this.setState({
          mapMarkers: updatedMarkers,
          isUpdate: true,
          isLastChunk: lastChunk
        })

      }
    }
  }

  getIndexOfMarker = (marker) => {
    let matchedIndex = -1
    for (let index = 0; index < this.state.mapMarkers.length; index++) {
      if (this.state.mapMarkers[index].title == marker.title) {
        matchedIndex = index
        break;
      }
    }
    return matchedIndex;
  }

//   initializeMap = () => {
//     const { center, zoom, maxZoom, zoomControl, location, search } = this.props;

//     const timer = setInterval(() => {
//         if (MapmyIndia && MapmyIndia.Map) {
//             clearInterval(timer);

//             // 🌍 Correct Map Initialization
//             this.map = new MapmyIndia.Map(this.mapNode, {
//                 center,
//                 zoom,
//                 maxZoom,
//                 zoomControl,
//                 location,
//                 search
//             });

//             // 🔍 Log available properties and functions
//             console.log("Available MapmyIndia Map properties:", Object.keys(this.map));

//             // 🔍 Check if satellite view is supported
//             if ("vectorLayer" in this.map || "satellite" in this.map) {
//                 console.log("✅ Satellite view is supported!");
//             } else {
//                 console.log("❌ No direct satellite view found in MapmyIndia. Using external tiles.");
//             }

//             this.renderMarkers();
//             this.boundMarkers();
//         }
//     }, 100);
// };



  // toggleLayer = () => {
  //   if (this.map.hasLayer(this.satelliteLayer)) {
  //     this.map.removeLayer(this.satelliteLayer);
  //   } else {
  //     this.satelliteLayer.addTo(this.map);
  //   }
  // };


  initializeMap = () => {
    const {
      center,
      zoomControl,
      location,
      zoom,
      hybrid,
      search,
      maxZoom,

      // Map events
      onResize,
      onZoom,
      onMove,
      onClick,
      onDblclick,
      onMousedown,
      onMouseup,
      onMouseover,
      onMouseout,
      onKeypress
    } = this.props;

    const timer = setInterval(() => {
      let tried = 0;
      if (MapmyIndia && MapmyIndia.Map) {
        clearInterval(timer);
        /**
         * Init Map
         */
        // console.log("zoom control", zoomControl)
        this.map = new MapmyIndia.Map(this.mapNode, {
          center,
          zoomControl,
          location,
          zoom,
          hybrid,
          search,
          maxZoom
        });


        // console.log("Available MapmyIndia Map functions:", Object.keys(this.map));

        if (this.props.vehicleReplayRoute !== undefined && this.props.vehicleReplayRoute) {
          clearInterval(this.interval)
          setInterval(this.interval, 1000)
          this.ReplayRoute(this.props)
        }
        this.renderMarkers();
        this.boundMarkers()
        // this.ReplayRoute()
        if (this.props.stops !== undefined && this.props.stops.length > 0) {
          // console.log('Stop circle', this.props)
          this.removeStopRadius()
          this.stopCircle();
        }
        // console.log('intialize props', this.props)
        this.showCicrle()
        // this.stopCircle();
        // this.DrawPolyLine()             
        /**
         * Attach events
         */
        onResize && this.map.addEventListener("resize", onResize);
        onZoom && this.map.addEventListener("zoom", onZoom);
        onClick && this.map.addEventListener("click", onClick);
        onDblclick && this.map.addEventListener("dblclick", onDblclick);
        onKeypress && this.map.addEventListener("keypress", onKeypress);
        onMousedown && this.map.addEventListener("mousedown", onMousedown);
        onMouseout && this.map.addEventListener("resize", onMouseout);
        onMouseover && this.map.addEventListener("mouseover", onMouseover);
        onMove && this.map.addEventListener("move", onMove);
        onMouseup && this.map.addEventListener("mouseup", onMouseup);
      } else {
        tried++;
        tried === 1500 && clearInterval(timer);
      }
    }, 100);
  };

  
  removeMarkers = () => {
    this.markers.map(mk =>
      this.map.removeLayer(mk)
    );
    this.markers = [];
  };

  removeCluster = () => {
    if (this.cluster) {
      this.map.removeLayer(this.cluster)
    }
  }

  getCornerCoordinate = () => {

    var bounds = this.map.getBounds();
    var NECorner = bounds.getNorthEast();
    var SWCorner = bounds.getSouthWest();

    var data = {
      lat1: NECorner.lat.toString(),
      lon1: SWCorner.lng.toString(),
      lat2: NECorner.lat.toString(),
      lon2: NECorner.lng.toString(),
      lat3: SWCorner.lat.toString(),
      lon3: NECorner.lng.toString(),
      lat4: SWCorner.lat.toString(),
      lon4: SWCorner.lng.toString(),
    }

    this.map.addEventListener("zoom", this.getCornerCoordinate);

    if (this.map.getZoom() >= 12) {
      //this.map.addEventListener("mousedown", this.getCornerCoordinate);
      // this.map.addEventListener("move", this.getCornerCoordinate);

      // console.log("Payload Data:", JSON.stringify(data)); // Print data to console

      ApiUrlCall.sendBalloonPinData(JSON.stringify(data)).then(response => {
        // console.log("response", response)
        if (response.data.response_code === 200 && response.data.is_success) {
          this.setState({
            locationTrack: response.data.location_list,
          }, () => {
            if (this.state.showLocation) {
              this.drawLocation()
            }
          })
        } if (response.data.response_code === 200 && response.data.is_success === false) {
          this.setState({
            openAlert: true,
            errorMessage: "Location Not Available",
          })
        }
      }).catch(error => {
        console.log('error while fetching lat log data', error.response)
      })
    } else {
      this.removeLocation()
      this.setState({
        openAlert: true,
        errorMessage: "Please zoom in to see your locations",
      })
    }
  }


  // clusterMarkers = () => {

  //     this.removeCluster()
  //     this.cluster = new L.markerClusterGroup()

  //     for (let i = 0; i < this.markers.length; i++) {
  //       const element = this.markers[i];

  //       // Check if marker has valid lat/lng properties before adding to the cluster
  //       if (element && element.getLatLng && element.getLatLng()) {
  //         const latLng = element.getLatLng();
  //         if (latLng.lat !== null && latLng.lng !== null) {
  //           this.cluster.addLayer(element)
  //         } else {
  //           console.warn('Marker has invalid latitude or longitude:', element);
  //         }
  //       } else {
  //         console.warn('Invalid marker:', element);
  //       }
  //     }

  //      //this.map.addLayer(this.cluster)

  //     if (this.markers.length > 0) {
  //       this.map.fitBounds(this.cluster.getBounds())
  //     }

  // }

  // clusterMarkers = () => {
  //   // Add a flag to track whether fitBounds has already been called
  //   if (!this.hasBoundsSet) {
  //     this.hasBoundsSet = false; // Initialize the flag if not already present
  //   }

  //   // Remove any existing clusters
  //   this.removeCluster();
  //   this.cluster = new L.markerClusterGroup();

  //   // Iterate through the markers and add them to the cluster group
  //   for (let i = 0; i < this.markers.length; i++) {
  //     const element = this.markers[i];

  //     // Check if marker has valid lat/lng properties before adding to the cluster
  //     if (element && element.getLatLng && element.getLatLng()) {
  //       const latLng = element.getLatLng();
  //       if (latLng.lat !== null && latLng.lng !== null) {
  //         this.cluster.addLayer(element);
  //       } else {
  //         console.warn('Marker has invalid latitude or longitude:', element);
  //       }
  //     } else {
  //       console.warn('Invalid marker:', element);
  //     }
  //   }

  //   // Add the cluster layer to the map
  //   this.map.addLayer(this.cluster);

  //   // Call fitBounds only if it hasn't been set before
  //   if (this.markers.length > 0 && !this.hasBoundsSet) {
  //     this.map.fitBounds(this.cluster.getBounds());
  //     this.hasBoundsSet = true; // Mark bounds as set
  //   }
  // };




  clusterMarkers = () => {
    // Initialize the flag to track if fitBounds has been called
    if (this.hasBoundsSet === undefined) {
      this.hasBoundsSet = false; // Ensures it initializes only once
    }

    this.removeCluster();
    this.cluster = new L.markerClusterGroup();

    // Add all markers to the cluster group
    for (let i = 0; i < this.markers.length; i++) {
      const element = this.markers[i];
      if (element && element.getLatLng && element.getLatLng()) {
        const latLng = element.getLatLng();
        if (latLng.lat !== null && latLng.lng !== null) {
          this.cluster.addLayer(element);
        }
      }
    }

    this.map.addLayer(this.cluster);

    // Call fitBounds only if it has not been called yet
    if (this.markers.length > 0 && !this.hasBoundsSet) {
      this.map.fitBounds(this.cluster.getBounds());
      this.hasBoundsSet = true; // Set flag to true so it doesn't execute again
    }
  };



  drawLocation = () => {
    const array = []
    const icon = [Location_ICON]
    if (this.state.locationTrack) {
      for (let i = 0; i < this.state.locationTrack.length; i++) {
        array.push(
          {
            position: [this.state.locationTrack[i].lattitude, this.state.locationTrack[i].longitude],
            //rotateMarker: true,
            icon: L.icon({
              iconUrl: ApiUrlCall.getLocationIcon(),
              iconRetinaUrl: ApiUrlCall.getLocationIcon(),
              iconSize: [20, 20],
              popupAnchor: [-3, -15],
            }),
            draggable: false,
            setViewFlag: this.state.setView,
            location_id: this.state.locationTrack[i].location_id,
            location_name: this.state.locationTrack[i].location_name,
            onClick: () => { },
            onDragend: () => { }
          }
        )
      }
    }
    this.setState({
      locations: array,
    }, () => {
      if (this.state.flag) {
        //this.removeLocation()
        this.renderLocation()
        //this.removeLocation()
      }
      this.setState({
        flag: true,
      })

    })
  }

  renderLocation = () => {
    $('#processing').hide()
    this.state.locations.map(m => {
      const { position, icon, location_id, location_name } = m
      let mk
      mk = new L.Marker(position, { icon, });
      var infoData = "<div><p><b>Location Id : </b>" + location_id + "</p><p><b>Location Name: </b>" + location_name + "</p></div>"
      mk.bindPopup(infoData);
      this.locations.push(mk);
      this.map.addLayer(mk);
    })
  }

  removeLocation = () => {
    this.locations.map(mk =>
      this.map.removeLayer(mk)
    );
    this.state.locations = [];
  }

  renderMarkers = (newvalue) => {
    //console.log("renderMarkers",newvalue)
    let markers = [];
    let propBoundFlag = undefined;

    if (!newvalue) {
      markers = this.props.markers || [];
    } else if (Array.isArray(newvalue)) {
      markers = newvalue;
    } else {
      markers = newvalue.markers || [];
      propBoundFlag = newvalue.pauseBound;
    }

    if (!this.map) {
      return;
    }



    markers.forEach(m => {
      // console.log("marker",m)
      if (m.position && Array.isArray(m.position)) {
        const {
          battery_percentage, peopleTracking, position, draggable, title, serial_number, latitude, longitude, Last_Reported_Time, icon, onClick, onDragend, setViewFlag, pauseBound, rotateMarker, infoWinData, vehicle_type_id
        } = m;

        let angle = 0; // Default angle

        if (rotateMarker) {
          // Calculate rotation angle
          if (this.previousMarkerPosition == null) {
            this.previousMarkerPosition = position;
          } else {
            const [oldlat, oldlng] = this.previousMarkerPosition;
            const [newlat, newlng] = position;
            const angleDeg = Math.atan2(oldlng - newlng, oldlat - newlat) * 180 / Math.PI;
            angle = (angleDeg === 0) ? this.previousAngle : angleDeg;
            this.previousAngle = angleDeg;
            this.previousMarkerPosition = position;
          }
        }

        // Create marker
        let mk;
        if (icon == '') {
          mk = new L.Marker(position, { rotationAngle: angle, rotationOrigin: "center", draggable, title });
        } else {
          mk = new L.Marker(position, { icon, rotationAngle: angle, rotationOrigin: "center", draggable, title });
        }

        // Prepare popup content
        let popupContent = `<div><p><b>Vehicle name : </b>${title}</p><p><b>Last Reported Time : </b>${Last_Reported_Time}</p></div>`;

        if (peopleTracking) {
          popupContent = `<div><p><b>Name : </b>${infoWinData.name}</p><p><b>Location : </b>${infoWinData.location}</p><p><b>Last Reported : </b>${infoWinData.last_reported}</p></div>`;
        } else {
          if (this.props.jws) {
            popupContent = `<div><p><b>Vehicle name : </b>${title}</p></div>`;
          } else {
            let battery_percentagevalue = '';
            if (battery_percentage || battery_percentage === 0) {
              battery_percentagevalue = `<p><b>Battery Percentage : </b>${battery_percentage} %</p>`;
            }
            popupContent = `<div><p><b>Vehicle name : </b>${title}</p><p><b>Address : </b>${m.Address}</p><p><b>Last Reported Time : </b>${Last_Reported_Time}</p>${battery_percentagevalue}</div>`;
          }
        }

        // Bind popup content
        mk.bindPopup(popupContent);

        // Handle marker click event
        mk.on("click", () => {
          const serialno = serial_number;
          const lat = latitude;
          const lng = longitude;
          const userid = localStorage.getItem('user_id');

          ApiUrlCall.getVehicleInfoData(JSON.stringify({ serialno, lat, lng, userid }))
            .then(response => {
              let updatedpopupContent = popupContent;
              const data = response.data.data;

              if (data.distance_to_nearest_location) {
                const distance_to_nearest_location = data.distance_to_nearest_location;
                const updatedAddress = data.address;
                const nearest_location_id = data.nearest_location_id;
                const battery_percentage = data.battery_percentage;
                const site_types = data.site_type;
                const fse_name = data.fse_name;
                const eco_site = data.eco_site;

                updatedpopupContent = `<div>
                            <p><b>Vehicle name : </b>${title}</p>
                            <p><b>Address : </b>${updatedAddress}</p>
                            <p><b>Last Reported Time : </b>${Last_Reported_Time}</p>
                            <p><b>Battery Percentage : </b>${battery_percentage}%</p>
                            <p><b>Nearest Location Id : </b>${nearest_location_id}</p>
                            <p><b>Distance To Nearest Location : </b>${distance_to_nearest_location}</p>
                            ${site_types ? `<p><b>Site Type : </b>${site_types}</p>` : ''}
                            ${fse_name ? `<p><b>FSE Name : </b>${fse_name}</p>` : ''}
                            ${eco_site ? `<p><b>Eco Site : </b>${eco_site}</p>` : ''}
                        </div>`;

              } else if (data.battery_percentage || data.battery_percentage === 0) {
                const updatedAddress = data.address;
                const battery_percentage = data.battery_percentage;
                const site_types = data.site_types;
                const fse_name = data.fse_name;
                const eco_site = data.eco_site;

                updatedpopupContent = `<div>
                            <p><b>Vehicle name : </b>${title}</p>
                            <p><b>Address : </b>${updatedAddress}</p>
                            <p><b>Last Reported Time : </b>${Last_Reported_Time}</p>
                            <p><b>Battery Percentage : </b>${battery_percentage}%</p>
                            ${site_types ? `<p><b>Site Type : </b>${site_types}</p>` : ''}
                            ${fse_name ? `<p><b>FSE Name : </b>${fse_name}</p>` : ''}
                            ${eco_site ? `<p><b>Eco Site : </b>${eco_site}</p>` : ''}
                        </div>`;
              } else {
                const updatedAddress = data.address;
                const site_types = data.site_types;
                const fse_name = data.fse_name;
                const eco_site = data.eco_site;

                updatedpopupContent = `<div>
                            <p><b>Vehicle name : </b>${title}</p>
                            <p><b>Address : </b>${updatedAddress}</p>
                            <p><b>Last Reported Time : </b>${Last_Reported_Time}</p>
                            ${site_types ? `<p><b>Site Type : </b>${site_types}</p>` : ''}
                            ${fse_name ? `<p><b>FSE Name : </b>${fse_name}</p>` : ''}
                            ${eco_site ? `<p><b>Eco Site : </b>${eco_site}</p>` : ''}
                        </div>`;
              }

              mk.bindPopup(updatedpopupContent, {
                closeOnClick: false,
                autoClose: false
              });

              mk.openPopup();
              setTimeout(() => {
                if (mk.isPopupOpen()) {
                  // Your logic for what to do if the popup is still open after 90 seconds
                }
              }, 90000);
            })
            .catch(error => {
              console.log('Error while fetching data:', error.response);
            });
        });



        if (vehicle_type_id === 16) {
          // if (16 === 16) {






          mk.on("contextmenu", (e) => {
            const popupContent = `
<style>
             .leaflet-popup-content {
              width: 200px !important; /* Reduce width */
              max-width: 200px !important;
            }
          
            .leaflet-popup-content-wrapper {
              padding: 5px !important; /* Reduce padding */
              font-size: 10px !important; /* Reduce text size */
              border-radius: 8px !important;
            }
          
            .leaflet-popup-tip {
              display: none; /* Hide the pointer tip */
            }
            </style>
          
            <div id="popupContainer" style="
                font-size: 10px; 
                font-family: Arial, sans-serif;
                background: #FFF;
                color: #fff;
                padding: 20px;
                border-radius: 12px;
                box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
                text-align: center;
                width: 200px;
                position: relative;
              ">
          
              <h2 style="margin: 0; font-size: 24px bold; color: orange;">Dashcam Control</h2>
          
              <!-- Info Button -->
              <div id="infoButton" style="
                position: absolute; top: 10px; right: 10px;
                background: #555;
                color: white;
                width: 24px; height: 24px;
                display: flex; align-items: center; justify-content: center;
                font-size: 14px;
                border-radius: 50%;
                cursor: pointer;
                transition: background 0.3s;
              "><img src="images/info_icon.png" alt="info"></div>
          
              <!-- Hover Message Box -->
              <div id="infoContainer" style="
                position: absolute; top: 10px; right: -180px;
                background: #444;
                color: white;
                padding: 10px 15px;
                font-size: 12px;
                border-radius: 5px;
                box-shadow: 0px 2px 5px rgba(0,0,0,0.2);
                white-space: nowrap;
                transition: right 0.3s ease-in-out, opacity 0.3s ease-in-out;
                opacity: 0;
                pointer-events: none;
              ">
                Click on the car to view dashcam footage.
              </div>
          
              
          
              <div style="position: relative; width: 140px; height: 200px; margin: auto;">
                <img src="images/vehicle_icon/popup_car.png" alt="Car Image" style="
                  width: 100%; height: 100%; display: block; border-radius: 8px;
                ">
          
                <!-- Polygon overlays for clickable areas -->
                <div id="front_camera" style="
                  position: absolute; top: 7%; left: 25%;
                  width: 50%; height: 23%;
                  background: rgba(255, 255, 255, 0.1);
                  clip-path: polygon(25% 0%, 82% 0%, 100% 100%, 0% 100%);
                  cursor: pointer;
                  transition: background 0.3s, transform 0.2s;
                "></div>
          
                <div id="rear_camera" style="
                  position: absolute; top: 45%; left: 27%;
                  width: 50%; height: 30%;
                  background: rgba(255, 255, 255, 0.1);
                  clip-path: polygon(10% 0%, 90% 0%, 100% 100%, 0% 100%);
                  cursor: pointer;
                  transition: background 0.3s, transform 0.2s;
                "></div>
              </div>
          
              <div style="display: flex; justify-content: space-between; margin-top: 15px; ">
                <button class="playback-btn_all-btn" id="both_camera" style="width: 35%; font-size:15px;">
                  <img src="images/vehicle_icon/videography.png" alt="ALL" style="width: 16px; height: 16px; margin-right: 5px;">
                  ALL
                </button>
          
                <button class="playback-btn_all-btn" id="playback_camera" style="width: 60%; font-size:15px;">
                  <img src="images/vehicle_icon/videography.png" alt="Playback" style="width: 16px; height: 16px; margin-right: 5px;">
                  Playback
                </button>
              </div>
            </div>`;


            // Open Leaflet Popup
            const popup = L.popup().setLatLng(e.latlng).setContent(popupContent).openOn(this.map);

            // Ensure event listeners attach immediately
            setTimeout(() => {
              const infoButton = document.getElementById("infoButton");
              const infoContainer = document.getElementById("infoContainer");

              if (infoButton && infoContainer) {
                // Show info on hover
                infoButton.addEventListener("mouseenter", () => {
                  infoContainer.style.right = "40px";
                  infoContainer.style.opacity = "1";
                });

                // Hide info when mouse leaves
                infoButton.addEventListener("mouseleave", () => {
                  infoContainer.style.right = "-180px";
                  infoContainer.style.opacity = "0";
                });
              }

              const frontCamera = document.getElementById("front_camera");
              const rearCamera = document.getElementById("rear_camera");

              if (frontCamera) {
                // Add hover effect
                frontCamera.addEventListener("mouseenter", () => {
                  frontCamera.style.background = "rgba(255, 255, 255, 0.3)"; // Brighter overlay
                  frontCamera.style.transform = "scale(1.05)"; // Slight pop effect
                });

                frontCamera.addEventListener("mouseleave", () => {
                  frontCamera.style.background = "rgba(255, 255, 255, 0.1)"; // Normal overlay
                  frontCamera.style.transform = "scale(1)"; // Reset scale
                });

                frontCamera.addEventListener("mousedown", () => {
                  frontCamera.style.transform = "scale(0.95)"; // Click effect
                });

                frontCamera.addEventListener("mouseup", () => {
                  frontCamera.style.transform = "scale(1.05)";
                });

                frontCamera.onclick = () => {
                  this.props.onMarkerRightClick({ serial_number, title, camera_options: "front" });
                  this.map.closePopup(popup);
                };
              }

              if (rearCamera) {
                rearCamera.addEventListener("mouseenter", () => {
                  rearCamera.style.background = "rgba(255, 255, 255, 0.3)";
                  rearCamera.style.transform = "scale(1.05)";
                });

                rearCamera.addEventListener("mouseleave", () => {
                  rearCamera.style.background = "rgba(255, 255, 255, 0.1)";
                  rearCamera.style.transform = "scale(1)";
                });

                rearCamera.addEventListener("mousedown", () => {
                  rearCamera.style.transform = "scale(0.95)";
                });

                rearCamera.addEventListener("mouseup", () => {
                  rearCamera.style.transform = "scale(1.05)";
                });

                rearCamera.onclick = () => {
                  this.props.onMarkerRightClick({ serial_number, title, camera_options: "rear" });
                  this.map.closePopup(popup);
                };
              }

              document.getElementById("both_camera").onclick = () => {
                this.props.onMarkerRightClick({ serial_number, title, camera_options: "both" });
                this.map.closePopup(popup);
              };

              document.getElementById("playback_camera").onclick = () => {
                this.props.onMarkerRightClick({ serial_number, title, camera_options: "playback" });
                this.map.closePopup(popup);
              };
            }, 50); // Slight delay to ensure popup renders first
          });


        }
        // Attach event handlers
        onDragend && mk.on("dragend", onDragend);
        onClick && mk.on("click", onClick);

        // Add marker to map or cluster
        if (this.props.cluster) {
          this.markers.push(mk);
        } else {
          this.map.addLayer(mk);
          if (setViewFlag) {
            this.removeCluster();
            this.map.setView(position, 16);
          }
          this.markers.push(mk);
          if (pauseBound) {
            this.boundMarkers();
          }
        }
      }
    });


    if (this.props.cluster) {
      this.clusterMarker(propBoundFlag !== undefined ? propBoundFlag : this.state.mapBoundFlag);
    }

    if (this.state.showLocation) {
      this.getCornerCoordinate();
      this.clusterMarkers();
    }
  }


  clusterMarker = (stopBound) => {
    setTimeout(() => {
      this.removeCluster()
      this.cluster = new L.markerClusterGroup()

      for (let i = 0; i < this.markers.length; i++) {
        const element = this.markers[i];

        // Check if marker has valid lat/lng properties before adding to the cluster
        if (element && element.getLatLng && element.getLatLng()) {
          const latLng = element.getLatLng();
          if (latLng.lat !== null && latLng.lng !== null) {
            this.cluster.addLayer(element)
          } else {
            console.warn('Marker has invalid latitude or longitude:', element);
          }
        } else {
          console.warn('Invalid marker:', element);
        }
      }

      this.map.addLayer(this.cluster)

      if (stopBound && this.markers.length > 0) {
        this.map.fitBounds(this.cluster.getBounds())
      }
    }, 100);
  }



  boundMarkers = () => {
    if (this.props.bounds) {
      const boundLng = this.props.bounds[0].lng
      const boundLat = this.props.bounds[0].lat
      if (boundLat.length > 0 && boundLng.length > 0) {
        var maxlat = Math.max.apply(null, boundLat);
        var maxlon = Math.max.apply(null, boundLng);
        var minlat = Math.min.apply(null, boundLat);
        var minlon = Math.min.apply(null, boundLng);
        var southWest = L.latLng(maxlat, maxlon);
        var northEast = L.latLng(minlat, minlon);
        var Latbounds = L.latLngBounds(southWest, northEast);
        this.map.fitBounds(Latbounds)
      }
    }
  }

  removeRadius = () => {
    this.boundrycircle.map(rr => this.map.removeLayer(rr));
    this.boundrycircle = [];
  }

  showCicrle = (newvalue) => {

    if (this.props.circle) {

      const { circle = [] } = (() => {
        if (newvalue === undefined) {
          return this.props;
        } else {
          return newvalue;
        }
      })()
      circle.map(c => {
        if (c.position && Array.isArray(c.position)) {
          const { color_code, position, radius } = c
          let ck
          if (this.props.jws) {
            ck = L.circle([position[0], position[1]],
              {
                color: 'transparent',
                fillColor: color_code,
                fillOpacity: 0.28,
                radius: radius * 1000
              });
          } else {
            ck = L.circle([position[0], position[1]],
              {
                color: 'transparent',
                fillColor: '#f48221',
                fillOpacity: 0.28,
                radius: radius * 1000
              });
          }
          ck.addTo(this.map);
          // console.log('bound value is', this.map.fitBounds(ck.getBounds()))          
          this.map.fitBounds(ck.getBounds());
          this.boundrycircle.push(ck)
        }
      })
      if (this.props.jws) {
        this.circleFitbound()
      }
    }
  }// console.log('location is', zoom_location)

  removeStopRadius = () => {
    this.stopcircle.map(rr => this.map.removeLayer(rr));
    this.stopcircle = [];
  }

  stopCircle = () => {
    if (this.props.stops) {
      const { stops = [] } = this.props
      stops.map(c => {
        if (c.position && Array.isArray(c.position)) {
          const { position, name, images } = c
          var has_image = false
          var images_element = images.map(img => {
            if (img.url) {
              has_image = true
              return (
                "<div class='stop_img_box'><p>" + img.tag + "</p><img src=" + img.url + "></br><a target='_blank' href=" + img.url + ">image link</a></div>"
              )
            } else {
              return (
                "<div class='stop_img_box'><p>" + img.tag + "</p><img src=" + img.url + "></div>"
              )
            }
          })
          // console.log(images_element,'>>>>>>>>>>>>>>>>>>>>>>>>')
          let ck
          let color
          if (has_image) {
            color = '#000000'
          } else {
            color = '#f48221'
          }
          ck = L.circle([position[0], position[1]],
            {
              color: 'transparent',
              fillColor: color,
              fillOpacity: 0.8,
              radius: 250
            });
          if (this.map !== null) {
            ck.addTo(this.map);
            ck.bindPopup(
              "<div style='width : 150px'>" +
              "<p style='font-size : 15px;'><b>Stop Name</b> : <i>" + name + "</i></p>" +
              "<div class='row' style='max-height : 100px; overflow-y : auto; margin : 0px;'>" + images_element +
              "</div>" +
              "</div>"
            )
            this.stopcircle.push(ck)
          }
        }
      })
    }
  }

  circleFitbound = (position) => {
    setTimeout(() => {
      if (this.props.fitBound.length > 0) {
        if (this.props.fitBound.length === 1) {
          this.map.setView(this.props.fitBound[0], 15)
        } else {
          this.map.fitBounds(this.props.fitBound)
        }
      }
    }, 1000);
  }

  removeTrackingMarkers = () => {
    if (this.tmarker !== undefined && this.tmarker.length > 0) {
      this.tmarker.map(plotmarker => this.map.removeLayer(plotmarker));
      this.tmarker = [];
    }
  };


  TrackingMarker = (trackingmarker, state, angle, name, vehicle_type,serial_numbers) => {
    //  console.log(vehicle_type, "vehicle type")
    // console.log("trackingmarker", state)
    // {console.log("")}

    var icon = L.icon({
      //iconUrl: icon,
      iconUrl: ApiUrlCall.getVehicleIcon(vehicle_type, state),
      //iconRetinaUrl: icon,
      iconRetinaUrl: ApiUrlCall.getVehicleIcon(vehicle_type, state),
      iconSize: [40, 40],
      popupAnchor: [-3, -15],
    })
    if (angle == 0) {
      if (this.previousLiveTrackAngle !== null) {
        angle = this.previousLiveTrackAngle
      }
    }
    let plotmarker = new L.Marker(trackingmarker, { icon: icon, title: name, rotationAngle: angle, rotationOrigin: "center" })
    // this.map.addLayer(plotmarker)
    plotmarker.addTo(this.map)
    // this.stopCircle()
    // plotmarker.leaflet.rotatedMarker.setRotationAngle(180)
    // console.log('panning flag', this.props.panningflag)    
    this.map.setView(trackingmarker, 16)
    // if (this.props.panningflag === undefined) {
    //   this.map.setView(trackingmarker, 16)
    // } else {              
    //     if(this.props.panningflag){
    //       this.map.setView(trackingmarker, 16)
    //     }
    // }
    // this.map.fitBounds(plotmarker.getBounds())
    this.tmarker.push(plotmarker)

    // Add right-click context menu to the marker




  }

  stopAnimationInterval = () => {
    if (this.pathInterval !== null) {
      clearInterval(this.pathInterval);
      this.pathInterval = null;
    }
  }

  DrawPolyLine = (newvalue, name) => {
    var vehicle_type = 1//this.props.polyline.vehicle_type_id
    var serial_numbers = newvalue?.polyline?.serial_number;
    var local_list = localStorage.getItem('tracker_list')
    var marker_line = this.props.markers
    if (marker_line == undefined || marker_line.length == 0) {
      marker_line = this.props.polyline
    }


    if (local_list == null) {
      var data = {}
      ApiUrlCall.getVehcile_Name(data).then(response => {
        if (response.data.response_code == 200 && response.data.is_success) {
          var list = response.data.data.vehicle_list
          localStorage.setItem("tracker_list", JSON.stringify(list))
        }
      })
    } else {
      local_list = JSON.parse(localStorage.getItem('tracker_list'))
      for (let i = 0; i < local_list.length; i++) {
        // console.log(local_list[i] , " SERIAL GOTTE ")
        try {
          if (local_list[i]["serial_number"] == marker_line.serial_number) {
            vehicle_type = local_list[i]["vehicle_type_id"]

            break;
          }
          else{
            vehicle_type = 14
          }

        } catch {

          vehicle_type = 1
        }
      }

    }

    if (this.props.polyline) {
      var vehicle_name
      if (newvalue.polyline.points !== "") {
        var polyline = newvalue.polyline
      } else {
        return
      }
      if (polyline.title !== undefined) {
        vehicle_name = polyline.title
      } else {
        vehicle_name = name
      }
      var divval = polyline.speed
      var oldlat = polyline.points[0].oldlat
      var oldlng = polyline.points[0].oldlng
      var newlat = polyline.points[1].newlat
      var newlng = polyline.points[1].newlng
      var setView = { lat: newlat, lng: newlng }
      var diffLat = newlat - oldlat
      var diffLng = newlng - oldlng
      var divideLat = diffLat / divval
      var divideLng = diffLng / divval
      var state = polyline.state
      //console.log(polyline, " GOTTE POLYLINE ")

      var angleDeg = Math.atan2(oldlng - newlng, oldlat - newlat) * 180 / Math.PI;
      if (this.previousLiveTrackAngle == null) {
        if (angleDeg !== 0) {
          this.previousLiveTrackAngle = angleDeg
        }
      }

      var counter = 0

      this.stopAnimationInterval()
      //   if (replay.flag == true) {
      //     // this.newremoveDrawPolyline();
      //      this.clearReplayRoute();
      //  }
      this.clearReplayRoute();

      //   this.newremoveDrawPolyline()
      this.pathInterval = setInterval(() => {

        counter = counter + 1
        if (counter >= divval) {
          clearInterval(this.pathInterval)
        }
        oldlat += divideLat
        oldlng += divideLng
        newlat = oldlat - divideLat
        newlng = oldlng - divideLng
        var data = [{ "lat": oldlat, "lng": oldlng }, { "lat": newlat, "lng": newlng }]
        var points = [
          new L.LatLng(data[0].lat, data[0].lng),
          new L.LatLng(data[1].lat, data[1].lng),
        ];
        this.removeTrackingMarkers()
        this.TrackingMarker(points[1], state, angleDeg, vehicle_name, vehicle_type, serial_numbers)
        this.path.addLatLng(points[1]).addTo(this.map)
      }, 10)
    }
  }

  getSlidedIndex = (dropindex) => {
    var array = this.pp.map(val => {
      return val[2]
    })
    const closest = array.reduce((a, b) => {
      return Math.abs(b - dropindex) < Math.abs(a - dropindex) ? b : a;
    });
    var returnIndex = array.findIndex(x => x == closest)
    return returnIndex
  }

  getDistance = (newlat, newlng) => {
    var R = 6371; // km
    var dLat = this.toRad(newlat - this.distanceOld[0]);
    var dLon = this.toRad(newlng - this.distanceOld[1]);
    var lat1 = this.toRad(this.distanceOld[0]);
    var lat2 = this.toRad(newlat);

    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    this.distanceOld = [newlat, newlng]
    return d;
  }

  toRad = (Value) => {
    return Value * Math.PI / 180;
  }

  replayRouteVehicelState = (routeData) => {
    if (this.distanceOld !== null) {
      var distance = this.getDistance(routeData.lat, routeData.lng) * 1000
    } else {
      this.distanceOld = [routeData.lat, routeData.lng]
      var icon1 = (() => {
        if (!routeData.ignition_status) {
          return 'images/parking_icon.png'
        } else if (routeData.exc_idling) {
          return 'images/idling.png'
        }
      })()

      if (icon1 != undefined) {

        var icon = L.icon({
          iconUrl: icon1,
          iconRetinaUrl: icon1,
          iconSize: [25, 25],
          popupAnchor: [-3, -15],
        })
        var points = [routeData.lat, routeData.lng];
        let plotmarker = new L.Marker(points, { icon });
        this.map.addLayer(plotmarker)
        this.vehicleStateMarker.push(plotmarker)
      }
    }
    if (parseInt(distance) > 0) {
      var icon1 = (() => {
        if (!routeData.ignition_status) {
          return 'images/parking_icon.png'
        } else if (routeData.exc_idling) {
          return 'images/idling.png'
        }
      })()
      if (icon1 != undefined) {

        var icon = L.icon({
          iconUrl: icon1,
          iconRetinaUrl: icon1,
          iconSize: [25, 25],
          popupAnchor: [-3, -15],
        })
        var points = [routeData.lat, routeData.lng];
        // console.log(">>>>>>>.", points)
        let plotmarker = new L.Marker(points, { icon });
        this.map.addLayer(plotmarker)
        // plotmarker.addTo(this.map)
        // plotmarker.leaflet.rotatedMarker.setRotationAngle(180)        
        // this.map.fitBounds(plotmarker.getBounds())
        this.vehicleStateMarker.push(plotmarker)
      }
    }
  }


  ReplayRoute = (route) => {
    console.log("Assigning value to this.pp:", this.pp, new Error().stack);

    // console.log("ReplayRoute", route);
    console.log("data in PP",this.pp)
    const vehicle_type_replay = route.vehicle_type_id;

    // Initialize a flag to track if the block has already been executed
    if (!this.hasExecutedReplayBlock) {
      this.hasExecutedReplayBlock = false;
    }

    if (route !== undefined) {
      var replay = route.replayroutedata;
      console.log("ReplayRoute for replay", replay);
      console.log("ReplayRoute for replay.replay", replay.replay);


      var pp = this.pp;
      
      var dropindex = replay.index;
      //  console.log("dropindex", dropindex);
      var title = replay.title;

      // Check if the group is 34
      const isGroup = localStorage.getItem('group') == 34;

      // Process replay data only if isGroup is true and the block hasn't executed yet
      if (isGroup && replay && replay.replay && !this.hasExecutedReplayBlock) {
        this.hasExecutedReplayBlock = true; // Mark the block as executed

        let fullPath = [];
        for (let i = 0; i < replay.replay.length; i++) {
          let latLng = [replay.replay[i].lat, replay.replay[i].lng];
          fullPath.push(latLng);
        }

        // Draw the polyline on the map
        if (fullPath.length > 0) {
          this.replaypath.setLatLngs(fullPath).addTo(this.map);
        }

        // Add the first icon marker
        if (replay.replay.length > 0) {
          const firstPoint = replay.replay[0];
          const replaymarker = [firstPoint.lat, firstPoint.lng];
          const vehicle_type = 1; // Default or determine dynamically
          const state = 4; // Default to parked or determine dynamically
          const angle = 0; // Default angle

          var icon = L.icon({
            iconUrl: ApiUrlCall.getVehicleIcon(vehicle_type, state),
            iconRetinaUrl: ApiUrlCall.getVehicleIcon(vehicle_type, state),
            iconSize: [40, 40],
            popupAnchor: [-3, -15],
          });

          let replayicon = new L.Marker(replaymarker, {
            icon: icon,
            title: title,
            rotationAngle: angle,
            rotationOrigin: "center",
          });

          replayicon.addTo(this.map);
          this.map.setView(replaymarker, 16);

          // Store the marker reference for future management
          if (!this.replaymarker) {
            this.replaymarker = [];
          }
          this.replaymarker.push(replayicon);
        }
      }

      // Handle other replay functionalities
      if (!isEqual(dropindex, this.counter)) {
        clearInterval(this.interval);
        this.stopAnimationInterval();
        this.removeDrawPolyline();
        var ppIndex = this.getSlidedIndex(dropindex);
        this.forwardFlag = false;
        var forwardLatLng = pp.slice(0, ppIndex);
        this.counter = ppIndex;
        if (!this.forwardFlag) {
          for (let i = 0; i < forwardLatLng.length; i++) {
            const oldLatLng = {
              lat: forwardLatLng[this.forwardCounter][0],
              lng: forwardLatLng[this.forwardCounter][1],
            };
            this.forwardCounter = this.forwardCounter + 1;
            this.path.addLatLng(oldLatLng).addTo(this.map);
            this.removeTrackingMarkers();
            var ignition_status = pp[this.forwardCounter][3];
            var idling_status = pp[this.forwardCounter][4];
            var state = 4;
            if (ignition_status && !idling_status) {
              state = 2;
            } else if (idling_status) {
              state = 3;
            } else if (!ignition_status) {
              state = 4;
            }

            this.TrackingMarker(oldLatLng, state, 0, '', vehicle_type_replay);
          }
          this.forwardFlag = true;
          this.forwardCounter = 0;
          setInterval(this.interval, 1000);
        }
      }

      if (this.Dataflag && replay.replay !== undefined) {
        for (let i = 0; i < replay.replay.length; i++) {
          if (i < 2) {
            let ignition_status = replay.replay[i].ignition_status;
            let idling_status = replay.replay[i].is_idling;
            let latLng = [
              replay.replay[i].lat,
              replay.replay[i].lng,
              i,
              ignition_status,
              idling_status,
            ];
            pp.push(latLng);
          } else {
            if (replay.replay[i].speed > 0) {
              let ignition_status = replay.replay[i].ignition_status;
              let idling_status = replay.replay[i].is_idling;
              let latLng = [
                replay.replay[i].lat,
                replay.replay[i].lng,
                i,
                ignition_status,
                idling_status,
              ];
              pp.push(latLng);
            } else {
              this.vehicleStateMarker = [];
              this.replayRouteVehicelState(replay.replay[i]);
            }
          }
        }
        this.Dataflag = false;
      }
      // var line = null      
      if (pp.length > 0 && !replay.flag) {
        this.stopAnimationInterval();
        clearInterval(this.interval);
        if (this.counter === pp.length) {
          this.path._latlngs = [];
          this.counter = 0;
        }
      } else {
        if (this.counter <= pp.length && this.forwardFlag) {
          this.interval = setInterval(() => {
            if (pp[this.counter] !== undefined) {
              var oldLatLng = pp[this.counter];
              var newLatLng = pp[this.counter + 1];
              var index = pp[this.counter][2];
              var ignition_status = pp[this.counter][3];
              var idling_status = pp[this.counter][4];
              var state = 4;
              if (ignition_status && !idling_status) {
                state = 2;
              } else if (idling_status) {
                state = 3;
              } else if (!ignition_status) {
                state = 4;
              }
              this.counter = this.counter + 1;
              console.log("old lat lng",oldLatLng)
              console.log("New lat lng",newLatLng)
              console.log("Index ",index)
              console.log("replay data heyyy",this.pp)
              





              replay.sliderValue(index, this.endReplay);
              if (newLatLng !== undefined) {
                var RepalyLatLng = {
                  polyline: {
                    points: [
                      { oldlat: oldLatLng[0], oldlng: oldLatLng[1] },
                      { newlat: newLatLng[0], newlng: newLatLng[1] },
                    ],
                    speed: 100,
                    state: state,
                  },
                };
                this.DrawPolyLine(RepalyLatLng, title);
              }
            } else {
              this.endReplay = true;
              clearInterval(this.interval);
              replay.sliderValue(index, this.endReplay);
              this.endReplay = false;
              return;
            }
          }, route.replayIntervalSpeed);
        }
      }
    }
  };


  clearReplayRoute = () => {
    // Clear the `fullPath` array
    let fullPath = [];

    this.replaypath.removeFrom(this.map);

    // console.log("Replay route cleared, fullPath reset.");


    this.replaymarker.map(replayicon => this.map.removeLayer(replayicon));
    this.replaymarker = [];
    // Remove the replay marker/icon if it exists
    if (this.replayIcon) {
      this.replayIcon.remove(); // Remove the marker from the map
      this.replayIcon = null; // Reset the marker reference
    }

  };


  removeReplayMarker = () => {
    if (this.replayMarker) { // Check if the marker exists
      this.map.removeLayer(this.replayMarker); // Remove the marker from the map
      this.replayMarker = null; // Reset the marker reference
      // console.log("Replay marker removed successfully.");
    } else {
      console.log("No replay marker to remove.");
    }
  };






  removeDrawPolyline = () => {
    this.path._latlngs = []
    this.map.removeLayer(this.path)
  }

  removePolyline = () => {
    this.path._latlngs = []
    this.pp = new Array()
    this.counter = 0
    this.map.removeLayer(this.path)
  }

  removeLiveTrackPoly = () => {
    this.path._latlngs = new Array()
    this.map.removeLayer(this.path)
  }


  render() {
    const { mapWidth, mapHeight } = this.state;
    return (
      <div
        ref={e => (this.mapNode = e)}
        id="map"
        className="map"
        style={{ mapWidth, mapHeight }}
      >
        {/* <button
          onClick={this.toggleLayer}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            zIndex: 1000,
            padding: "8px",
            background: "white",
            border: "1px solid #ccc",
            cursor: "pointer"
          }}
        >
          Toggle Satellite View
        </button> */}
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          autoHideDuration={700}
          onClose={(e, reason) => {
            if (reason === 'clickaway') {
              return;
            }
            this.setState({ openAlert: false })
          }
          }
          open={this.state.openAlert}
          message={this.state.errorMessage}
        />
      </div>
    );
  }
}

Map.defaultProps = {
  center: [19.0760, 72.8777],
  zoomControl: true,
  hybrid: false,
  location: false,
  search: false,
  zoom: 15,
  height: "500px",
  width: "100%",
  markers: [],
  maxZoom: 18
};

Map.propTypes = {
  // map attributes
  center: PropTypes.array,
  zoomControl: PropTypes.bool,
  location: PropTypes.bool,
  height: PropTypes.string,
  width: PropTypes.string,
  zoom: PropTypes.number,
  hybrid: PropTypes.bool,
  search: PropTypes.bool,
  maxZoom: PropTypes.number,

  // Map events
  onResize: PropTypes.func,
  onZoom: PropTypes.func,
  onMove: PropTypes.func,
  onClick: PropTypes.func,
  onDblclick: PropTypes.func,
  onMousedown: PropTypes.func,
  onMouseup: PropTypes.func,
  onMouseover: PropTypes.func,
  onMouseout: PropTypes.func,
  onKeypress: PropTypes.func,

  // onMarkerRightClick: PropTypes.func,

  onMarkerRightClick: PropTypes.string,

  // Markers
  markers: PropTypes.array,
  bounds: PropTypes.array,
  polyline: PropTypes.object,
  replayroutedata: PropTypes.object,
  circle: PropTypes.array,
};
