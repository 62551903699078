import React, { Component } from 'react';
import $ from 'jquery';
import { Grid } from '@mui/material';
import ApiUrlCall from '../API/apiurl';
import Snackbar from '@mui/material/Snackbar';
import analytics from '../../Config/firebase'
import Amplify, { Analytics } from 'aws-amplify';
import awswebapp from '../../aws-exports';
import constant from '../constant.json';
import { withTranslation } from 'react-i18next';

const SUMMARY = "summary_report_schedule"; 
const ALERT_NAME = [{"alert_id" : 14 , "name" : "Ignition"}, {"alert_id" : 6 , "name" : "Idling"}, {"alert_id" : 52 , "name" : "Boundary"},
                    {"alert_id" : 1 , "name" : "Speed"}, {"alert_id" : 4 , "name" : "Late Night Ignition"}] ;

class AlertReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      regionContent: [],
      regionList: '',
      regionCounter: 0,
      regionData: [],
      regionListFlag: false,
      openAlert: false,
      errorMessage: '',
      EmailAlertData: '',
      fleetData: '',
      vehicleList: '',
      AlertConfig: '',
      alertArray : [],
      nextCount : 0,
      searchText : '',
      selectValue : '',
      vehicleArray : [],
      flag : true,
      selectedSerialNumber : ''
    }
  }

  componentDidMount() {
    analytics.logEvent('screen_view',{
      screen_name : "AlertSetting"
    })
    analytics.logEvent('page_view',{
      page_title : "AlertSetting",        
    })
    Amplify.configure(awswebapp);
    Analytics.record({
      name: constant.awsName, 
      // Attribute values must be strings
      attributes: { screen_name: 'WEB_ALERT_SETTING'}
    });
    this.getZoneList()
    this.getUserAlertSetting()
    this.getFleetData()    
    this.getVehicleList()
  }

  addRegion = () => {
    // console.log('add region clicked')
    const {t} = this.props
    const { regionContent, regionList, regionCounter, regionData, regionListFlag } = this.state
    // console.log('in region list data', '..........', regionList.length)
    // this.setState({
    //   regionContent : [],
    //   regionObj : [],
    // })
    if (regionList.length > 0 && regionListFlag) {
      this.setState({ regionList: false })
      // console.log('in region list data', '..........', regionList.length)
      for (let i = 0; i < regionList.length; i++) {
        // console.log(' in for loop')
        const element = regionList[i];
        var regionObj = { id: element.id, name: element.name, address: element.address, lat: element.lat, lng: element.lng, radius: element.radius }
        regionData.push(regionObj)
        regionContent.push(
          <Grid item xl={3} xs={4} style={{ paddingRight: "15px", paddingTop: "15px" }}>
            <input type="text" defaultValue={element.name} style={{ marginBottom: "0px" }} className="vd_input" placeholder={t('ss.as.region')}
              onChange={(e) => {
                const { regionData } = this.state
                regionData[i].name = e.currentTarget.value
                this.setState({
                  regionData,
                }, () => {
                  // console.log('region data is', this.state.regionData)
                })
              }}></input>
          </Grid>
        )
      }
    } else {
      var regionObj = { name: "", address: "", lat: 0.0, lng: 0.0, radius: 0 }
      regionData.push(regionObj)
      regionContent.push(
        <Grid item xl={3} xs={4} style={{ paddingRight: "15px", paddingTop: "15px" }}>
          <input type="text" defaultValue="" style={{ marginBottom: "0px" }} className="vd_input" placeholder={t('ss.as.region')}
            onChange={(e) => {
              const { regionData } = this.state
              regionData[regionCounter].name = e.currentTarget.value
              this.setState({
                regionData,
              }, () => {
                // console.log('region data is', this.state.regionData)
              })
            }}></input>
        </Grid>
      )
      this.setState({ regionCounter: regionCounter + 1 })
    }
    this.forceUpdate()
    // console.log('region content', regionData)
  }


  getZoneList = () => {
    // $('#processing').show()
    ApiUrlCall.getZone().then(response => {
      // console.log('region list is', response)
      if (response.status === 200) {
        // $('#processing').hide()
        this.setState({
          regionList: response.data,
          regionCounter: response.data.length,
          regionListFlag: true,
        }, () => {
          // console.log('00000000000000', this.state.regionList)
          this.addRegion()
        })
      }
    }).catch(error => {
      $('#processing').hide()
      console.log('error while fetching zone list', error)
    })
  }

  createZone = () => {
    var data = this.state.regionData
    $('#processing').show()
    if (data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        const sendData = data[i];
        if ('id' in sendData) {
          // console.log('has key', sendData)
          ApiUrlCall.updateZone(sendData.id, JSON.stringify(sendData)).then(response => {
            $('#processing').hide()
            // console.log(response, "zone created and response is")
          }).catch(error => {
            $('#processing').hide()
            this.setState({
              openAlert: true,
              errorMessage: 'Kindly enter region name'
            })
            console.log('error while creating zone', error.response.data)
          })
        } else {
          // console.log('has no key', sendData)
          ApiUrlCall.createZone(JSON.stringify(sendData)).then(response => {
            $('#processing').hide()
            // location.reload()
            window.location.reload(false);
            // this.getZoneList()
            // console.log(response, "zone created and response is")
          }).catch(error => {
            $('#processing').hide()
            this.setState({
              openAlert: true,
              errorMessage: 'Kindly enter region name'
            })
            console.log('error while creating zone', error)
          })
        }
      }
    } else {
      alert('create zone')
    }
  }

  AlertNameFun = (id) => {
    for (let j = 0; j < ALERT_NAME.length; j++) {
      const element = ALERT_NAME[j];
      if(element.alert_id === id){
        return element.name
      }
    }
    return 
  }

  selectVehicleAlert = (e) => {     
    this.setState({
      alertArray : []          
    })        
    var serial_number = $(e.currentTarget).children(":selected").attr("id")
    this.getUserVehicleAlert(serial_number)
    // console.log('serial number', serial_number)
  }

  createVehicleAlert = () => {
    let { AlertConfig, alertArray } = this.state
    if (AlertConfig) {      
    // $('#alert_id_div').empty()          
    // var alert_name = ALERT_NAME.map(name => { return name})
      for (let i = 0; i < AlertConfig.length; i++) {
        var element = AlertConfig[i];
        var alert_name = this.AlertNameFun(element.alert_id)       
        if(alert_name){          
          alertArray.push(
            <Grid key={i} item xs={3}>
              <label class="checkbox-inline cj_w_header">
                <input type="checkbox" name={element.alert_id}  defaultChecked={element.state === 1 ? 'checked' : ''}
                onChange={this.toggelAlertSetting}></input>{alert_name}
              </label>
            </Grid>
          )
        }else{
          
        }        
      }
      this.forceUpdate()          
    }
  }

  createDropdown = () => {
    const {vehicleList, vehicleArray} = this.state
    for (let i = 0; i < vehicleList.length; i++) {
      const element = vehicleList[i];
      // console.log('vehicle array list', element)
      var data = {name : element.vehicle_name, serial_number : element.serial_number}
      vehicleArray.push(data)
    }
    this.forceUpdate()
    if(vehicleArray.length > 0){
      this.setState({
        selectValue : vehicleArray[0].name,
        selectedSerialNumber : vehicleArray[0].serial_number,
      },() =>{
        if(this.state.flag){
          this.getUserVehicleAlert(vehicleArray[0].serial_number)
        }
      })
    }
  }

  emptyTable = () => {
    this.setState({
      vehicleArray : []
    })
  }

  getVehicleList = () => {
    $('#processing').show()
    var data = {next_count : this.state.nextCount , search : this.state.searchText, search_column: "vehicle_name"}
    // console.log('data is', data)
    ApiUrlCall.getVehicleList(JSON.stringify(data)).then(response => {
      // console.log('vehicle list', response)
      this.emptyTable()
      if (response.data.response_code === 200 && response.data.is_success === true) {
        $('#processing').hide()
        this.setState({
          vehicleList: response.data.data.vehicle_summary_list,
          nextCount : response.data.data.next_count,          
        }, () => {          
          this.createDropdown()          
        })
      }
    }).catch(error => {
      console.log('error while fetching vehicle list', error)
    })
  }


  getUserVehicleAlert = (serial_number) => {
    // console.log('serial_number is', serial_number)
    const data = JSON.stringify({ serial_number: serial_number, mode: 1 })
    ApiUrlCall.getModeConfigData(data).then(response => {
      this.setState({
        AlertConfig: response.data.data.config,
        flag : false
      },() => {        
        this.createVehicleAlert()
      })
      // console.log('config alert is', response)
    }).catch(error => {
      console.log('error while fetching user vehicle alert', error)
    })
  }

  getUserAlertSetting = () => {
    ApiUrlCall.getAlertSetting().then(response => {
      // console.log('alert data is', response)
      if (response.data.response_code === 200 && response.data.is_success === true) {
        this.setState({
          EmailAlertData: response.data.data
        })
      }
    }).catch(error => {
      console.log('error while fetching user alert data', error)
    })
  }

  getFleetData = () => {
    ApiUrlCall.getFleetSummarySetting().then(response => {
      // console.log('response', response)
      if (response.data.response_code === 200 && response.data.is_success === true) {
        this.setState({
          fleetData: response.data.data
        })
      }
    }).catch(error => {
      console.log('error while fetching fleet data', error)
    })
  }

  checkInput = (id) => {    
    var alertInput = $('#' + id)
    var selectInput = this.state.EmailAlertData[id]
    for (let i = 0; i < selectInput.length; i++) {
      $(alertInput).find('[name=' + selectInput[i] + ']').attr("checked", true)
    }    
  }

  emailAlertContent = () => {
    const { EmailAlertData } = this.state
    const {t} = this.props
    let emailContent = []
    if (EmailAlertData) {
      var report = Object.keys(EmailAlertData)      
      if (report.includes(SUMMARY)) {        
        emailContent.push(
          <Grid container id={SUMMARY}>
            <Grid item xs={3}>
              <p className="cj_w_header email_right_text">{t('ars.cust.summary')}</p>
            </Grid>
            <Grid item xs={3}>
              <input type="checkbox" name="1" value=""></input>
            </Grid>
            <Grid item xs={3}>
              <input type="checkbox" name="2" value=""></input>
            </Grid>
            <Grid item xs={3}>
              <input type="checkbox" name="3" value=""></input>
            </Grid>
            {/* <Grid item xs={3}>
              <input type="checkbox" name="4" value=""></input>
            </Grid> */}
          </Grid>
        )
        this.checkInput(SUMMARY)
      } else {
        // console.log('in else statement')
      }
    }
    return emailContent
  }

  savePeriodic = () => {
    var inputParent = $('#'+SUMMARY).find('input:checkbox:checked')
    var alertArray = []
    for (let i = 0; i < inputParent.length; i++) {
      const element = inputParent[i];
      var selectedAlert = $(element).attr('name')
      alertArray.push(selectedAlert)    
    }
     //console.log('data of alert is', alertArray)
    var data = {schedule_list : alertArray}
    ApiUrlCall.savePeriodicReport(JSON.stringify(data)).then(response => {
      // console.log('response of save setting', response)
      this.getUserAlertSetting()
    }).catch(error => {
      console.log('error while updating periodic alert',error)
    })
    // console.log('parent is', inputParent)
  }

  toggelAlertSetting = (e) => {
    var alert_id = $(e.currentTarget).attr('name')    
    var state
    // var serial_number = $("#selected_vehicle").children(":selected").attr("id")
    if($(e.currentTarget).is(':checked')){
      state = 1
    }else{
      state = 0
    }
    var data = {serial_number: this.state.selectedSerialNumber ,state: state,alert_id: alert_id}
    console.log('state is', data)
    ApiUrlCall.toggleAlert(JSON.stringify(data)).then(response => {
      console.log('response is', response)
      $('#processing').show()
      if(response.data.response_code === 200 && response.data.is_success === true){
        $('#processing').hide()
      }else{
        $('#processing').hide()
      }
    }).catch(error => {
      console.log('error while changing the alert setting', error)
    })
  }

  existingVehicle = (e) => {
    var search_text = $(e.currentTarget).val()
    // console.log('search value is', search_text.length)
    if(search_text.length >= 3){
      // console.log('1111111111111')
      $(e.currentTarget).next().slideDown("slow")
      this.setState({
        searchText : search_text,
        nextCount : 0,
        vehicleArray : [],        
      }, () => {
        this.getVehicleList()
      })
    }else if(search_text.length === 0){
      // console.log('2222222')
      $(e.currentTarget).next().fadeOut("slow")
      this.setState({
        searchText : search_text,
        nextCount : 0,
        vehicleArray : [],        
      }, () => {
        this.getVehicleList()
      })
    }
  }

  selectVehicle = (e) => {
      var serial_number = $(e.currentTarget).parent().prop('id')
      var vehicle_name = $(e.currentTarget).text()      
      this.setState({
        selectValue : vehicle_name, 
        alertArray : [],
        selectedSerialNumber : serial_number,   
      },() => {
        $('.dv_dropdow').fadeOut('slow')
        $('.addtional_detail_input').val(vehicle_name)
        this.getUserVehicleAlert(serial_number)
      })
  }

  render() {
    let { fleetData, vehicleList, alertArray, vehicleArray } = this.state
    const {t} = this.props
    if (vehicleList) {
      var vehicleOption = vehicleList.map(list => {
        return (
          <option id={list.serial_number}>{list.vehicle_name}</option>
        )
      })
    }
    

    if(alertArray.length > 0){      
      var alertCotent = alertArray.map(content => {return content})
    }

    if(vehicleArray.length > 0){
      var vehicle_list = vehicleArray.map(data => {
        return(
            <div id={data.serial_number}>
              <p className="cj_w_header" style={{margin : "0px"}} onClick={this.selectVehicle}>{data.name}</p>
              <hr className="drop-hr"/>
            </div>
        )
      })
    }

    // console.log('selected value is', this.state.selectValue)

    return (
      <div className="alert_report_parent">
        <div className="user_profile">
          <p className="p_header">{t('ss.as.fleet')}</p>
          <Grid container>
            <Grid container item xl={2} xs={3} style={{ borderRight: "1px solid #e2e2e2" }}>
              <Grid item xs={5}>
                <img src="images/vehicle-1.png" className="s_center_image" alt="icon"></img>
              </Grid>
              <Grid item xs={7}>
                <p className="renew_modal_header p_margin0">{fleetData.no_of_vehicles < 10 ? '0' + fleetData.no_of_vehicles : fleetData.no_of_vehicles}</p>
                <p className="p_header">{t('ars.sum.v2')}</p>
              </Grid>
            </Grid>
            <Grid container item xl={2} xs={3}>
              <Grid item xs={5}>
                <img src="images/setting_driver.png" style={{ marginTop: "10px" }} className="s_center_image" alt="icon"></img>
              </Grid>
              <Grid item xs={7}>
                <p className="renew_modal_header p_margin0">{fleetData.no_of_drivers < 10 ? '0' + fleetData.no_of_drivers :
                  fleetData.no_of_drivers}</p>
                <p className="p_header">{t('ars.dr.driver')}</p>
              </Grid>
            </Grid>
            <Grid item xs>
              <button className="btn orange-btn" onClick={this.savePeriodic}>{t('ss.as.save')}</button>
            </Grid>
          </Grid>
        </div>

        <div className="user_profile" style={{ marginTop: "40px", marginBottom: "40px" }}>
          <div>
            <p className="p_header">{t('ss.as.report')}</p>
            <label class="alert_report_h">{t('ss.as.email')}</label>
            <div style={{ textAlign: "center", marginTop: "25px" }}>
              <Grid container>
                <Grid item xs={3}>
                  <p className="email_r_header email_right_text">{t('ss.as.type')}</p>
                </Grid>
                <Grid item xs={3}>
                  <p className="email_r_header">{t('ss.as.daily')}</p>
                </Grid>
                <Grid item xs={3}>
                  <p className="email_r_header">{t('ss.as.weekly')}</p>
                </Grid>
                <Grid item xs={3}>
                  <p className="email_r_header">{t('ss.as.monthly')}</p>
                </Grid>
                {/* <Grid item xs={3}>
                  <p className="email_r_header">Quaterly</p>
                </Grid> */}
              </Grid>
              {this.emailAlertContent()}
            </div>
          </div>
          <hr className="cust_hr" />
          <div>
            <Grid container>
              <Grid item xs xl>
                <p className="p_header">{t('ds.noalert.header')}</p>
                <p className="alert_report_h">{t('ss.as.select')}</p>
              </Grid>
              <Grid item xs={3} xl={2} style={{position : "relative"}}>
                {/* <select className="select_dropdown" id="selected_vehicle" style={{ border: "1px solid #eeeeee", background: "transparent" }} onChange={this.selectVehicleAlert}>
                  {vehicleOption}
                </select> */}                                  
                  <input type="text" className="addtional_detail_input" 
                  defaultValue={this.state.selectValue} style={{width : "100%"}} onKeyUp={this.existingVehicle}
                  placeholder="Enter Vehicle Name"></input>
                  <div className="dv_dropdow"  style={{display : "none"}}>                    
                      {vehicle_list}            
                  </div>                
              </Grid>
            </Grid>
            <Grid container spacing={4} id="alert_id_div">
              {alertCotent}
            </Grid>
          </div>
          {/* <div style={{ marginTop: "40px" }}>
            <p className="alert_report_h">Select the types of journey alerts you would like to receive</p>
            <Grid container spacing={4}>
              <Grid item xs={3}>
                <label class="checkbox-inline cj_w_header">
                  <input type="checkbox" value=""></input>Journey Start
                </label>
              </Grid>
              <Grid item xs={3}>
                <label class="checkbox-inline cj_w_header">
                  <input type="checkbox" value=""></input>Journey End
                </label>
              </Grid>
              <Grid item xs={3}>
                <label class="checkbox-inline cj_w_header">
                  <input type="checkbox" value=""></input>Route Diversion
                </label>
              </Grid>
              <Grid item xs={3}>
                <label class="checkbox-inline cj_w_header">
                  <input type="checkbox" value=""></input>Parameter 4
                </label>
              </Grid>
              <Grid item xs={3}>
                <label class="checkbox-inline cj_w_header">
                  <input type="checkbox" value=""></input>Parameter 5
                </label>
              </Grid>
              <Grid item xs={3}>
                <label class="checkbox-inline cj_w_header">
                  <input type="checkbox" value=""></input>Parameter 6
                </label>
              </Grid>
            </Grid>
          </div> */}
          {/* <hr className="cust_hr" style={{ marginTop: "20px" }} />
          <div>
            <p className="p_header" style={{ display: 'inline' }}>{t('ss.as.region1')}</p>
            <button className="orange-btn btn" style={{
              display: this.state.regionCounter > 0
                ? '' : 'none'
            }} onClick={this.createZone}>{t('ss.as.save.region')}</button>            
            <Grid container>
              {this.state.regionContent}
            </Grid>
            <img src="images/add_zone.png" alt="icon" onClick={this.addRegion}></img>
          </div> */}
        </div>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          autoHideDuration={1800}
          onClose={(e, reason) => {
            if (reason === 'clickaway') {
              return;
            }
            this.setState({ openAlert: false })
          }
          }
          open={this.state.openAlert}
          message={this.state.errorMessage}
        />
      </div>
    );
  }
}

export default withTranslation()(AlertReport);