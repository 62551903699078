import React, { Component } from 'react';
import $ from 'jquery';
import { Grid } from '@mui/material';
import ApiUrlCall from '../API/apiurl';
import CommonFunction from '../common_function'
import Snackbar from '@mui/material/Snackbar';
import { withTranslation } from 'react-i18next';
import Calendar from 'react-calendar/dist/entry.nostyle';
import html2canvas from "html2canvas";
var Chart = require("chart.js");
const pdfConverter = require("jspdf");


class ApiReport extends Component {
  constructor(props){
    super(props);
    this.state = {
        startDate : new Date(),
        endDate : new Date(),
        xLable : '',
        charts : [],
        apiList : '',
        openAlert : false,
        errorMessage : '',
        apiData : [],
        reportData : ''
    }
  }

  componentDidMount(){
    //   console.log('component did mount api report')        
    this.getApiDropdownList()
  }

    getApiDropdownList = () => {
        var minDate = new Date(); minDate.setDate(minDate.getDate() - 6);
        ApiUrlCall.getApiList().then(response => {
            // console.log('response api list', response)
            if(response.data.response_code === 200 && response.data.is_success){
                this.setState({
                    apiList : response.data.data.api_list,
                    startDate : minDate
                }, () => {
                    this.state.apiList.map(id => {
                        this.state.apiData.push(id.id)
                        this.forceUpdate()
                        $('#api_select').find('input').prop('checked',true)
                        // console.log('spi data is', this.state.apiData)                    
                        this.generateApiReport()
                    })
                })
            }else{
                this.setState({
                    openAlert : true,
                    errorMessage : response.data.response_message
                })
            }
        }).catch(error => {
            console.log('error while fetching api list', error)
        })
    }

  showStartDate = (e) => {
    $('#end_date_cal').hide()
    $('#api_dropdown').hide()
    $(e.currentTarget).next().slideToggle(200, "swing")
  }
  showEndDate = (e) => {
    $('#start_date_cal').hide()      
    $('#api_dropdown').hide()
    $(e.currentTarget).next().slideToggle(200, "swing")
  }
  
  StartdateClicked = date => {    
    var isoStartDate = date
    // console.log('props value', CommonFunction.getTime(date))

    this.setState({ startDate: isoStartDate })
    $('#start_date_cal').slideUp(200)
  }

  EnddateClicked = date => {
    // var {startDate} = this.state
    var isoStartDate = date
    isoStartDate.setHours(23,59,59,0)   
    // var Difference_In_Time = isoStartDate.getTime() - this.state.startDate.getTime();
    // var Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));
    // if(Difference_In_Days > 7){ 
    //   $('#processing').hide()     
    //   this.setState({
    //     sevenDayFlag : false,   
    //     openAlert : true,
    //     message : "Not allowed to select more than 7 days"     
    //   })     
    //   return 
    // } 
    // console.log('props value', CommonFunction.getTime(date))
    this.setState({ endDate: isoStartDate })
    $('#end_date_cal').slideUp(200)
  }

  dropDown_Api_Click = (e) => {
    $('#start_date_cal').hide()
    $('#end_date_cal').hide() 
    //$('#region_select').slideDown('fast');
    var dropdownwidth = e.currentTarget.offsetWidth
    var checklist = e.currentTarget.nextSibling
    $(checklist).css({
      width: dropdownwidth
    })
    $(checklist).slideToggle(300);
    //$('#vehicles_select').slideUp(200)
  }
    

  stackLable = (id) => {
      const {apiList} = this.state
      if(apiList.length > 0){
          for (let i = 0; i < apiList.length; i++) {
              const element = apiList[i];
            //   console.log(id,'---------',element.name)
              if(id === element.id){
                  return element.name
              }
          }
      }
  }

  stackData = (id) => {
      const {reportData} = this.state      
      if(reportData){          
          var dataArray = []
        //   console.log('report data', reportData)
          for (let i = 0; i < reportData.report_list.length; i++) {
              const element = reportData.report_list[i];
              for (let j = 0; j < element.details.length; j++) {
                  const element1 = element.details[j];
                  if(id === element1.id){
                    //   console.log('count is', element1.count)
                      dataArray.push(element1.count)
                  }
              }
            }
            // console.log('data array is', dataArray)
            return dataArray
        }
  }

  stackXaxis = () => {
      const {reportData} = this.state
      if(reportData){
          var lableArray = []
          for (let i = 0; i < reportData.report_list.length; i++) {
              const element = reportData.report_list[i];
            //   console.log('date is', element.date)
              lableArray.push(CommonFunction.tableDateFormate(element.date))
          }
        //   console.log('label data is', lableArray)
          return lableArray
      }
  }

  generateGraph = () => { 
    if(this.state.charts.length > 0){
        for(var i = 0 ; i<this.state.charts.length; i ++){
            this.state.charts[i].destroy();
        }
    }  
    const {t} = this.props
    var canvas = document.getElementById('api_chart');
    var ctx = canvas.getContext('2d');   
    var color = ["#7ac7a9","#90ca57","#f1d628","#2b8283"]
    var xLable = this.stackXaxis()
    var myChart = new Chart(ctx, {
    type: 'bar',
    data: {
        labels: xLable  ,
        datasets: [
        {
            label: this.stackLable(1),
            data: this.stackData(1),
            backgroundColor: color[0],
        },
        {
            label: this.stackLable(2),
            data: this.stackData(2),
            backgroundColor: color[1],
        },
        {
            label: this.stackLable(3),
            data: this.stackData(3),
            backgroundColor: color[2],
        },
        {
            label: this.stackLable(4),
            data: this.stackData(4),
            backgroundColor: color[3],
        }
        ]
    },
    options: {
        legend: {
            position: "top",
            align : "start",
            labels : {
                boxWidth : 5,
                fontSize : 13,
                usePointStyle : true,
            },
        },
        scales: {
            xAxes: [
                { 
                    stacked: true,
                    ticks: {                        
                        fontSize : 13,
                      },
                      gridLines : {
                        borderDash : [2,2]
                      }
                },
            ],
            yAxes: [
                { 
                    stacked: true,
                    scaleLabel: {
                      display: true,
                      labelString: t('ss.api.yaxes'),
                      fontSize : 13,
                    },
                    ticks : {
                        fontSize : 13,
                      },
                      gridLines : {
                        display : false
                      }
                }
            ]
        }
    }
    });
    this.state.charts.push(myChart)
    this.forceUpdate()
  }

  generateReportPdf() {
    let input = window.document.getElementsByClassName("report_graph")[0];
    // console.log('inout is', input)
    var date = new Date()        
    var name = "API_Report_"+CommonFunction.downloadReportDate(date)+".pdf"    
    html2canvas(input)
      .then(canvas => {
        //   console.log('canvas', canvas)
        const imgData = canvas.toDataURL("image/png");
        const pdf = new pdfConverter("l", "pt");
        pdf.addImage(imgData, "JPEG", 15, 15, 800, 570);
        pdf.save(name);
      })
      .catch(err => console.log(err.message));
  }

  generateApiReport = () => {
      $('#processing').show()
      $('#start_date_cal').hide()
      $('#end_date_cal').hide()
      $('#api_dropdown').hide()
      const {startDate, endDate, apiData} = this.state
      var data = {start_date : CommonFunction.ServerDateTime(startDate), end_date : CommonFunction.ServerDateTime(endDate),
                 api_list : apiData}
        // console.log('api data called')
      ApiUrlCall.generateApiReport(JSON.stringify(data)).then(response => {
        //   console.log('report data is', response)
        if(response.data.response_code === 200 && response.data.is_success){
            $('#processing').hide()
            this.setState({
                reportData : response.data.data
            },() => {
                this.generateGraph()
            })
        }else{
            $('#processing').hide()
            this.setState({
                openAlert : true,
                errorMessage : response.data.response_message
            })
        }

      }).catch(error => {
        $('#processing').hide()
          console.log('error while generating api report', error)
      })
    //   this.generateGraph()
  }

  apiDrop = (e) => {       
    var id = $(e.currentTarget).prop('id')        
    if(e.currentTarget.checked){
        this.state.apiData.push(id)
        this.forceUpdate()
    }else{
        if(this.state.apiData.length > 0){
            this.state.apiData.splice(id,1)
            this.forceUpdate()
        }
    } 
    // console.log('api data is', this.state.apiData)   
  }

  selectAll = (e) => {
    var currParent = $(e.currentTarget).parent().parent().siblings().find('input')
    if(e.currentTarget.checked){
        $(currParent).prop('checked',true)
        if(this.state.apiList){
            this.state.apiData = []
            this.forceUpdate()
            this.state.apiList.map(id => {
                this.state.apiData.push(id.id)                
            })
            this.forceUpdate()
            // console.log('api data', this.state.apiData)
        }
    }else{
        $(currParent).prop('checked',false)
        this.state.apiData = []
        this.forceUpdate()
        // console.log('api data', this.state.apiData)
    }
  }

  render(){
    const {t} = this.props
    const {apiList, reportData} = this.state
    var minDate = new Date(); minDate.setDate(minDate.getDate() - 90);
    if(apiList){
        // console.log('22222222', this.state.stateContent)
        var apiOption = apiList.map(option => {
          return(
            <Grid key={option.id} container className="select_opt_row">
              <Grid item xs={2}>
                <input type="checkbox" onChange={this.apiDrop} id={option.id} name="vehicle_tick"/>
              </Grid>
              <Grid item xs={10}>
                <p className="p_margin0">{option.name}</p>
              </Grid>
            </Grid> 
          )
        })
      } 

      if(reportData){
          var active_count = reportData.active_count
          var total_count = reportData.total_count
      }

    return (
      <div className="api_report_parent" style={{marginBottom : "40px"}}>
          <div className="user_profile">
            <Grid container spacing={2}>
                <Grid item xs>
                    <p>{t('ct.startdate')}</p>
                    <div className="input_div" onClick={this.showStartDate}>
                        <input type="text" placeholder={t('ct.startdate')} id="start_date" disabled value={CommonFunction.dateFormate(this.state.startDate)} className="journey_input"></input>
                        <img src="images/fill-111.png" className="vd_journey_icon"></img>
                    </div>
                    <div className="calendar_parent" id="start_date_cal">
                        <Calendar
                        selectRange={false}
                        onChange={this.StartdateClicked}
                        value={this.state.startDate}
                        maxDate={this.state.date}
                        minDate={minDate}
                        />
                    </div>
                </Grid>
                <Grid item xs>
                    <p>{t('ct.enddate')}</p>
                    <div className="input_div" onClick={this.showEndDate}>
                        <input type="text" placeholder={t('ct.enddate')} id="end_date" disabled value={CommonFunction.dateFormate(this.state.endDate)} className="journey_input"></input>
                        <img src="images/fill-111.png" className="vd_journey_icon"></img>
                    </div>
                    <div className="calendar_parent" id="end_date_cal">
                        <Calendar
                        selectRange={false}
                        onChange={this.EnddateClicked}
                        value={this.state.endDate}
                        maxDate={this.state.date}
                        minDate={minDate}
                        />
                    </div>
                </Grid>
                <Grid item xs>
                    <label className="work_sans_regular16">{t('ss.api.select3')}</label>
                    <div style={{ position: "relative" }} onClick={this.dropDown_Api_Click}>
                        <div className="summary_report_filters" >                  
                        <label>{this.state.apiData.length > 0 ? this.state.apiData.length+" Selected" : t('ss.api.select3')}</label>
                        </div>
                        <i class="fa fa-angle-down search_icon" aria-hidden="true" style={{ color: "#F0762B" }}></i>
                    </div>                   
                    <div className="all_region_dd" style={{zIndex : "9"}} id="api_dropdown">
                        <div className="dropdown_parent" id="api_select">
                        <Grid container className="select_opt_row">
                        <Grid item xs={2}>
                            <input type="checkbox" onChange={this.selectAll} id="all_state" name="vehicle_tick"/>
                        </Grid>
                        <Grid item xs={10}>
                            <p className="p_margin0">{t('ct.all.text')}</p>
                        </Grid>
                        </Grid> 
                        {apiOption}                                                         
                        </div>
                    </div>
                </Grid>
                <Grid item xs>
                    <label>&nbsp;</label>
                    <button className="btn orange-btn btn-block" style={{ marginTop: "5px" }} onClick={this.generateApiReport}>{t('ars.generate')}</ button>
                </Grid>
                <Grid item xs={1} className="pdf_excel_download" onClick={this.generateReportPdf}>
                    <label>&nbsp;</label>
                    <img src="images/group-6-copy-2.png" style={{cursor : "pointer"}}></img>&nbsp;<label className="BSC_semi_bold18">{t('ars.pdf')}</label>
                </Grid>
                {/* <Grid item xs={1} className="pdf_excel_download">
                    <label>&nbsp;</label>
                    <img src="images/group-6-copy-2.png" style={{cursor : "pointer"}}></img>&nbsp;<label className="BSC_semi_bold18">{t('ars.excel')}</label>
                </Grid> */}
            </Grid>
          </div>

          <div style={{marginTop : "30px"}}>
              <Grid container spacing={3}>
                <Grid item xs={3}>
                    <div className="user_profile" style={{textAlign : "center"}}>
                        <p className="p_big_grey">{total_count <= 9 ? "0"+total_count : total_count}</p>
                        <p className="cj_w_header" style={{textTransform : "uppercase"}}>{t('ss.api.hits')}</p>
                        <hr/>
                        <p className="p_big_grey">{active_count <= 9 ? "0"+active_count : active_count}</p>
                        <p className="cj_w_header" style={{textTransform : "uppercase"}}>{t('ss.api.active')}</p>
                        <hr/>
                        <p className="p_big_grey">00</p>
                        <p className="cj_w_header" style={{textTransform : "uppercase"}}>{t('ss.api.avail')}</p>
                    </div>
                </Grid>
                <Grid item xs={9}>
                    <div className="user_profile report_graph">
                        <canvas id="api_chart"></canvas>
                    </div>
                </Grid>
              </Grid>
          </div>
          <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          autoHideDuration={1800}
          onClose={(e, reason) => {
            if (reason === 'clickaway') {
              return;
            }
            this.setState({ openAlert: false })
          }
          }
          open={this.state.openAlert}
          message={this.state.errorMessage}
        />
      </div>
    );
  }
}

export default withTranslation()(ApiReport);
