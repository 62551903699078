import React, { Component } from 'react';
import $ from 'jquery';
import { Grid } from '@mui/material';
import { withTranslation } from 'react-i18next';
import ApiUrlCall from '../API/apiurl';
import Snackbar from '@mui/material/Snackbar';
import CommonFunction from '../common_function'
import analytics from '../../Config/firebase'
import Amplify, { Analytics } from 'aws-amplify';
import awswebapp from '../../aws-exports';
import constant from '../constant.json'


class NewRegion extends Component {
  constructor(props){
    super(props);
    this.state = {
        regionList : '',
        regionCounter : 0,  
        openAlert : false,
        errorMessage : '',
        selectFlag : false, 
        deleteRegion : [] ,
        regionName : '',
        regionId : '',
        buttonText : '',
        vehilcleListData : [],
        DriverListData : [],
        selectedRegionVehicle : [],
        selectedRegionDriver : [],
        regionVehicle : [],
        regionDriver : []
    }
  }

  componentDidMount(){
      analytics.logEvent('screen_view',{
          screen_name : "CreateRegion"
      })
      analytics.logEvent('page_view',{
          page_title : "CreateRegion",        
      })
      Amplify.configure(awswebapp);        
          Analytics.record({
          name: constant.awsName, 
          // Attribute values must be strings
          attributes: { screen_name: 'WEB_CREATE_REGION'}
      });
      this.getZoneList()
  }

  getZoneList = () => {
    $('#processing').show()
    ApiUrlCall.getZone().then(response => {
      console.log('region list is', response)
      if (response.status === 200) {
        $('#processing').hide()
        this.setState({
          regionList: response.data,
          regionCounter: response.data.length,                  
        })
      }
    }).catch(error => {
      $('#processing').hide()
      console.log('error while fetching zone list', error)
    })
  }

  selectRegion = (e) => {
    const {selectFlag, deleteRegion, regionList} = this.state
    var region_id = $(e.currentTarget).prop('id')
    var region_name = $(e.currentTarget).find('.region_name').text()
    if(selectFlag){
        if($(e.currentTarget).hasClass('select_region')){
            $(e.currentTarget).removeClass('select_region')
            var index = deleteRegion.findIndex(x => x === region_id)
            deleteRegion.splice(index,1)
            // console.log('delete region list', deleteRegion)
        }else{
            $(e.currentTarget).addClass('select_region')
            deleteRegion.push(region_id) 
            // console.log('delete region list', deleteRegion)           
        }        
    }else{
        var regionObj = regionList.filter(obj => {            
            return obj.id === parseInt(region_id)
        })        
        this.setState({
            regionName : region_name,
            regionId : region_id,
            buttonText : "Save",
            selectedRegionVehicle : regionObj[0].vehicles,
            selectedRegionDriver : regionObj[0].drivers
        },() => {
            $('.modal_region').val(region_name)
            $('#add_driver_vehicle').slideDown('slow') 
        })
    }
  }

  deleteClick = (e) => {
    var showText = $(e.currentTarget).next()
    this.setState({
        selectFlag : true,    
    })
  }

  deleteNow = () => {
      const {deleteRegion} = this.state
      if(deleteRegion.length > 0){
          for (let i = 0; i < deleteRegion.length; i++) {
              const element = deleteRegion[i];
              ApiUrlCall.deleteZone(element).then(response => {
                // console.log('response is', response)
                
              })
            }
            setTimeout(() => {
                this.setState({
                    selectFlag : false
                },() => {
                    $('.vehicle_sub').removeClass('select_region')
                    this.getZoneList()    
                })
            }, 500*deleteRegion.length);
      }else{
          this.setState({
              openAlert : true,
              errorMessage : "Select atleast one region"
          })
      }
  }

  showRegion = () => {
    this.setState({
        regionName : '',
        regionId : '',
        buttonText : "Add"
    },() => {
        $('.modal_region').val("")
        $('#add_driver_vehicle').slideDown('slow') 
    })
  }

  addNewRegion = (e) => {
    const {regionName, regionId} = this.state      
    var data = { id: regionId, name: regionName, address: "", lat: 0, lng: 0, radius: 0 }
    if(regionId){
        ApiUrlCall.updateZone(regionId, JSON.stringify(data)).then(response => {
            // console.log(response,"response is")
            if(response.status === 200){ 
                $('.modal_region').val("")  
                this.setState({
                    regionName : "",
                    regionId : "",                    
                },() => {
                    $('#add_driver_vehicle').fadeOut('slow')
                    this.getZoneList() 
                })             
            }
        }).catch(error => {
            console.log('error while updating region')
        })
    }else{
        ApiUrlCall.createZone(JSON.stringify(data)).then(response => {
            // console.log('response is', response)
            if(response.status === 200){ 
                $('.modal_region').val("")  
                this.setState({
                    regionName : "",
                    regionId : ""
                },() => {
                    $('#add_driver_vehicle').fadeOut('slow')
                    this.getZoneList() 
                })              
            }
        }).catch(error => {
            console.log('error while creating region')
        })
    }    
    // this.state.regionName = ""
    // this.state.regionId = ""
    // this.forceUpdate()
    // console.log(this.state.regionId,'-----------', this.state.regionName)
  }

  searchVehicle = (e) => {      
      var data = {next_count : 0 , search : e.currentTarget.value}   
      if(e.currentTarget.value.length > 3){
          ApiUrlCall.getVehicleList(JSON.stringify(data)).then(response => {
              if(response.data.response_code === 200 && response.data.is_success){                
                this.setState({
                    vehilcleListData : response.data.data.vehicle_summary_list                
                },() => {
                    $('#vehicle_drop').slideDown()                    
                })                
            }
        }).catch(error => {
            console.log('error while fetching vehicle list', error)
        })
    }else{
        $('#vehicle_drop').slideUp()
    }
  }

  searchDriver = (e) => {
    var data = {next_count : 0 , search : e.currentTarget.value}   
      if(e.currentTarget.value.length > 3){
          ApiUrlCall.getDriverDetails(JSON.stringify(data)).then(response => {
              console.log('resoponse', response)              
              if(response.data.response_code === 200 && response.data.is_success){                
                this.setState({
                    DriverListData : response.data.data.driver_list                
                },() => {
                    $('#driver_drop').slideDown()                    
                })                
            }
        }).catch(error => {
            console.log('error while fetching vehicle list', error)
        })
    }else{
        $('#driver_drop').slideUp()
    }
  }

  selectRegionVehicle = (e) => {
    const {selectedRegionVehicle} = this.state
    var id = $(e.currentTarget).prop('id')
    var name = $(e.currentTarget).text()
    var data = {serial_number : id, name : name}  
    const found = selectedRegionVehicle.some( el => parseInt(el.serial_number) === parseInt(id))  
    console.log('found is',found)
    if(!found){        
        selectedRegionVehicle.push(data)
        this.forceUpdate()
    }else{
        return
    }  
  }

  selectRegionDriver = (e) => {
    const {selectedRegionDriver} = this.state
    var id = $(e.currentTarget).prop('id')
    var name = $(e.currentTarget).text()
    var data = {driver_id : id, name : name}
    const found = selectedRegionDriver.some( el => parseInt(el.driver_id) === parseInt(id))  
    console.log('found is',found)
    if(!found){
        selectedRegionDriver.push(data)
        this.forceUpdate()
    }else{
        return
    }
  }

  render(){
    const {t} = this.props
    const {regionList,vehilcleListData,DriverListData,selectedRegionVehicle,selectedRegionDriver} = this.state
    if(regionList){
        var region_list = regionList.map(data => {
            return(
                <Grid item xl={3} xs={4}>
                    <div id={data.id} style={{cursor : "pointer"}} className="vehicle_sub" onClick={this.selectRegion}>                
                    <Grid container>
                        <Grid item xs={8}>
                        <p className="cj_w_header">{t('ss.reg.name')}</p>                    
                        </Grid>
                        <Grid item xs={4} className="sub_data">
                        <p className="region_name">{data.name}</p>                    
                        </Grid>
                        <Grid item xs={8}>                    
                        <p className="cj_w_header">{t('ss.reg.vehicle')}</p>                    
                        </Grid>
                        <Grid item xs={4} className="sub_data">                    
                        <p>{CommonFunction.zeroAppend(data.vehicles.length)}</p>                    
                        </Grid>
                        <Grid item xs={8}>                    
                        <p className="cj_w_header">{t('ss.reg.driver')}</p>                    
                        </Grid>
                        <Grid item xs={4} className="sub_data">                    
                        <p>{CommonFunction.zeroAppend(data.drivers.length)}</p>                    
                        </Grid>
                        <Grid item xs={8}>                    
                        <p className="cj_w_header">{t('ss.reg.date')}</p>
                        </Grid>
                        <Grid item xs={4} className="sub_data">                    
                        <p>-</p>
                        </Grid>
                    </Grid>
                    {/* <button className="btn orange-btn sub_btn" style={{ float: "none" }}>renew subscription</button> */}
                    </div>
                </Grid>
            )
        })
    }

    if(vehilcleListData.length > 0){
        var vehicleData = vehilcleListData.map(data => {
            return(
                <p id={data.serial_number} onClick={this.selectRegionVehicle}>{data.vehicle_name}</p>
            )
        })
    }else{
        var vehicleData = (() => {
            return(
                <p>No data available</p>
            )
        })()
        
    }
    if(DriverListData.length > 0){
        var driverData = DriverListData.map(data => {
            return(
                <p id={data.id} onClick={this.selectRegionDriver}>{data.driver_name}</p>
            )
        })
    }else{
        var driverData = (() => {
            return(
                <p>No data available</p>
            )
        })()
        
    }

    if(selectedRegionVehicle.length > 0){
        var selVehicle = selectedRegionVehicle.map(data => {
            return(
                <Grid item xs={6} className="content_div">
                    <p>{data.name}</p>
                </Grid>                
            )
        })
    }else{
        var selVehicle = (() => {
            return(
                <p>No Vehicle Assigned</p>
            )
        })()
    }

    if(selectedRegionDriver.length > 0){
        var selDriver = selectedRegionDriver.map(data => {
            return(
                <Grid item xs={6} className="content_div">
                    <p>{data.name}</p>
                </Grid>                
            )
        })
    }else{
        var selDriver = (() => {
            return(
                <p>No Driver Assigned</p>
            )
        })()
    }
    // console.log('region list', this.state.regionList)
    return (
      <div className="new_region">
        <Grid container spacing={2}>
            <Grid item xs>
                <p className="p_header">Total Regions - {CommonFunction.zeroAppend(this.state.regionCounter)}</p>
            </Grid>
            <Grid item xs>
                <button className="btn orange-btn" onClick={this.deleteClick}
                style={{display : this.state.selectFlag || this.state.regionList.length == 0 ? "none" : ""}}>Delete</button>
                <p className="cj_w_header" style={{display : this.state.selectFlag ? "" : "none", textAlign : "right"}}>Please select the region that needs to be deleted</p>
            </Grid>
        </Grid>
        <Grid container spacing={4}>
            {region_list}
        </Grid>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          autoHideDuration={1800}
          onClose={(e, reason) => {
            if (reason === 'clickaway') {
              return;
            }
            this.setState({ openAlert: false })
          }
          }
          open={this.state.openAlert}
          message={this.state.errorMessage}
        />
        <Grid container style={{position : "fixed", bottom : "20px", width : "80%"}}>
          <Grid item xs>
            <img src="images/add_zone.png" alt="icon" style={{display : this.state.selectFlag ? "none" : ""}} onClick={this.showRegion}></img>
          </Grid>
          <Grid item xs>
            <button className="btn orange-btn" onClick={this.deleteNow}
            style={{display : this.state.selectFlag ? "" : "none"}}>Delete</button>
          </Grid>
        </Grid>
        <div id="add_driver_vehicle" className="p_modal_parent" style={{display:"none", left : "0"}}>
            <div className="p_modal_boday" style={{bottom : "auto", top:"100px"}}>
                <p className="modal_header">Create Region</p>
                <Grid container spacing={3}>
                    <Grid item xs>
                        <input text="text" className="vd_input modal_region" placeholder="Enter Region Name" style={{marginBottom : "10px"}}
                        defaultValue={this.state.regionName} onChange={(e) => {
                            this.setState({
                                regionName : e.currentTarget.value
                            })
                        }}></input>     
                    </Grid>
                    <Grid item xs>
                        <button className="btn orange-btn btn-block" style={{float : "none"}}
                        onClick={this.addNewRegion} id={this.state.regionId}>{this.state.buttonText}</button> 
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs style={{position : "relative", borderRight : "1px solid #cccccc"}}>
                        {/* <input text="text" className="vd_input" style={{marginBottom : "0px"}} placeholder="Search Vehicle"
                        onChange={this.searchVehicle} onBlur={(e) => {
                            $('#vehicle_drop').slideUp()
                            $(e.currentTarget).val("")
                        }} disabled></input>
                        <div id="vehicle_drop" className="search-drop">
                            {vehicleData}
                        </div> */}
                        <p className="cj_w_header"><b>Assigned Vehicle</b></p>
                        <hr style={{marginTop : "5px"}}/>
                        <div>
                            <Grid container spacing={2}>
                                {selVehicle}
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs style={{position : "relative"}}>
                        {/* <input text="text" className="vd_input" style={{marginBottom : "0px"}} placeholder="Search Driver"
                        onChange={this.searchDriver} onBlur={(e) => {
                            $('#driver_drop').slideUp()
                            $(e.currentTarget).val("")
                        }} disabled></input>
                        <div id="driver_drop" className="search-drop">
                            {driverData}
                        </div> */}
                        <p className="cj_w_header"><b>Assigned Driver</b></p>
                        <hr style={{marginTop : "5px"}}/>
                        <div>
                            <Grid container spacing={2}>
                                {selDriver}
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
                <div className="close_modal" onClick={() => {
                    $('#add_driver_vehicle').fadeOut("slow")
                }}>
                    <i class="fa fa-close" aria-hidden="true"></i>
                </div>
            </div>
       </div>
      </div>
    );
  }
}

export default withTranslation()(NewRegion);
