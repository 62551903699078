import React, { Component } from 'react';
import $ from 'jquery';
import Grid from '@mui/material/Grid';
import ApiUrlCall from '../../API/apiurl';
import CommonFunction from '../../common_function'
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'mdbreact/dist/css/mdb.css';
import Snackbar from '@mui/material/Snackbar';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { process } from '@progress/kendo-data-query';
import Calendar from 'react-calendar/dist/entry.nostyle'
import { MDBDataTable } from 'mdbreact';
import { withTranslation } from 'react-i18next';

const data1 = [
    {
        label: 'ATTENDANCE DATE',
        field: 'date',
        sort: 'asc',
        width: 200,
        attributes: {
            'aria-controls': 'DataTable',
            'aria-label': 'Name',
        },
    },
    {
        label: 'EMPLOYEE NAME',
        field: 'name',
        sort: 'asc',
        width: 150,
    },
    // {
    //     label: 'ATTENDANCE TYPE',
    //     field: 'type',
    //     sort: 'asc',
    //     width: 200,
    // },
    {
        label: 'MARK IN TIME',
        field: 'in_time',
        sort: 'asc',
        width: 200,
    },
    {
        label: 'MARK IN LOCATION',
        field: 'in_location',
        sort: 'asc',
        width: 250,
    },
    {
        label: 'MARK OUT TIME',
        field: 'out_time',
        sort: 'asc',
        width: 200,
    },
    {
        label: 'MARK OUT LOCATION',
        field: 'out_location',
        sort: 'asc',
        width: 250,
    },
    {
        label: 'WORKING HOURS',
        field: 'working_hour',
        sort: 'asc',
        width: 150,
    }
]


class AttendanceReport extends Component {
    counter = 0
    constructor(props) {
        super(props);
        this.state = {
            date: new Date(),
            StartDate: new Date(),
            EndDate: new Date(),
            datatable: [],
            nextCount: 0,
            totalCount: 0,
            dataCollection: [],
            chunckValue: '',
            searchText: '',
            totalEntries: 0,
            attendanceType: 1,
            openAlert: false,
            errorMessage: '',
            attendanceList: [],
            reportData: [],
            reportNext: 0,
            downloadReportFlag: true,
        }
    }

    componentDidMount() {
        this.setDefaultDate()
    }
    export = () => {
        this._exporter.save();
    }

    setDefaultDate = () => {
        var date = new Date()
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        this.setState({
            StartDate: firstDay,
            EndDate: date
        }, () => {
            this.getAttendanceReport()
            this.createAttendanceReportData()
        })
    }



    createAttendanceReportData = () => {
        const { StartDate, EndDate, attendanceType, reportData, reportNext, searchText } = this.state
        var data = {
            attendance_type: attendanceType, start_date: CommonFunction.ServerDateTime(StartDate), end_date: CommonFunction.ServerDateTime(EndDate),
            next_count: reportNext, search: searchText
        }

        const apiCall = localStorage.getItem('group') == 37 ? ApiUrlCall.ptAttendanceReport37 : ApiUrlCall.ptAttendanceReport;
        // console.log("this is repoerts api call", apiCall)
        // console.log("loacal storage group", localStorage.getItem('group'))

        apiCall(JSON.stringify(data)).then(response => {
            if (response.data.response_code === 200 && response.data.is_success) {
                var data = response.data.data.user_list
                for (let i = 0; i < data.length; i++) {
                    const element = data[i];
                    var row_data = {
                        date: CommonFunction.tableDateFormate(element.login_date) + ", " + CommonFunction.timeFormate(element.login_date),
                        name: element.name, type: this.attendanceType(element.attendance_type),
                        in_time: CommonFunction.tableDateFormate(element.login_date) + ", " + CommonFunction.timeFormate(element.login_date),
                        in_location: element.login_address, out_location: element.logout_address,
                        out_time: CommonFunction.tableDateFormate(element.logout_date) + ", " + CommonFunction.timeFormate(element.logout_date),
                        working_hour: CommonFunction.convertSecToHoursMin(element.duration)
                    }
                    // avg_fuel : element.avg_consumed} 
                    reportData.push(row_data)
                }
                this.forceUpdate()
                if (response.data.data.next_count !== response.data.data.total_count) {
                    this.setState({
                        reportNext: response.data.data.next_count
                    }, () => {
                        this.createAttendanceReportData()
                    })
                } else {
                    $('#export_excel').addClass("enabled-btn")
                    $('#export_excel').removeClass("disable-btn")
                    this.setState({
                        reportNext: 0,
                        downloadReportFlag: true,
                    })
                }
            }
        }).catch(error => {
            console.log('error while getting fuel report', error)
        })
    }

    getAttendanceReport = () => {
        $('#processing').show()
        const { StartDate, EndDate, attendanceType, nextCount, searchText } = this.state
        var data = {
            attendance_type: attendanceType, 
            start_date: CommonFunction.ServerDateTime(StartDate), end_date: CommonFunction.ServerDateTime(EndDate),
            next_count: nextCount, search: searchText
        }
        const apiCall = localStorage.getItem('group') == 37 ? ApiUrlCall.ptAttendanceReport37 : ApiUrlCall.ptAttendanceReport;

        apiCall(JSON.stringify(data)).then(response => {
            console.log('>>>>>>', response)
            $('#processing').hide()
            this.emptyTable()
            if (response.data.response_code == 200 && response.data.is_success) {
                this.setState({
                    attendanceList: response.data.data.user_list,
                    nextCount: response.data.data.next_count,
                    totalCount: response.data.data.total_count,
                    chunckValue: response.data.data.size
                }, () => {
                    this.createTableData()
                })
            } else if (response.data.response_code == 410 && !response.data.is_success) {
                if ($('#pplt_expire_dialog').is(':hidden')) {
                    $('#pplt_expire_dialog').slideDown()
                }
            } else {
                this.setState({
                    openAlert: true,
                    errorMessage: response.data.response_message
                })
            }
        }).catch(error => {
            $('#processing').hide()
            console.log('error while getting attendance report', error)
        })
    }

    StartdateClicked = date => {
        date.setHours(0, 0, 0, 0)
        this.setState({ StartDate: date })
        $('#start_date_cal').slideUp(200)
    }
    showStartDate = (e) => {
        $('#end_date_cal').slideUp(200)
        $(e.currentTarget).next().slideToggle(200, "swing")
    }

    EnddateClicked = date => {
        date.setHours(23, 59, 59, 59)
        this.setState({ EndDate: date })
        $('#end_date_cal').slideUp(200)
    }
    showEndDate = (e) => {
        $('#start_date_cal').slideUp(200)
        $(e.currentTarget).next().slideToggle(200, "swing")
    }

    buttonStatus = () => {
        const { nextCount, totalCount } = this.state
        // console.log(nextCount,'.................',totalCount)
        if (nextCount === totalCount) {
            // console.log('iffffffffff')      
            if (this.counter === 0) {
                $('#dt-previous').removeClass('enabled-btn')
                $('#dt-previous').addClass('disable-btn')
                $('#dt-next').removeClass('enabled-btn')
                $('#dt-next').addClass('disable-btn')
            } else {
                $('#dt-previous').removeClass('disable-btn')
                $('#dt-previous').addClass('enabled-btn')
                $('#dt-next').removeClass('enabled-btn')
                $('#dt-next').addClass('disable-btn')
            }
        } else {
            // console.log('elseeeeeeee', this.counter)
            if (this.counter === 0) {
                // console.log('else 1111111111')
                $('#dt-previous').removeClass('enabled-btn')
                $('#dt-previous').addClass('disable-btn')
                $('#dt-next').removeClass('disable-btn')
                $('#dt-next').addClass('enabled-btn')
            } else {
                // console.log('else 22222222')
                $('#dt-previous').removeClass('disable-btn')
                $('#dt-previous').addClass('enabled-btn')
                $('#dt-next').addClass('enabled-btn')
                $('#dt-next').removeClass('disable-btn')
            }
        }
    }

    entriesData = () => {
        const { nextCount, totalCount, chunckValue } = this.state
        if (nextCount != totalCount) {
            var start = nextCount - chunckValue + 1
            var end = nextCount
            var entries_text = start + '-' + end
            this.setState({
                totalEntries: entries_text
            })
        } else {
            if (nextCount <= 10) {
                if (nextCount === 0) {
                    var entries_text = '0' + '-' + totalCount
                } else {
                    var entries_text = '1' + '-' + totalCount
                }
                this.setState({
                    totalEntries: entries_text
                })
            } else {
                var module = nextCount % chunckValue
                var start
                var end
                var entries_text
                if (module > 0) {
                    start = nextCount - module + 1
                    end = nextCount
                    entries_text = start + '-' + end
                } else {
                    start = nextCount - chunckValue
                    end = nextCount
                    entries_text = start + '-' + end
                }
                this.setState({
                    totalEntries: entries_text
                })
            }
        }
    }

    attendanceType = (value) => {
        if (value == 2) {
            return "Present"
        } else {
            return "Absent"
        }
    }

    createTableData = () => {
        const { attendanceList, datatable } = this.state
        // console.log('lenght is', driverdata.length)
        for (let i = 0; i < attendanceList.length; i++) {
            const element = attendanceList[i];
            var row_data = {
                date: CommonFunction.tableDateFormate(element.login_date) + ", " + CommonFunction.timeFormate(element.login_date),
                name: element.name,
                type: this.attendanceType(element.attendance_type),
                in_time: CommonFunction.tableDateFormate(element.login_date) + ", " + CommonFunction.timeFormate(element.login_date),
                in_location: element.login_address, out_location: element.logout_address,
                out_time: CommonFunction.tableDateFormate(element.logout_date) + ", " + CommonFunction.timeFormate(element.logout_date),
                working_hour: CommonFunction.convertSecToHoursMin(element.duration)
            }
            datatable.push(row_data)
        }
        this.buttonStatus()
        this.entriesData()
        this.forceUpdate()
        // this.intervalData()
    }

    emptyTable = () => {
        this.setState({
            datatable: []
        })
    }

    pageNextClick = () => {
        const { totalCount, nextCount, dataCollection, datatable } = this.state
        // console.log(this.counter,'next clicked',totalCount,'---------',nextCount)
        this.counter = this.counter + 1
        for (let i = 0; i < datatable.length; i++) {
            const element = datatable[i];
            dataCollection.push(element)
        }
        this.setState({
            datatable: []
        }, () => {
            this.getAttendanceReport()
        })
    }

    pagePreviousClick = () => {
        const { dataCollection, chunckValue, nextCount } = this.state
        var pageSize = nextCount % chunckValue
        if (pageSize === 0) {
            pageSize = chunckValue
        }
        // console.log('previous clicked', pageSize)    
        var last = dataCollection.splice(Math.max(dataCollection.length - chunckValue))
        this.setState({
            datatable: last,
            nextCount: nextCount - pageSize
        }, () => {
            this.buttonStatus()
            this.entriesData()
        })
        this.counter = this.counter - 1
    }

    optimizationSearch = (value) => {
        setTimeout(() => {
            this.counter = 0
            if (value.length >= 3) {
                this.setState({
                    datatable: [],
                    dataCollection: [],
                    totalCount: '',
                    chunckValue: '',
                    nextCount: 0,
                    searchText: value
                }, () => {
                    this.getAttendanceReport()
                })
            } else if (value.length === 0) {
                this.setState({
                    datatable: [],
                    dataCollection: [],
                    totalCount: '',
                    chunckValue: '',
                    nextCount: 0,
                    searchText: value
                }, () => {
                    this.getAttendanceReport()
                })
            }
        }, 1000);
    }

    resetStateValue = () => {
        this.counter = 0
        this.setState({
            datatable: [],
            dataCollection: [],
            totalCount: '',
            chunckValue: '',
            nextCount: 0,
            reportNext: 0,
            reportData: []
        }, () => {
            this.counter = 0
            this.getAttendanceReport()
            this.createAttendanceReportData()
        })
    }

    selectFilter = (e) => {
        this.setState({
            attendanceType: e.currentTarget.value
        })
    }

    createReport = () => {
        this.resetStateValue()
    }

    render() {
        const { t } = this.props
        var excel_name = "Attendance_Report" + CommonFunction.downloadReportDate(this.state.date) + ".xlsx"
        if (this.state.downloadReportFlag) {
            var excel_data = process(this.state.reportData, {
                // group: group
            }).data;
            console.log('excel data is', excel_data)
            console.log('Report name is', this.state.reportData)
        }
        return (
            <div className="attendance_report">
                <div className="report_filter_div">
                    <Grid spacing={3} container>
                        <Grid item xs={3}>
                            <p>Attendance Start Date</p>
                            <div className="input_div" onClick={this.showStartDate}>
                                <input type="text" placeholder={t('ct.startdate')} id="start_date" disabled value={CommonFunction.dateFormate(this.state.StartDate)} className="journey_input"></input>
                                <img src="images/fill-111.png" className="vd_journey_icon"></img>
                            </div>
                            <div className="calendar_parent" id="start_date_cal">
                                <Calendar
                                    selectRange={false}
                                    onChange={this.StartdateClicked}
                                    value={this.state.StartDate}
                                    maxDate={this.state.date}
                                />
                            </div>
                        </Grid>
                        <Grid item xs={3}>
                            <p>Attendance End Date</p>
                            <div className="input_div" onClick={this.showEndDate}>
                                <input type="text" placeholder={t('ct.enddate')} id="end_date" disabled value={CommonFunction.dateFormate(this.state.EndDate)} className="journey_input"></input>
                                <img src="images/fill-111.png" className="vd_journey_icon"></img>
                            </div>
                            <div className="calendar_parent" id="end_date_cal">
                                <Calendar
                                    selectRange={false}
                                    onChange={this.EnddateClicked}
                                    value={this.state.EndDate}
                                    maxDate={this.state.date}
                                />
                            </div>
                        </Grid>

                        <Grid item xs={2} display={localStorage.getItem('group') == 37 ? "none" : "block"}>
                            <p>Attendance Type</p>
                            <select className="status-filter" style={{ position: "relative", width: "100%" }}
                                onChange={this.selectFilter}>
                                <option value="1">All</option>
                                <option value="2">Present</option>
                                <option value="3">Absent</option>

                            </select>
                        </Grid>
                        <Grid item xs>
                            <p>&nbsp;</p>
                            <button className="btn orange-btn" style={{ marginTop: "0px" }} onClick={this.createReport}>{t('ars.generate')}</ button>
                        </Grid>
                    </Grid>
                </div>
                <div style={{ marginTop: "40px" }}>
                    <button className=" btn orange-btn" onClick={this.export} id="export_excel" style={{ position: "absolute", boxShadow: "none", borderRadius: "5px" }}>Download Excel</button>
                    <ExcelExport
                        data={excel_data}
                        fileName={excel_name}
                        ref={(exporter) => { this._exporter = exporter; }}>
                        <ExcelExportColumn field="date" title="ATTENDANCE DATE" />
                        <ExcelExportColumn field="name" title="EMPLOYEE NAME" />
                        {/* <ExcelExportColumn field="type" title="ATTENDANCE TYPE" /> */}
                        <ExcelExportColumn field="in_time" title="MARK IN TIME" />
                        <ExcelExportColumn field="in_location" title="MARK IN LOCATION" />
                        <ExcelExportColumn field="out_time" title="MARK OUT TIME" />
                        <ExcelExportColumn field="out_location" title="MARK OUT LOCATION" />
                        <ExcelExportColumn field="working_hour" title="WORKING HOURS" />
                    </ExcelExport>
                    <MDBDataTable
                        displayEntries={false}
                        paging={false}
                        scrollX
                        entries={10}
                        striped
                        bordered
                        data={{ columns: data1, rows: this.state.datatable }}
                        onSearch={this.optimizationSearch}
                    />
                    <Grid container style={{ margin: "20px 0px" }}>
                        <Grid item xs>
                            <p className="cj_w_header"><b>Entries {this.state.totalEntries} of {this.state.totalCount}</b></p>
                        </Grid>
                        <Grid item xs style={{ textAlign: "right" }}>
                            <button className="btn disable-btn" id="dt-previous" onClick={this.pagePreviousClick}>Previous</button>
                            <button className="btn disable-btn" id="dt-next" onClick={this.pageNextClick}>Next</button>
                        </Grid>
                    </Grid>
                </div>
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    autoHideDuration={1200}
                    onClose={(e, reason) => {
                        if (reason === 'clickaway') {
                            return;
                        }
                        this.setState({ openAlert: false })
                    }
                    }
                    open={this.state.openAlert}
                    message={this.state.errorMessage}
                />
            </div>
        );
    }
}

export default withTranslation()(AttendanceReport);